import React from 'react';
import {
  List,
  Edit,
  Create,
  TextField
} from 'react-admin';
import GeneralPagination from 'components/GeneralPagination';
import CompanyCreateForm from './Forms/CompanyCreateForm';
import CompanyEditForm from './Forms/CompanyEditForm';
import CustomAction from './Actions/index';
import { CustomDataGrid } from 'components/DataGrid/index';
import l18n from 'containers/I18nProvider/index';

const CompanyList = (props) => (
  <List
    {...props}
    actions={<CustomAction />}
    pagination={<GeneralPagination />}
    className="table-responsive"
    bulkActionButtons={false}
  >
    <CustomDataGrid edit="" delete="true">
      <TextField source="id" />
      <TextField source="companyCode" />
      <TextField source="companyName" />
      <TextField
        source="operation"
        sortable={false}
        label={l18n.translate('ra.action.operation')}
        className="operation"
      />
    </CustomDataGrid>
  </List>
);

const CompanyCreate = (props) => (
  <Create {...props} className="table-responsive">
    <CompanyCreateForm />
  </Create>
);

const CompanyEdit = (props) => {
  return (
    <Edit {...props} undoable={false} className="table-responsive">
      <CompanyEditForm />
    </Edit>
  );
};

export {
  CompanyList as List,
  CompanyCreate as Create,
  CompanyEdit as Edit
};
