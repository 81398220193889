/*
 *
 * GlobalFilter actions
 *
 */

import {
  SET_CURRENT_COMPANY_SUCCESS,
  AVAILABLE_COMPANIES_SUCCESS,
  REHYDRATE_CURRENT_COMPANY,
  LOAD_CURRENT_SHOP,
  CURRENT_SHOP_SUCCESS,
  CURRENT_SHOP_ERROR,
  AVAILABLE_SHOPS_SUCCESS,
  HIDE_BREADCRUMB_SHOP,
  SHOW_BREADCRUMB_SHOP,
  CLEAR_GLOBAL_FILTER_STATE
} from './constants';

export function setCurrentCompanySuccess(company) {
  return {
    type: SET_CURRENT_COMPANY_SUCCESS,
    company
  };
}

export function loadAvailableCompaniesSuccess(availableCompanies) {
  return {
    type: AVAILABLE_COMPANIES_SUCCESS,
    availableCompanies
  };
}

export function rehydrateCurrentCompany() {
  return {
    type: REHYDRATE_CURRENT_COMPANY
  };
}

export function loadCurrentShop() {
  return {
    type: LOAD_CURRENT_SHOP
  };
}

export function setCurrentShopSuccess(shop) {
  return {
    type: CURRENT_SHOP_SUCCESS,
    shop
  };
}

export function loadAvailableShopsSuccess(availableShops) {
  return {
    type: AVAILABLE_SHOPS_SUCCESS,
    availableShops
  };
}

export function loadCurrentShopError() {
  return {
    type: CURRENT_SHOP_ERROR
  };
}

export function hideBreadcrumbShop() {
  return {
    type: HIDE_BREADCRUMB_SHOP
  };
}

export function showBreadcrumbShop() {
  return {
    type: SHOW_BREADCRUMB_SHOP
  };
}

export function clearGlobalFilter() {
  return {
    type: CLEAR_GLOBAL_FILTER_STATE
  };
}
