import { PATTERN_LOGIN_ID, PATTERN_PASSWORD } from 'containers/Common/patterns';

/**
 * [Get validation schema for login]
 * @param  {[Object]} values [Form input]
 * @return {[Object]} schema
 */
const getLoginValidationSchema = () => {
  const schema = {
    loginId: { required: true, length: [3, 30], pattern: PATTERN_LOGIN_ID },
    password: { required: true, length: [8, 50], pattern: PATTERN_PASSWORD }
  };

  return schema;
};

export { getLoginValidationSchema };
