import React from 'react';
import Loading from 'components/LoadingIndicator';
import {
  TableRow,
  TableCell,
  Checkbox
} from '@material-ui/core';
import {
  Datagrid,
  DatagridBody,
  EditButton,
  DeleteWithConfirmButton,
  useRefresh,
  useNotify
} from 'react-admin';
import * as Constants from 'containers/Common/constants';
import { authProvider } from 'providers/auth.provider';
import classnames from 'classnames';

const CustomDatagridRow = ({ ...props }) => {
  const refresh = useRefresh(),
    notify = useNotify(),
    {
      record,
      resource,
      id,
      onToggleItem,
      children,
      selected,
      basePath,
      hasBulkActions
    } = props,
    account = props['account'];

  let customChildren = [...children],
    hideEdit = props['edit'],
    hideDelete = props['delete'];
  if (!hideEdit || !hideDelete) {
    // Remove operation column
    customChildren.pop();
  }

  // Check hide button delete by resource by record data
  let isEditProfile = false;
  if (!hideDelete) {
    switch (resource) {
      case Constants.RESOURCE_USERS:
        // In case of logged in account.id === id of list account, hide delete button
        if (account.id === record.id) {
          hideDelete = 'true';
          isEditProfile = true;
        }
        break;
    }
  }

  // Set flag edit only
  let isEditOnly = false;
  if (!hideEdit && hideDelete) {
    isEditOnly = true;
  }

  // Set flag no output table cell operation
  let isHideAll = false;
  if (hideEdit && hideDelete) {
    isHideAll = true;
  }

  const onDeleteSuccess = (data) => {
    notify('ra.notification.deleted', 'info', {
      attribute: id
    });
    refresh();
  };

  const onDeleteFailure = (error) => {
    notify('ra.common.fatalError', 'error');
  };

  return (
    <TableRow key={id}>
      {hasBulkActions && (
        <TableCell padding="none">
          <Checkbox
            disabled={record.selectable}
            checked={selected}
            onClick={() => onToggleItem(id)}
          />
        </TableCell>
      )}
      {React.Children.map(customChildren, field => (
        (field && (
          <TableCell key={`${id}-${field.props.source}`}>
            {React.cloneElement(field, {
              record,
              basePath,
              resource
            })}
          </TableCell>
        ))
      ))}

      {!isHideAll && (
        <TableCell key="operation" className={classnames('operation', isEditOnly ? ' edit-only' : '')}>
          {!hideEdit && (
            (isEditProfile ? (
              React.cloneElement(<EditButton basePath="/auth/profile" variant="contained" disableElevation/>, {
                record: {id: ''},
                basePath: 'auth/profile',
                resource: Constants.RESOURCE_AUTH_PROFILE
              })
            ) : (
              React.cloneElement(<EditButton basePath={basePath} variant="contained" disableElevation/>, {
                record,
                basePath,
                resource
              })
            ))
          )}
          {!hideDelete && (
            React.cloneElement(<DeleteWithConfirmButton onSuccess={onDeleteSuccess} onFailure={onDeleteFailure} />, {
              record,
              basePath,
              resource
            })
          )}
        </TableCell>
      )}
    </TableRow>
  )
};

const CustomDatagridBody = (props) => (
  <DatagridBody {...props} row={
    <CustomDatagridRow
      edit={props.edit}
      delete={props.delete}
      account={props.account} 
    />
  } />
);

export const CustomDataGrid = (props) => {
  if (props.loading) return <Loading />

  // Get account info to use check record data
  const accountInfo = authProvider.getAccountInfo();

  return <Datagrid {...props} body={
      <CustomDatagridBody
        edit={props.edit}
        delete={props.delete}
        account={accountInfo}
      />
    } />;
};
