import React from 'react';
import AppBar from 'containers/App/customAppBar';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { Redirect } from 'react-router';
import l18n from 'containers/I18nProvider';

export const ErrorPage = (props) => {
    document.title = document.title = process.env.REACT_APP_WEBSITE_NAME + ' - ' + props.title;

    return (
        <div>
            <AppBar isError="true" />
            <div className="error-page">
                <h1><span>{ props.subTitle }</span></h1>
                <p>{ props.description }</p>
                <Link to="/dashboard">
                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-default"
                        startIcon={<ArrowBack />}
                    >
                        {l18n.translate('ra.action.back')}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export const NotFoundPage = () => {
    return <Redirect to="/404" />;
};
