import React from 'react';
import {
  NumberInput,
  SelectInput,
  ReferenceInput,
  useCreate,
  useNotify,
  useRefresh,
  SaveButton,
  Toolbar
} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
import { Cancel } from '@material-ui/icons';
import { barrelStatus } from '../constants';
import l18n from 'containers/I18nProvider';
import { handleSubmitFactory } from 'containers/Common/helper';
import TableForm from 'components/TableForm';

const BarrelCreateForm = ({ ...props }: any) => {
  let state = props.state;
  const onCloseDialogue = () => {
      props.setState({
        showDialogue: false,
        shopId: state?.shopId
      });
    },
    refresh = useRefresh(),
    [localState, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    [create] = useCreate('tap-beer-server'),
    notify = useNotify(),
    onTransform = (values) => {
      values['shop'] = { id: state?.shopId };
      values['device'] = { id: props.record.id };
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource: 'tap-beer-server',
      buildValidationSchema: () => ({}),
      setState,
      notify,
      redirectTo: () => ({}),
      onSubmit: create,
      onTransform: onTransform,
      state: localState,
      successMsgKey: 'created',
      onSuccessCallback: () => {
        onCloseDialogue();
        refresh();
        return {};
      }
    });

  return (
    <Dialog
      fullWidth
      open={Boolean(state.showDialogue)}
      onClose={onCloseDialogue}
    >
      <DialogTitle>
        {l18n.translate('resources.devices.fields.beerServer.createTitle')}
      </DialogTitle>
      <DialogContent>
        <TableForm
          resource="tap-beer-server"
          save={handleSubmit}
          record=""
          toolbar={
            <Toolbar {...props}>
              <Button
                variant="contained"
                color="primary"
                className="btn-default"
                startIcon={<Cancel />}
                onClick={onCloseDialogue}
                data-cy="cancelEditBarrelButton"
                style={{ marginRight: '1rem' }}
              >
                {l18n.translate('ra.action.cancel')}
              </Button>
              <SaveButton
                className="btn-primary btn-save"
                data-cy="submitAddBarrelButton"
              />
            </Toolbar>
          }
        >
          <ReferenceInput
            source="beerServer.id"
            reference="beer-servers"
            className="form-control no-help-block"
            title={l18n.translate(
              'resources.devices.fields.beerServer.serverCode'
            )}
          >
            <SelectInput optionText="serverCode" />
          </ReferenceInput>

          <ReferenceInput
            source="tapBeer.id"
            reference="tap-beers"
            className="form-control no-help-block"
            title={l18n.translate(
              'resources.devices.fields.beerServer.beerName'
            )}
          >
            <SelectInput optionText="beerName" />
          </ReferenceInput>

          <NumberInput
            source="originalAmount"
            className="form-control"
            min={0}
            helperText={l18n.translate('ra.helpBlock.numberOnly')}
            title={l18n.translate('resources.devices.fields.beerServer.amount')}
          />

          <NumberInput
            source="remainingAmount"
            className="form-control"
            min={0}
            helperText={l18n.translate('ra.helpBlock.numberOnly')}
            title={l18n.translate(
              'resources.devices.fields.beerServer.remainingAmount'
            )}
          />

          <NumberInput
            source="outStandardDisplay"
            className="form-control"
            min={0}
            helperText={l18n.translate('ra.helpBlock.numberOnly')}
            title={l18n.translate(
              'resources.devices.fields.beerServer.beerOutStandard'
            )}
          />

          <SelectInput
            source="maintainFlag"
            choices={barrelStatus}
            className="form-control no-help-block"
            title={l18n.translate('resources.devices.fields.beerServer.status')}
          />
        </TableForm>
      </DialogContent>
    </Dialog>
  );
};

export default BarrelCreateForm;
