const messages = {
  'sale-logs': {
    name: 'Sale Log List',
    fields: {
      paymentMethod: 'Payment Method',
      totalSellingPrice: 'Total Selling Price',
      totalTax: 'Tax',
      payment_house_money: 'House money',
      payment_cash: 'Cash',
      payment_emoney: 'Electronic money',
      payment_credit_card: 'Credit card',
      payment_qr: 'QR',
      payment_others: 'Other',
      saleLogDetails: {
        goodsId: 'Goods Name',
        tapBeerId: 'Beer Name',
        amount: 'Amount',
        sellingPrice: 'Selling Price',
        purchaseCost: 'Purchase Cost',
        subtotal: 'Subtotal'
      }
    },
    saleTab: 'セール',
    topUpTab: '預り金'
  }
};

export default messages;
