const messages = {
  goods: {
    name: 'Product List',
    createTitle: 'Create Product',
    updateTitle: 'Update Product',
    fields: {
      id: 'ID',
      goodsCode: 'Product Code',
      janCode: 'JAN Code',
      goodsName: 'Product Name',
      goodsShortName: 'Product Short Name',
      purchaseCost: 'Purchase Cost(Company)',
      sellingPrice: 'Selling Price(Company)',
      imageUrl: 'Product Image(Company)',
      company: {
        id: 'Affiliation Company',
        image: 'Product Image(Company)',
        description: 'Product Description(Company)',
        purchaseCost: 'Purchase Cost(Company)',
        sellingPrice: 'Selling Price(Company)',
        companyName: 'Affiliation Company'
      },
      shop: {
        image: 'Product Image(Shop)',
        description: 'Product Description(Shop)',
        purchaseCost: 'Purchase Cost(Shop)',
        sellingPrice: 'Selling Price(Shop)',
        handlingFlag: 'Handling Flag',
        soldOutFlag: 'Sold Out Flag'
      },
      goodsTypeObj: {
        beer: 'Beer',
        food: 'Food'
      },
      handlingFlagObj: {
        have: 'Yes'
      },
      soldOutFlagObj: {
        soldOut: 'Sold Out'
      },
      taxReduction: 'Tax Reduction',
      group: 'Group Name',
      section: 'Section Name'
    },
    filter: 'Filter Area',
    validation: {
      goodsCode: {
        format: 'Please use alphabet, number only for Goods Code.'
      },
      image: {
        error: 'Can not convert the image. Please select the another image.'
      }
    },
    searchFilterLabel: 'Filter',
    goodsNameInKana: 'Goods Name In Kana',
  },
  'goods-shop': {
    name: 'Goods Shop',
    createTitle: 'Create Goods Shop',
    updateTitle: 'Update Goods Shop'
  }
};

export default messages;
