import React from 'react';
import { ReduxState } from 'ra-core';
import { useSelector } from 'react-redux';
import Loading from 'components/LoadingIndicator';
import { useSafeSetState } from 'react-admin';
import { AlertCommon } from './alert';
import { onFailureCallback } from './helper';

export const loadGlobalFilter = (BaseComponent) => (props) => {
  const globalFilterReducer = useSelector(
    (state: ReduxState) => state.globalFilterReducer
  );
  const { loadingCompany, loadingShop } = globalFilterReducer;

  if (loadingCompany || loadingShop) {
    return <Loading />;
  }

  return <BaseComponent {...props} globalFilterReducer={globalFilterReducer} />;
};

export const errorMessageWrap = (BaseComponent) => (props) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    onFailure = (error) => {
      onFailureCallback(error, props.resource, setState, state);
    };

  return (
    <React.Fragment>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />
      <BaseComponent {...props} onFailure={onFailure} />
    </React.Fragment>
  );
};
