const messages = {
  groups: {
    name: 'グループ部門一覧',
    createTitle: 'グループ作成',
    updateTitle: 'グループの更新',
    fields: {
      id: 'ID',
      groupCode: 'グループコード',
      groupName: 'グループ名',
      taxReduction: '軽減税率',
      company: {
        companyName: '会社名'
      }
    }
  }
};

export default messages;
