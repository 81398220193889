import produce from 'immer';
import { UPDATE_TABLET_STATUS } from '../Actions/tabletSurvivalSignal';
import { TabletData } from '../interfaces';

const initialState: {
  tablets: TabletData[];
} = {
  tablets: []
};

const tabletSurvivalSignalReducer = (
  state = initialState,
  { type, payload }
) =>
  produce(state, (draft) => {
    switch (type) {
      case UPDATE_TABLET_STATUS:
        draft.tablets = payload;
        break;
    }
  });

export default tabletSurvivalSignalReducer;
