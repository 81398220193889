import React, { Children } from 'react';
import { TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';

const MyDatagridRow = ({ records, resource, id, children, basePath }) => (
  <>
    <TableHead key="tableHead">
      <TableRow>
        {Children.map(
          children,
          (field) =>
            field && (
              <TableCell key={`${id}-${field.props?.source}`}>
                {field.props?.label || field.props?.source}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
    <TableBody>
      {records.map((record) => (
        <TableRow key={record.id}>
          {/* data columns based on children */}
          {Children.map(
            children,
            (field) =>
              field && (
                <TableCell key={`${id}-${field.props?.source}`}>
                  {React.cloneElement(field, {
                    record,
                    basePath,
                    resource
                  })}
                </TableCell>
              )
          )}
        </TableRow>
      ))}
    </TableBody>
  </>
);

export default MyDatagridRow;
