import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput
} from 'react-admin';
import FilterCommon from 'components/Filters/index';
import l18n from 'containers/I18nProvider/index';
import SectionInput from 'components/SectionInput';

const CustomFilter = () => {
  return (
    <FilterCommon
      title={l18n.translate('resources.goods.filter')}
      searchLabel={l18n.translate('resources.goods.searchFilterLabel')}
      inputElement={
        <Table className="input-form-table">
          <TableBody>
            <TableRow>
              <TableCell className="table-item-name">
                {l18n.translate('resources.goods.fields.goodsCode')}
              </TableCell>
              <TableCell>
                <TextInput
                  source="goodsCode"
                  className="form-control"
                  data-cy="filterGoodsCode"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-item-name">
                {l18n.translate('resources.goods.fields.janCode')}
              </TableCell>
              <TableCell>
                <TextInput
                  source="janCode"
                  className="form-control"
                  data-cy="filterGoodsJanCode"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-item-name">
                {`${l18n.translate(
                  'resources.goods.fields.goodsName'
                )} / ${l18n.translate('resources.goods.goodsNameInKana')}`}
              </TableCell>
              <TableCell>
                <TextInput
                  source="goodsName"
                  className="form-control"
                  data-cy="filterGoodsName"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-item-name">
                {l18n.translate('resources.goods.fields.shop.handlingFlag')}
              </TableCell>
              <TableCell>
                <BooleanInput
                  label=""
                  source="handlingFlag"
                  data-cy="filterGoodsHandlingFlag"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="table-item-name">
                {l18n.translate('resources.goods.fields.shop.soldOutFlag')}
              </TableCell>
              <TableCell>
                <BooleanInput
                  label=""
                  source="soldOutFlag"
                  data-cy="filterGoodsSoldOutFlag"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="table-item-name">
                {l18n.translate('resources.goods.fields.group')}
              </TableCell>
              <TableCell>
                <ReferenceInput
                  source="groupId"
                  reference="groups"
                  className="form-control custom-select no-help-block"
                  title={l18n.translate('resources.goods.fields.group')}
                  data-cy="filterGoodsGroup"
                >
                  <SelectInput optionText="groupName" />
                </ReferenceInput>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="table-item-name">
                {l18n.translate('resources.goods.fields.section')}
              </TableCell>
              <TableCell>
                <SectionInput source="sectionId" data-cy="filterGoodsSection" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      }
    />
  );
};

export default CustomFilter;
