// Icon
import {
  Home,
  AccountCircle,
  SupervisorAccount,
  Business,
  Storefront,
  Settings,
  CloudCircle,
  TabletAndroid,
  ErrorOutline,
  EventAvailableOutlined,
  PieChart,
  ShowChart
} from '@material-ui/icons';
import {
  GoodIcon,
  BeerIcon,
  SaleLogsIcon,
  LogsIcon,
  OperationLogsIcon
} from 'containers/Common/icons';
// Resources
import * as Dashboard from 'containers/Resources/Dashboard';
import * as User from 'containers/Resources/User';
import * as Company from 'containers/Resources/Company';
import * as Shop from 'containers/Resources/Shop';
import * as Goods from 'containers/Resources/Goods';
import * as TapBeer from 'containers/Resources/TapBeer';
import * as BeerServer from 'containers/Resources/BeerServer';
import * as Device from 'containers/Resources/Device';
import * as SaleLog from 'containers/Resources/SaleLog';
import * as OperationLog from 'containers/Resources/OperationLog';
import * as ErrorLog from 'containers/Resources/ErrorLog';
import * as EventLog from 'containers/Resources/EventLog';
import * as Constants from 'containers/Common/constants';
import RealtimeSaleChart from 'containers/Pages/Statistics/RealtimeSale';
import ProductHistory from 'containers/Pages/Statistics/ProductHistory';
import SaleHistory from 'containers/Pages/Statistics/SaleHistory';
import SaleHistoryWithFilter from 'containers/Pages/Statistics/SaleHistoryWithFilter';
import SaleReportByWeekDay from 'containers/Pages/Statistics/SaleReportByWeekDay';
import SaleReportByHour from '../Pages/Statistics/SaleReportByHour';
import SaleReportBySection from '../Pages/Statistics/SaleReportBySection';
import ShopCheckIn from 'containers/Pages/Management/ShopCheckIn';
import * as Group from 'containers/Resources/Group';
import * as Section from 'containers/Resources/Section';
import BeerServerAppendix from 'components/BeerServerAppendix';
import React from 'react';
import DeviceAppendix from 'components/DeviceAppendix';

export const resourceList = [
  {
    name: Constants.RESOURCE_DASHBOARD,
    list: Dashboard.Dashboard,
    create: null,
    edit: null,
    icon: Home,
    subMenus: []
  },
  {
    name: Constants.STATISTICS,
    list: null,
    create: null,
    edit: null,
    icon: PieChart,
    subMenus: [
      {
        name: Constants.STATISTICS_REALTIME_SALE_CHART,
        list: RealtimeSaleChart,
        create: null,
        edit: null,
        icon: ShowChart,
        subMenus: []
      },
      {
        name: Constants.STATISTICS_SALE_REPORT_BY_HOUR,
        list: SaleReportByHour,
        create: null,
        edit: null,
        icon: ShowChart,
        subMenus: []
      },
      {
        name: Constants.STATISTICS_SALE_REPORT_BY_WEEK_DAY,
        list: SaleReportByWeekDay,
        create: null,
        edit: null,
        icon: ShowChart,
        subMenus: []
      },
      {
        name: Constants.STATISTICS_SALE_REPORT_BY_SECTION,
        list: SaleReportBySection,
        create: null,
        edit: null,
        icon: ShowChart,
        subMenus: []
      },
      {
        name: Constants.STATISTICS_PRODUCT_HISTORY,
        list: ProductHistory,
        create: null,
        edit: null,
        icon: ShowChart,
        subMenus: []
      },
      {
        name: Constants.STATISTICS_SALE_HISTORY,
        list: SaleHistory,
        create: null,
        edit: null,
        icon: ShowChart,
        subMenus: []
      },
      {
        name: Constants.STATISTICS_SALE_HISTORY_WITH_FILTER,
        list: SaleHistoryWithFilter,
        create: null,
        edit: null,
        icon: ShowChart,
        subMenus: []
      },
      {
        name: Constants.RESOURCE_SALE_LOGS,
        list: SaleLog.List,
        create: null,
        edit: null,
        icon: SaleLogsIcon
      }
    ]
  },
  {
    name: Constants.PRODUCT,
    icon: Settings,
    subMenus: [
      {
        name: Constants.RESOURCE_GOODS,
        list: Goods.List,
        create: Goods.Create,
        edit: Goods.Edit,
        icon: GoodIcon
      },
      {
        name: Constants.RESOURCE_GOODS_SHOP,
        edit: Goods.GoodsShopEdit,
        icon: GoodIcon
      },
      {
        name: Constants.RESOURCE_TAP_BEERS,
        list: TapBeer.List,
        create: TapBeer.Create,
        edit: TapBeer.Edit,
        icon: BeerIcon
      },
      {
        name: Constants.RESOURCE_TAP_BEERS_SHOP,
        edit: TapBeer.ShopTapBeerEdit,
        icon: BeerIcon
      },
      {
        name: Constants.RESOURCE_GROUP,
        list: Group.List,
        create: Group.Create,
        edit: Group.Edit,
        icon: Storefront
      },
      {
        name: Constants.RESOURCE_SECTION,
        list: null,
        create: Section.Create,
        edit: Section.Edit,
        icon: Storefront
      }
    ]
  },
  {
    name: Constants.MASTER_DATA_SETTING,
    icon: Settings,
    subMenus: [
      {
        name: Constants.RESOURCE_DEVICES,
        list: Device.List,
        create: Device.Create,
        edit: Device.Edit,
        icon: TabletAndroid,
        appendix: <DeviceAppendix />
      },
      {
        name: Constants.RESOURCE_BEER_SERVERS,
        list: BeerServer.List,
        create: BeerServer.Create,
        edit: BeerServer.Edit,
        icon: CloudCircle,
        appendix: <BeerServerAppendix />
      }
    ]
  },
  {
    name: Constants.LOG_CONFIRMATION,
    icon: LogsIcon,
    subMenus: [
      {
        name: Constants.RESOURCE_EVENT_LOGS,
        list: EventLog.List,
        create: null,
        edit: null,
        icon: EventAvailableOutlined
      },
      {
        name: Constants.RESOURCE_ERROR_LOGS,
        list: ErrorLog.List,
        create: null,
        edit: null,
        icon: ErrorOutline
      },
      {
        name: Constants.RESOURCE_OPERATION_LOGS,
        list: OperationLog.List,
        create: null,
        edit: null,
        icon: OperationLogsIcon
      }
    ]
  },
  {
    name: Constants.PROCESSING,
    icon: LogsIcon,
    list: ShopCheckIn,
    create: null,
    edit: null,
    subMenus: []
  },
  {
    name: Constants.ADMIN_SETTING,
    icon: AccountCircle,
    subMenus: [
      {
        name: Constants.RESOURCE_COMPANIES,
        list: Company.List,
        create: Company.Create,
        edit: Company.Edit,
        icon: Business
      },
      {
        name: Constants.RESOURCE_SHOPS,
        list: Shop.List,
        create: Shop.Create,
        edit: Shop.Edit,
        icon: Storefront
      },
      {
        name: Constants.RESOURCE_USERS,
        list: User.List,
        create: User.Create,
        edit: User.Edit,
        icon: SupervisorAccount
      }
    ]
  }
];
