/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React from 'react';
import { Admin, Resource } from 'react-admin';
// Providers
import { authProvider } from 'providers/auth.provider';
import dataProvider from 'providers/data.provider';
// Components
import customLayout from 'components/BaseLayout';
// Containers
import { NotFoundPage } from 'containers/Pages/Error';
import globalFilterReducer from 'containers/GlobalFilter/reducer';
import globalFilterSaga from 'containers/GlobalFilter/saga';
import i18nProvider from 'containers/I18nProvider';
import LoginPage from 'containers/Pages/Login';
import { resourceList } from 'containers/Common/resources';
import statisticsRealtimeSaleReducer from 'containers/Common/Reducers/statisticsRealtimeSale';
// Local
import 'styles/common.css';
import customRoutes from './customRoutes';
import { checkPermissions } from './utils';
import statisticsSaga from 'containers/Common/Sagas/statisticsSaga';
import reportDataReducer from 'containers/Common/Reducers/reportDataReducer';
import reportDataSaga from 'containers/Common/Sagas/reportDataSaga';
import chartModeReducer from 'containers/Common/Reducers/chartModeReducer';
import saleLogFilterFormReducer from 'containers/Common/Reducers/saleLogFilterFormReducer';
import beerServerSurvivalSignalReducer from 'containers/Common/Reducers/beerServerSurvivalSignalReducer';
import tabletSurvivalSignalReducer from 'containers/Common/Reducers/tabletSurvivalSignalReducer';

import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

export const black = {
  50: '#e0e0e0',
  100: '#b3b3b3',
  200: '#808080',
  300: '#4d4d4d',
  400: '#262626',
  500: '#000000',
  600: '#000000',
  700: '#000000',
  800: '#000000',
  900: '#000000',
  A100: '#a6a6a6',
  A200: '#8c8c8c',
  A400: '#737373',
  A700: '#666666',
  contrastDefaultColor: 'light'
};

/* For use in material-ui/src/styles/colors.js */
export const white = {
  50: '#ffffff',
  100: '#ffffff',
  200: '#ffffff',
  300: '#ffffff',
  400: '#ffffff',
  500: '#ffffff',
  600: '#ffffff',
  700: '#ffffff',
  800: '#ffffff',
  900: '#ffffff',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: 'dark'
};

const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: black,
    secondary: white
  },
  overrides: {
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: '#fff !important'
        }
      },
      active: {
        color: '#fff !important'
      },
      icon: {
        color: '#fff !important'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#555',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#000',
          border: '0.3px solid #aaa'
        }
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: '#fff',
          color: '#000',
          border: '0.3px solid #aaa'
        }
      },
      textPrimary: {
        color: '#fff'
      }
    }
  }
});

const App = () => {
  const dashboard = resourceList.find(
    (resource) => resource.name === 'dashboard'
  );

  // Generate resource
  const generateResouces = (permissions) => {
    let resources = [];
    resourceList.forEach((resource) => {
      const subMenus = resource.subMenus,
        subMenuLength = subMenus.length;
      if (subMenuLength > 0) {
        // In case of having sub menu
        for (let i = 0; i < subMenuLength; i++) {
          const subResource = subMenus[i],
            subResourceName = subResource.name;
          resources.push(
            <Resource
              name={subResourceName}
              list={
                checkPermissions(permissions, `/${subResourceName}`)
                  ? subResource.list
                  : null
              }
              create={
                subResource.create &&
                checkPermissions(permissions, `/${subResourceName}/create`)
                  ? subResource.create
                  : null
              }
              edit={
                subResource.edit &&
                checkPermissions(permissions, `/${subResourceName}/edit`)
                  ? subResource.edit
                  : null
              }
              icon={subResource.icon}
            />
          );
        }
      } else {
        // No sub menu
        const resourceName = resource.name;
        resources.push(
          <Resource
            name={resourceName}
            list={
              checkPermissions(permissions, `/${resourceName}`)
                ? resource.list
                : null
            }
            create={
              resource.create &&
              checkPermissions(permissions, `/${resourceName}/create`)
                ? resource.create
                : null
            }
            edit={
              resource.edit &&
              checkPermissions(permissions, `/${resourceName}/edit`)
                ? resource.edit
                : null
            }
            icon={resource.icon}
          />
        );
      }
    });

    return resources;
  };

  return (
    <Admin
      dashboard={dashboard.list}
      layout={customLayout}
      theme={myTheme}
      customRoutes={customRoutes}
      catchAll={NotFoundPage}
      customSagas={[globalFilterSaga, statisticsSaga, reportDataSaga]}
      customReducers={{
        globalFilterReducer,
        statisticsRealtimeSale: statisticsRealtimeSaleReducer,
        reportData: reportDataReducer,
        chartMode: chartModeReducer,
        saleLogFilterForm: saleLogFilterFormReducer,
        beerServerSurvivalSignals: beerServerSurvivalSignalReducer,
        tabletSurvivalSignals: tabletSurvivalSignalReducer
      }}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
    >
      {(permissions) => generateResouces(permissions)}
    </Admin>
  );
};

export default App;
