import React from 'react';
import { Button, ReduxState, useSafeSetState } from 'react-admin';
import l18n from 'containers/I18nProvider/index';
import ExportIcon from '@material-ui/icons/CloudDownload';
import { Card, Grid, CardContent } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from 'react-data-table-component';
import FilterSidebar from './FilterSidebar';
import dataProvider from 'providers/data.provider';
import { getListDataByCompanyId } from 'containers/Common/api';
import moment from 'moment';
import { SUBTOTAL_BEER_AND_GOODS_COLUMNS } from '../SaleHistory/constants';
import { useSelector } from 'react-redux';
import DataTablePagination from 'components/DataTablePagination';

const customStyles = {
  rows: {
    style: {
      borderRightStyle: 'solid',
      borderRightWidth: '0.5px',
      borderRightColor: '#dddddd'
    }
  },
  cells: {
    style: {
      borderLeftStyle: 'solid',
      borderLeftWidth: '0.5px',
      borderLeftColor: '#dddddd'
    }
  },
  headCells: {
    style: {
      backgroundColor: '#555555',
      color: 'white'
    },
    activeSortStyle: {
      color: 'white',
      '&:focus': {
        outline: 'none'
      },
      '&:hover:not(:focus)': {
        color: 'white'
      }
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: 'white'
      },
      '&:hover': {
        color: 'white'
      }
    }
  }
};

const SaleHistoryWithFilter = (props) => {
  document.title = document.title =
    process.env.REACT_APP_WEBSITE_NAME +
    ' - ' +
    l18n.translate('resources.sale-history-with-filter.pageTitle');
  const globalFilterReducer = useSelector(
    (state: ReduxState) => state.globalFilterReducer
  );

  const [data, setData] = useSafeSetState([]),
    [startDate, setStartDate] = useSafeSetState(moment()),
    [endDate, setEndDate] = useSafeSetState(moment()),
    [weekDay, setWeekDay] = useSafeSetState(null);

  React.useEffect(() => {
    (async function fetchData() {
      const results = await getListDataByCompanyId(
        dataProvider,
        'sale-logs/best-seller',
        {
          sort: { field: 'count_item', order: 'DESC' },
          filter: {
            q: {
              shop_id: globalFilterReducer.shop?.id,
              startDate: startDate.format('YYYYMMDD'),
              endDate: endDate.format('YYYYMMDD'),
              order: 'count_item'
            }
          }
        }
      );
      results.map(item => {
        if(!item.count_glass) item.count_glass = item.count_item;
      });
      setData(results);
    })();
  }, [globalFilterReducer.shop?.id, setData, startDate, endDate]);

  return (
    <div style={{ padding: '1rem' }}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <FilterSidebar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            weekDay={weekDay}
            setWeekDay={setWeekDay}
          />
        </Grid>
        <Grid item xs={10}>
          <Card>
            <CardContent
              style={{
                border: 'none',
                minHeight: 600
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                  <Button
                    label={l18n.translate('ra.action.download')}
                    startIcon={<ExportIcon />}
                    variant="outlined"
                    style={{ float: 'right' }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: '1rem' }}
                  data-cy="saleHistoryDataTable"
                >
                  <DataTable
                    data={data}
                    columns={SUBTOTAL_BEER_AND_GOODS_COLUMNS(
                      props.basePath,
                      startDate,
                      endDate
                    )}
                    defaultSortField="sale"
                    defaultSortAsc={false}
                    pagination
                    paginationComponent={DataTablePagination}
                    paginationPerPage={50}
                    sortIcon={<ExpandMoreIcon />}
                    striped
                    highlightOnHover
                    customStyles={customStyles}
                    dense
                    noHeader
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SaleHistoryWithFilter;
