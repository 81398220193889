const messages = {
  'sale-logs': {
    name: '販売履歴',
    fields: {
      paymentMethod: '支払方法',
      totalSellingPrice: '総販売価格',
      totalTax: '税金',
      payment_house_money: 'ハウスマネー',
      payment_cash: '現金',
      payment_emoney: '電子マネー',
      payment_credit_card: 'クレジットカード',
      payment_qr: 'QR',
      payment_others: 'その他',
      saleLogDetails: {
        goodsId: '商品名',
        tapBeerId: 'ビール名',
        amount: '量',
        sellingPrice: '販売価格',
        purchaseCost: '購入費用',
        subtotal: '小計'
      },
      createdAt: '日時',
    },
    saleTab: 'セール',
    topUpTab: '預り金'
  }
};

export default messages;
