import { getAccessToken, getCurrentShopId } from 'providers/auth.provider';
import io from 'socket.io-client';
import { env } from 'environment';

let socket = null;
const socketUrl = env.baseApi + '/statistics';

export const initWs = () => {
  socket = io(socketUrl, {
    transports: ['websocket'],
    query: {
      shop_id: getCurrentShopId(),
      token: getAccessToken()
    }
  });
  return socket;
};

const getWs = () => {
  if (socket === null) {
    socket = initWs();
  }
  return socket;
};

export const destroyWs = () => {
  socket = null;
};

export default getWs;
