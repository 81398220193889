import React from 'react';
import {
  TextInput,
  NumberInput,
  ImageInput,
  ImageField,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  FormDataConsumer,
  ReferenceInput
} from 'react-admin';
import { InputAdornment, TableRow, TableCell } from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { AlertCommon } from 'containers/Common/alert';
import {
  getBase64,
  handleSubmitFactory,
  prepareImage
} from 'containers/Common/helper';
import l18n from 'containers/I18nProvider/index';
// Local
import { getCreateTapBeerValidationSchema } from './validations';
import { colourValues, tasteValues } from '../constants';
import { currency } from 'containers/App/constants';
import CountryInput from 'components/CountryInput';
import SectionInput from 'components/SectionInput';

const AddForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate(resource),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = async (values) => {
      // Get image base64 to pass to api
      const imageResult = await getBase64(values, 'imageUrl');
      if (imageResult) {
        values['image'] = prepareImage(imageResult);
      }

      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCreateTapBeerValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: create,
      onTransform
    });

  let { company, availableCompanies } = globalFilterReducer;

  // Avoid error in case of company undefined
  // if (!company) {
  //   company = availableCompanies[0];
  // }

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />
      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerCode')}
          required
          source="beerCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitCodeMax', { max: 30 })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerName')}
          required
          source="beerName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerNameEn')}
          required
          source="beerNameEn"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerShortName')}
          required
          source="beerShortName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.colour')}
          source="colour"
          choices={colourValues}
          className="form-control custom-select no-help-block"
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerStyle')}
          required
          source="beerStyle"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <NumberInput
          title={l18n.translate('resources.tap-beers.fields.ibu')}
          required
          source="ibu"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <NumberInput
          title={l18n.translate('resources.tap-beers.fields.alcohol')}
          required
          source="alcohol"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.brewery')}
          required
          source="brewery"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.breweryEn')}
          required
          source="breweryEn"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate(
            'resources.tap-beers.fields.company.purchaseCostMl'
          )}
          required
          source="purchaseCost"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate(
            'resources.tap-beers.fields.company.sellingPriceMl'
          )}
          required
          source="sellingPrice"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.description')}
          multiline
          rows="5"
          source="description"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitMax', { max: 255 })}
        />

        <ImageInput
          title={l18n.translate('resources.tap-beers.fields.imageUrl')}
          required
          source="imageUrl"
          label="Related pictures"
          accept=".jpg,.jpeg,.png"
          className="img-input"
          helperText={l18n.translate('ra.helpBlock.extension')}
        >
          <ImageField source="imageUrl" title="title" />
        </ImageInput>

        <SelectInput
          title={l18n.translate('resources.goods.fields.company.id')}
          required
          disabled
          source="company.id"
          optionText="companyName"
          choices={availableCompanies}
          defaultValue={company?.id}
          className="form-control custom-select no-help-block"
        />

        <CountryInput
          title={l18n.translate('resources.tap-beers.fields.country.id')}
          required
          source="country.id"
          id="country.id"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.smell')}
          required
          source="smell"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.acidity')}
          required
          source="acidity"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.bitter')}
          required
          source="bitter"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.sweet')}
          required
          source="sweet"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.weight')}
          required
          source="weight"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <ReferenceInput
          required
          source="group.id"
          reference="groups"
          className="form-control custom-select no-help-block"
          title={l18n.translate('resources.tap-beers.fields.group')}
        >
          <SelectInput optionText="groupName" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData }) =>
            formData.group &&
            formData.group.id && (
              <TableRow key="section">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.tap-beers.fields.section')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <SectionInput source="section.id" />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>
      </TableForm>
    </>
  );
};

export default AddForm;
