import React from 'react';
import GeneralPagination from 'components/GeneralPagination';
import {
  Create,
  Edit,
  List,
  SelectField,
  TextField,
  useRefresh,
  useSafeSetState
} from 'react-admin';
import { Typography, Chip, Button as MuiButton } from '@material-ui/core';
import moment from 'moment';
import TimeIcon from '@material-ui/icons/Timer';
import { errorMessageWrap, loadGlobalFilter } from 'containers/Common/hocs';
import CustomAction from './Actions/index';
import {
  beerOrderMode,
  invalidColor,
  shopPayment,
  shopPaymentMethod,
  textColor,
  validColor
} from './constants';
import ShopCreateForm from './Forms/ShopCreateForm';
import ShopEditForm from './Forms/ShopEditForm';
import { UserRole } from 'providers/auth.provider';
import { CustomDataGrid } from 'components/DataGrid/index';
import l18n from 'containers/I18nProvider/index';
import { DATE_FORMAT_DATE_AND_TIME } from 'containers/App/constants';
import { getListData, postData } from 'containers/Common/api';
import dataProvider from 'providers/data.provider';

const ShopListPage = ({ globalFilterReducer, ...props }) => {
  let hideCreate = '',
    hideEdit = '',
    hideDelete = '',
    isHideAll = false,
    authority = props.permissions;
  if (authority === UserRole.SHOP || authority === UserRole.STAFF) {
    hideCreate = 'true';
    hideEdit = 'true';
    hideDelete = 'true';
    isHideAll = true;
  }

  // Company
  const { availableCompanies } = globalFilterReducer,
    companyLength = availableCompanies.length;

  return (
    <List
      {...props}
      actions={<CustomAction hide={hideCreate} />}
      pagination={<GeneralPagination />}
      bulkActionButtons={false}
      className="table-responsive"
    >
      <CustomDataGrid edit={hideEdit} delete={hideDelete}>
        <TextField source="id" />
        <TextField source="shopCode" />
        <TextField source="shopName" />
        <SelectField source="paymentType" choices={shopPayment} />
        <SelectField source="paymentMethod" choices={shopPaymentMethod} />
        <SelectField source="beerOrderMode" choices={beerOrderMode} />
        {companyLength > 0 && (
          <SelectField
            source="companyId"
            choices={availableCompanies}
            optionText="companyName"
            label={l18n.translate('resources.shops.fields.company.id')}
            sortable={false}
          />
        )}
        {!isHideAll && (
          <TextField
            source="operation"
            sortable={false}
            label={l18n.translate('ra.action.operation')}
            className="operation"
          />
        )}
      </CustomDataGrid>
    </List>
  );
};

const ShopList = loadGlobalFilter(ShopListPage);

const ShopCreatePage = (props) => (
  <Create {...props} className="table-responsive">
    <ShopCreateForm permissions={props.permissions} />
  </Create>
);

const ShopCreate = errorMessageWrap(ShopCreatePage);

const EditShopActions = ({ data }: { data?: any }) => {
  const refresh = useRefresh(),
    [shopOpenCloseTimeData, setShopOpenCloseTimeData] = useSafeSetState({
      openedAt: '', // example data : '2021-03-23T02:32:57.000Z'
      closedAt: ''
    }),
    openShopUrl = (shopId) => 'shops/' + shopId + '/open',
    closeShopUrl = (shopId) => 'shops/' + shopId + '/close',
    handleOpenShop = async (shopId) => {
      await postData(openShopUrl(shopId), {});
      refresh();
    },
    handleCloseShop = async (shopId) => {
      await postData(closeShopUrl(shopId), {});
      refresh();
    };
  React.useEffect(() => {
    const updateTapBeerServerData = () => {
      if (data && data.id) {
        getListData(dataProvider, 'shop-open-close-time', {
          filter: {
            'shopId||$eq': data.id
          }
        }).then((res) => {
          if (res.length > 0) {
            setShopOpenCloseTimeData(res.shift());
          }
        });
      }
    };
    updateTapBeerServerData();
  }, [setShopOpenCloseTimeData, data]);

  return (
    <Typography variant="h6" className="shopActionHeader">
      Working time:
      {shopOpenCloseTimeData.openedAt ? (
        <Chip
          label={moment(shopOpenCloseTimeData.openedAt).format(
            DATE_FORMAT_DATE_AND_TIME
          )}
          size="small"
          icon={<TimeIcon style={{ color: textColor }} />}
          style={{
            fontSize: '1.1rem',
            color: textColor,
            backgroundColor: validColor
          }}
        />
      ) : (
        '--:--'
      )}
      {shopOpenCloseTimeData.closedAt ? (
        <Chip
          label={moment(shopOpenCloseTimeData.closedAt).format(
            DATE_FORMAT_DATE_AND_TIME
          )}
          size="small"
          icon={<TimeIcon style={{ color: textColor }} />}
          style={{
            fontSize: '1.1rem',
            color: textColor,
            backgroundColor: invalidColor
          }}
        />
      ) : (
        '--:--'
      )}
      {!!shopOpenCloseTimeData.openedAt === true &&
      !!shopOpenCloseTimeData.closedAt === true ? (
        ''
      ) : !!shopOpenCloseTimeData.openedAt === false ? (
        <MuiButton
          variant="contained"
          color="primary"
          className="btn-primary"
          onClick={() => handleOpenShop(data.id)}
          cy-data="OpenShopButton"
          style={{ float: 'right' }}
        >
          {l18n.translate('resources.shops.openShop')}
        </MuiButton>
      ) : (
        <MuiButton
          variant="contained"
          color="primary"
          className="btn-primary"
          onClick={() => handleCloseShop(data.id)}
          cy-data="CloseShopButton"
          style={{ float: 'right' }}
        >
          {l18n.translate('resources.shops.closeShop')}
        </MuiButton>
      )}
    </Typography>
  );
};

const ShopEditPage = (props) => (
  <Edit
    undoable={false}
    className="table-responsive"
    actions={<EditShopActions />}
    {...props}
  >
    <ShopEditForm permissions={props.permissions} />
  </Edit>
);

const ShopEdit = errorMessageWrap(ShopEditPage);

export { ShopList as List, ShopCreate as Create, ShopEdit as Edit };
