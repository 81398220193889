import { DATE_FORMAT_DATE_ONLY } from 'containers/App/constants';
import produce from 'immer';
import moment from 'moment';
import {
  DATE_SELECTED,
  INITIAL_STATISTIC_DATA
} from '../Actions/statisticsRealtimeSale';

const initialState = {
  loadingChartData: false,
  date: moment().format(DATE_FORMAT_DATE_ONLY),
  chartData: [],
  aggregatedData: [],
  statisticsData: {}
};

const statisticsRealtimeSaleReducer = (
  state = initialState,
  { type, payload }
) =>
  produce(state, (draft) => {
    switch (type) {
      case DATE_SELECTED:
        draft.date = payload.date;
        draft.loadingChartData = false;
        break;

      case INITIAL_STATISTIC_DATA:
        draft.statisticsData = payload;
        draft.loadingChartData = false;
        break;
    }
  });

export default statisticsRealtimeSaleReducer;
