import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ToolbarProps, Drawer } from '@material-ui/core';
import { downloadCSV, Exporter } from 'ra-core';
import { useListContext, TopToolbar, sanitizeListRestProps } from 'react-admin';
import { Button } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ImportIcon from '@material-ui/icons/CloudUpload';
import ExportIcon from '@material-ui/icons/CloudDownload';
import l18n from 'containers/I18nProvider/index';
import ImportForm from './Forms/ImportForm';
import { Link } from 'react-router-dom';
import { env } from 'environment';
import { getAccessToken } from 'providers/auth.provider';
import moment from 'moment';
import CustomFilter from './Filters';

const handleExportFile = (url: string) => {
  let headers = new Headers();
  headers.set('Authorization', `Bearer ${getAccessToken()}`);

  const timeStamp = moment(new Date()).format('YYYY_MM_DD_H:m:s');

  fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((response) => response.blob())
    .then((res) => {
      downloadCSV(res, `goods_${timeStamp}`);
    });
};
const GoodsActions: FC<GoodsActionsProps> = (props) => {
  const {
      className,
      exporter,
      filters,
      maxResults,
      showCreateButton = false,
      showImportButton = false,
      showExportButton = false,
      ...rest
    } = props,
    { basePath, loading } = useListContext(),
    createLink: string = basePath + '/create',
    [open, setState] = React.useState(false),
    toggleDrawer = (open: boolean) => {
      setState(!open);
    },
    exportApiUrl = env.baseApi + '/goods/export/goods.csv';

  if (loading) return null;

  return (
    <div className="toolbar-wrapper">
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <div className="form-inline list-header">
          <CustomFilter />

          {showCreateButton && (
            <Link to={createLink}>
              <Button
                variant="contained"
                color="primary"
                className="btn-primary float-right"
                startIcon={<Add />}
              >
                {l18n.translate('ra.action.create')}
              </Button>
            </Link>
          )}

          {showExportButton && (
            <Button
              variant="contained"
              color="primary"
              className="btn-primary float-right"
              startIcon={<ExportIcon />}
              onClick={() => handleExportFile(exportApiUrl)}
              data-cy="exportGoodsButton"
            >
              {l18n.translate('ra.action.export')}
            </Button>
          )}

          {showImportButton && (
            <Button
              onClick={() => {
                toggleDrawer(open);
              }}
              variant="contained"
              color="primary"
              className="btn-primary float-right"
              startIcon={<ImportIcon />}
              data-cy="importGoodsButton"
            >
              {l18n.translate('ra.action.import')}
            </Button>
          )}

          <Drawer
            anchor={'right'}
            open={open}
            onClose={() => {
              toggleDrawer(open);
            }}
          >
            <ImportForm closeDrawer={() => toggleDrawer(open)} />
          </Drawer>
        </div>
      </TopToolbar>
    </div>
  );
};

GoodsActions.propTypes = {
  filters: PropTypes.element,
  // @ts-ignore
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  // @ts-ignore
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  maxResults: PropTypes.number
};

export interface GoodsActionsProps
  extends Omit<ToolbarProps, 'classes' | 'onSelect'> {
  actions?: ReactElement | false;
  filters?: ReactElement;
  exporter?: Exporter | false;
  maxResults?: number;
  showCreateButton?: boolean;
  showImportButton?: boolean;
  showExportButton?: boolean;
}

export default React.memo(GoodsActions);
