const messages = {
  'tap-beers': {
    name: 'セルフタップ商品一覧',
    createTitle: 'ビール作成',
    updateTitle: 'ビール編集',
    fields: {
      id: 'ID',
      beerCode: 'ビールコード',
      beerName: 'ビール名',
      beerNameEn: 'ビール名(英)',
      beerShortName: 'ビール名短',
      beerStyle: 'ビールスタイル',
      alcohol: 'アルコール',
      ibu: 'IBU',
      brewery: '醸造所',
      breweryEn: '醸造所(英)',
      description: '説明',
      imageUrl: '画像設定',
      breweryUrl: '醸造所画像',
      company: {
        id: '所属会社',
        purchaseCost: '原価',
        sellingPrice: '販売価格(税抜)',
        purchaseCostMl: '原価(1ml)',
        sellingPriceMl: '販売価格(1ml)(税抜)'
      },
      shop: {
        purchaseCost: '店舗設定原価',
        sellingPrice: '店舗設定販売価格(税抜)',
        purchaseCostMl: '店舗設定原価(1ml)',
        sellingPriceMl: '店舗設定販売価格(1ml)(税抜)'
      },
      richTaste: 'コク',
      sharpnessTaste: 'キレ',
      bitterTaste: '苦味',
      sweetTaste: '甘味',
      smell: '香り',
      country: {
        id: '国'
      },
      acidity: '酸味',
      bitter: '苦味',
      sweet: '甘味',
      weight: '重さ',
      group: 'グループ',
      section: '部門',
      logo1: '画像設定',
      logo2: '画像設定',
      colour: '色',
      colourOptions: {
        red: '赤',
        yellow: '黄',
        white: '白',
        black: '黒',
        orange: 'オレンジ',
        sour: 'サワー',
        highball: 'ハイボール'
      },
      purchaseCost: '会社原価',
      sellingPrice: '会社売価(税抜)'
    },
    validation: {
      beerCode: {
        format: 'ビールコードは半角英数字でご入力ください。'
      }
    }
  },
  'tap-beers-shop': {
    name: 'ビール情報一覧',
    createTitle: 'ショップのビールを作る',
    updateTitle: 'ショップのビールを編集する',
    fields: {
      purchaseCost: '店舗設定原価(1ml)',
      sellingPrice: '店舗設定販売価格(1ml)(税抜)'
    }
  }
};

export default messages;
