const messages = {
  companies: {
    name: '会社',
    createTitle: '会社作成',
    updateTitle: '会社編集',
    fields: {
      id: 'ID',
      companyName: '会社名',
      companyCode: '会社コード'
    },
    receiptInfo: '領収書情報',
    validation: {
      companyCode: {
        format: '会社コードは半角英数字でご入力ください。'
      }
    }
  }
};

export default messages;
