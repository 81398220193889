import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishMessages from './en';
import japaneseMessages from './ja';

const messages = {
  ja: japaneseMessages,
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'ja');

export default i18nProvider;
