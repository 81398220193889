/*
 *
 * GlobalFilter constants
 *
 */

export const DEFAULT_ACTION = 'DEFAULT_ACTION';
export const LOAD_CURRENT_COMPANY = 'LOAD_CURRENT_COMPANY';
export const SET_CURRENT_COMPANY_SUCCESS = 'SET_CURRENT_COMPANY_SUCCESS';
export const CURRENT_COMPANY_ERROR = 'CURRENT_COMPANY_ERROR';
export const AVAILABLE_COMPANIES_SUCCESS = 'AVAILABLE_COMPANIES_SUCCESS';

export const REHYDRATE_CURRENT_COMPANY = 'REHYDRATE_CURRENT_COMPANY';
export const REHYDRATE_CURRENT_COMPANY_SUCCESS =
  'REHYDRATE_CURRENT_COMPANY_SUCCESS';

export const LOAD_CURRENT_SHOP = 'LOAD_CURRENT_SHOP';
export const CURRENT_SHOP_SUCCESS = 'CURRENT_SHOP_SUCCESS';
export const CURRENT_SHOP_ERROR = 'CURRENT_SHOP_ERROR';
export const AVAILABLE_SHOPS_SUCCESS = 'AVAILABLE_SHOPS_SUCCESS';
export const REHYDRATE_CURRENT_SHOP = 'REHYDRATE_CURRENT_SHOP';
export const REHYDRATE_CURRENT_SHOP_SUCCESS = 'REHYDRATE_CURRENT_SHOP_SUCCESS';

export const HIDE_BREADCRUMB_SHOP = 'HIDE_BREADCRUMB_SHOP';
export const SHOW_BREADCRUMB_SHOP = 'SHOW_BREADCRUMB_SHOP';

export const CLEAR_GLOBAL_FILTER_STATE = 'CLEAR_GLOBAL_FILTER_STATE';
