import { takeLatest, call, put } from 'redux-saga/effects';
import {
  REHYDRATE_CURRENT_COMPANY,
  LOAD_CURRENT_COMPANY,
  SET_CURRENT_COMPANY_SUCCESS,
  REHYDRATE_CURRENT_COMPANY_SUCCESS
} from './constants';
import {
  loadAvailableCompaniesSuccess,
  setCurrentCompanySuccess,
  loadCurrentShop,
  loadCurrentShopError,
  loadAvailableShopsSuccess,
  setCurrentShopSuccess,
  hideBreadcrumbShop,
  showBreadcrumbShop,
  clearGlobalFilter
} from './actions';
import { savelocalStorage } from './localStorageCaller';
import { httpClient } from 'network/httpClient';
import { env } from 'environment';
import { authProvider, UserRole } from 'providers/auth.provider';
import * as Constants from 'containers/Common/constants';

const expireOffset = process.env.REACT_APP_EXPIRE_LOCAL_STORAGE_OFFSET || 5;

export function* getCurrentCompany() {
  let accountInfo = authProvider.getAccountInfo(),
    authority = accountInfo.authority,
    response = { data: [] };
  if (authority === UserRole.SYSTEM_ADMINISTRATOR) {
    const result = yield call(httpClient, `${env.baseApi}/companies`, {
      method: 'GET'
    });
    response = result.json;
  } else {
    // In case of authority not admin system, can not call api companies
    response.data = [accountInfo.company];
  }
  yield call(savelocalStorage, 'availableCompanies', response.data);
  yield put(loadAvailableCompaniesSuccess(response.data));
  if (response.data.length > 0) {
    const firstData = response.data[0];
    const d = new Date();
    const firstCompany = {
      id: firstData.id,
      companyName: firstData.companyName,
      companyCode: firstData.companyCode,
      expireAt: d.setMinutes(d.getMinutes() + expireOffset)
    };
    yield call(savelocalStorage, 'currentCompany', firstCompany);
    yield put(setCurrentCompanySuccess(firstCompany));
  } else {
    yield put(setCurrentCompanySuccess([]));
  }
}

export function* getCurrentShop() {
  yield put(loadCurrentShop());

  // Don't have current shop local storage data or local storage data company does not match
  // Load current shop by api
  try {
    const { json: response } = yield call(httpClient, `${env.baseApi}/shops`, {
      method: 'GET'
    });
    if (response.data) {
      yield call(savelocalStorage, 'availableShops', response.data);
      yield put(loadAvailableShopsSuccess(response.data));

      if (response.data.length > 0) {
        const firstData = response.data[0];

        // Adapt currentShop entity
        const d = new Date();
        const firstShop = {
          id: firstData.id,
          shopName: firstData.shopName,
          shopCode: firstData.shopCode,
          companyId: firstData.companyId,
          expireAt: d.setMinutes(d.getMinutes() + expireOffset)
        };
        yield put(setCurrentShopSuccess(firstShop));
        yield call(savelocalStorage, 'currentShop', firstShop);
      } else {
        yield put(setCurrentShopSuccess(''));
        yield call(savelocalStorage, 'currentShop', '');
      }
    }
  } catch (err) {
    yield put(loadCurrentShopError(err));
  }

  return true;
}

export function* toggleBreadcrumb() {
  const currentPath = window.location.hash.split('?')[0].replace('#/', '');

  if (Constants.LIST_PATH_CAN_SHOW_SHOP_FILTER.includes(currentPath)) {
    yield put(showBreadcrumbShop());
  } else {
    yield put(hideBreadcrumbShop());
  }
}

export function* clearGlobalFilterState() {
  yield put(clearGlobalFilter());
}

export default function* globalFilterSaga() {
  yield takeLatest(REHYDRATE_CURRENT_COMPANY, getCurrentCompany);
  yield takeLatest(LOAD_CURRENT_COMPANY, getCurrentCompany);
  yield takeLatest(SET_CURRENT_COMPANY_SUCCESS, getCurrentShop);
  yield takeLatest(REHYDRATE_CURRENT_COMPANY_SUCCESS, getCurrentShop);
  yield takeLatest('@@router/LOCATION_CHANGE', toggleBreadcrumb);
  yield takeLatest('RA/CLEAR_STATE', clearGlobalFilterState);
}
