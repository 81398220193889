/**
 * [Get validation schema for creating Device]
 * @return  {[Object]} schema
 */
const getCreateDeviceValidationSchema = () => ({
  hardwareCode: { required: true, maxLength: 30, emojis: true },
  hardwareName: { required: true, maxLength: 100, emojis: true },
  password: { required: true, number: true, equalLength: 6 },
  confirmPassword: { confirmPassword: true },
  'company.id': { selected: true, number: true, minNumber: 1 },
  'shop.id': { selected: true, number: true, minNumber: 1 }
});

/**
 * [Get validation schema for updating Device]
 * @return  {[Object]} schema
 */
const getEditDeviceValidationSchema = () => ({
  hardwareName: { required: true, maxLength: 100, emojis: true },
  password: { number: true, equalLength: 6 },
  confirmPassword: { confirmPassword: true },
});

export { getCreateDeviceValidationSchema, getEditDeviceValidationSchema };
