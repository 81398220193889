import React from 'react';
import { useListContext } from 'react-admin';
import Pagination from '@material-ui/lab/Pagination';
import { NativeSelect } from '@material-ui/core';
import l18n from 'containers/I18nProvider/index';

const GeneralPagination = (props) => {
  const { ids, page, perPage, total, setPage, setPerPage, loading } =
    useListContext();

  if (loading) return null;

  // Set offset begin page, offset end page
  let dataLength = ids.length - 1,
    offsetBegin = 1;

  if (page > 1) {
    offsetBegin = (page - 1) * perPage + 1;
  }

  const offsetEnd = offsetBegin + dataLength;

  // Set page size
  let pageSize = 0;
  if (total > 0 && perPage > 0) {
    pageSize = Math.ceil(total / perPage);
  }

  // Define default perPage list
  const perPageList = [10, 25, 50, 100, 200];

  const onChangePage = (
    event: React.ChangeEvent<unknown>,
    currentPage: number
  ) => {
    setPage(currentPage);
  };

  const onChangePerPage = (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
  };

  const getPageRangeInfo = () => {
    if (dataLength === 0) {
      return l18n.translate('ra.navigation.page_one_record');
    } else {
      return l18n.translate('ra.navigation.page_range_info', {
        offsetBegin: offsetBegin,
        offsetEnd: offsetEnd,
        total: total
      });
    }
  };

  if (dataLength < 0) {
    return (
      <div className="empty-search-result" data-cy="empty-search-result">
        {l18n.translate('ra.navigation.no_results')}
      </div>
    );
  } else {
    return (
      <div className="wrapper-pagination">
        <Pagination
          count={pageSize}
          page={page}
          shape="rounded"
          onChange={onChangePage}
          variant="outlined"
          size="small"
          className="pagination"
          data-cy="pagination"
        />
        <span className="page-info" data-cy="page-info">
          {getPageRangeInfo()}
        </span>
        <div className="pagination-right">
          <span>
            <form
              autoComplete="off"
              className={pageSize <= 1 ? 'form-padding' : ''}
            >
              {l18n.translate('ra.navigation.page_rows_per_page')}
              <NativeSelect
                id="perPage"
                value={perPage}
                onChange={onChangePerPage}
                className="form-control"
                data-cy="selectPerPage"
              >
                {perPageList.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </NativeSelect>
            </form>
          </span>
        </div>
      </div>
    );
  }
};

export default GeneralPagination;
