import { Table } from '@material-ui/core';
import BooleanField from 'components/BooleanField';
import GeneralPagination from 'components/GeneralPagination';
import MyDatagridRow from 'components/MyDatagridRow';
import { checkPermissions } from 'containers/App/utils';
import * as Constants from 'containers/Common/constants';
import { loadGlobalFilter } from 'containers/Common/hocs';
import l18n from 'containers/I18nProvider/index';
import dataProvider from 'providers/data.provider';
import React, { useEffect } from 'react';
import {
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  List,
  Loading,
  Record,
  ReferenceField,
  TextField,
  useSafeSetState
} from 'react-admin';
import CustomAction from './Actions';
import GroupCreateForm from './Forms/GroupCreateForm';
import GroupEditForm from './Forms/GroupEditForm';

const SectionPanel = ({ record, permissions }) => {
  const [data, setData] = useSafeSetState([] as Record[]),
    [loading, setLoading] = useSafeSetState(true),
    [error, setError] = useSafeSetState();
  useEffect(() => {
    dataProvider
      .getList('sections', {
        filter: {
          q: {
            group_id: record.id
          }
        },
        pagination: {
          page: 1,
          perPage: 99
        },
        sort: {
          field: 'id',
          order: 'DESC'
        }
      })
      .then(({ data }) => {
        setData(data as Record[]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [record.id, setData, setError, setLoading]);

  if (loading) return <Loading />;
  if (error) return null;
  if (!data) return null;

  return (
    <div className="sectionExpandWrap">
      <Table>
        <MyDatagridRow
          records={data}
          resource={Constants.RESOURCE_SECTION}
          id={record.id}
          basePath={Constants.RESOURCE_SECTION}
        >
          <TextField source="id" label="ID" />

          <TextField
            source="sectionCode"
            label={l18n.translate('resources.sections.fields.sectionCode')}
          />
          <TextField
            source="sectionName"
            label={l18n.translate('resources.sections.fields.sectionName')}
          />
          <BooleanField
            source="taxReduction"
            label={l18n.translate('resources.sections.fields.taxReduction')}
          />
          {checkPermissions(permissions, '/groups/create') && (
            <EditButton
              data-cy="editSectionButton"
              variant="contained"
              disableElevation
            />
          )}
          {checkPermissions(permissions, '/groups/create') && (
            <DeleteWithConfirmButton />
          )}
        </MyDatagridRow>
      </Table>
    </div>
  );
};

const GroupListPage = ({
  globalFilterReducer,
  dispatch,
  permissions,
  ...props
}) => {
  return (
    <List
      {...props}
      actions={<CustomAction />}
      pagination={<GeneralPagination />}
      className="table-responsive"
      bulkActionButtons={false}
    >
      <Datagrid
        expand={
          <SectionPanel record={props.record} permissions={permissions} />
        }
      >
        <TextField source="id" />
        <TextField source="groupCode" />
        <TextField source="groupName" />
        <BooleanField source="taxReduction" />
        {checkPermissions(permissions, '/companies') && (
          <ReferenceField
            source="companyId"
            reference={Constants.RESOURCE_COMPANIES}
            link={false}
            basePath={props.basePath}
            record={props.record}
            label="resources.groups.fields.company.companyName"
          >
            <TextField source="companyName" />
          </ReferenceField>
        )}
        {checkPermissions(permissions, '/groups/create') && (
          <EditButton
            basePath={props.basePath}
            data-cy="editGroupButton"
            variant="contained"
            disableElevation
          />
        )}
        {checkPermissions(permissions, '/groups/create') && (
          <DeleteWithConfirmButton data-cy="deleteGroupButton" />
        )}
      </Datagrid>
    </List>
  );
};

const GroupList = loadGlobalFilter(GroupListPage);

const GroupCreatePage = ({ globalFilterReducer, ...props }) => (
  <Create {...props} className="table-responsive">
    <GroupCreateForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Create>
);

const GroupCreate = loadGlobalFilter(GroupCreatePage);

const GroupEditPage = ({ globalFilterReducer, ...props }) => (
  <Edit {...props} undoable={false} className="table-responsive">
    <GroupEditForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Edit>
);

const GroupEdit = loadGlobalFilter(GroupEditPage);

export { GroupList as List, GroupCreate as Create, GroupEdit as Edit };
