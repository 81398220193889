import { Option } from 'containers/App/types';

const scope: string = 'resources.shops.fields';

const shopPayment: Option[] = [
  {
    id: 1,
    name: `${scope}.paymentObj.immediate`
  },
  {
    id: 2,
    name: `${scope}.paymentObj.buffet`
  }
];

const shopPaymentMethod: Option[] = [
  {
    id: 1,
    name: `${scope}.paymentMethodObj.pointPlus`
  }
];

const beerOrderMode: Option[] = [
  {
    id: 1,
    name: `${scope}.beerOrderModeObj.usage`
  }
];

const validColor = '#81c784',
    invalidColor = '#e57373',
    textColor = '#fff';

export { shopPayment, shopPaymentMethod, beerOrderMode, validColor, invalidColor, textColor };
