const messages = {
  'beer-servers': {
    name: 'Beer Server List',
    createTitle: 'Create Beer Server',
    updateTitle: 'Update Beer Server',
    fields: {
      id: 'ID',
      serverCode: 'Server CD',
      obnizId: 'Obniz Id',
      shop: {
        id: 'Affiliation Shop',
        shopCode: 'Shop Code',
        shopName: 'ShopName'
      },
      company: {
        id: 'Affiliation Company',
        companyName: 'Company Name'
      },
      startUsingAt: 'Start Using At',
      isOnline : 'Is Online'
    },
    validation: {
      serverCode: {
        format: 'Please use alphabet, number only for Goods Code.'
      }
    },
    headings : {
      tapBeerStatistic : 'Tap Beer Remaining'
    }
  }
};

export default messages;
