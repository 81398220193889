import { PATTERN_CODE, PATTERN_TIME } from 'containers/Common/patterns';
import { required, maxLength, regex, number } from 'react-admin';
import l18n from 'containers/I18nProvider/index';

/**
 * [Get validation schema for creating shop]
 * @return  {[Object]} schema
 */
const getCreateShopValidationSchema = () => ({
  'company.id': { selected: true, number: true, minNumber: 1 },
  shopCode: { required: true, maxLength: 30, pattern: PATTERN_CODE },
  shopName: { required: true, maxLength: 100, emojis: true },
  // shopPrintableName: { required: true, maxLength: 100, emojis: true },
  postalCode: { required: true, maxLength: 100, emojis: true },
  address: { required: true, maxLength: 100, emojis: true },
  phoneNumber: { required: true, maxLength: 100 },
  paymentType: { selected: true, number: true, betweenNumber: [1, 2] },
  paymentMethod: { selected: true, number: true, betweenNumber: [1, 2] },
  beerOrderMode: { selected: true, number: true, betweenNumber: [1, 2] },
  notificationStartTime: { required: true, pattern: PATTERN_TIME },
  notificationEndTime: { required: true, pattern: PATTERN_TIME },
  notificationEmails: { required: true, multiEmails: true },
  businessStartTime: { required: true, pattern: PATTERN_TIME },
  businessEndTime: { required: true, pattern: PATTERN_TIME },
  logo1: { selected: true, image: true },
  logo2: { selected: true, image: true },
  receiptHeader: { required: true, maxLength: 255 },
  receiptFooter: { required: true, maxLength: 255 },
  receiptStamp: { maxLength: 255 }
});

const validateShopCode = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.shopCode')
    })}`
  ),
  maxLength(
    30,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.shopCode'),
      max: 30
    })}`
  ),
  regex(PATTERN_CODE)
];

const validateShopName = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.shopName')
    })}`
  ),
  maxLength(
    100,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.shopName'),
      max: 100
    })}`
  )
];

const validatePaymentType = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.paymentType')
    })}`
  )
];

const validatePaymentMethod = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.paymentMethod')
    })}`
  )
];

const validateBeerOrderMode = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.beerOrderMode')
    })}`
  )
];

const validateNotificationTime = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.notificationTime')
    })}`
  )
];

const validateBusinessTime = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.businessTime')
    })}`
  )
];

const validatePostalCode = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.shopPostalCode')
    })}`
  ),
  maxLength(
    100,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.shopPostalCode'),
      max: 100
    })}`
  )
];

const validateAddress = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.shopAddress')
    })}`
  ),
  maxLength(
    100,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.shopAddress'),
      max: 100
    })}`
  )
];

const validatePhoneNumber = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.shopPhone')
    })}`
  ),
  maxLength(
    100,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.shopPhone'),
      max: 100
    })}`
  )
];

const validateNotificationEmails = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.notificationEmails')
    })}`
  ),
  maxLength(
    255,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.notificationEmails'),
      max: 255
    })}`
  )
];

const validateLogo1 = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.logo1')
    })}`
  )
];

const validateLogo2 = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.logo1')
    })}`
  )
];

const validateReceiptHeader = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.receiptHeader')
    })}`
  ),
  maxLength(
    255,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.receiptHeader'),
      max: 255
    })}`
  )
];

const validateReceiptFooter = [
  required(
    `${l18n.translate('ra.validation.required', {
      attribute: l18n.translate('resources.shops.fields.receiptFooter')
    })}`
  ),
  maxLength(
    255,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.receiptFooter'),
      max: 255
    })}`
  )
];

const validateReceiptStamp = [
  maxLength(
    255,
    `${l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate('resources.shops.fields.receiptStamp'),
      max: 255
    })}`
  )
];

const validateBeerPouringErrorCorrectionValue = [
  number(
    `${l18n.translate('ra.validation.number', {
      attribute: l18n.translate(
        'resources.shops.fields.beerPouringErrorCorrectionValue'
      )
    })}`
  )
];

export {
  getCreateShopValidationSchema,
  validateShopCode,
  validateShopName,
  validatePaymentType,
  validatePaymentMethod,
  validateBeerOrderMode,
  validateNotificationTime,
  validateBusinessTime,
  validatePostalCode,
  validateAddress,
  validatePhoneNumber,
  validateNotificationEmails,
  validateLogo1,
  validateLogo2,
  validateReceiptHeader,
  validateReceiptFooter,
  validateReceiptStamp,
  validateBeerPouringErrorCorrectionValue
};
