import React from 'react';
import { Button, useSafeSetState } from 'react-admin';
import { CSVLink } from 'react-csv';
import l18n from 'containers/I18nProvider/index';
import ExportIcon from '@material-ui/icons/CloudDownload';
import { Paper, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataTable from 'react-data-table-component';
import { customDataTableStyles } from 'containers/Common/styles';
import { loadGlobalFilter } from 'containers/Common/hocs';
import { getListDataByCompanyId } from 'containers/Common/api';
import moment from 'moment';
import dataProvider from 'providers/data.provider';
import {
  renderReferenceGoodsCode,
  renderReferenceTapBeerCode,
  renderReferenceGoodsName,
  renderReferenceTapBeerName
} from '../SaleHistory/constants';
import DataTablePagination from 'components/DataTablePagination';

// const DEPARTMENT = ['タップビア', '缶', 'ボトル'];
const ODER_OPTIONS = {
  SUB_TOTAL: 'sub_total',
  COUNT_ITEM: 'count_item',
  PROFIT: 'profit'
};

const ProductHistory = (props) => {
  document.title = document.title =
    process.env.REACT_APP_WEBSITE_NAME +
    ' - ' +
    l18n.translate('resources.product-history.pageTitle');
  const columns = [
    {
      name: l18n.translate('resources.product-history.ranking'),
      format: (row, index) => `${index + 1}`,
      selector: 'tap_beer_id',
      width: '70px'
    },
    {
      selector: 'goods_id',
      name: l18n.translate('resources.product-history.productCode'),
      format: (row) =>
        row.goods_id
          ? renderReferenceGoodsCode(props.basePath, row.goods_id)
          : renderReferenceTapBeerCode(props.basePath, row.tap_beer_id)
    },
    {
      selector: 'goods_id',
      name: l18n.translate('resources.product-history.beerName'),
      format: (row) =>
        row.goods_id
          ? renderReferenceGoodsName(props.basePath, row.goods_id)
          : renderReferenceTapBeerName(props.basePath, row.tap_beer_id)
    },
    {
      selector: 'profit',
      name: l18n.translate('resources.product-history.earning'),
      type: 'number',
      sortable: true,
      format: (row) => row.profit.toLocaleString('ja-JP'),
      right: true,
      width: '150px'
    }
  ];

  const [data, setData] = useSafeSetState([]);

  React.useEffect(() => {
    (async function fetchData() {
      const results = await getListDataByCompanyId(
        dataProvider,
        'sale-logs/best-seller',
        {
          filter: {
            q: {
              shop_id: props.globalFilterReducer.shop?.id,
              startDate: moment().format('YYYYMMDD'),
              endDate: moment().format('YYYYMMDD'),
              order: ODER_OPTIONS.SUB_TOTAL
            }
          }
        }
      );
      setData(results);
    })();
  }, [props.globalFilterReducer.shop?.id, setData]);

  return (
    <div style={{ padding: '1rem' }}>
      <Paper style={{ padding: '1rem' }}>
        <Grid container spacing={0}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <CSVLink
              data={data}
              filename={`Product_History_Report_${moment().format('LLL')}.csv`}
              data-cy="downloadProductHistory"
            >
              <Button
                label={l18n.translate('ra.action.download')}
                startIcon={<ExportIcon />}
                variant="outlined"
                style={{ float: 'right' }}
              />
            </CSVLink>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ paddingTop: '1rem' }}
            data-cy="ProductHistoryDataTable"
          >
            <DataTable
              data={data}
              columns={columns}
              defaultSortField="sale"
              defaultSortAsc={false}
              pagination
              paginationComponent={DataTablePagination}
              paginationPerPage={50}
              sortIcon={<ExpandMoreIcon />}
              striped
              highlightOnHover
              customStyles={customDataTableStyles}
              dense
              noHeader
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default loadGlobalFilter(ProductHistory);
