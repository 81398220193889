const messages = {
  shops: {
    name: '店舗',
    createTitle: '店舗作成',
    updateTitle: '店舗編集',
    fields: {
      id: 'ID',
      shopName: '店舗名',
      shopPrintableName: 'レシート印字店舗',
      shopPostalCode: 'レシート印字郵便番号',
      shopAddress: 'レシート印字住所',
      shopPhone: 'レシート印字電話番号',
      shopCode: '店舗コード',
      paymentType: '決済タイミング',
      paymentMethod: '決済手段',
      beerOrderMode: 'ビール購入モード',
      notificationTime: '通知時間',
      notificationStartTime: '通知開始時間',
      notificationEndTime: '通知終了時間',
      notificationEmails: '通知先メール',
      businessTime: '事業時間',
      businessStartTime: '事業開始時間',
      businessEndTime: '事業終了時間',
      company: {
        id: '会社所有'
      },
      paymentObj: {
        immediate: '即時',
        buffet: '飲み放題'
      },
      paymentMethodObj: {
        pointPlus: 'PointPlus'
      },
      beerOrderModeObj: {
        usage: '従量'
      },
      logo1: 'ショップロゴ1',
      logo2: 'ショップロゴ2',
      receiptHeader: '領収書ヘッダー',
      receiptFooter: 'レシートフッター',
      receiptStamp: 'レシートスタンプ',
      beerPouringErrorCorrectionValue: '標準誤差 (ml)'
    },
    validation: {
      shopCode: {
        format: '店舗コードは半角英数字でご入力ください。'
      },
      notificationStartTime: {
        format: '通知開始時間はHH:mmの形式でご入力ください。'
      },
      notificationEndTime: {
        format: '通知終了時間はHH:mmの形式でご入力ください。'
      },
      businessStartTime: {
        format: '事業開始時間はHH:mmの形式でご入力ください。'
      },
      businessEndTime: {
        format: '事業終了時間はHH:mmの形式でご入力ください。'
      }
    },
    helpBlock: {
      notificationEmails: 'メールアドレス形式。,又は;で分割。'
    },
    openTime: '営業時間',
    closeTime: 'クローズタイム',
    openShop: 'オープン',
    closeShop: 'クローズ'
  }
};

export default messages;
