import GeneralPagination from 'components/GeneralPagination';
import { loadGlobalFilter } from 'containers/Common/hocs';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  ReferenceField,
  SelectField,
  downloadCSV
} from 'react-admin';
import { Option } from 'containers/App/types';
import FilterSidebar from './Filters';
import DateField from 'components/DateField';
import * as TopUp from 'containers/Resources/TopUp';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import l18n from 'containers/I18nProvider/index';
import jsonExport from 'jsonexport/dist';
import CommonAction from 'components/Actions';
import moment from 'moment';

const exporter = (data) =>
  jsonExport(data, {
    headers: [
      "id",
"pointPlusId",
"receiptCode",
"companyId",
"shopId",
"paymentMethod",
"totalSellingPrice",
"purchaseCost",
"totalTax",
"balanceBefore",
"balanceAfter",
"takeAway",
"reportDate",
"reportWeek",
"reportMonth",
"reportYear",
"countItem",
"countPrinted",
"isIssued",
"createDeviceId",
"createdAt",
"saleLogDetails.id",
"saleLogDetails.saleLogId",
"saleLogDetails.goodsId",
"saleLogDetails.goodsData.id",
"saleLogDetails.goodsData.groupId",
"saleLogDetails.goodsData.janCode",
"saleLogDetails.goodsData.taxRate",
"saleLogDetails.goodsData.imageKey",
"saleLogDetails.goodsData.imageUrl",
"saleLogDetails.goodsData.companyId",
"saleLogDetails.goodsData.goodsCode",
"saleLogDetails.goodsData.goodsName",
"saleLogDetails.goodsData.sectionId",
"saleLogDetails.goodsData.description",
"saleLogDetails.goodsData.purchaseCost",
"saleLogDetails.goodsData.sellingPrice",
"saleLogDetails.goodsData.taxReduction",
"saleLogDetails.goodsData.goodsShortName",
"saleLogDetails.tapBeerId",
"saleLogDetails.tapBeerData",
"saleLogDetails.amount",
"saleLogDetails.purchaseCost",
"saleLogDetails.sellingPrice",
"saleLogDetails.tax",
"saleLogDetails.taxRate",
"saleLogDetails.goodsData",
"saleLogDetails.tapBeerData.id",
"saleLogDetails.tapBeerData.ibu",
"saleLogDetails.tapBeerData.smell",
"saleLogDetails.tapBeerData.sweet",
"saleLogDetails.tapBeerData.bitter",
"saleLogDetails.tapBeerData.colour",
"saleLogDetails.tapBeerData.weight",
"saleLogDetails.tapBeerData.acidity",
"saleLogDetails.tapBeerData.alcohol",
"saleLogDetails.tapBeerData.brewery",
"saleLogDetails.tapBeerData.groupId",
"saleLogDetails.tapBeerData.taxRate",
"saleLogDetails.tapBeerData.beerCode",
"saleLogDetails.tapBeerData.beerName",
"saleLogDetails.tapBeerData.imageKey",
"saleLogDetails.tapBeerData.imageUrl",
"saleLogDetails.tapBeerData.beerStyle",
"saleLogDetails.tapBeerData.breweryEn",
"saleLogDetails.tapBeerData.companyId",
"saleLogDetails.tapBeerData.countryId",
"saleLogDetails.tapBeerData.sectionId",
"saleLogDetails.tapBeerData.beerNameEn",
"saleLogDetails.tapBeerData.description",
"saleLogDetails.tapBeerData.purchaseCost",
"saleLogDetails.tapBeerData.sellingPrice",
"saleLogDetails.tapBeerData.beerShortName",
    ],
    rename: [
      "id",
      "ポイントプラスID2",
      "領収書コード",
      "会社コード",
      "ショップID",
      "支払方法",
      "総販売価格",
      "purchaseCost",
      "税金",
      "決済前残高",
      "決済後残高",
      "takeAway",
      "reportDate",
      "reportWeek",
      "reportMonth",
      "reportYear",
      "売上",
      "countPrinted",
      "isIssued",
      "端末Id",
      "createdAt",
      "saleLogDetails.id",
      "saleLogDetails.saleLogId",
      "商品コード",
      "saleLogDetails.goodsData.id",
      "saleLogDetails.goodsData.groupId",
      "saleLogDetails.goodsData.janCode",
      "saleLogDetails.goodsData.taxRate",
      "saleLogDetails.goodsData.imageKey",
      "saleLogDetails.goodsData.imageUrl",
      "saleLogDetails.goodsData.companyId",
      "saleLogDetails.goodsData.goodsCode",
      "saleLogDetails.goodsData.goodsName",
      "saleLogDetails.goodsData.sectionId",
      "saleLogDetails.goodsData.description",
      "saleLogDetails.goodsData.purchaseCost",
      "saleLogDetails.goodsData.sellingPrice",
      "saleLogDetails.goodsData.taxReduction",
      "saleLogDetails.goodsData.goodsShortName",
      "ビールコード",
      "saleLogDetails.tapBeerData",
      "saleLogDetails.amount",
      "saleLogDetails.purchaseCost",
      "saleLogDetails.sellingPrice",
      "saleLogDetails.tax",
      "saleLogDetails.taxRate",
      "saleLogDetails.goodsData",
      "saleLogDetails.tapBeerData.id",
      "saleLogDetails.tapBeerData.ibu",
      "saleLogDetails.tapBeerData.smell",
      "saleLogDetails.tapBeerData.sweet",
      "saleLogDetails.tapBeerData.bitter",
      "saleLogDetails.tapBeerData.colour",
      "saleLogDetails.tapBeerData.weight",
      "saleLogDetails.tapBeerData.acidity",
      "saleLogDetails.tapBeerData.alcohol",
      "saleLogDetails.tapBeerData.brewery",
      "saleLogDetails.tapBeerData.groupId",
      "saleLogDetails.tapBeerData.taxRate",
      "saleLogDetails.tapBeerData.beerCode",
      "saleLogDetails.tapBeerData.beerName",
      "saleLogDetails.tapBeerData.imageKey",
      "saleLogDetails.tapBeerData.imageUrl",
      "saleLogDetails.tapBeerData.beerStyle",
      "saleLogDetails.tapBeerData.breweryEn",
      "saleLogDetails.tapBeerData.companyId",
      "saleLogDetails.tapBeerData.countryId",
      "saleLogDetails.tapBeerData.sectionId",
      "saleLogDetails.tapBeerData.beerNameEn",
      "saleLogDetails.tapBeerData.description",
      "saleLogDetails.tapBeerData.purchaseCost",
      "saleLogDetails.tapBeerData.sellingPrice",
      "saleLogDetails.tapBeerData.beerShortName"
    ]
  }, (err, csv) => {
    downloadCSV(csv, `sale_logs_${moment().format('LLL')}`);
  });

const scope: string = 'resources.sale-logs.fields';

export const PAYMENT_METHOD: Option[] = [
  {
    id: 1,
    name: `${scope}.payment_house_money`
  },
  {
    id: 2,
    name: `${scope}.payment_cash`
  },
  {
    id: 3,
    name: `${scope}.payment_emoney`
  },
  {
    id: 4,
    name: `${scope}.payment_credit_card`
  },
  {
    id: 5,
    name: `${scope}.payment_qr`
  },
  {
    id: 6,
    name: `${scope}.payment_others`
  }
];

const SaleLogDetailPanel = (props) => (
  <ArrayField source="saleLogDetails" {...props}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        label={`${scope}.saleLogDetails.goodsId`}
        source="saleLogDetails.goodsId"
        reference="goods"
        link={false}
      >
        <TextField source="goodsCode" />
      </ReferenceField>
      <ReferenceField
        label={`${scope}.saleLogDetails.tapBeerId`}
        source="tapBeerId"
        reference="tap-beers"
        link={false}
      >
        <TextField source="beerName" />
      </ReferenceField>
      <TextField label={`${scope}.saleLogDetails.amount`} source="amount" />
      <TextField
        label={`${scope}.saleLogDetails.sellingPrice`}
        source="sellingPrice"
      />
      <TextField
        label={`${scope}.saleLogDetails.purchaseCost`}
        source="purchaseCost"
      />
    </Datagrid>
  </ArrayField>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
export const SaleLogListPage = ({ globalFilterReducer, ...props }) => {
  const useStyles = makeStyles({
      default_tabStyle: {
        color: '#333333',
        fontSize: 13,
        backgroundColor: '#cccccc',
        minHeight: 30
      },
      active_tabStyle: {
        fontSize: 13,
        color: '#ffffff',
        backgroundColor: '#555555',
        fontWeight: 'bold',
        minHeight: 30
      }
    }),
    classes = useStyles(),
    [value, setValue] = React.useState(0),
    handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#000000'
          }
        }}
        style={{ margin: '1rem 0px 0px 0px', minHeight: 30 }}
      >
        <Tab
          label={l18n.translate('resources.sale-logs.saleTab')}
          {...a11yProps(0)}
          data-cy="saleTab"
          className={
            value === 0 ? classes.active_tabStyle : classes.default_tabStyle
          }
        />
        <Tab
          label={l18n.translate('resources.sale-logs.topUpTab')}
          {...a11yProps(1)}
          data-cy="topUpTab"
          className={
            value === 1 ? classes.active_tabStyle : classes.default_tabStyle
          }
        />
      </Tabs>
      <TabPanel value={value} index={0} style={{ border: '0.5px solid #ddd' }}>
        <List
          {...props}
          actions={<CommonAction showExport={true} hide={true} />}
          pagination={<GeneralPagination />}
          perPage={50}
          aside={<FilterSidebar />}
          bulkActionButtons={false}
          className="table-responsive"
          sort={{ field: 'id', order: 'DESC' }}
          exporter={exporter}
        >
          <Datagrid expand={SaleLogDetailPanel}>
            <TextField source="id" sortable={false} />
            <SelectField
              choices={PAYMENT_METHOD}
              source="paymentMethod"
              sortable={false}
            />
            <TextField source="totalSellingPrice" sortable={false} />
            <TextField source="totalTax" sortable={false} />
            <DateField source="createdAt" sortable={false} />
          </Datagrid>
        </List>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        style={{ padding: '1em', border: '0.5px solid #ddd' }}
      >
        <TopUp.List resource="topup" basePath={props.basePath} />
      </TabPanel>
    </div>
  );
};
const SaleLogList = loadGlobalFilter(SaleLogListPage);

export { SaleLogList as List };
