import * as React from 'react';
import PropTypes from 'prop-types';
import { RESOURCE_SECTION } from 'containers/Common/constants';
import { useGetOne } from 'react-admin';
import Loading from 'components/LoadingIndicator';
import { memo } from 'react';

const SectionField = ({ record }) => {
  const { data, loading, error } = useGetOne(
    RESOURCE_SECTION,
    record.sectionId
  );
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return <span>{data.sectionName}</span>;
};

SectionField.propTypes = {
  record: PropTypes.object.isRequired,
  label: PropTypes.string
};

export default memo(SectionField);
