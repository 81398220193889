import { TableCell, TableRow } from '@material-ui/core';
import { CompanyInput } from 'components/InputCommon/index';
// Components
import TableForm from 'components/TableForm';
import { AlertCommon } from 'containers/Common/alert';
import * as Constants from 'containers/Common/constants';
// Containers
import {
  convertMultiEmails,
  getBase64,
  handleSubmitFactory,
  prepareImage
} from 'containers/Common/helper';
import { loadGlobalFilter } from 'containers/Common/hocs';
import l18n from 'containers/I18nProvider/index';
import React from 'react';
import {
  ImageField,
  ImageInput,
  Loading,
  NumberInput,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  useSafeSetState
} from 'react-admin';
// Local
import { beerOrderMode, shopPayment, shopPaymentMethod } from '../constants';
import {
  validateAddress,
  validateBeerOrderMode,
  validateBeerPouringErrorCorrectionValue,
  validateBusinessTime,
  validateLogo1,
  validateLogo2,
  validateNotificationEmails,
  validateNotificationTime,
  validatePaymentMethod,
  validatePaymentType,
  validatePhoneNumber,
  validatePostalCode,
  validateReceiptFooter,
  validateReceiptHeader,
  validateReceiptStamp,
  validateShopCode,
  validateShopName
} from './validations';

const AddForm = ({ globalFilterReducer, ...props }) => {
  const { company, availableCompanies } = globalFilterReducer,
    onTransform = async (values) => {
      if (values.notificationEmails) {
        values.notificationEmails = convertMultiEmails(
          values.notificationEmails
        );
      }

      const imageLogo1Result = await getBase64(values, 'logo1');
      if (imageLogo1Result) {
        values['logo1'] = prepareImage(imageLogo1Result);
      }

      const imageLogo2Result = await getBase64(values, 'logo2');
      if (imageLogo2Result) {
        values['logo2'] = prepareImage(imageLogo2Result);
      }

      return values;
    };
  const resource = props.resource,
    [create] = useCreate(resource),
    notify = useNotify(),
    redirectTo = useRedirect(),
    [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    });

  const handleSubmit = handleSubmitFactory({
    resource,
    buildValidationSchema: () => ({}),
    setState,
    notify,
    redirectTo: () => redirectTo(`/${Constants.RESOURCE_SHOPS}`),
    onSubmit: create,
    onTransform,
    state
  });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />
      <TableForm {...props} save={handleSubmit}>
        <CompanyInput
          company={company}
          availableCompanies={availableCompanies}
          authority={props.permissions}
          title={l18n.translate('resources.shops.fields.company.id')}
          required
        />

        <TextInput
          title={l18n.translate('resources.shops.fields.shopCode')}
          required
          source="shopCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitCodeMax', { max: 30 })}
          validate={validateShopCode}
        />

        <TextInput
          title={l18n.translate('resources.shops.fields.shopName')}
          required
          source="shopName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
          validate={validateShopName}
        />

        <SelectInput
          title={l18n.translate('resources.shops.fields.paymentType')}
          required
          source="paymentType"
          choices={shopPayment}
          className="form-control custom-select no-help-block"
          validate={validatePaymentType}
        />

        <SelectInput
          title={l18n.translate('resources.shops.fields.paymentMethod')}
          required
          source="paymentMethod"
          choices={shopPaymentMethod}
          className="form-control custom-select no-help-block"
          validate={validatePaymentMethod}
        />

        <SelectInput
          title={l18n.translate('resources.shops.fields.beerOrderMode')}
          required
          source="beerOrderMode"
          choices={beerOrderMode}
          className="form-control custom-select no-help-block"
          validate={validateBeerOrderMode}
        />

        <TableRow>
          <TableCell className="table-item-name">
            {l18n.translate('resources.shops.fields.notificationTime')}
            <span className="required-asterisk">*</span>
          </TableCell>
          <TableCell className="input-time">
            <TextInput
              type="time"
              source="notificationStartTime"
              className="form-control no-help-block"
              validate={validateNotificationTime}
            />
            <span>〜</span>
            <TextInput
              type="time"
              source="notificationEndTime"
              className="form-control no-help-block"
              validate={validateNotificationTime}
            />
          </TableCell>
        </TableRow>

        <TextInput
          title={l18n.translate('resources.shops.fields.notificationEmails')}
          multiline
          rows="3"
          source="notificationEmails"
          className="form-control"
          required
          helperText={l18n.translate(
            'resources.shops.helpBlock.notificationEmails'
          )}
          validate={validateNotificationEmails}
        />

        <TableRow key="businessTime">
          <TableCell className="table-item-name">
            {l18n.translate('resources.shops.fields.businessTime')}
            <span className="required-asterisk">*</span>
          </TableCell>
          <TableCell className="input-time">
            <TextInput
              type="time"
              source="businessStartTime"
              className="form-control no-help-block"
              validate={validateBusinessTime}
            />
            <span>〜</span>
            <TextInput
              type="time"
              source="businessEndTime"
              className="form-control no-help-block"
              validate={validateBusinessTime}
            />
          </TableCell>
        </TableRow>

        <NumberInput
          title={l18n.translate(
            'resources.shops.fields.beerPouringErrorCorrectionValue'
          )}
          source="beerPouringErrorCorrectionValue"
          className="form-control"
          min={-2147483647}
          max={2147483647}
          validate={validateBeerPouringErrorCorrectionValue}
        />

        <TableRow key="partitionDivider">
          <TableCell style={{ textAlign: 'right' }}>
            {l18n.translate('resources.companies.receiptInfo')}
          </TableCell>
          <TableCell></TableCell>
        </TableRow>

        <TextInput
          title={l18n.translate('resources.shops.fields.shopPostalCode')}
          required
          source="postalCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
          validate={validatePostalCode}
        />

        <TextInput
          title={l18n.translate('resources.shops.fields.shopAddress')}
          required
          source="address"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
          validate={validateAddress}
        />

        <TextInput
          title={l18n.translate('resources.shops.fields.shopPhone')}
          required
          source="phoneNumber"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
          validate={validatePhoneNumber}
        />

        <ImageInput
          title={l18n.translate('resources.tap-beers.fields.logo1')}
          required
          source="logo1"
          label="Shop Logo 1"
          accept=".jpg,.jpeg,.png"
          className="img-input"
          helperText={l18n.translate('ra.helpBlock.extension')}
          validate={validateLogo1}
        >
          <ImageField source="logo1" />
        </ImageInput>

        <ImageInput
          title={l18n.translate('resources.tap-beers.fields.logo2')}
          required
          source="logo2"
          label="Shop Logo 2"
          accept=".jpg,.jpeg,.png"
          className="img-input"
          helperText={l18n.translate('ra.helpBlock.extension')}
          validate={validateLogo2}
        >
          <ImageField source="logo2" />
        </ImageInput>

        <TextInput
          title={l18n.translate('resources.shops.fields.receiptHeader')}
          required
          source="receiptHeader"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
          validate={validateReceiptHeader}
        />

        <TextInput
          title={l18n.translate('resources.shops.fields.receiptFooter')}
          required
          source="receiptFooter"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
          validate={validateReceiptFooter}
        />

        <TextInput
          title={l18n.translate('resources.shops.fields.receiptStamp')}
          source="receiptStamp"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
          validate={validateReceiptStamp}
        />
      </TableForm>
    </>
  );
};

export default loadGlobalFilter(AddForm);
