import React, { useEffect } from 'react';
import { Button, ReduxState, useSafeSetState, useNotify } from 'react-admin';
import { CSVLink } from 'react-csv';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import l18n from 'containers/I18nProvider/index';
import ExportIcon from '@material-ui/icons/CloudDownload';
import { Paper, Grid } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { currency, DATE_FORMAT_DATE_ONLY } from 'containers/App/constants';
import { datepickerTheme } from 'containers/Common/styles';
import { ThemeProvider } from '@material-ui/styles';
import { getReportData } from 'containers/Common/api';
import Loading from 'components/LoadingIndicator';
import { env } from 'environment';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { ExtractedSaleData } from 'containers/Common/interfaces';
import {
  buildChartData,
  renderPrice,
  sumValueAtColumnIndex
} from 'containers/Common/helper';

const LABELS_MONTH_KEYS = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
];
const LABELS_MONTH = {
  '01': '1月',
  '02': '2月',
  '03': '3月',
  '04': '4月',
  '05': '5月',
  '06': '6月',
  '07': '7月',
  '08': '8月',
  '09': '9月',
  '10': '10月',
  '11': '11月',
  '12': '12月'
};

const YearlyTable = (props) => {
  document.title = document.title =
    process.env.REACT_APP_WEBSITE_NAME +
    ' - ' +
    l18n.translate('resources.sale-report-by-hour.pageTitle');
  const [currentYear, setStartDate] = useSafeSetState(moment()),
    { loadingCompany, loadingShop, shop } = useSelector(
      (state: ReduxState) => state.globalFilterReducer
    ),
    [chartData, setChartData] = useSafeSetState({
      labels: LABELS_MONTH_KEYS,
      datasets: [
        {
          //   label: 'My First dataset',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    }),
    // @ts-ignore
    [saleData, setSaleData] = useSafeSetState([]),
    extractChartData = (rawSaleData): ExtractedSaleData => {
      if (rawSaleData?.length === 0) {
        return {
          labels: [],
          data: []
        };
      }

      return {
        labels: LABELS_MONTH_KEYS,
        data: LABELS_MONTH_KEYS.map((hour) => {
          for (let index = 0; index < rawSaleData?.length; index++) {
            const rawSaleDataSale = rawSaleData[index];
            if (String(rawSaleDataSale.block) === String(hour)) {
              return parseFloat(rawSaleDataSale.sale);
            }
          }
          return 0;
        })
      };
    },
    notify = useNotify();

  useEffect(() => {
    (async function fetchData() {
      const transformChartData = (rawSaleData) => {
        const { labels, data: chartData } = extractChartData(
          rawSaleData?.salePerDay
        );
        return buildChartData(labels, chartData);
      };
      const transformSaleData = (rawSaleData): any[] => {
        return LABELS_MONTH_KEYS.map((hour) => {
          for (let index = 0; index < rawSaleData?.length; index++) {
            const rawSaleDataSale = rawSaleData[index];
            if (String(rawSaleDataSale.block.slice(-2)) === String(hour)) {
              return [
                LABELS_MONTH[hour],
                parseFloat(rawSaleDataSale.sale).toFixed(2),
                (
                  parseFloat(rawSaleDataSale.sale) -
                  parseFloat(rawSaleDataSale.tax)
                ).toFixed(2),
                parseFloat(rawSaleDataSale.tax).toFixed(2),
                '-',
                parseFloat(rawSaleDataSale.profit).toFixed(2),
                '-',
                '-',
                parseFloat(rawSaleDataSale.count_item).toFixed(2),
                parseFloat(rawSaleDataSale.count_transaction).toFixed(2),
                (
                  parseFloat(rawSaleDataSale.purchaseCost) /
                  parseFloat(rawSaleDataSale.count_item)
                ).toFixed(2),
                (rawSaleDataSale.totalCustomer?parseFloat(rawSaleDataSale.totalCustomer):0),
                (rawSaleDataSale.customerUnitPrice?parseFloat(rawSaleDataSale.customerUnitPrice):0),
                (rawSaleDataSale.paymentMethod_1?parseFloat(rawSaleDataSale.paymentMethod_1).toFixed(2):0),
                (rawSaleDataSale.paymentMethod_2?parseFloat(rawSaleDataSale.paymentMethod_2).toFixed(2):0),
                (rawSaleDataSale.paymentMethod_3?parseFloat(rawSaleDataSale.paymentMethod_3).toFixed(2):0),
                (rawSaleDataSale.paymentMethod_4?parseFloat(rawSaleDataSale.paymentMethod_4).toFixed(2):0),
                (rawSaleDataSale.paymentMethod_5?parseFloat(rawSaleDataSale.paymentMethod_5).toFixed(2):0),
                (rawSaleDataSale.paymentMethod_6?parseFloat(rawSaleDataSale.paymentMethod_6).toFixed(2):0)
              ];
            }
          }
          return [LABELS_MONTH[hour], 0, 0, 0, '-', 0, '-', '-', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        });
      };
      const url = `${env.baseApi}/sale-logs/yearly-report?${stringify({
        shop_id: shop?.id,
        year: moment(props.selectedDate).format('YYYY')
      })}`;

      const { json, error } = await getReportData(url);
      if (error) {
        notify(error);
      } else {
        setSaleData(transformSaleData(json?.salePerMonth));
        const chartData = transformChartData(json);
        //   @ts-ignore
        setChartData(chartData);
      }
    })();
  }, [props.selectedDate, shop?.id, setChartData, setSaleData, notify]);

  if (loadingCompany || loadingShop) return <Loading />;

  const csvFileName = `Sale_Report_By_Hour_Of_Shop_#${
    shop?.id
  }_From_${currentYear.format(DATE_FORMAT_DATE_ONLY)}.csv`;
  let csvData = [
    [
      l18n.translate('ra.common.timeZone'),
      l18n.translate('ra.common.totalSale'),
      l18n.translate('ra.common.totalSale') +
        ' ' +
        l18n.translate('ra.common.taxExcluded'),
      l18n.translate('ra.common.totalTax'),
      l18n.translate('ra.common.compositionRatio'),
      l18n.translate('ra.common.totalNetSale'),
      l18n.translate('ra.common.discount'),
      l18n.translate('ra.common.pointUsage'),
      l18n.translate('ra.common.soldQuantity') +
        ' ' +
        l18n.translate('ra.common.returnQuantity'),
      l18n.translate('ra.common.totalTransaction'),
      l18n.translate('ra.common.averageUnitCost'),
      l18n.translate('ra.common.totalCustomer'),
      l18n.translate('ra.common.customerUnitPrice')
    ],
    ...saleData
  ];

  csvData.push([
    l18n.translate('ra.common.total'),
    sumValueAtColumnIndex(saleData, 1),
    sumValueAtColumnIndex(saleData, 2),
    sumValueAtColumnIndex(saleData, 3),
    '-',
    sumValueAtColumnIndex(saleData, 5),
    '-',
    '-',
    sumValueAtColumnIndex(saleData, 8),
    sumValueAtColumnIndex(saleData, 9),
    sumValueAtColumnIndex(saleData, 10),
    sumValueAtColumnIndex(saleData, 11),
    sumValueAtColumnIndex(saleData, 12),
    '-',
    '-',
    '-',
    '-',
    '-',
    '-'
  ]);

  return (
    <div style={{ padding: '1rem' }}>
      <Paper style={{ padding: '1rem' }}>

        <Grid item xs={12} style={{ paddingTop: '1rem' }}>
          <Table>
            <TableHead className="borderTableHead">
              <TableRow>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  月
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalSale')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalSale')}
                  <br />({l18n.translate('ra.common.taxExcluded')})
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalTax')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.compositionRatio')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  colSpan={3}
                  style={{ textAlign: 'center' }}
                >
                  {l18n.translate('ra.common.averageUnitCost')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.soldQuantity')}
                  <br />({l18n.translate('ra.common.returnQuantity')})
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalTransaction')}
                </TableCell>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.averageUnitCost')}
                </TableCell>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalCustomer')}
                </TableCell>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.customerUnitPrice')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  colSpan={6}
                  style={{ textAlign: 'center' }}
                >
                  {"会計種別"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {l18n.translate('ra.common.totalNetSale')}
                </TableCell>
                <TableCell>{l18n.translate('ra.common.discount')}</TableCell>
                <TableCell>{l18n.translate('ra.common.pointUsage')}</TableCell>
                <TableCell>{"現金"}</TableCell>
                <TableCell>{"ハウスマネー"}</TableCell>
                <TableCell>{"電子マネー"}</TableCell>
                <TableCell>{"クレジットカード"}</TableCell>
                <TableCell>{"QR決済"}</TableCell>
                <TableCell>{"その他"}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="borderTableBody">
              {saleData.map((data) => (
                <TableRow key={data[0]}>
                  <TableCell>{data[0]}</TableCell>
                  <TableCell>{renderPrice(data[1])}</TableCell>
                  <TableCell>{renderPrice(data[2])}</TableCell>
                  <TableCell>{renderPrice(data[3])}</TableCell>
                  <TableCell>{renderPrice(data[4], '')}</TableCell>
                  <TableCell>{renderPrice(data[5])}</TableCell>
                  <TableCell>{renderPrice(data[6], '')}</TableCell>
                  <TableCell>{renderPrice(data[7], '')}</TableCell>
                  <TableCell>{renderPrice(data[8], '')}</TableCell>
                  <TableCell>{renderPrice(data[9], '')}</TableCell>
                  <TableCell>{renderPrice(data[10])}</TableCell>
                  <TableCell>{renderPrice(data[11], '')}</TableCell>
                  <TableCell>{renderPrice(data[12])}</TableCell>
                  <TableCell>{renderPrice(data[13])}</TableCell>
                  <TableCell>{renderPrice(data[14])}</TableCell>
                  <TableCell>{renderPrice(data[15])}</TableCell>
                  <TableCell>{renderPrice(data[16])}</TableCell>
                  <TableCell>{renderPrice(data[17])}</TableCell>
                  <TableCell>{renderPrice(data[18])}</TableCell>
                </TableRow>
              ))}

              <TableRow key="summary" className="dataTableFooter">
                <TableCell>
                  <strong>{l18n.translate('ra.common.total')}</strong>
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 1))}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 2))}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 3))}
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 5))}
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 8), '')}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 9), '')}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 10))}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 11), '')}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 12))}
                </TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 13))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 14))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 15))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 16))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 17))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 18))}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Paper>
    </div>
  );
};

export default YearlyTable;
