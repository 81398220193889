import { put, takeLatest, call } from 'redux-saga/effects';
import { httpClient } from 'network/httpClient';
import { reportDataLoaded } from '../Actions/reportData';
import {
  getSessionStorage,
  saveSessionStorage
} from 'containers/GlobalFilter/localStorageCaller';

const buildCacheKey = (url: String) => `reportData|${url}`;

export function* loadReportData(action) {
  if (action.payload) {
    const cachedKey = buildCacheKey(action.payload);
    const localStorageReportData = yield call(getSessionStorage, cachedKey);
    if (localStorageReportData) {
      return yield put(reportDataLoaded(JSON.parse(localStorageReportData)));
    }

    const { json } = yield call(httpClient, action.payload, {
      method: 'GET'
    });

    yield put(reportDataLoaded(json));
    yield call(saveSessionStorage, cachedKey, json);
  }
}

export default function* reportDataSaga() {
  yield takeLatest('FETCHING', loadReportData);
}
