import React from 'react';
import { TextInput } from 'react-admin';
import FilterCommon from 'components/Filters/index';
import l18n from 'containers/I18nProvider/index';

const CustomFilter = (props) => {
  return (
    <FilterCommon inputElement= {
      <TextInput
        label=""
        className="form-control"
        source="companyName"
        placeholder={l18n.translate('resources.companies.fields.companyName')}
      />
    } />
  );
};

export default CustomFilter;
