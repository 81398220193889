import React, { Fragment } from 'react';
import { SimpleForm, FormDataConsumer } from 'react-admin';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import CustomToolbar from 'components/Toolbar';

const TableForm = ({ extraComponents = null,transform = null, ...props }) => {
  const { children } = props;

  const generateChildElement = (childElement) => {
    if (typeof childElement !== 'object' || !childElement) return null;

    const type = childElement.type;

    // Loop fragment to generate element
    if (type === Fragment) return null;

    if (type === TableRow || type === FormDataConsumer) {
      return childElement;
    }

    // Add key to row and cell to avoid warning unique key of react
    const key = childElement.props?.id ?? childElement.props.source;

    return (
      <TableRow key={`${key}-row`}>
        <TableCell className="table-item-name" key={`${key}-row-title`}>
          {childElement.props.title}
          {childElement.props.required && (
            <span className="required-asterisk">*</span>
          )}
          {typeof childElement.props.children === 'object' &&
            childElement.props.children.props &&
            childElement.props.children.props.required && (
              <span className="required-asterisk">*</span>
            )}
        </TableCell>
        <TableCell key={`${key}-row-input`}>{childElement}</TableCell>
      </TableRow>
    );
  };

  return (
    <SimpleForm {...props} toolbar={props.toolbar ?? <CustomToolbar transform={transform}/>} submitOnEnter={false}>
      <>
        <Table className="input-form-table">
          <TableBody>
            {children.map((child) => {
              if (
                child &&
                typeof child === 'object' &&
                child.type === Fragment
              ) {
                const fragmentChildren = child.props.children;
                if (Array.isArray(fragmentChildren)) {
                  return fragmentChildren.map((item) => {
                    return generateChildElement(item);
                  });
                } else {
                  return generateChildElement(fragmentChildren);
                }
              }

              return generateChildElement(child);
            })}
          </TableBody>
        </Table>
        {extraComponents ?? null}
      </>
    </SimpleForm>
  );
};

export default TableForm;
