import { Grid, CardContent, Card, Paper, Tooltip } from '@material-ui/core';
import RealtimeChart from 'components/Statistics/Charts/RealtimeChart';
import Summary from 'components/Statistics/Summary';
import { getListData } from 'containers/Common/api';
import React from 'react';
import { useSafeSetState, useDataProvider } from 'react-admin';
import l18n from 'containers/I18nProvider/index';
import { customDataTableStyles } from 'containers/Common/styles';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import DateField from 'components/DateField';
import DataTablePagination from 'components/DataTablePagination';

const columns = [
  {
    name: l18n.translate('resources.beer-servers.fields.obnizId'),
    selector: 'obnizID',
    width: '120px'
  },
  {
    selector: 'message',
    name: l18n.translate('resources.error-logs.name'),
    format: (row) => (
      <p
        style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}
      >
        {row.message}
      </p>
    )
  },
  {
    name: l18n.translate('resources.error-logs.fields.occurredAt'),
    selector: 'occurredAt',
    width: '150px',
    format: (row: { occurredAt: String }) => (
      <DateField source="occurredAt" record={row} />
    )
  }
];
export const Dashboard = () => {
  let [errorLog, setErrorLog]: any[] = useSafeSetState([]);
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    const updateShopChoices = () => {
      getListData(dataProvider, 'error-logs', {
        sort: { field: 'occurredAt', order: 'DESC' },
        filter: {
          'occurredAt||$gte': moment().subtract(1, 'days').format('YYYYMMDD')
        }
      }).then((data) => {
        setErrorLog(data);
      });
    };
    updateShopChoices();
  }, [dataProvider, setErrorLog]);

  return (
    <Paper style={{ padding: '1rem' }}>
      <Grid container spacing={1} style={{ padding: '1rem' }}>
        <Grid item xs={12}>
          <h2>{l18n.translate('resources.error-logs.name')}</h2>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Tooltip
                title={l18n.translate('ra.common.showErrorLogFromYesterday')}
                arrow
                placement="top-start"
              >
                <InfoIcon color="primary" />
              </Tooltip>
              <DataTable
                data={errorLog}
                columns={columns}
                defaultSortField="sale"
                defaultSortAsc={false}
                pagination
                paginationComponent={DataTablePagination}
                striped
                highlightOnHover
                customStyles={customDataTableStyles}
                dense
                noHeader
                className="table-responsive"
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Summary />
        </Grid>
        <Grid item xs={9}>
          <RealtimeChart />
        </Grid>
      </Grid>
    </Paper>
  );
};
