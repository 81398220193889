import React from 'react';
import {
  TextInput,
  SelectInput,
  PasswordInput,
  FormDataConsumer,
  useUpdate,
  useNotify,
  useRedirect,
  useDataProvider
} from 'react-admin';
import { TableRow, TableCell } from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
// Providers
import { UserRole } from 'providers/auth.provider';
// Components
import ShopInput from 'components/ShopInput';
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { getAuthorityChoices } from 'containers/Resources/User/constants';
import { filterAuthorityChoicesByRole } from 'containers/Resources/User/utils';
import { AlertCommon } from 'containers/Common/alert';
import { handleSubmitFactory } from 'containers/Common/helper';
import l18n from 'containers/I18nProvider/index';
// Local
import { getEditUserValidationSchema } from './validations';
import { CompanyInput } from 'components/InputCommon/index';

const EditForm = ({ globalFilterReducer, ...props }) => {
  // Can not update companyId in case of authority company, shop and staff
  // If need to update companyId, create other account

  let [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    record = props.record,
    shopManagements = (record.shopManagements) ?? [],
    resource = props.resource,
    // Get company
    recordAuthority = record.authority,
    companyId = record.companyId,
    { availableCompanies } = globalFilterReducer,
    company = availableCompanies.find((item) => item.id === companyId),
    // Get authority choices
    // Filter by edited authority to avoid update authority from low to high authority
    // Only update staff to shop admin
    authorityChoices = filterAuthorityChoicesByRole(
      (recordAuthority === UserRole.STAFF) ? UserRole.SHOP : recordAuthority,
      getAuthorityChoices
    ),
    [update] = useUpdate(resource, record.id),
    notify = useNotify(),
    redirectTo = useRedirect(),
    dataProvider = useDataProvider(),
    onSuccessCallback = async ({ data, values }) => {
      // Attach account to shop
      let addedShopIds = [],
        removedShopManagements = [],
        formAuthority = Number(values.authority),
        shop = values.shop;

      if (
        formAuthority === UserRole.SYSTEM_ADMINISTRATOR ||
        formAuthority === UserRole.COMPANY_ADMINISTRATOR
      ) {
        // In case of update role system admin or company, delete all attached shop
        removedShopManagements = shopManagements;
      } else {
        // In case of shop or staff, filter by selected shop and attached shop of this account
        addedShopIds = shop.id.filter((shopId) => {
          return !shopOptions.includes(shopId);
        });

        shopManagements.forEach((item) => {
          if (!shop.id.includes(item.shopId)) {
            removedShopManagements.push(item);
          }
        });
      }

      // Attach account for shops
      if (addedShopIds.length > 0) {
        await Promise.all(
          addedShopIds.map((addedShopId) =>
            dataProvider.create('shop-managements', {
              data: {
                user: { id: record.id },
                shop: { id: addedShopId }
              }
            })
          )
        );
      }
      // Detach account from shops
      if (removedShopManagements.length > 0) {
        dataProvider.deleteMany('shop-managements',{ids:removedShopManagements?.map(item=>item.id)});
      }
    },
    onTransform = (values) => {
      if (!values['password']) {
        delete values['password'];
        delete values['confirmPassword'];
      }
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getEditUserValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: update,
      onSuccessCallback,
      onTransform,
      successMsgKey: 'updated'
    });

  // Get list shop id to mapping select input
  const shopOptions = shopManagements?.map((item) => {
    return item.shopId;
  });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <span title={l18n.translate('resources.users.fields.id')} id="id">
          {record.id}
        </span>

        <span
          title={l18n.translate('resources.users.fields.loginId')}
          id="loginId"
        >
          {record.loginId}
        </span>

        <TextInput
          title={l18n.translate('resources.users.fields.loginName')}
          required={true}
          source="loginName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 30
          })}
        />

        <PasswordInput
          title={l18n.translate('resources.users.fields.password')}
          source="password"
          className="form-control"
          defaultValue=""
          helperText={l18n.translate('ra.helpBlock.limitAlphabetSymbolMinMax', {
            min: 8,
            max: 50
          })}
        />

        <PasswordInput
          title={l18n.translate('resources.users.fields.confirmPassword')}
          source="confirmPassword"
          className="form-control no-help-block"
          defaultValue=""
        />

        <SelectInput
          title={l18n.translate('resources.users.fields.authority')}
          required={true}
          source="authority"
          className="form-control custom-select no-help-block"
          choices={authorityChoices}
        />

        <FormDataConsumer>
          {({ formData }) =>
            formData.authority &&
            formData.authority !== UserRole.SYSTEM_ADMINISTRATOR && (
              <TableRow key="company">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.users.fields.company.id')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <CompanyInput
                    company={company}
                    availableCompanies={availableCompanies}
                    authority={recordAuthority}
                  />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) =>
            formData.authority &&
            formData.company &&
            formData.company.id &&
            [
              UserRole.SYSTEM_ADMINISTRATOR,
              UserRole.COMPANY_ADMINISTRATOR
            ].every((role) => role !== formData.authority) && (
              <TableRow key="shop">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.users.fields.shop.id')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <ShopInput
                    source="shop.id"
                    multiselect
                    defaultValue={shopOptions}
                  />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>
      </TableForm>
    </>
  );
};

export default EditForm;
