import React, { useEffect } from 'react';
import GeneralPagination from 'components/GeneralPagination';
import {
  Create,
  Edit,
  List,
  SelectField,
  TextField,
  FunctionField,
  useDataProvider
} from 'react-admin';
import { loadGlobalFilter } from 'containers/Common/hocs';
import CustomAction from './Actions/index';
import { getAuthorityChoices } from './constants';
import UserCreateForm from './Forms/UserCreateForm';
import UserEditForm from './Forms/UserEditForm';
import { CustomDataGrid } from 'components/DataGrid/index';
import { getMultiListShopByCompanyIds } from 'containers/Common/api';
import l18n from 'containers/I18nProvider/index';
import _ from 'lodash';
import { useSafeSetState } from 'ra-core';
import { getShopNameByShopId } from 'containers/Common/helper';
import { isAdmin } from 'containers/App/utils';

export const UserListPage = ({ globalFilterReducer, ...props }) => {
  const dataProvider = useDataProvider(),
    [state, setState] = useSafeSetState({
      loading: true,
      shopList: []
    });

  let hideEdit = '',
    hideDelete = '',
    isHideAll = false,
    authority = props.permissions;

  // Company
  const { availableCompanies } = globalFilterReducer,
    companyLength = availableCompanies.length;

  useEffect(() => {
    // Get shop list to display the attached shop info
    const getShopList = () => {
      const companyIds = _.map(availableCompanies, 'id');
      getMultiListShopByCompanyIds(dataProvider, {fields: ['id', 'shopName'], companyIds})
      .then((data) => {
        setState({
          loading: false,
          shopList: data
        })
      });
    };

    getShopList();
  }, [availableCompanies, dataProvider, setState]);

  if (state.loading) return null;

  return (
    <List
      {...props}
      actions={<CustomAction />}
      pagination={<GeneralPagination />}
      className="table-responsive"
      bulkActionButtons={false}
    >
      <CustomDataGrid edit={hideEdit} delete={hideDelete}>
        <TextField source="id" />
        <TextField source="loginId" />
        <TextField source="loginName" />
        <SelectField source="authority" choices={getAuthorityChoices} />
        {companyLength > 0 && (
          <SelectField
            source="companyId"
            choices={availableCompanies}
            optionText="companyName"
            label={l18n.translate('resources.users.fields.company.id')}
            sortable={isAdmin(authority)}
          />
        )}
        {state.shopList.length > 0 && (
          <FunctionField
            label={l18n.translate('resources.users.fields.shop.id')}
            key="shopManagements"
            className="wrapper-tag"
            render={
              record => record.shopManagements?.map(item => {
                const shopName = getShopNameByShopId(state.shopList, item.shopId);
                return shopName ? <span className="tag" key={item.id}>{ shopName }</span> : null;
              })
            }
          />
        )}
        {!isHideAll && (
          <TextField
            source="operation"
            sortable={false}
            label={l18n.translate('ra.action.operation')}
            className="operation"
          />
        )}
      </CustomDataGrid>
    </List>
  );
};

const UserList = loadGlobalFilter(UserListPage);

export const AddUserPage = ({ globalFilterReducer, ...props }) => {
  return (
    <Create {...props} className="table-responsive">
      <UserCreateForm
        permissions={props.permissions}
        globalFilterReducer={globalFilterReducer}
      />
    </Create>
  );
};

const UserCreate = loadGlobalFilter(AddUserPage);

export const UserEditPage = ({ globalFilterReducer, ...props }) => {
  return (
    <Edit {...props} undoable={false} className="table-responsive">
      <UserEditForm
        permissions={props.permissions}
        globalFilterReducer={globalFilterReducer}
      />
    </Edit>
  );
};

const UserEdit = loadGlobalFilter(UserEditPage);

export {
  UserList as List,
  UserCreate as Create,
  UserEdit as Edit
};
