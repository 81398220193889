import React from 'react';

const RealtimeIndicator = () => (
  <svg height="20" width="20" className="blinking">
    <circle cx="10" cy="10" r="6" fill="#6ac135" />
    Sorry, your browser does not support inline SVG.
  </svg>
);

export default RealtimeIndicator;
