import produce from 'immer';
import {
  RESET_SALE_LOG_FILTER_FORM,
  SET_SALE_LOG_FILTER_START_DATE,
  SET_SALE_LOG_FILTER_END_DATE,
  SET_SALE_LOG_FILTER_WEEK_DAY
} from '../Actions/saleLogFilterForm';

const initialState = {
  startDate: null,
  endDate: null,
  weekDay: null
};

const chartModeReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case RESET_SALE_LOG_FILTER_FORM:
        draft.startDate = null;
        draft.endDate = null;
        draft.weekDay = null;
        break;

      case SET_SALE_LOG_FILTER_START_DATE:
        draft.startDate = payload;
        break;

      case SET_SALE_LOG_FILTER_END_DATE:
        draft.endDate = payload;
        break;

      case SET_SALE_LOG_FILTER_WEEK_DAY:
        draft.weekDay = payload;
        break;
    }
  });

export default chartModeReducer;
