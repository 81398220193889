import { BeerServerData } from '../interfaces';

export const UPDATE_BEER_SERVER_STATUS = 'UPDATE_BEER_SERVER_STATUS';

export const updateBeerServerStatus = (payload: BeerServerData[]) => {
  return {
    type: UPDATE_BEER_SERVER_STATUS,
    payload: payload
  };
};
