import React from 'react';
import { useDataProvider, useSafeSetState } from 'react-admin';
import { customDataTableStyles } from 'containers/Common/styles';
import l18n from 'containers/I18nProvider/index';
import { getListData } from 'containers/Common/api';
import DataTable from 'react-data-table-component';
import DataTablePagination from 'components/DataTablePagination';
import moment from 'moment';

const columns = [
  {
    selector: 'pointPlusId',
    name: l18n.translate('resources.topup.fields.pointPlusId')
  },
  {
    selector: 'receiptCode',
    name: l18n.translate('resources.topup.fields.receiptCode')
  },
  {
    selector: 'amount',
    name: l18n.translate('resources.topup.fields.amount')
  },
  {
    selector: 'createdAt',
    name: l18n.translate('resources.topup.fields.settlementTime')
  },
  {
    selector: 'paymentMethod',
    name: l18n.translate('resources.topup.fields.paymentMethod')
  },
  {
    selector: 'pointPlusBalance',
    name: l18n.translate('resources.topup.fields.pointPlusBalance')
  }
];

const TopUpListPage = (props) => {
  let [data, setData]: any[] = useSafeSetState([]);
  const dataProvider = useDataProvider(),
    { resource } = props;

  React.useEffect(() => {
    const updateShopChoices = () => {
      getListData(dataProvider, resource).then((data) => {

        // FIXME 210908 暫定でデータを加工する。バックエンドで行うか、専用メソッドを準備するか等を後で行う事

        // var addition = 9 * 60 * 60 * 1000;
        let _data = data.map(element => {
          /*
          let temp = new Date(element.createdAt);
          let mills = temp.getTime() + addition;
          let date = new Date(mills);
          let str = "";
          str += date.getFullYear()+"/";
          let _month = date.getMonth()+1;
          str += (_month<10?"0"+_month:_month)+"/";
          let _date = date.getDate();
          str += (_date<10?"0"+_date:_date)+" ";
          let _hours = date.getHours();
          str += (_hours<10?"0"+_hours:_hours)+":";
          let _minutes = date.getMinutes();
          str += (_minutes<10?"0"+_minutes:_minutes);
          //str += date.getSeconds();
          //element.createdAt = str;
          */
          element.createdAt = moment(element.createdAt).format("YYYY/MM/DD HH:mm");

          
          // FIXME 210908 現時点で全てのデータが1なので、対応する値と種別がわかり次第修正
          switch(element.paymentMethod){
            case 1:
            element.paymentMethod = "ハウスマネー";
            break;
            case 2:
              element.paymentMethod = "現金";
            break;
            case 3:
            element.paymentMethod = "電子マネー";
            break;
            case 4:
            element.paymentMethod = "クレジットカード";
            break;
            case 5:
            element.paymentMethod = "QR決済";
            break;
            case 6:
            element.paymentMethod = "その他";
            break;
          }
          return element;
        });
        //console.log(_data);
        setData(_data);
      });
    };
    updateShopChoices();
  }, [dataProvider, resource, setData]);

  return (
    <DataTable
      data={data}
      columns={columns}
      pagination
      paginationComponent={DataTablePagination}
      paginationPerPage={50}
      striped
      highlightOnHover
      customStyles={customDataTableStyles}
      dense
      noHeader
    />
  );
};

export { TopUpListPage as List };
