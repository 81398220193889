import { Button } from '@material-ui/core';
import l18n from 'containers/I18nProvider/index';
import { authProvider } from 'providers/auth.provider';
import React from 'react';
import { useLogout } from 'react-admin';
import { Link } from 'react-router-dom';

const CustomAppBar = (props) => {
  const logout = useLogout(),
    accountInfo = authProvider.getAccountInfo();

  return (
    <div className="navbar navbar-inverse navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-header navbar-left">
          <Link to="/dashboard" className="navbar-brand">
            <div className="header-label-wrapper">
              <img
                id="logo-shallwetap"
                src="/logo-shallwetap-white.png"
                alt="Logo Shallwetap"
              />
            </div>
          </Link>
        </div>

        {!props.isError && (
          <div className="navbar-right">
            <div className="account-info">
              <Link to="/auth/profile" className="header-login-name">
                <div>{accountInfo.loginName}</div>
              </Link>
              <div>{accountInfo.authorityName}</div>
            </div>
            <Button
              className="btn-info"
              variant="contained"
              onClick={() => logout()}
              data-cy="logoutButton"
            >
              {l18n.translate('ra.auth.logout')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomAppBar;
