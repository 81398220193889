import { InputAdornment } from '@material-ui/core';
import Loading from 'components/LoadingIndicator';
// Components
import TableForm from 'components/TableForm';
import CustomToolBar from 'components/Toolbar';
import { currency } from 'containers/App/constants';
import { formatImageUrl } from 'containers/App/utils';
import { AlertCommon } from 'containers/Common/alert';
import { RESOURCE_GOODS } from 'containers/Common/constants';
// Containers
import {
  getBase64,
  handleSubmitFactory,
  prepareImage
} from 'containers/Common/helper';
import l18n from 'containers/I18nProvider/index';
import { useSafeSetState } from 'ra-core';
import React from 'react';
import {
  BooleanInput,
  ImageField,
  ImageInput,
  TextInput,
  useNotify,
  useRedirect,
  useUpdate
} from 'react-admin';
// Local
import { getEditShopGoodsValidationSchema } from './validations';

const ShopGoodsToolBar = (props) => (
  <CustomToolBar {...props} basePath={'/' + RESOURCE_GOODS} />
);

const EditForm = (props) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    record = props.record,
    resource = props.resource,
    [update] = useUpdate('goods-shop', record.id),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = async (values) => {
      // Get image base64 to pass to api
      const imageResult = await getBase64(values, 'imageUrl');
      if (imageResult) {
        values['image'] && delete values['image'];
        values['image'] = prepareImage(imageResult);
      }
      // @Todo
      // Display error msg when convert base64 error
      // saveState(setState, true, l18n.translate('resources.goods.validation.image.error'));
      // return;

      return {
        purchaseCost: values['purchaseCost'],
        sellingPrice: values['sellingPrice'],
        handlingFlag: values['handlingFlag'] ? 1 : 0,
        soldOutFlag: values['soldOutFlag'] ? 1 : 0,
        description: values['description'],
        image: values['image']
      };
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getEditShopGoodsValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('/goods'),
      // TODO Call api save info to goods_shop by dataProvider
      onSubmit: update,
      onTransform,
      successMsgKey: 'updated'
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit} toolbar={<ShopGoodsToolBar />}>
        <TextInput
          title={l18n.translate('resources.goods.fields.shop.id')}
          source="id"
          className="form-control"
          disabled
          helperText={false}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.shop.purchaseCost')}
          required={true}
          source="purchaseCost"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.shop.sellingPrice')}
          required={true}
          source="sellingPrice"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.shop.description')}
          multiline
          rows="5"
          source="description"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitMax', { max: 255 })}
        />

        <ImageInput
          title={l18n.translate('resources.goods.fields.shop.image')}
          required={true}
          format={formatImageUrl}
          source="imageUrl"
          label="Related pictures"
          accept="image/*"
          className="img-input"
          helperText={l18n.translate('ra.helpBlock.extension')}
        >
          <ImageField source="url" />
        </ImageInput>

        <BooleanInput
          title={l18n.translate('resources.goods.fields.shop.handlingFlag')}
          required={true}
          label=""
          source="handlingFlag"
          className="no-help-block"
        />

        <BooleanInput
          title={l18n.translate('resources.goods.fields.shop.soldOutFlag')}
          required={true}
          label=""
          source="soldOutFlag"
          className="no-help-block"
        />
      </TableForm>
    </>
  );
};

export default EditForm;
