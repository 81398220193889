const messages = {
    topup: {
      name: 'トップアップ',
      fields: {
        id: 'ID',
        pointPlusId: 'ポイントプラスID',
        receiptCode: '領収書コード',
        amount: '量',
        pointPlusBalance: 'ポイントプラスバランス',
        settlementTime: '決済完了時刻',
        paymentMethod: '会計種別',
        company: {
          id: '会社'
        },
        shop: {
          id: 'ショップ'
        }
      }
    }
  };
  
  export default messages;
  