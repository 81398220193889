import { TabletData } from '../interfaces';

export const UPDATE_TABLET_STATUS = 'UPDATE_TABLET_STATUS';

export const updateTabletStatus = (payload: TabletData[]) => {
  return {
    type: UPDATE_TABLET_STATUS,
    payload: payload
  };
};
