import { PATTERN_CODE } from 'containers/Common/patterns';

/**
 * [Get TapBeer validation schema for creating]
 * @return  {[Object]} schema
 */
const getCreateTapBeerValidationSchema = () => ({
  beerCode: { required: true, maxLength: 30, pattern: PATTERN_CODE },
  beerName: { required: true, maxLength: 100, emojis: true },
  beerNameEn: { required: true, maxLength: 100, emojis: true },
  beerShortName: { required: true, maxLength: 100, emojis: true },
  beerStyle: { required: true, maxLength: 100, emojis: true },
  ibu: { required: true, number: true, minNumber: 0 },
  alcohol: { required: true },
  brewery: { required: true, maxLength: 100, emojis: true },
  breweryEn: { required: true, maxLength: 100, emojis: true },
  purchaseCost: { required: true, minNumber: 0.01 },
  sellingPrice: { required: true, minNumber: 0.01 },
  description: { maxLength: 255 },
  imageUrl: { selected: true, image: true },
  smell: { selected: true, number: true, betweenNumber: [1, 5] },
  'company.id': { selected: true, number: true, minNumber: 1 },
  'country.id': { selected: true, number: true, minNumber: 1 },
  acidity: { selected: true, number: true, betweenNumber: [1, 5] },
  bitter: { selected: true, number: true, betweenNumber: [1, 5] },
  sweet: { selected: true, number: true, betweenNumber: [1, 5] },
  weight: { selected: true, number: true, betweenNumber: [1, 5] },
  'group.id': { required: true, number: true, minNumber: 1 },
  'section.id': { required: true, number: true, minNumber: 1 },
  colour: { required: true, maxLength: 50, emojis: true }
});

/**
 * [Get TapBeer validation schema for updating]
 * @return  {[Object]} schema
 */
const getEditTapBeerValidationSchema = () => ({
  beerCode: { required: true, maxLength: 30, pattern: PATTERN_CODE },
  beerName: { required: true, maxLength: 100, emojis: true },
  beerNameEn: { required: true, maxLength: 100, emojis: true },
  beerShortName: { required: true, maxLength: 100, emojis: true },
  beerStyle: { required: true, maxLength: 100, emojis: true },
  alcohol: { required: true, number: true, minNumber: 0, maxNumber: 100 },
  ibu: { required: true, number: true, minNumber: 0 },
  brewery: { required: true, maxLength: 100, emojis: true },
  breweryEn: { required: true, maxLength: 100, emojis: true },
  purchaseCost: { required: true, minNumber: 0.01 },
  sellingPrice: { required: true, minNumber: 0.01 },
  description: { maxLength: 255 },
  imageUrl: { image: true },
  smell: { selected: true, number: true, betweenNumber: [1, 5] },
  acidity: { selected: true, number: true, betweenNumber: [1, 5] },
  bitter: { selected: true, number: true, betweenNumber: [1, 5] },
  sweet: { selected: true, number: true, betweenNumber: [1, 5] },
  weight: { selected: true, number: true, betweenNumber: [1, 5] },
  'group.id': { required: true, number: true, minNumber: 1 },
  'section.id': { required: true, number: true, minNumber: 1 },
  colour: { required: true, maxLength: 50, emojis: true }
});

/**
 * [Get ShopTapBeer validation schema for updating]
 * @return  {[Object]} schema
 */
const getShopTapBeerValidationSchema = (values) => ({
  purchaseCost: { number: true, minNumber: 0.01 },
  sellingPrice: { required: true, number: true, minNumber: 0.01 }
});

export {
  getCreateTapBeerValidationSchema,
  getEditTapBeerValidationSchema,
  getShopTapBeerValidationSchema
};
