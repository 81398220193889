import React from 'react';

const Loading = () => (
  <>
    <div className="overlay-progress"></div>
    <div className="progress-loading">
      <div className="progress-image"></div>
    </div>
  </>
);

export default Loading;
