import { PATTERN_LOGIN_ID, PATTERN_PASSWORD } from 'containers/Common/patterns';
import { UserRole } from 'providers/auth.provider';

/**
 * [Get validation schema for creating account]
 * @param  {[Object]} values [Form input]
 * @return {[Object]} schema
 */
const getCreateUserValidationSchema = (values) => {
  let schema = {
    loginId: { required: true, length: [3, 30], pattern: PATTERN_LOGIN_ID },
    loginName: { required: true, minLength:3, maxLength: 30, emojis: true },
    authority: { selected: true, number: true, betweenNumber: [1, 4] },
    password: { required: true, length: [8, 50], pattern: PATTERN_PASSWORD },
    confirmPassword: { confirmPassword: true }
  };

  const authority = values ? values['authority'] : -1;
  if (authority !== UserRole.SYSTEM_ADMINISTRATOR) {
    schema['company.id'] = { selected: true, number: true, minNumber: 1 };
  }

  if (authority === UserRole.SHOP || authority === UserRole.STAFF) {
    schema['addedShopIds'] = { selected: true, multiNumbers: true };
  }

  return schema;
};

/**
 * [Get validation schema for updating account]
 * @param  {[Object]} values [Form input]
 * @return {[Object]} schema
 */
const getEditUserValidationSchema = (values) => {
  let schema = {
    loginName: { required: true, maxLength: 30, emojis: true },
    authority: { selected: true, number: true, betweenNumber: [1, 4] },
    password: { length: [8, 50], pattern: PATTERN_PASSWORD },
    confirmPassword: { confirmPassword: true }
  };

  const authority = values ? values['authority'] : -1;
  if (authority !== UserRole.SYSTEM_ADMINISTRATOR) {
    schema['company.id'] = { selected: true, number: true, minNumber: 1 };
  }

  if (authority === UserRole.SHOP || authority === UserRole.STAFF) {
    schema['shop.id'] = { selected: true, multiNumbers: true };
  }

  return schema;
};

/**
 * [Get validation schema for updating logged in account]
 * @param  {[Object]} values [Form input]
 * @return {[Object]} schema
 */
const getEditProfileValidationSchema = (values) => {
  let schema = {
    loginName: { required: true, maxLength: 30, emojis: true },
    password: { length: [8, 50], pattern: PATTERN_PASSWORD },
    confirmPassword: { confirmPassword: true }
  };

  return schema;
};

export {
  getCreateUserValidationSchema,
  getEditUserValidationSchema,
  getEditProfileValidationSchema
};
