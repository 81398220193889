import React from 'react';
import CustomFilter from '../Filters/index';
import CommonAction from 'components/Actions/index';

const CustomAction = (props: any) => {
    return (
        <CommonAction { ...props } filters={<CustomFilter />} />
    );
};

export default CustomAction;
