import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import l18n from 'containers/I18nProvider';
import { Link } from 'react-router-dom';

export const BackButton = ({ ...props }) => {
  if (!props.basePath) {
    return null;
  }
  return (
    <Link to={props.basePath}>
      <Button
        variant="contained"
        color="primary"
        className="btn-default"
        startIcon={<ArrowBack />}
      >
        {l18n.translate('ra.action.customBack', {
          page: l18n.translate(`resources.${props.resource}.name`)
        })}
      </Button>
    </Link>
  );
};

const CustomToolBar = ({ transform,...props }) => (
  <Toolbar {...props}>
    <BackButton {...props} />
    <SaveButton className="btn-primary btn-save" data-cy="saveButton" transform={transform}/>
  </Toolbar>
);

export default CustomToolBar;
