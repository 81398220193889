/**
 * [Get validation schema for creating Group]
 * @return  {[Object]} schema
 */
const getCreateGroupValidationSchema = () => ({
  groupCode: { required: true, maxLength: 30, emojis: true },
  groupName: { required: true, maxLength: 100, emojis: true },
  taxReduction: { required: true },
  'company.id': { selected: true, number: true, minNumber: 1 }
});

/**
 * [Get validation schema for updating Group]
 * @return  {[Object]} schema
 */
const getEditGroupValidationSchema = () => ({
  groupCode: { required: true, maxLength: 30, emojis: true },
  groupName: { required: true, maxLength: 100, emojis: true },
  taxReduction: { required: true }
});

export { getCreateGroupValidationSchema, getEditGroupValidationSchema };
