import * as React from 'react';
import { FC, ReactElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { ToolbarProps, Drawer } from '@material-ui/core';
import { Exporter } from 'ra-core';
import {
  useListContext,
  TopToolbar,
  Button,
  sanitizeListRestProps
} from 'react-admin';
import ImportIcon from '@material-ui/icons/CloudUpload';
import ImportForm from 'containers/Resources/TapBeer/ImportForm';
import l18n from 'containers/I18nProvider/index';
import { Link } from 'react-router-dom';
import Add from '@material-ui/icons/Add';

const TapBeerActions: FC<TapBeerActionsProps> = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    showCreateButton,
    showImportButton = false,
    ...rest
  } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } =
    useListContext();
  const [open, setState] = React.useState(false),
    toggleDrawer = (open: boolean) => {
      setState(!open);
    },
    createLink: string = basePath + '/create';
  return (
    <div className="toolbar-wrapper">
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <div className="form-inline list-header">
          {filters &&
            cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button'
            })}
          {showCreateButton && (
            <Link to={createLink}>
              <Button
                variant="contained"
                color="primary"
                className="btn-primary float-right"
                startIcon={<Add />}
                label={l18n.translate('ra.action.create')}
              />
            </Link>
          )}

          {showImportButton && (
            <Button
              onClick={() => {
                toggleDrawer(open);
              }}
              variant="contained"
              color="primary"
              className="btn-primary float-right"
              startIcon={<ImportIcon />}
              data-cy="importTapBeerButton"
              label={l18n.translate('ra.action.import')}
            />
          )}
          <Drawer
            anchor={'right'}
            open={open}
            onClose={() => {
              toggleDrawer(open);
            }}
          >
            <ImportForm closeDrawer={() => toggleDrawer(open)} />
          </Drawer>
        </div>
      </TopToolbar>
    </div>
  );
};

TapBeerActions.propTypes = {
  filters: PropTypes.element,
  // @ts-ignore
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  // @ts-ignore
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  maxResults: PropTypes.number
};

export interface TapBeerActionsProps
  extends Omit<ToolbarProps, 'classes' | 'onSelect'> {
  actions?: ReactElement | false;
  filters?: ReactElement;
  exporter?: Exporter | false;
  maxResults?: number;
  showImportButton: boolean;
  showCreateButton: boolean;
}

export default React.memo(TapBeerActions);
