const messages = {
  'event-logs': {
    name: 'イベントログ',
    fields: {
      obnizID: 'obnizID',
      deviceCode: 'デバイスコード',
      message: 'メッセージ',
      eventType: 'イベントタイプ',
      eventTime: 'イベント時間'
    }
  }
};

export default messages;
