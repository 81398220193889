import React from 'react';
import {
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton
} from 'react-admin';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import ExportIcon from '@material-ui/icons/CloudDownload';
import l18n from 'containers/I18nProvider/index';

const CommonAction = (props: {
  [x: string]: any;
  filters?: any;
  hide: any;
  showExport?: boolean;
  extra?: any;
  className?: any;
  maxResults?: any;
}) => {
  const { className, maxResults, hide, showExport, ...rest } = props,
    {
      basePath,
      loading,
      filterValues,
      currentSort,
      resource,
      total,
      ids
    } = useListContext(),
    createLink: string = basePath + '/create';

  if (ids.length > 0 && Object.keys(filterValues).length <= 0) {
    // default export current page
    filterValues['id||$in'] = ids;
  } else {
    try {
      delete filterValues['id||$in'];
    } catch (error) {}
  }

  if (loading) return null;

  return (
    <div className="toolbar-wrapper">
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <div className="form-inline list-header">
          {props.filters}
          {!props.hide && (
            <Link to={createLink}>
              <Button
                variant="contained"
                color="primary"
                className="btn-primary float-right"
                startIcon={<Add />}
              >
                {l18n.translate('ra.action.create')}
              </Button>
            </Link>
          )}
          {showExport && (
            <ExportButton
              variant="contained"
              className="btn-primary float-right"
              icon={<ExportIcon />}
              disableElevation
              label={l18n.translate('ra.action.export')}
              sort={currentSort}
              filterValues={filterValues}
              resource={resource}
              disabled={total === 0}
            />
          )}
          {props.extra ?? ''}
        </div>
      </TopToolbar>
    </div>
  );
};

export default CommonAction;
