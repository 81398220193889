import l18n from 'containers/I18nProvider/index';
import emojiRegex from 'emoji-regex';
import {
  getValueByStringPath,
  getFileExtension,
  getFileSize
} from 'containers/Common/helper';
import { PATTERN_EMAIL_REGEX } from './patterns';

/**
 * [validation Validation input parameters]
 * @param  {[String]} resource [Resource name]
 * @param  {[Object]} schema [Schema validation]
 * @param  {[Object]} values [Form values]
 * @return {[mixed]}
 */
let validation = (resource: string, schema = {}, values = {}) => {
  const keySchemas = Object.keys(schema);
  for (let index = 0; index < keySchemas.length; index++) {
    const keySchema = keySchemas[index];
    const validationObj = schema[keySchema];
    for (let key in validationObj) {
      let result = '';
      if (key === 'confirmPassword' && validationObj[key] === true) {
        result = checkConfirmPassword(
          values['password'],
          values['confirmPassword']
        );
      } else {
        result = checkValidation(
          resource,
          key,
          validationObj[key],
          keySchema,
          getValueByStringPath(values, keySchema)
        );
      }

      if (result) return result;
    }
  }

  return '';
};

const validationMapping = {
  required: ({ resource, value, key }) => isEmpty(resource, value, key, false),
  selected: ({ resource, value, key }) => isEmpty(resource, value, key, true),
  length: ({ resource, value, key, validationValue }) =>
    checkLength(resource, value, key, validationValue[0], validationValue[1]),
  minLength: ({ resource, value, key, validationValue }) =>
    checkMinLength(resource, value, key, validationValue),
  maxLength: ({ resource, value, key, validationValue }) =>
    checkMaxLength(resource, value, key, validationValue),
  equalLength: ({ resource, value, key, validationValue }) =>
    checkEqualLength(resource, value, key, validationValue),
  pattern: ({ resource, value, key, validationValue }) =>
    checkPattern(resource, value, key, validationValue),
  emojis: ({ resource, value, key }) => checkEmojis(resource, value, key),
  number: ({ resource, value, key }) => checkNumber(resource, value, key),
  betweenNumber: ({ resource, value, key, validationValue }) =>
    checkBetweenNumber(
      resource,
      value,
      key,
      validationValue[0],
      validationValue[1]
    ),
  minNumber: ({ resource, value, key, validationValue }) =>
    checkMinNumber(resource, value, key, validationValue),
  maxNumber: ({ resource, value, key, validationValue }) =>
    checkMaxNumber(resource, value, key, validationValue),
  multiEmails: ({ resource, value, key }) =>
    checkMultiEmails(resource, value, key),
  multiNumbers: ({ resource, value, key }) =>
    checkMultiNumbers(resource, value, key),
  image: ({ resource, value, key }) => checkImage(resource, value, key)
};

/**
 * [Validation parameters]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} validationKey [Validation key]
 * @param  {[String]} validationValue [Validation value]
 * @param  {[String]} key [Key Schema]
 * @param  {[mixed]} value [Value]
 * @return {[String]}
 */
let checkValidation = (
  resource: string,
  validationKey: string,
  validationValue: any,
  key: string,
  value: any
) => {
  let result = '';

  if (validationValue === undefined || validationValue === null) {
    return result;
  }

  return validationMapping[validationKey]({
    resource,
    value,
    key,
    validationValue
  });
};

/**
 * [Check required]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Boolean]} isSelected [Input or select]
 * @return {[String]}
 */
let isEmpty = (
  resource: string,
  value: any,
  key: string,
  isSelected: boolean
) => {
  let emptyKey = isSelected ? 'selected' : 'required';

  if (
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return l18n.translate(`ra.validation.${emptyKey}`, {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`)
    });
  }

  return '';
};

/**
 * [Check min and max length value]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} min [Min length]
 * @param  {[Number]} max [Max length]
 * @return {[String]}
 */
let checkLength = (
  resource: string,
  value: any,
  key: string,
  min: number,
  max: number
) => {
  if (!value) return '';

  const valueLength = value.length;

  if (valueLength < min || valueLength > max) {
    return l18n.translate('ra.validation.length', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      min: min,
      max: max
    });
  }

  return '';
};

/**
 * [Check min length value]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} min [Min length]
 * @return {[String]}
 */
let checkMinLength = (
  resource: string,
  value: any,
  key: string,
  min: number
) => {
  if (!value) return '';

  const valueLength = value.length;
  if (valueLength < min) {
    return l18n.translate('ra.validation.minLength', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      min: min
    });
  }

  return '';
};

/**
 * [Check max length value]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} max [Max length]
 * @return {[String]}
 */
let checkMaxLength = (
  resource: string,
  value: any,
  key: string,
  max: number
) => {
  if (!value) return '';

  const valueLength = value.length;
  if (valueLength > max) {
    return l18n.translate('ra.validation.maxLength', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      max: max
    });
  }

  return '';
};

/**
 * [Check equal length]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} length [length]
 * @param  {[Boolean]} isNumber [True: Number False: String]
 * @return {[String]}
 */
let checkEqualLength = (
  resource: string,
  value: any,
  key: string,
  length: number,
  isNumber = true
) => {
  if (!value) return '';

  const valueLength = value.length;
  if (valueLength !== length) {
    let translateKey = isNumber ? 'equalValue' : 'equalLength';
    return l18n.translate(`ra.validation.${translateKey}`, {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      length: length
    });
  }

  return '';
};

/**
 * [Check pattern format]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Regex]} pattern [Pattern format]
 * @return {[String]}
 */
let checkPattern = (
  resource: string,
  value: any,
  key: string,
  pattern: { test: (arg0: any) => boolean }
) => {
  if (!value) return '';

  if (!pattern.test(value)) {
    return l18n.translate(`resources.${resource}.validation.${key}.format`);
  }

  return '';
};

/**
 * [Check emojis]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @return {[String]}
 */
let checkEmojis = (resource: string, value: string, key: string) => {
  if (!value) return '';

  const regex = emojiRegex(),
    match = regex.exec(value);
  if (match !== null && match.length > 0) {
    return l18n.translate('ra.validation.emojis', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`)
    });
  }

  return '';
};

/**
 * [Check multi numbers]
 * @param  {[String]} resource [Resource name]
 * @param  {[Array]} values [Number List]
 * @param  {[String]} key [Key]
 * @return {[String]}
 */
let checkMultiNumbers = (
  resource: string,
  values: string | any[],
  key: string
) => {
  const valueLength = values.length;

  for (let i = 0; i < valueLength; i++) {
    const result = checkNumber(resource, values[i], key);
    if (result) return result;
  }

  return '';
};

/**
 * [Check number]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @return {[String]}
 */
let checkNumber = (resource: string, value: any, key: string) => {
  if (!value) return '';

  if (isNaN(Number(value))) {
    return l18n.translate('ra.validation.number', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`)
    });
  }

  return '';
};

/**
 * [Check min and max number]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} min [Min number]
 * @param  {[Number]} max [Max number]
 * @return {[String]}
 */
let checkBetweenNumber = (
  resource: string,
  value: any,
  key: string,
  min: number,
  max: number
) => {
  if (!value) return '';

  const convertValue = Number(value);
  if (convertValue < min || convertValue > max) {
    return l18n.translate('ra.validation.betweenValue', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      min: min,
      max: max
    });
  }

  return '';
};

/**
 * [Check min number]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} min [Min number]
 * @return {[String]}
 */
let checkMinNumber = (
  resource: string,
  value: any,
  key: string,
  min: number
) => {
  if (value === undefined || value === null) return '';

  const convertValue = Number(value);

  if (convertValue < min) {
    return l18n.translate('ra.validation.minValue', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      min: min
    });
  }

  return '';
};

/**
 * [Check max number]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} max [Max number]
 * @return {[String]}
 */
let checkMaxNumber = (
  resource: string,
  value: any,
  key: string,
  max: number
) => {
  if (value === undefined || value === null) return '';

  const convertValue = Number(value);
  if (convertValue > max) {
    return l18n.translate('ra.validation.maxValue', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      max: max
    });
  }

  return '';
};

/**
 * [Check multi emails]
 * @param  {[String]} resource [Resource name]
 * @param  {[Array]} values [Email List]
 * @param  {[String]} key [Key]
 * @return {[String]}
 */
let checkMultiEmails = (
  resource: string,
  values: string | any[],
  key: string
) => {
  const emailLength = values.length;

  for (let i = 0; i < emailLength; i++) {
    const result = checkEmail(resource, values[i], key);
    if (result) return result;
  }

  return '';
};

/**
 * [Check email]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @return {[String]}
 */
let checkEmail = (resource: string, value: string, key: string) => {
  if (!value) return '';

  if (!PATTERN_EMAIL_REGEX.test(value)) {
    return l18n.translate('ra.validation.email', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`)
    });
  }

  return '';
};

/**
 * [Check confirm password and password match]
 * @param  {[String]} password [Password]
 * @param  {[String]} confirmPassword [Confirm password]
 * @return {[String]}
 */
let checkConfirmPassword = (password: any, confirmPassword: any) => {
  if (!password && !confirmPassword) return '';

  if (password !== confirmPassword) {
    return l18n.translate('ra.validation.confirmPassword');
  }

  return '';
};

/**
 * [Check image]
 * @param  {[String]} resource [Resource name]
 * @param  {[File]} value [File]
 * @param  {[String]} key [Key]
 * @param  {[Number]} maxSize [Max size default 1 image 2MB]
 * @return {[String]}
 */
let checkImage = (resource: string, value: any, key: string, maxSize = 2) => {
  if (!value) return '';
  const file = value['rawFile'];
  if (!file) return '';

  // Check extension
  const extension = getFileExtension(file.name),
    resultCheckExtension = checkFileExtension(resource, extension, key);
  if (resultCheckExtension) return resultCheckExtension;

  // Check size
  const size = getFileSize(file.size),
    resultCheckSize = checkFileSize(resource, size, key, maxSize);
  if (resultCheckSize) return resultCheckSize;

  return '';
};

/**
 * [Check extension file]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Array]} targetExtensions [List target extension]
 * @return {[String]}
 */
let checkFileExtension = (
  resource: string,
  value: string,
  key: string,
  targetExtensions = ['png', 'jpg', 'jpeg']
) => {
  if (targetExtensions.length === 0 || !value) return '';

  if (!targetExtensions.includes(value)) {
    return l18n.translate('ra.validation.extension', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      extension: targetExtensions.join(', ')
    });
  }

  return '';
};

/**
 * [Check size file]
 * @param  {[String]} resource [Resource name]
 * @param  {[String]} value [Value]
 * @param  {[String]} key [Key]
 * @param  {[Number]} maxSize [File size]
 * @return {[String]}
 */
let checkFileSize = (
  resource: string,
  value: any,
  key: string,
  maxSize: number
) => {
  if (!value) return '';

  if (value > maxSize) {
    return l18n.translate('ra.validation.size', {
      attribute: l18n.translate(`resources.${resource}.fields.${key}`),
      maxSize: maxSize
    });
  }

  return '';
};

export { validation };
