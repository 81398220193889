import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Cancel from '@material-ui/icons/Cancel';
import { sendImportFile } from 'containers/App/utils';
import { env } from 'environment';
import React, { useCallback } from 'react';
import {
  Button,
  downloadCSV,
  FileField,
  FileInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRefresh
} from 'react-admin';

const ImportToolbar = ({ closeDrawer, ...props }) => (
  <Toolbar {...props}>
    <Button
      label="ra.action.cancel"
      onClick={closeDrawer}
      data-cy="closeImportGoodsForm"
      startIcon={<Cancel />}
      variant="contained"
      color="default"
      style={{ height: 32, marginRight: 10 }}
    />

    <SaveButton
      label="ra.action.import"
      data-cy="submitImportGoodsForm"
      redirect={false}
      submitOnEnter={false}
      disabled={props.pristine}
      variant="contained"
      color="primary"
    />
  </Toolbar>
);

const ImportForm = ({ closeDrawer }) => {
  const refresh = useRefresh();

  const handleSave = useCallback(
    async (values) => {
      const { importFile } = values;
      if (importFile) {
        sendImportFile(
          env.baseApi + '/goods/import',
          'file',
          importFile.rawFile
        )
          .then((response) => response.blob())
          .then((res) => {
            downloadCSV(res, 'import-goods-result');
            refresh();
          });
      }
    },
    [refresh]
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <h1>Import Product</h1>
        <SimpleForm
          toolbar={<ImportToolbar closeDrawer={closeDrawer} />}
          record={{}}
          save={handleSave}
          data-cy="importGoodsForm"
        >
          <FileInput source="importFile" label="File" required>
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Container>
    </React.Fragment>
  );
};

export default ImportForm;
