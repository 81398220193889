import React from 'react';
import {
  NumberInput,
  SelectInput,
  ReferenceInput,
  useSafeSetState,
  useNotify,
  useUpdate,
  SaveButton,
  Toolbar,
  useRefresh
} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { barrelStatus } from '../constants';
import l18n from 'containers/I18nProvider';
import TableForm from 'components/TableForm';
import { handleSubmitFactory } from 'containers/Common/helper';

const CancelButton = ({ onCloseDialogue, ...props }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      className="btn-default"
      startIcon={<Cancel />}
      onClick={onCloseDialogue}
      data-cy="cancelEditBarrelButton"
      style={{ marginRight: '1rem' }}
    >
      {l18n.translate('ra.action.cancel')}
    </Button>
  );
};
const EditForm = ({ setState, save, ...props }: any) => {
  let state = props.state;

  /**
   * Close dialogue add barrel
   */
  const onCloseDialogue = () => {
      setState({
        showEditDialogue: false,
        shopId: state?.shopId
      });
    },
    refresh = useRefresh(),
    [localState, setLocalState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    [update] = useUpdate('tap-beer-server', state.barrelRecord?.id),
    notify = useNotify(),
    handleSubmit = handleSubmitFactory({
      resource: 'tap-beer-server',
      buildValidationSchema: () => ({}),
      setState: setLocalState,
      notify,
      redirectTo: () => ({}),
      onSubmit: update,
      state: localState,
      successMsgKey: 'updated',
      onSuccessCallback: ({ data, values }) => {
        onCloseDialogue();
        notify('Update Success');
        refresh();
        return {};
      }
    });

  return (
    <Dialog
      fullWidth
      open={Boolean(state.showEditDialogue)}
      onClose={onCloseDialogue}
    >
      <DialogTitle>
        {l18n.translate('resources.devices.fields.beerServer.editTitle')}
      </DialogTitle>
      <DialogContent>
        {state.barrelRecord && (
          <TableForm
            resource="tap-beer-server"
            record={state.barrelRecord}
            save={handleSubmit}
            toolbar={
              <Toolbar {...props}>
                <CancelButton onCloseDialogue={onCloseDialogue} {...props} />
                <SaveButton
                  className="btn-primary btn-save"
                  data-cy="submitEditBarrelButton"
                />
              </Toolbar>
            }
          >
            <ReferenceInput
              source="beerServer.id"
              reference="beer-servers"
              className="form-control no-help-block"
              title={l18n.translate(
                'resources.devices.fields.beerServer.serverCode'
              )}
              filter={{ shopId: props.record?.shopId }}
              defaultValue={state.barrelRecord?.beerServerId}
            >
              <SelectInput optionText="serverCode" />
            </ReferenceInput>

            <ReferenceInput
              source="tapBeer.id"
              reference="tap-beers"
              className="form-control no-help-block"
              title={l18n.translate(
                'resources.devices.fields.beerServer.beerName'
              )}
              defaultValue={state.barrelRecord?.tapBeerId}
            >
              <SelectInput optionText="beerName" />
            </ReferenceInput>

            <NumberInput
              source="originalAmount"
              className="form-control"
              min={0}
              helperText={l18n.translate('ra.helpBlock.numberOnly')}
              defaultValue={state.barrelRecord?.originalAmount}
              title={l18n.translate(
                'resources.devices.fields.beerServer.amount'
              )}
            />

            <NumberInput
              source="remainingAmount"
              className="form-control"
              min={0}
              helperText={l18n.translate('ra.helpBlock.numberOnly')}
              defaultValue={state.barrelRecord?.remainingAmount}
              title={l18n.translate(
                'resources.devices.fields.beerServer.remainingAmount'
              )}
            />

            <NumberInput
              source="outStandardDisplay"
              className="form-control"
              min={0}
              helperText={l18n.translate('ra.helpBlock.numberOnly')}
              defaultValue={state.barrelRecord?.outStandardDisplay}
              title={l18n.translate(
                'resources.devices.fields.beerServer.beerOutStandard'
              )}
            />

            <SelectInput
              source="maintainFlag"
              choices={barrelStatus}
              className="form-control no-help-block"
              defaultValue={state.barrelRecord?.maintainFlag}
              title={l18n.translate(
                'resources.devices.fields.beerServer.status'
              )}
            />
          </TableForm>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditForm;
