import * as React from 'react';

import NotificationNumber from 'components/NotificationNumber';
import { ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';

const BeerServerAppendix = () => {
  const { beerServers } = useSelector(
    (state: ReduxState) => state.beerServerSurvivalSignals
  );
  const totalErrorBeerServer = beerServers.reduce((res, beerServer) => {
    if (beerServer.isOnline === 0) {
      res += 1;
    }
    return res;
  }, 0)
  return (
    <NotificationNumber
      number={totalErrorBeerServer}
    />
  );
};

export default BeerServerAppendix;
