import React from 'react';
import {
  TextInput,
  useUpdate,
  useNotify,
  useRedirect,
  BooleanInput
} from 'react-admin';
import { useSafeSetState } from 'ra-core';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import { AlertCommon } from 'containers/Common/alert';
import l18n from 'containers/I18nProvider';
// Local
import { getEditSectionValidationSchema } from './validations';
import * as Constants from 'containers/Common/constants';

const EditForm = (props) => {
  const record = props.record,
    [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [update] = useUpdate(resource, record.id),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = (values) => {
      values['taxReduction'] = values['taxReduction'] ? 1 : 0;
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getEditSectionValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo(`/${Constants.RESOURCE_GROUP}`),
      onSubmit: update,
      state: state,
      onTransform: onTransform,
      successMsgKey: 'updated'
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.sections.fields.sectionCode')}
          source="sectionCode"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.sections.fields.sectionName')}
          source="sectionName"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <BooleanInput
          title={l18n.translate('resources.sections.fields.taxReduction')}
          required={true}
          label=""
          source="taxReduction"
          className="no-help-block"
        />
      </TableForm>
    </>
  );
};

export default EditForm;
