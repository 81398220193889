import React from 'react';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';
import { TextInput, useListContext } from 'react-admin';
import { Search } from '@material-ui/icons';
import { handleFilter } from 'components/Filters/index';
import l18n from 'containers/I18nProvider/index';

const CustomFilter = () => {
  const { filterValues, setFilters } = useListContext();

  const onHandleFilter = (values) => {
    handleFilter(setFilters, values, { functionId: '' });
  };

  return (
    <Form onSubmit={onHandleFilter} initialValues={filterValues}>
      {({ handleSubmit }) => (
        <div className="wrapper-form">
          <form onSubmit={handleSubmit}>
            <TextInput
              label=""
              className="form-control"
              source="functionId"
              placeholder={l18n.translate(
                'resources.operation-logs.fields.functionId'
              )}
            />

            <Button
              className="btn btn-default form-control"
              type="submit"
              startIcon={<Search />}
              data-cy="filterSubmit"
            >
              {l18n.translate('ra.action.search')}
            </Button>
          </form>
        </div>
      )}
    </Form>
  );
};

export default CustomFilter;
