import { ThemeOptions } from '@material-ui/core';
import {
  createMuiTheme,
  createStyles,
  withStyles
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import { ComponentPropType, CustomRoutes } from 'ra-core';
import React, {
  Component,
  ComponentType,
  createElement,
  ErrorInfo,
  HtmlHTMLAttributes,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  defaultTheme,
  Error,
  Menu,
  Notification,
  LayoutProps,
  setSidebarVisibility
} from 'react-admin';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Providers
import { authProvider } from 'providers/auth.provider';
// Component
import LayoutBreadcrumb from 'components/Breadcrumb/index';
// Containers
import { ErrorPage } from 'containers/Pages/Error';
import l18n from 'containers/I18nProvider/index';
import AppBar from 'containers/App/customAppBar';
import Sidebar from 'containers/App/customSidebar';
// Css
import 'styles/layout.css';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
      color: theme.palette.getContrastText(theme.palette.background.default)
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1
    }
  });

class Layout extends Component<MuiLayoutProps, LayoutState> {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });

    props.setSidebarVisibility(true);
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    // Check permissions
    const auth = authProvider.getAccountInfo(),
      permissionStatus = authProvider.checkPermissions(auth.authority, this.props.location.pathname);

    if (permissionStatus.status === 403) {
      return (
        <ErrorPage
          title="403"
          subTitle="403 Access Forbidden"
          description={l18n.translate('ra.common.forbidden')}
        />
      );
    }

    const {
      appBar,
      children,
      classes,
      className,
      customRoutes,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      title,
      staticContext,
      ...props
    } = this.props;
    const { hasError, errorMessage, errorInfo } = this.state;
    return (
      <>
        <div className={classnames('layout', classes.root, className)}>
          <div className="app-frame">
            {createElement(appBar, { title, open, logout })}
            <main className={classes.contentWithSidebar}>
              {createElement(sidebar, {
                children: createElement(menu, {
                  logout,
                  hasDashboard: !!dashboard
                })
              })}
              <div className="wrapper-container">
                <div className={classnames('container', open ? 'sidebar-active' : 'sidebar-not-active')}>
                  <LayoutBreadcrumb {...props} />
                  {hasError
                    ? createElement(error, {
                        error: errorMessage,
                        errorInfo,
                        title
                      })
                    : children}

                  {createElement(notification)}
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }

  static propTypes = {
    appBar: ComponentPropType,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.string,
    customRoutes: PropTypes.array,
    dashboard: ComponentPropType,
    error: ComponentPropType,
    history: PropTypes.object.isRequired,
    logout: PropTypes.element,
    menu: ComponentPropType,
    notification: ComponentPropType,
    open: PropTypes.bool,
    sidebar: ComponentPropType,
    title: PropTypes.node.isRequired,
    setSidebarVisibility: PropTypes.func.isRequired
  };

  static defaultProps = {
    appBar: AppBar,
    error: Error,
    menu: Menu,
    notification: Notification,
    sidebar: Sidebar
  };
}

export interface MuiLayoutProps
  extends LayoutProps,
    RouteComponentProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  className?: string;
  classes?: any;
  customRoutes?: CustomRoutes;
  appBar?: ComponentType<{
    title?: string | ReactElement<any>;
    open?: boolean;
    logout?: ReactNode;
  }>;
  sidebar?: ComponentType<{ children: JSX.Element }>;
  error?: ComponentType<{
    error?: string;
    errorInfo?: React.ErrorInfo;
    title?: string | ReactElement<any>;
  }>;
  notification?: ComponentType;
  open?: boolean;
  setSidebarVisibility?: any;
}

export type RestProps = Omit<
  MuiLayoutProps,
  | 'appBar'
  | 'children'
  | 'classes'
  | 'className'
  | 'customRoutes'
  | 'error'
  | 'dashboard'
  | 'logout'
  | 'menu'
  | 'notification'
  | 'open'
  | 'sidebar'
  | 'title'
  | 'setSidebarVisibility'
>;

export interface LayoutState {
  hasError: boolean;
  errorMessage: string;
  errorInfo: ErrorInfo;
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen
});

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {
      setSidebarVisibility
    }
  ),
  withRouter,
  withStyles(styles, { name: 'RaLayout' })
)(Layout);

const LayoutWithTheme = ({
  theme: themeOverride,
  ...props
}: LayoutWithThemeProps): JSX.Element => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(createMuiTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  );
};

LayoutWithTheme.propTypes = {
  theme: PropTypes.object
};

LayoutWithTheme.defaultProps = {
  theme: defaultTheme
};

interface LayoutWithThemeProps extends LayoutProps {
  theme?: ThemeOptions;
}

export default LayoutWithTheme;
