import * as React from 'react';
import {
  Card as MuiCard,
  CardContent,
  withStyles,
  Button
} from '@material-ui/core';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import { FilterList, FilterListItem, useListContext } from 'react-admin';
import { PAYMENT_METHOD } from '..';
import SaleLogFilterForm from './FilterForm';
import FilterLiveSearch from './FilterLiveSearch';
import l18n from 'containers/I18nProvider';
import { resetForm } from 'containers/Common/Actions/saleLogFilterForm';
import { useDispatch } from 'react-redux';

const PaymentMethodFilter = () => (
  <FilterList
    label="resources.sale-logs.fields.paymentMethod"
    icon={<PaymentOutlinedIcon />}
  >
    {PAYMENT_METHOD.map((payment) => (
      <FilterListItem
        label={payment.name}
        key={payment.id}
        value={{ paymentMethod: payment.id }}
      />
    ))}
  </FilterList>
);

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '15em',
      marginRight: '1em'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))(MuiCard);

const ResetFilterButton = () => {
  const { setFilters } = useListContext(),
    dispatch = useDispatch(),
    dispatchResetForm = () => dispatch(resetForm()),
    resetFilter = () => {
      setFilters({}, []);
      dispatchResetForm();
    };
  return (
    <Button
      variant="contained"
      disableElevation
      onClick={resetFilter}
      data-cy="resetFilterButton"
      style={{ display: 'block', margin: '1rem auto', backgroundColor: '#555', color:"#fff" }}
    >
      {l18n.translate('ra.common.resetFilter')}
    </Button>
  );
};

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch
        source="id"
        label={l18n.translate('ra.common.searchById')}
        data-cy="searchSaleLogIdButton"
      />
      <hr />
      <SaleLogFilterForm />
      <hr />
      <PaymentMethodFilter />
      <hr />
      <ResetFilterButton />
    </CardContent>
  </Card>
);

export default FilterSidebar;
