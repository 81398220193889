const ADMIN_SETTING = 'admin-setting',
  PRODUCT = 'product',
  MASTER_DATA_SETTING = 'master-data-setting',
  SALE_CONFIRMATION = 'sale-confirmation',
  PROCESSING = 'processing',
  LOG_CONFIRMATION = 'log-confirmation',
  RESOURCE_DASHBOARD = 'dashboard',
  RESOURCE_USERS = 'users',
  RESOURCE_COMPANIES = 'companies',
  RESOURCE_SHOPS = 'shops',
  RESOURCE_GOODS = 'goods',
  RESOURCE_GOODS_SHOP = 'goods-shop',
  RESOURCE_TAP_BEERS = 'tap-beers',
  RESOURCE_TAP_BEERS_SHOP = 'tap-beers-shop',
  RESOURCE_BEER_SERVERS = 'beer-servers',
  RESOURCE_DEVICES = 'devices',
  RESOURCE_SALE_LOGS = 'sale-logs',
  RESOURCE_SALE_AGGREGATION = 'sale-aggregation',
  RESOURCE_OPERATION_LOGS = 'operation-logs',
  RESOURCE_EVENT_LOGS = 'event-logs',
  RESOURCE_ERROR_LOGS = 'error-logs',
  RESOURCE_AUTH_PROFILE = 'auth',
  RESOURCE_UPDATE_PROFILE = 'profile',
  RESOURCE_GROUP = 'groups',
  RESOURCE_SECTION = 'sections',
  RESOURCE_TOP_UP = 'topup',
  STATISTICS = 'statistics',
  STATISTICS_REALTIME_SALE_CHART = 'realtime-sale-chart',
  STATISTICS_SALE_REPORT_BY_HOUR = 'sale-report-by-hour',
  STATISTICS_PRODUCT_HISTORY = 'product-history',
  STATISTICS_SALE_HISTORY = 'sale-history',
  STATISTICS_SALE_HISTORY_WITH_FILTER = 'sale-history-with-filter',
  STATISTICS_SALE_REPORT_BY_WEEK_DAY = 'sale-report-by-week-day',
  STATISTICS_SALE_REPORT_BY_SECTION = 'sale-report-by-section',
  LIST_PATH_CAN_SHOW_SHOP_FILTER = [
    RESOURCE_DASHBOARD,
    STATISTICS_REALTIME_SALE_CHART,
    STATISTICS_PRODUCT_HISTORY,
    STATISTICS_SALE_HISTORY,
    STATISTICS_SALE_HISTORY_WITH_FILTER,
    STATISTICS_SALE_REPORT_BY_WEEK_DAY,
    STATISTICS_SALE_REPORT_BY_HOUR,
    STATISTICS_SALE_REPORT_BY_SECTION,
    RESOURCE_TAP_BEERS,
    RESOURCE_GOODS,
    RESOURCE_SALE_LOGS,
    RESOURCE_TOP_UP,
    RESOURCE_BEER_SERVERS,
    RESOURCE_DEVICES
  ];
export {
  ADMIN_SETTING,
  PRODUCT,
  MASTER_DATA_SETTING,
  SALE_CONFIRMATION,
  PROCESSING,
  LOG_CONFIRMATION,
  RESOURCE_DASHBOARD,
  RESOURCE_USERS,
  RESOURCE_COMPANIES,
  RESOURCE_SHOPS,
  RESOURCE_GOODS,
  RESOURCE_GOODS_SHOP,
  RESOURCE_TAP_BEERS,
  RESOURCE_TAP_BEERS_SHOP,
  RESOURCE_BEER_SERVERS,
  RESOURCE_DEVICES,
  RESOURCE_SALE_LOGS,
  RESOURCE_SALE_AGGREGATION,
  RESOURCE_OPERATION_LOGS,
  RESOURCE_EVENT_LOGS,
  RESOURCE_ERROR_LOGS,
  RESOURCE_AUTH_PROFILE,
  RESOURCE_UPDATE_PROFILE,
  RESOURCE_GROUP,
  RESOURCE_SECTION,
  RESOURCE_TOP_UP,
  STATISTICS,
  STATISTICS_REALTIME_SALE_CHART,
  STATISTICS_SALE_REPORT_BY_HOUR,
  STATISTICS_PRODUCT_HISTORY,
  STATISTICS_SALE_HISTORY,
  STATISTICS_SALE_HISTORY_WITH_FILTER,
  STATISTICS_SALE_REPORT_BY_WEEK_DAY,
  STATISTICS_SALE_REPORT_BY_SECTION,
  LIST_PATH_CAN_SHOW_SHOP_FILTER
};
