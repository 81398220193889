const messages = {
  users: {
    name: 'アカウント',
    createTitle: 'アカウント作成',
    updateTitle: 'アカウント編集',
    fields: {
      id: 'ID',
      loginId: 'ユーザCD',
      loginName: '氏名',
      email: 'メールアドレス',
      password: 'パスワード',
      authority: '権限',
      company: {
        id: '所属会社'
      },
      shop: {
        id: '所属店舗'
      },
      confirmPassword: 'パスワード確認',
      // Get field text when error validation in case of creating user
      addedShopIds: '所属店舗'
    },
    adminRole: 'システム管理',
    companyRole: '会社アカウント',
    shopRole: '店舗アカウント',
    staffRole: 'スタッフ',
    validation: {
      loginId: {
        format: 'ユーザCDは半角英数字、記号（「_（アンダーバー）」「-（ハイフン）」「.（ドット）」でご入力ください。'
      },
      password: {
        format: 'パスワードは半角英数字と次の記号（!"#$%&()*+,-./:;<=>?@[]^_`{|}~）でご入力ください。'
      }
    },
    helpBlock: {
      loginId: '半角英数記号 -_. のみ 3文字以上 30文字以内'
    }
  },
  'users.login': {
    fields: {
      loginId: 'ログインID',
      password: 'パスワード'
    },
    validation: {
      loginId: {
        format: 'ログインIDは半角英数字、記号（「_（アンダーバー）」「-（ハイフン）」「.（ドット）」でご入力ください。'
      },
      password: {
        format: 'パスワードは半角英数字と次の記号（!"#$%&()*+,-./:;<=>?@[]^_`{|}~）でご入力ください。'
      },
      loginWrong: 'ログインIDかパスワードが間違っています。'
    }
  }
};

export default messages;
