import * as React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';

const DeviceIsOnlineRealtimeField = ({ record = { id: null }, label = '' }) => {
  const { tablets } = useSelector(
      (state: ReduxState) => state.tabletSurvivalSignals
    ),
    { id } = record;

  let isOnline = true;

  if (id) {
    tablets.map((item) => {
      if (item.id === id && item.isOnline === 0) {
        isOnline = false;
      }
      return item;
    });
  }

  if (isOnline) {
    //@ts-ignore
    return <CheckCircleIcon color="secondary" label={label} />;
  }
  //@ts-ignore
  return <CancelIcon color="error" label={label} />;
};

export default DeviceIsOnlineRealtimeField;
