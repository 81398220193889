import produce from 'immer';

const initialState = {
  status: 'idle',
  currentReportUrl: '',
  error: null,
  data: {
    salePerHour: [],
    statistics: {
      totalSale: '0',
      totalPurchaseCost: '0',
      grossProfit: '0',
      grossMargin: '0',
      totalTransaction: '0',
      totalItem: '0',
      totalTax: '0'
    }
  }
};

const reportDataReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case 'FETCHING':
        draft.status = 'fetching';
        draft.currentReportUrl = payload;
        break;

      case 'FETCHED':
        draft.status = 'fetched';
        draft.data = payload;
        break;

      // case 'FETCH_ERROR':
      //   draft.status = 'fetched';
      //   draft.error = payload;
      //   break;
    }
  });

export default reportDataReducer;
