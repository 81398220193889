export enum CHART_MODE {
  DAILY = 1,
  MONTHLY,
  YEARLY
}

export const SWITCH_CHART_MODE = 'SWITCH_CHART_MODE';

export const switchChartMode = (mode: CHART_MODE) => {
  return {
    type: SWITCH_CHART_MODE,
    payload: mode
  };
};
