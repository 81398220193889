import React from 'react';
import { Form } from 'react-final-form';
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Divider,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Search } from '@material-ui/icons';
import classnames from 'classnames';
import { useListContext } from 'react-admin';
import l18n from 'containers/I18nProvider/index';

export const handleFilter = (setFilters, values, resetParams) => {
  if (Object.keys(values).length > 0) {
    setFilters(values, null);
  } else {
    setFilters(resetParams, null);
  }
};

const FilterCommon = (props) => {
  const { filterValues, setFilters } = useListContext(),
    onHandleFilter = (values) => {
      // Set filters
      if (Object.keys(values).length > 0) {
        setFilters(values, null);
      } else {
        setFilters({}, null);
      }
    },
    onKeyPress = (e) => {
      // Avoid enter submit form
      const keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
      }
    };

  return (
    <Form onSubmit={onHandleFilter} initialValues={filterValues}>
      {({ handleSubmit }) => (
        <div
          className={classnames(
            'wrapper-form',
            props.title ? 'accordion-form' : ''
          )}
        >
          <form
            onSubmit={handleSubmit}
            onKeyPress={onKeyPress}
            data-cy="formFilterSubmit"
          >
            {props.title ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-cy="filterExpandHeader"
                >
                  <Typography>{props.title}</Typography>
                </AccordionSummary>

                <Divider />

                <AccordionDetails>{props.inputElement}</AccordionDetails>

                <Divider />

                <AccordionActions>
                  <Button
                    className="btn btn-default form-control"
                    type="submit"
                    startIcon={<Search />}
                  >
                    {props.searchLabel ?? l18n.translate('ra.action.search')}
                  </Button>
                </AccordionActions>
              </Accordion>
            ) : (
              <>
                {props.inputElement}

                <Button
                  className="btn btn-default form-control"
                  type="submit"
                  startIcon={<Search />}
                  data-cy="filterSubmit"
                >
                  {props.searchLabel ?? l18n.translate('ra.action.search')}
                </Button>
              </>
            )}
          </form>
        </div>
      )}
    </Form>
  );
};

export default FilterCommon;
