import dashboardMessages from 'containers/Resources/Dashboard/Messages/en';
import userMessages from 'containers/Resources/User/Messages/en';
import companyMessages from 'containers/Resources/Company/Messages/en';
import shopMessages from 'containers/Resources/Shop/Messages/en';
import goodMessages from 'containers/Resources/Goods/Messages/en';
import tapBeerMessages from 'containers/Resources/TapBeer/Messages/en';
import beerServerMessages from 'containers/Resources/BeerServer/Messages/en';
import deviceMessages from 'containers/Resources/Device/Messages/en';
import saleLogMessages from 'containers/Resources/SaleLog/Messages/en';
import operationLogMessages from 'containers/Resources/OperationLog/Messages/en';
import errorLogMessages from 'containers/Resources/ErrorLog/Messages/en';
import groupMessages from 'containers/Resources/Group/Messages/en';
import sectionMessages from 'containers/Resources/Section/Messages/en';
import topUpMessages from 'containers/Resources/TopUp/Messages/en';

const englishMessages = {
  ra: {
    action: {
      add_filter: 'Add filter',
      add: 'Add',
      back: 'Back',
      customBack: 'Back To %{page}',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: 'Cancel',
      clear_input_value: 'Clear value',
      clone: 'Clone',
      confirm: 'Delete',
      create: 'Create',
      delete: 'Delete',
      edit: 'Edit',
      export: 'Export CSV',
      download: 'Download CSV',
      list: 'List',
      refresh: 'Refresh',
      remove_filter: 'Remove this filter',
      remove: 'Remove',
      save: 'Save',
      search: 'Search',
      show: 'Show',
      sort: 'Sort',
      undo: 'Undo',
      unselect: 'Unselect',
      expand: 'Expand',
      close: 'Close',
      open_menu: 'Open menu',
      close_menu: 'Close menu',
      import: 'Import CSV',
      operation: 'Operation',
      show_detail: 'Show Detail',
      close_detail: 'Close Detail'
    },
    boolean: {
      true: 'Yes',
      false: 'No',
      null: ' '
    },
    page: {
      create: 'Create %{name}',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      error: 'Something went wrong',
      list: '%{name}',
      loading: 'Loading',
      not_found: 'Not Found',
      show: '%{name} #%{id}',
      empty: 'No %{name} yet.',
      invite: 'Do you want to add one?',
      processing: 'Processing'
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.'
      },
      image: {
        upload_several: 'Drop some pictures to upload, or click to select one.',
        upload_single: 'Drop a picture to upload, or click to select it.'
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing:
          'At least one of the associated references no longer appears to be available.',
        single_missing:
          'Associated reference no longer appears to be available.'
      },
      password: {
        toggle_visible: 'Hide password',
        toggle_hidden: 'Show password'
      }
    },
    message: {
      about: 'About',
      are_you_sure: 'Are you sure?',
      bulk_delete_content:
        'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
      bulk_delete_title: 'Delete %{name} |||| Delete %{smart_count} %{name}',
      delete_content: '*cannot be restored if deleted',
      delete_title: 'Is it okay to delete #%{id}?',
      details: 'Details',
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: 'The form is not valid. Please check for errors',
      loading: 'The page is loading, just a moment please',
      no: 'No',
      not_found: 'Either you typed a wrong URL, or you followed a bad link.',
      yes: 'Yes',
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
      reportNotAvailable: 'Report not available.',
      pleaseChooseShop: 'Please choose Shop',
      pleaseChooseEitherStartOrEndDate:
        'Please choose either start date or end date'
    },
    navigation: {
      no_results: 'No results found.',
      no_more_results:
        'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin} - %{offsetEnd} of %{total} Records',
      page_one_record: '1 Record',
      page_rows_per_page: 'Rows per page:',
      next: 'Next',
      prev: 'Prev'
    },
    sort: {
      sort_by: 'Sort by %{field} %{order}',
      ASC: 'ascending',
      DESC: 'descending'
    },
    auth: {
      loginTitle: 'Login',
      auth_check_error: 'Please login to continue',
      user_menu: 'Profile',
      loginId: 'LoginId',
      password: 'Password',
      sign_in: 'Sign in',
      logout: 'Logout',
      logo: 'CRAFT BEER PLATFORM',
      powered: 'Powered by CRAFT BEER',
      authority_admin: 'System Admin',
      authority_company: 'Company Admin',
      authority_shop_admin: 'Shop Admin',
      authority_shop_staff: 'Shop Staff'
    },
    notification: {
      updated: 'ID#%{attribute} was updated.',
      created: 'ID#%{attribute} was registered.',
      deleted: '%{smart_count} item was deleted.',
      bad_item: 'Incorrect element',
      item_doesnt_exist: 'Element does not exist',
      http_error: 'Server communication error',
      data_provider_error: 'dataProvider error. Check the console for details.',
      i18n_error: 'Cannot load the translations for the specified language',
      canceled: 'Action cancelled',
      logged_out: 'Your session has ended, please reconnect.'
    },
    validation: {
      required: 'Please enter %{attribute}.',
      selected: 'Please select the %{attribute}.',
      minLength: 'Please set %{attribute} from %{min} characters.',
      maxLength: 'Please set %{attribute} to %{max} characters.',
      equalLength: 'Please set %{attribute} %{length} characters.',
      length: 'Please set %{attribute} from %{min} to %{max} characters.',
      minValue: 'The %{attribute} must be at least %{min}.',
      maxValue: 'The %{attribute} may not be greater than %{max}.',
      equalValue: 'The %{attribute} must be %{length} numbers.',
      betweenValue:
        'The %{attribute} must be at least %{min} and may not be greater than %{max}.',
      number: 'The %{attribute} must be a number.',
      email: 'Invalid %{attribute} format.',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
      emojis: 'Do not use the emojis for %{attribute}.',
      confirmPassword: 'Confirmation Password must match the password.',
      extension:
        'Extensions of the uploadable %{attribute} are only 「%{extension}」.',
      size: 'Size of the uploadable %{attribute} are only %{maxSize}MB.',
      duplication: 'Same %{attribute} has already been registered.'
    },
    common: {
      forbidden: 'You do not have the necessary permissions to use.',
      notfound: 'Can not find this page.',
      fatalError: 'System error occurred. Please contact your administrator.',
      detailModalTitle: 'Modal Title',
      daily: 'Daily',
      monthly: 'Monthly',
      yearly: 'Yearly',
      previousDay: 'Previous Day',
      nextDay: 'Next Day',
      previousMonth: 'Previous Month',
      nextMonth: 'Next Month',
      previousYear: 'Previous Year',
      nextYear: 'Next Year',
      total: 'Total',
      totalSale: 'Total Sale',
      totalTax: 'Total Tax',
      totalCost: 'Total Cost',
      totalNetSale: 'Total Net Sale',
      totalMargin: 'Total Margin',
      totalTransaction: 'Total Transaction',
      totalQuantity: 'Total Quantity',
      totalGrossSale: 'Total Gross Sale',
      averageUnitCost: 'Average Unit Cost',
      timeZone: 'Time Zone',
      taxIncluded: 'Tax Included',
      taxExcluded: 'Tax Excluded',
      compositionRatio: 'Composition Ratio',
      discount: 'Discount',
      pointUsage: 'Point Usage',
      soldQuantity: 'Sold Quantity',
      returnQuantity: 'Return Quantity',
      totalCustomer: 'Total Customer',
      customerUnitPrice: 'Customer Unit Price',
      confirmDelete: 'Do you want to delete %{resource} #%{id}',
      liveUpdate: 'Live Update',
      dayOfWeek: 'Day of Week',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      startDate: 'Start Date',
      endDate: 'End Date',
      startHour: 'Start Hour',
      endHour: 'End Hour',
      searchById: 'Search By Id',
      resetFilter: 'Reset Filter',
      showErrorLogFromYesterday: 'Only show error log from yesterday',
      restrictedArea: 'Restricted Area. %{reason}.',
      requireShopAdminRole: 'Required Shop Amin Role'
    },
    sidebar: {
      'admin-settings': 'Administrator Setting',
      'master-data-setting': 'Master Data Setting',
      'log-confirmation': 'Log Confirmation',
      'sale-confirmation': 'Sale Confirmation',
      statistics: 'Statistics'
    },
    helpBlock: {
      limitCodeMax: 'Alphabet and Number only. Max %{max} characters.',
      limitNoEmojisMax: 'No Emojis. Max %{max} characters.',
      limitAlphabetSymbolMinMax:
        'Alphabet and Number and Symbol only. Minimum %{min} characters. Max %{max} characters.',
      limitMax: 'Max %{max} characters.',
      numberOnly: 'Number only.',
      numberEqual: '%{max} numbers.',
      numberMinMax: 'Min %{min}. Max %{max}.',
      extension: 'Extensions jpg、jpg、png only.'
    }
  },
  resources: {
    processing: {
      name: 'Processing'
    },
    'realtime-sale-chart': {
      name: 'Realtime Sale Chart'
    },
    'sale-report-by-hour': {
      name: 'Sale Report By Hour',
      pageTitle: 'Sale Report By Hour'
    },
    'product-history': {
      name: 'Product History',
      ranking: 'Ranking',
      productCode: 'Product Code',
      beerName: 'Beer Name',
      department: 'Department',
      earning: 'Earning'
    },
    'sale-history': {
      name: 'Sale History',
      pageTitle: 'Sale History',
      targetPeriod: 'Target Period',
      target: 'Target',
      beerOnly: 'Beer Only',
      productOnly: 'Product Only',
      both: 'Both',
      loss: 'Loss',
      count_glass: 'Quantity of Glass',
      quantity: 'Quantity',
      sale: 'Sale',
      bySale: 'By Sale',
      byQuantity: 'By Quantity',
      byGrossProfit: 'By Gross Profit'
    },
    'sale-history-with-filter': {
      name: 'Sale History',
      code: 'Code'
    },
    'tap-beer-server': {
      name: 'Sale History'
    },
    'sale-report-by-week-day': {
      name: 'Sale Report By Week Day',
      pageTitle: 'Sale Report By Week Day'
    },
    'sale-report-by-section': {
      name: 'Sale Report By Section',
      pageTitle: 'Sale Report By Section'
    },
    ...dashboardMessages,
    ...userMessages,
    ...companyMessages,
    ...shopMessages,
    ...goodMessages,
    ...tapBeerMessages,
    ...beerServerMessages,
    ...deviceMessages,
    ...saleLogMessages,
    ...operationLogMessages,
    ...errorLogMessages,
    ...groupMessages,
    ...sectionMessages,
    ...topUpMessages
  }
};

export default englishMessages;
