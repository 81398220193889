import * as React from 'react';
import { getListData } from 'containers/Common/api';
import dataProvider from 'providers/data.provider';
import { ReduxState, useSafeSetState } from 'react-admin';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

const TapBeerField = (props) => {
  const { record } = props,
    { shop } = useSelector((state: ReduxState) => state.globalFilterReducer),
    [tapBeerServerData, setTapBeerServerData] = useSafeSetState({
      originalAmount: 0,
      remainingAmount: 0,
      outStandardDisplay: 0,
      tapBeer: {
        beerCode: '',
        beerName: ''
      }
    });

  React.useEffect(() => {
    const updateTapBeerServerData = () => {
      if (record && record.id && shop && shop.id) {
        getListData(dataProvider, 'tap-beer-server', {
          filter: {
            'beerServerId||$eq': record.id,
            'shopId||$eq': shop.id
          }
        }).then((data) => {
          if (data.length > 0) {
            setTapBeerServerData(data.shift());
          }
        });
      }
    };
    updateTapBeerServerData();
  }, [setTapBeerServerData, shop, record]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        {tapBeerServerData.tapBeer?.beerName}
      </Grid>
      <Grid item xs={3}>
        {tapBeerServerData.tapBeer?.beerCode}
      </Grid>
      <Grid item xs={2}>
        <strong>{tapBeerServerData?.originalAmount}</strong>
      </Grid>
      <Grid item xs={2}>
        <strong>{tapBeerServerData?.remainingAmount}</strong>
      </Grid>
      <Grid item xs={2}>
        <strong>{tapBeerServerData?.outStandardDisplay}</strong>
      </Grid>
    </Grid>
  );
};

export default React.memo(TapBeerField);
