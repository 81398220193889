import React from 'react';
import { TextInput } from 'react-admin';
import FilterCommon from 'components/Filters/index';
import l18n from 'containers/I18nProvider/index';

const CustomFilter = (props) => {
    return (
      <FilterCommon inputElement= {
        <TextInput
          label=""
          className="form-control"
          source="groupCode"
          placeholder={l18n.translate('resources.groups.fields.groupCode')}
        />
      } />
    );
};

export default CustomFilter;
