import { PATTERN_CODE } from 'containers/Common/patterns';

/**
 * [Create Goods validation schema]
 * @return  {[Object]} schema
 */
const getCreateGoodsValidationSchema = () => ({
  goodsCode: { required: true, maxLength: 30, pattern: PATTERN_CODE },
  janCode: { required: true, number: true, equalLength: 13 },
  goodsName: { required: true, maxLength: 100, emojis: true },
  goodsShortName: { required: true, maxLength: 100, emojis: true },
  purchaseCost: { required: false, number: true, minNumber: 1 },
  sellingPrice: { required: true, number: true, minNumber: 1 },
  description: { maxLength: 255 },
  imageUrl: { selected: true, image: true },
  taxReduction: { required: true },
  'company.id': { selected: true, number: true, minNumber: 1 },
  'group.id': { required: true, number: true, minNumber: 1 },
  'section.id': { required: true, number: true, minNumber: 1 }
});

/**
 * [Edit Goods validation schema]
 * @return  {[Object]} schema
 */
const getEditGoodsValidationSchema = () => ({
  goodsCode: { required: true, maxLength: 30, pattern: PATTERN_CODE },
  janCode: { required: true, number: true, equalLength: 13 },
  goodsName: { required: true, maxLength: 100, emojis: true },
  goodsShortName: { required: true, maxLength: 100, emojis: true },
  purchaseCost: { required: false, number: true, minNumber: 1 },
  sellingPrice: { required: true, number: true, minNumber: 1 },
  description: { maxLength: 255 },
  imageUrl: { selected: true, image: true },
  taxReduction: { required: true },
  'group.id': { required: true, number: true, minNumber: 1 },
  'section.id': { required: true, number: true, minNumber: 1 }
});

/**
 * [Create ShopGoods validation schema]
 * @return  {[Object]} schema
 */
const getCreateShopGoodsValidationSchema = () => ({
  purchaseCost: { required: false, number: true, minNumber: 1 },
  sellingPrice: { required: true, number: true, minNumber: 1 },
  description: { maxLength: 255 },
  image: { required: false, image: true }
});

/**
 * [EDIT ShopGoods validation schema]
 * @return  {[Object]} schema
 */
const getEditShopGoodsValidationSchema = () => ({
  purchaseCost: { required: true, number: true, minNumber: 1 },
  sellingPrice: { required: true, number: true, minNumber: 1 },
  description: { maxLength: 255 },
  image: { image: true }
});

export {
  getCreateGoodsValidationSchema,
  getEditGoodsValidationSchema,
  getCreateShopGoodsValidationSchema,
  getEditShopGoodsValidationSchema
};
