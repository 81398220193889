import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorPage } from 'containers/Pages/Error';
import UserProfileEdit from 'containers/Resources/User/Forms/UserEditProfileForm';
import l18n from 'containers/I18nProvider';
import RealtimeSaleChart from 'containers/Pages/Statistics/RealtimeSale';

const router = [
  <Route exact path="/auth/profile" component={UserProfileEdit} />,
  <Route
    exact
    path="/404"
    //@ts-ignore: custom prop of react admin
    noLayout
    render={(props) => (
      <ErrorPage
        {...props}
        title="404"
        subTitle="404 Page Not Found"
        description={l18n.translate('ra.common.notFound')}
      />
    )}
  />,
  <Route
    exact
    path="/realtime-sale-chart"
    render={(props) => (
      <RealtimeSaleChart
        title={l18n.translate('resources.realtime-sale-chart.name')}
        {...props}
      />
    )}
  />
];

export default router;
