import {
  getAccessToken,
  getCurrentCompanyId,
  getCurrentShopId,
  refreshToken
} from 'providers/auth.provider';
import { HttpError } from 'ra-core';
import { fetchUtils } from 'react-admin';
import * as Constants from 'containers/Common/constants';

const EXCLUDED_SHOP_HEADER_RESOURCES = ['shop-open-close-time'];

export interface FetchJsonResult {
  status: number;
  headers: Headers;
  body: string;
  json: any;
}

export const httpClient = async (
  url: string,
  options: fetchUtils.Options = {
    method: 'GET'
  }
): Promise<FetchJsonResult> => {
  // Set access token
  let headers = new Headers({ Accept: 'application/json' });
  const { method, body } = options,
    resource = options['resource'],
    jsonBody = body ? JSON.parse(String(body)) : {};

  // Set authorization
  headers.set('Authorization', `Bearer ${getAccessToken()}`);
  // Set companyId
  if ('companyId' in options) {
    headers.set('Company-Id', options['companyId']);
  } else {
    if (
      ['POST', 'PATCH'].includes(method) &&
      jsonBody['company'] &&
      jsonBody['company']['id']
    ) {
      headers.set('Company-Id', jsonBody['company']['id']);
    } else {
      if (
        resource !== Constants.RESOURCE_USERS &&
        resource !== Constants.RESOURCE_COMPANIES
      ) {
        headers.set('Company-Id', getCurrentCompanyId());
      }
    }
  }

  // Set shopId
  // In case of no api get, do not set shopId in header
  if (method === 'GET') {
    const currentShopId = await getCurrentShopId();
    if (
      EXCLUDED_SHOP_HEADER_RESOURCES.every(
        (resource) => url.includes(resource) === false
      )
    ) {
      currentShopId && headers.set('Shop-Id', currentShopId);
    }
  }

  options.headers = headers;

  return fetchUtils.fetchJson(url, options).catch(async (err: HttpError) => {
    if (err.status === 401) {
      await refreshToken();
      return httpClient(url, options);
    }
    return Promise.reject(new HttpError(err.message, err.status, err.body));
  });
};
