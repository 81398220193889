const messages = {
  'operation-logs': {
    name: '操作ログ',
    fields: {
      startTime: '操作日付',
      account: {
        loginName: '操作者'
      },
      device: {
        hardwareCode: 'ハードウェアコード'
      },
      functionId: '操作機能',
      operationType: '操作種別',
      operationStatus: '稼働状況',
      status: '操作状態',
      statusCode: 'ステータスコード',
      className: '操作画面',
      method: 'アクション',
      operationContent: 'ターゲットエンティティ',
      targetShop: {
        shopCode: 'ショップコード'
      },
      company: {
        companyCode: '会社コード'
      },
      operationTypeObj: {
        login: 'ログイン',
        created: '登録',
        updated: '更新',
        deleted: '削除',
        imported: 'インポート'
      },
      statusObj: {
        success: '成功',
        failed: '失敗'
      }
    },
    BeerServersController: 'ビールサーバ',
    DeviceController: 'タブレット',
    GoodsController: '商品',
    ProductsController: '商品',
    GoodsShopController: '商品（店舗）',
    TapBeersController: 'ビール',
    TapBeersShopController: 'ビール（店舗）',
    GroupsController: 'グループ部門一覧',
    SectionsController: 'セクションリスト',
    UserController: 'アカウント',
    createOne: '登録',
    updateOne: '更新',
    deleteOne: '削除',
    import: '→インポート'
  }
};

export default messages;
