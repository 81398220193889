import { InputAdornment, TableCell, TableRow } from '@material-ui/core';
import Loading from 'components/LoadingIndicator';
import SectionInput from 'components/SectionInput';
// Components
import TableForm from 'components/TableForm';
import { currency } from 'containers/App/constants';
import { formatImageUrl } from 'containers/App/utils';
// Containers
import { AlertCommon } from 'containers/Common/alert';
import {
  getBase64,
  handleSubmitFactory,
  prepareImage
} from 'containers/Common/helper';
import l18n from 'containers/I18nProvider/index';
import { useSafeSetState } from 'ra-core';
import React from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useNotify,
  useRedirect,
  useUpdate
} from 'react-admin';
// Local
import { getEditGoodsValidationSchema } from './validations';

const EditForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    record = props.record,
    resource = props.resource,
    [update] = useUpdate(resource, record.id),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = async (values) => {
      // Get image base64 to pass to api
      const imageResult = await getBase64(values, 'imageUrl');
      if (imageResult) {
        values['image'] = prepareImage(imageResult);
      }
      // @Todo
      // Display error msg when convert base64 error
      // saveState(setState, true, l18n.translate('resources.goods.validation.image.error'));
      // return;

      if (values['groupId']) {
        values['group'] = { id: values['groupId'] };
      }

      if (values['sectionId']) {
        values['section'] = { id: values['sectionId'] };
      }

      values['taxReduction'] = values['taxReduction'] ? 1 : 0;

      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getEditGoodsValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: update,
      onTransform,
      successMsgKey: 'updated'
    });

  // Get company
  let { availableCompanies } = globalFilterReducer,
    company = availableCompanies.find((item) => item.id === record.companyId);

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
        data-cy="errorMessage"
      />

      <TableForm {...props} save={handleSubmit}>
        <span title={l18n.translate('resources.goods.fields.id')} id="id">
          {record.id}
        </span>

        <span
          title={l18n.translate('resources.goods.fields.company.id')}
          id="companyName"
        >
          {company?.companyName}
        </span>

        <TextInput
          title={l18n.translate('resources.goods.fields.goodsCode')}
          required
          source="goodsCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitCodeMax', { max: 30 })}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.janCode')}
          required
          source="janCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.numberEqual', { max: 13 })}
        />

        <TextInput
          title={`${l18n.translate(
            'resources.goods.fields.goodsName'
          )} / ${l18n.translate('resources.goods.goodsNameInKana')}`}
          required
          source="goodsName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />
        <TextInput
          title={l18n.translate('resources.goods.fields.goodsShortName')}
          required
          source="goodsShortName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.company.purchaseCost')}
          required
          source="purchaseCost"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.company.sellingPrice')}
          required
          source="sellingPrice"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.company.description')}
          multiline
          rows="5"
          source="description"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitMax', { max: 255 })}
        />

        <ImageInput
          title={l18n.translate('resources.goods.fields.company.image')}
          required
          format={formatImageUrl}
          source="imageUrl"
          label="Related pictures"
          accept=".jpg,.jpeg,.png"
          className="img-input"
          helperText={l18n.translate('ra.helpBlock.extension')}
        >
          <ImageField source="url" />
        </ImageInput>

        <BooleanInput
          title={l18n.translate('resources.goods.fields.taxReduction')}
          label=""
          required={true}
          source="taxReduction"
          className="no-help-block"
        />

        <ReferenceInput
          required
          source="groupId"
          reference="groups"
          className="form-control custom-select no-help-block"
          title={l18n.translate('resources.goods.fields.group')}
        >
          <SelectInput optionText="groupName" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData }) =>
            formData.groupId && (
              <TableRow key="section">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.goods.fields.section')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <SectionInput source="sectionId" />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>
      </TableForm>
    </>
  );
};

export default EditForm;
