import React from 'react';
import {
  TextInput,
  FormDataConsumer,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  DateInput
} from 'react-admin';
import { TableRow, TableCell } from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
// Components
import ShopInput from 'components/ShopInput';
import Loading from 'components/LoadingIndicator';
import TableForm from 'components/TableForm';
// Containers
import { isAdmin } from 'containers/App/utils';
import l18n from 'containers/I18nProvider/index';
import { handleSubmitFactory } from 'containers/Common/helper';
import { AlertCommon } from 'containers/Common/alert';
// Local
import { getCreateBeerServerValidationSchema } from './validations';
import moment from 'moment';

const AddForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate(resource),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = (values) => {
      values['startUsingAt'] = moment(values['startUsingAt']).toISOString();
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCreateBeerServerValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: create,
      onTransform
    });

  let authority = props.permissions,
    { company, availableCompanies } = globalFilterReducer;

  // Avoid error in case of company undefined
  // if (!company) {
  //   company = availableCompanies[0];
  // }

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.beer-servers.fields.serverCode')}
          required
          source="serverCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitCodeMax', { max: 100 })}
        />

        <TextInput
          title={l18n.translate('resources.beer-servers.fields.obnizId')}
          required
          source="obnizId"
          className="form-control"
        />

        <DateInput
          title={l18n.translate('resources.beer-servers.fields.startUsingAt')}
          required
          source="startUsingAt"
          className="form-control"
        />

        <SelectInput
          title={l18n.translate('resources.beer-servers.fields.company.id')}
          required
          disabled={!isAdmin(authority)}
          source="company.id"
          optionText="companyName"
          choices={availableCompanies}
          defaultValue={company.id}
          className="form-control custom-select no-help-block"
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.company &&
            formData.company.id && (
              <TableRow key="shopid">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.beer-servers.fields.shop.id')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell id="shopid">
                  <ShopInput source="shop.id" {...rest} />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>
      </TableForm>
    </>
  );
};

export default AddForm;
