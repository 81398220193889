import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Keg = () => (
  <SvgIcon
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{ width: 40, height: 40 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{ fill: '#AE8B70' }}
      d="M33.033,256c0,90.531,19.462,173.439,51.749,237.892c5.585,11.15,17.058,18.107,29.528,18.107h283.38
	c12.47,0,23.943-6.957,29.528-18.107C459.505,429.439,478.967,346.53,478.967,256S459.506,82.561,427.218,18.108
	C421.633,6.958,410.16,0.001,397.69,0.001H114.31c-12.47,0-23.943,6.957-29.528,18.107C52.494,82.561,33.033,165.469,33.033,256z"
    />
    <path
      style={{ fill: '#9B735A' }}
      d="M429.419,256c0-54.333-5.058-107.748-14.444-156.903h14.444c4.561,0,8.258-3.697,8.258-8.258l0,0
	c0-4.561-3.697-8.258-8.258-8.258h-17.848c-5.734-26.576-12.668-51.847-20.967-74.935c-0.995-2.766-2.279-5.277-3.69-7.645h-25.955
	c5.677,0,11.21,5.194,14.097,13.226c7.695,21.411,14.163,44.799,19.6,69.355h-71.519c-2.259-25.991-4.978-50.718-8.226-73.322
	c-0.518-3.598-1.21-6.56-1.968-9.258h-17.419c0.847,1.718,2.137,5.339,3.032,11.613c3.15,21.893,5.791,45.826,8.001,70.968H205.443
	c2.21-25.141,4.851-49.075,8-70.968c0.895-6.274,2.185-9.895,3.032-11.613h-17.419c-0.758,2.698-1.451,5.66-1.968,9.258
	c-3.248,22.604-5.966,47.332-8.225,73.322h-20.407c3.246-22.964,7.039-44.677,11.447-64.474c2.482-11.15,7.581-18.107,13.123-18.107
	L114.309,0c-12.469,0-23.94,6.957-29.525,18.105C52.495,82.558,33.033,165.468,33.033,256s19.462,173.442,51.751,237.895
	C90.369,505.043,101.84,512,114.309,512l78.717-0.001c-5.542,0-10.641-6.958-13.123-18.107c-0.36-1.618-0.709-3.271-1.052-4.942
	c-1.054-5.135,2.85-9.983,8.093-9.983h7.024c0.992,8.111,2.019,16.097,3.122,23.774c0.518,3.598,1.21,6.56,1.968,9.258h17.419
	c-0.847-1.718-2.137-5.339-3.032-11.613c-0.995-6.915-1.912-14.144-2.815-21.419h90.743c-0.903,7.276-1.82,14.504-2.815,21.419
	c-0.895,6.274-2.185,9.895-3.032,11.613h17.419c0.758-2.698,1.45-5.66,1.968-9.258c1.103-7.677,2.13-15.663,3.122-23.774h63.527
	c-2.102,6.706-4.206,13.412-6.504,19.806c-2.887,8.032-8.419,13.226-14.097,13.226h25.955c1.411-2.368,2.695-4.88,3.69-7.645
	c2.947-8.199,5.709-16.7,8.34-25.387h13.959c4.561,0,8.258-3.697,8.258-8.258l0,0c0-4.561-3.697-8.258-8.258-8.258h-9.368
	C420.252,400.831,429.419,329.261,429.419,256z M173.698,462.451C163.13,403.381,156.903,332.482,156.903,256
	c0-53.269,3.007-103.875,8.397-149.657c0.49-4.164,4.04-7.246,8.233-7.246h13.951c-3.775,49.097-5.806,102.535-5.806,156.903
	c0,73.348,3.694,144.961,10.423,206.451H173.698z M208.715,462.451c-6.796-61.276-10.521-132.96-10.521-206.451
	c0-54.427,2.039-107.901,5.844-156.903h103.924c3.805,49.001,5.844,102.476,5.844,156.903c0,73.491-3.725,145.175-10.521,206.451
	H208.715z M319.9,462.451c6.729-61.49,10.422-133.103,10.422-206.451c0-54.368-2.031-107.806-5.806-156.903h73.698
	c9.571,48.941,14.688,102.441,14.688,156.903c0,73.576-9.383,145.308-26.483,206.451H319.9z"
    />
    <path
      style={{ fill: '#8C5F50' }}
      d="M136.944,498.774C112.541,430.871,99.097,344.653,99.097,256s13.444-174.871,37.847-242.774
	C139.831,5.194,145.363,0,151.041,0h-25.955c-1.411,2.368-2.695,4.88-3.69,7.645C96.363,77.291,82.581,165.492,82.581,256
	s13.782,178.709,38.814,248.354c0.995,2.766,2.279,5.277,3.69,7.645h25.955C145.363,511.999,139.831,506.806,136.944,498.774z"
    />
    <path
      style={{ fill: '#AFB9D2' }}
      d="M59.027,82.581h393.947c-3.614-11.361-7.518-22.407-11.795-33.032H70.822
	C66.545,60.174,62.641,71.22,59.027,82.581z"
    />
    <path
      style={{ fill: '#959CB5' }}
      d="M70.822,49.549c-4.277,10.625-8.181,21.671-11.795,33.032h109.43
	c1.606-11.361,3.341-22.407,5.242-33.032H70.822z"
    />
    <path
      style={{ fill: '#AFB9D2' }}
      d="M59.027,429.419h393.947c-3.614,11.361-7.518,22.407-11.795,33.032H70.822
	C66.545,451.826,62.641,440.78,59.027,429.419z"
    />
    <path
      style={{ fill: '#959CB5' }}
      d="M70.822,462.451c-4.277-10.625-8.181-21.671-11.795-33.032h109.43
	c1.606,11.361,3.341,22.407,5.242,33.032H70.822z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);

export default Keg;
