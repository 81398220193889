import React from 'react';
import { useSafeSetState } from 'ra-core';
import { SelectInput, useDataProvider } from 'react-admin';
import { useFormState } from 'react-final-form';
import Loading from 'components/Loading';
import { AddIconLink } from 'components/InputCommon/index';
import { getListDataWithOption } from 'containers/Common/api';

const SectionInput = ({ defaultValue = [], ...props }) => {
  let [state, setState] = useSafeSetState({
      sectionChoices: [],
      isDone: false
    }),
    dataProvider = useDataProvider(),
    // Get group from form
    {
      values: { group = { id: undefined }, groupId = undefined }
    } = useFormState();

  // Reset select value when select group
  React.useEffect(() => {
    setState({
      sectionChoices: [],
      isDone: false
    });
    const updateSectionChoices = (groupId) => {
      getListDataWithOption(dataProvider, `sections?group_id=${groupId}`, {
        fields: ['id', 'sectionName']
      }).then((data) => {
        setState({
          sectionChoices: data,
          isDone: true
        });
      });
    };
    updateSectionChoices(group.id || groupId);
  }, [group.id, groupId, setState, dataProvider]);

  if (!state.isDone) return <Loading />;

  const sectionChoices = state.sectionChoices;

  if (Array.isArray(sectionChoices) && sectionChoices.length === 0) {
    // Return link add shop in case of empty shop
    return <AddIconLink to="/groups/" />;
  }

  return (
    <SelectInput
      {...props}
      choices={sectionChoices}
      optionText="sectionName"
      defaultValue={sectionChoices[0].id}
      className="form-control custom-select no-help-block"
    />
  );
};

export default SectionInput;
