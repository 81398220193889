import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { SelectInput } from 'react-admin';
import { isAdmin } from 'containers/App/utils';

export const AddIconLink = (props) => (
  <Link to={props.to} data-cy="AddIconLink">
    <IconButton
      color="primary"
      className="icon-default"
    >
      <AddCircle />
    </IconButton>
  </Link>
);

export const CompanyInput = (props) => {
  const availableCompanies = props.availableCompanies,
    company = props.company ?? availableCompanies[0],
    authorityAdmin = isAdmin(props.authority);

  return (
    <>
    {availableCompanies.length > 0 ? (
      <SelectInput
        source="company.id"
        optionText="companyName"
        choices={authorityAdmin ? availableCompanies : [company]}
        defaultValue={company.id}
        className="form-control custom-select no-help-block"
        disabled={!authorityAdmin}
      />
    ) : (
      <AddIconLink to="/companies/create" />
    )}
    </>
  );
};
