import React from 'react';
import { Edit, Create } from 'react-admin';
import SectionCreateForm from './Forms/SectionCreateForm';
import SectionEditForm from './Forms/SectionEditForm';

const SectionCreate = (props) => (
  <Create {...props} className="table-responsive">
    <SectionCreateForm />
  </Create>
);

const SectionEdit = (props) => (
  <Edit {...props} undoable={false} className="table-responsive">
    <SectionEditForm />
  </Edit>
);

export { SectionCreate as Create, SectionEdit as Edit };
