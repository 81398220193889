import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useRefresh, Link, ReduxState } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import l18n from 'containers/I18nProvider/index';
import Loading from 'components/Loading';
import * as Constants from 'containers/Common/constants';
import {
  rehydrateCurrentCompany,
  setCurrentCompanySuccess,
  setCurrentShopSuccess,
  loadAvailableShopsSuccess
} from 'containers/GlobalFilter/actions';
import {
  RESOURCE_AUTH_PROFILE,
  RESOURCE_GOODS_SHOP,
  RESOURCE_TAP_BEERS_SHOP
} from 'containers/Common/constants';

/**
 * [Get child title for breadcrumb]
 * @param  {[String]} rootPath   [Root Path]
 * @param  {[String]} childPath  [Child Path]
 * @return {[String]}
 */
const getChildTitle = (rootPath: string, childPath: string) => {
  if (!childPath) return '';

  return childPath === 'create'
    ? l18n.translate(`resources.${rootPath}.createTitle`)
    : l18n.translate(`resources.${rootPath}.updateTitle`);
};

const LayoutBreadcrumbs = ({ ...props }) => {
  const {
    loadingCompany,
    company,
    loadingShop,
    shop,
    availableCompanies,
    availableShops,
    isDisplayShopFilter
  } = useSelector((state: ReduxState) => state.globalFilterReducer);

  const dispatch = useDispatch();

  const NO_LINK_ROUTE = [
    RESOURCE_AUTH_PROFILE,
    RESOURCE_GOODS_SHOP,
    RESOURCE_TAP_BEERS_SHOP
  ];

  let pathname = props.location.pathname,
    pathParts = pathname.split('/'),
    rootPath = pathParts[1],
    childPath = pathParts[2],
    link = NO_LINK_ROUTE.some((route) => route === rootPath)
      ? ''
      : '/' + rootPath;

  // In case of use path splash -> rootPath null
  if (!rootPath) {
    rootPath = Constants.RESOURCE_DASHBOARD;
  }

  if (rootPath === Constants.RESOURCE_AUTH_PROFILE) {
    rootPath = Constants.RESOURCE_USERS;
  }

  // Get title
  let rootTitle = '',
    childTitle = '';
  // Avoid error when page not found
  switch (rootPath) {
    case Constants.RESOURCE_DASHBOARD:
    case Constants.RESOURCE_USERS:
    case Constants.RESOURCE_COMPANIES:
    case Constants.RESOURCE_SHOPS:
    case Constants.PROCESSING:
    case Constants.RESOURCE_GOODS:
    case Constants.RESOURCE_GOODS_SHOP:
    case Constants.RESOURCE_TAP_BEERS:
    case Constants.RESOURCE_TAP_BEERS_SHOP:
    case Constants.RESOURCE_BEER_SERVERS:
    case Constants.RESOURCE_DEVICES:
    case Constants.RESOURCE_SALE_LOGS:
    case Constants.RESOURCE_SALE_AGGREGATION:
    case Constants.RESOURCE_OPERATION_LOGS:
    case Constants.RESOURCE_ERROR_LOGS:
    case Constants.RESOURCE_EVENT_LOGS:
    case Constants.RESOURCE_AUTH_PROFILE:
    case Constants.RESOURCE_UPDATE_PROFILE:
    case Constants.RESOURCE_GROUP:
    case Constants.RESOURCE_SECTION:
    case Constants.STATISTICS:
    case Constants.STATISTICS_REALTIME_SALE_CHART:
    case Constants.STATISTICS_SALE_REPORT_BY_HOUR:
    case Constants.STATISTICS_PRODUCT_HISTORY:
    case Constants.STATISTICS_SALE_HISTORY:
    case Constants.STATISTICS_SALE_HISTORY_WITH_FILTER:
    case Constants.STATISTICS_SALE_REPORT_BY_WEEK_DAY:
    case Constants.STATISTICS_SALE_REPORT_BY_SECTION:
      rootTitle = l18n.translate(`resources.${rootPath}.name`);
      childTitle = getChildTitle(rootPath, childPath);
      break;
  }
  // Shuffle title in case of having child title
  let title = rootTitle;
  if (childTitle) {
    title = childTitle;
    childTitle = rootTitle;
  }

  // Set display select company & shop
  // In case of create or update screen, do not display select company & shop on the breadcrumb
  let isDisplay = true;
  if (
    childPath ||
    rootPath === Constants.RESOURCE_USERS ||
    rootPath === Constants.RESOURCE_COMPANIES ||
    rootPath === Constants.RESOURCE_OPERATION_LOGS ||
    rootPath === Constants.RESOURCE_ERROR_LOGS ||
    rootPath === Constants.RESOURCE_AUTH_PROFILE
  ) {
    isDisplay = false;
  }

  // Set flag call api company & shop
  let isCallApi = true;
  if (
    rootPath === Constants.RESOURCE_COMPANIES ||
    rootPath === Constants.RESOURCE_OPERATION_LOGS ||
    rootPath === Constants.RESOURCE_ERROR_LOGS ||
    childPath === Constants.RESOURCE_UPDATE_PROFILE
  ) {
    isCallApi = false;
  }

  // Setting title page
  document.title = process.env.REACT_APP_WEBSITE_NAME + ' - ' + title;

  // To avoid call company & shop in case of create, edit, company list, operation logs, error logs
  const refresh = useRefresh();
  useEffect(() => {
    if (isCallApi) {
      dispatch(rehydrateCurrentCompany());
    }
  }, [isCallApi, dispatch]);

  // Change company
  const handleChangeCurrentCompany = (e) => {
    const companyId = e.target.value;
    const chosenCompany = availableCompanies
      .filter((item) => item.id === companyId)
      .shift();
    localStorage.setItem('currentCompany', JSON.stringify(chosenCompany));
    dispatch(setCurrentCompanySuccess(chosenCompany));

    localStorage.setItem('currentShop', JSON.stringify({}));

    dispatch(setCurrentShopSuccess({}));
    dispatch(loadAvailableShopsSuccess([]));

    refresh();
  };

  // Change shop
  const handleChangeCurrentShop = (e) => {
    const shopId = e.target.value;
    let chosenShop = availableShops
      .filter((item) => item.id === shopId)
      .shift();
    const payload = (chosenShop = {
      ...chosenShop,
      company: {
        id: company.id
      }
    });
    localStorage.setItem('currentShop', JSON.stringify(payload));
    dispatch(setCurrentShopSuccess(payload));
    refresh();
  };

  return (
    <div>
      <Breadcrumbs separator=">" className="breadcrumbs">
        {childTitle && link ? (
          <Link to={link}>{childTitle}</Link>
        ) : (
          childTitle && <Typography>{childTitle}</Typography>
        )}
        <Typography>{title}</Typography>
      </Breadcrumbs>

      {isDisplay && (
        <div className="wrapper-select">
          {loadingCompany ? (
            <Loading />
          ) : (
            company &&
            availableCompanies.length > 0 && (
              <Select
                id="companies"
                value={company.id}
                onChange={handleChangeCurrentCompany}
                style={{
                  marginRight: '1rem'
                }}
                data-cy="global-company"
                className="whiteAppendix"
              >
                {availableCompanies.map(
                  (companyOption) =>
                    companyOption && (
                      <MenuItem
                        key={companyOption.id}
                        className="optionShop"
                        value={companyOption.id}
                        data-cy="global-filter-company-option"
                      >
                        {companyOption.companyName}
                      </MenuItem>
                    )
                )}
              </Select>
            )
          )}

          {loadingShop ? (
            <Loading />
          ) : (
            isDisplayShopFilter &&
            shop &&
            availableShops.length > 0 && (
              <Select
                id="shops"
                value={shop?.id ? shop?.id : ''}
                onChange={handleChangeCurrentShop}
                data-cy="global-shop"
                className="whiteAppendix"
              >
                {availableShops.map(
                  (shopOption) =>
                    shopOption && (
                      <MenuItem
                        key={shopOption.id}
                        className="optionShop"
                        value={shopOption.id}
                        data-cy="global-filter-shop-option"
                      >
                        {shopOption.shopName}
                      </MenuItem>
                    )
                )}
              </Select>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default LayoutBreadcrumbs;
