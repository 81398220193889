import * as React from 'react';
import { ReduxState, useListContext } from 'react-admin';
import l18n from 'containers/I18nProvider/index';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { join } from 'lodash';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DateRangeIcon from '@material-ui/icons/DateRange';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  setStartDate,
  setEndDate,
  setWeekDay
} from 'containers/Common/Actions/saleLogFilterForm';
import { datepickerTheme } from 'containers/Common/styles';
import { ThemeProvider } from '@material-ui/styles';
import { DATE_FORMAT_DATE_ONLY } from 'containers/App/constants';

export const weekDays = [
  {
    id: 1,
    name: 'ra.common.monday'
  },
  {
    id: 2,
    name: 'ra.common.tuesday'
  },
  {
    id: 3,
    name: 'ra.common.wednesday'
  },
  {
    id: 4,
    name: 'ra.common.thursday'
  },
  {
    id: 5,
    name: 'ra.common.friday'
  },
  {
    id: 6,
    name: 'ra.common.saturday'
  },
  {
    id: 0,
    name: 'ra.common.sunday'
  }
];

const getAllDayOfWeekInDateRange = (start, end, day) => {
  start = start.split('T').shift();
  end = end.split('T').shift();
  var result = [];
  var current = moment(start).clone();

  // Get first match required weekDay
  if (
    current.day(day).isBefore(moment(end)) &&
    current.day(day).isSameOrAfter(current)
  ) {
    result.push(current.clone());
  }

  // shift 1 week to find next weekDay
  while (current.add(7, 'day').isBefore(moment(end))) {
    result.push(current.clone());
  }

  return result;
};

const buildFilter = (
  startDate: string,
  endDate: string,
  weekDay: number,
  filterValues: any
) => {
  var filters = { ...filterValues };

  delete filters['createdAt||$between'];
  delete filters['createdAt||$gte'];
  delete filters['createdAt||$lte'];
  delete filters['q'];
  delete filters['reportDate||$in'];

  if (
    weekDay !== null &&
    [0, 1, 2, 3, 4, 5, 6].some((item) => item === weekDay)
  ) {
    const selectedDates = getAllDayOfWeekInDateRange(
      startDate ?? moment().format(DATE_FORMAT_DATE_ONLY),
      endDate ?? moment().format(DATE_FORMAT_DATE_ONLY),
      weekDay
    );
    if (selectedDates.length > 0) {
      const transformedSelectedDates = selectedDates.map((selectedDate) =>
        selectedDate.clone().format('YYYYMMDD')
      );
      filters['reportDate||$in'] = join(transformedSelectedDates, ',');
    }
  } else {
    if (startDate) {
      filters['createdAt||$gte'] = moment(startDate.replace('T', ' ')).format(
        'YYYY-MM-DDTh:mm'
      );
    }

    if (endDate) {
      filters['createdAt||$lte'] = moment(endDate.replace('T', ' ')).format(
        'YYYY-MM-DDTh:mm'
      );
    }
  }

  return filters;
};

const SaleLogFilterForm = () => {
  const { displayedFilters, filterValues, setFilters } = useListContext(),
    { startDate, endDate, weekDay } = useSelector(
      (state: ReduxState) => state.saleLogFilterForm
    ),
    dispatch = useDispatch(),
    dispatchSetStartDate = (startDate: string) =>
      dispatch(setStartDate(startDate)),
    dispatchSetEndDate = (endDate: string) => dispatch(setEndDate(endDate)),
    dispatchSetWeekDay = (weekDay: number) => dispatch(setWeekDay(weekDay));

  React.useEffect(() => {
    const filters = buildFilter(startDate, endDate, weekDay, filterValues);
    if (_.isEqual(filters, filterValues) === false) {
      setFilters(filters, displayedFilters);
    }
  }, [startDate, endDate, weekDay, setFilters, displayedFilters, filterValues]);

  return (
    <>
      <ThemeProvider theme={datepickerTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            value={
              startDate
                ? moment(startDate?.replace('T', ' ')).format('LLL')
                : null
            }
            onChange={(val) => {
              dispatchSetStartDate(val.format('YYYY-MM-DDTh:mm'));
            }}
            data-cy="startDateSaleLog"
            format="LLL"
            label={l18n.translate('ra.common.startDate')}
            style={{ width: '100%' }}
            initialFocusedDate={null}
          />

          <DateTimePicker
            value={
              endDate ? moment(endDate?.replace('T', ' ')).format('LLL') : null
            }
            onChange={(val) => {
              dispatchSetEndDate(val.format('YYYY-MM-DDTh:mm'));
            }}
            data-cy="endDateSaleLog"
            format="LLL"
            label={l18n.translate('ra.common.endDate')}
            style={{ width: '100%' }}
            initialFocusedDate={null}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>

      <RadioGroup
        aria-label={l18n.translate('ra.common.dayOfWeek')}
        name="weekDay"
        value={weekDay}
        onChange={(e) => dispatchSetWeekDay(parseInt(e.target.value, 10))}
        style={{ marginTop: '2em' }}
      >
        <Box display="flex" flexDirection="row">
          <Box>
            <DateRangeIcon />
          </Box>
          <Box ml={1} pt={0.3} style={{ fontSize: 11 }}>
            {l18n.translate('ra.common.dayOfWeek')}
          </Box>
          <Box pt={0.5}>
            <Tooltip
              title={l18n.translate(
                'ra.message.pleaseChooseEitherStartOrEndDate'
              )}
              arrow
            >
              <InfoIcon style={{ height: '0.7em' }} />
            </Tooltip>
          </Box>
        </Box>

        {weekDays.map((weekDay) => (
          <FormControlLabel
            value={weekDay.id}
            control={<Radio />}
            label={l18n.translate(weekDay.name)}
            key={weekDay.id}
            disabled={startDate === null && endDate === null}
            data-cy="weekDayFilterSaleLog"
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default SaleLogFilterForm;
