const messages = {
  companies: {
    name: 'Company List',
    createTitle: 'Create Company',
    updateTitle: 'Update Company',
    fields: {
      id: 'ID',
      companyName: 'Company Name',
      companyCode: 'Company Code'
    },
    receiptInfo: 'Receipt Information',
    validation: {
      companyCode: {
        format: 'Please use alphabet, number only for Company Code.'
      }
    }
  }
};

export default messages;
