const messages = {
  'tap-beers': {
    name: 'Beer List',
    createTitle: 'Create Beer',
    updateTitle: 'Update Beer',
    fields: {
      id: 'ID',
      beerCode: 'Beer Code',
      beerName: 'Beer Name',
      beerNameEn: 'Beer Name(Eng)',
      beerShortName: 'Beer Short Name',
      beerStyle: 'Beer Type',
      alcohol: 'Alcohol',
      ibu: 'International Bitterness Unit',
      brewery: 'Brewery',
      breweryEn: 'Brewery(Eng)',
      description: 'Description',
      imageUrl: 'Beer Image',
      breweryUrl: 'Brewery Image URL',
      company: {
        id: 'Affiliation Company',
        purchaseCost: 'Purchase Cost(Company)',
        sellingPrice: 'Selling Price(Company)',
        purchaseCostMl: 'Purchase Cost(1ml)(Company)',
        sellingPriceMl: 'Selling Price(1ml)(Company)'
      },
      shop: {
        purchaseCost: 'Purchase Cost(Shop)',
        sellingPrice: 'Selling Price(Shop)',
        purchaseCostMl: 'Purchase Cost(1ml)(Shop)',
        sellingPriceMl: 'Selling Price(1ml)(Shop)'
      },
      richTaste: 'Rich Taste',
      sharpnessTaste: 'Sharpness Taste',
      bitterTaste: 'Bitter',
      sweetTaste: 'Sweet Taste',
      smell: 'Flavor',
      country: {
        id: 'Country'
      },
      acidity: 'Sour',
      bitter: 'Bitter',
      sweet: 'Sweet',
      weight: 'Body',
      group: 'Group Name',
      section: 'Section Name',
      logo1: 'Beer Image',
      logo2: 'Beer Image',
      colour: 'Colour',
      colourOptions: {
        red : 'red',
        yellow: 'yellow',
        white: 'white',
        black : 'black',
        orange : 'orange'
      }
    },
    validation: {
      beerCode: {
        format: 'Please use alphabet, number only for Beer Code.'
      }
    }
  },
  'tap-beers-shop': {
    name: 'Beer Shop List',
    createTitle: 'Create Goods Shop',
    updateTitle: 'Update Goods Shop'
  }
};

export default messages;
