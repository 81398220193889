import getWs, { destroyWs } from 'network/webSocket';
import { getCurrentCompanyId, getCurrentShopId } from 'providers/auth.provider';
import {
  initData,
  StatisticsDataPayload
} from '../Actions/statisticsRealtimeSale';
import { take, put, call, takeLatest } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { CURRENT_SHOP_SUCCESS } from 'containers/GlobalFilter/constants';
import { updateTabletStatus } from '../Actions/tabletSurvivalSignal';
import { updateBeerServerStatus } from '../Actions/beerServerSurvivalSignal';
import dataProvider from 'providers/data.provider';
import { getListData } from '../api';

const EVENT_TYPE_NEW_SALE = 'new-sale';
const EVENT_TYPE_REQUEST_SALE_DATA = 'request-sale-data';
const EVENT_TYPE_TABLET_STATUS_CHANGE = 'tablet-status-change';
const EVENT_TYPE_BEER_SERVER_STATUS_CHANGE = 'beer-server-status-change';

// this function creates an event channel from a given socket
// Setup subscription to incoming `ping` events
function createSocketChannel(socket) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel((emit) => {
    const errorHandler = (errorEvent) => {
      // create an Error object and put it into the channel
      emit(new Error(errorEvent.reason));
    };

    // setup the subscription
    socket.on('error', errorHandler);
    socket.on('connect', () => {
      const shopId = getCurrentShopId();
      if (!!shopId) {
        socket.emit(
          EVENT_TYPE_REQUEST_SALE_DATA,
          { companyId: getCurrentCompanyId(), shopId: shopId },
          (data: StatisticsDataPayload) =>
            emit({ type: EVENT_TYPE_REQUEST_SALE_DATA, payload: data })
        );

        // init tablet data
        getListData(dataProvider, 'devices').then((data) => {
          emit({ type: EVENT_TYPE_TABLET_STATUS_CHANGE, payload: data });
        });

        // init beer server data
        getListData(dataProvider, 'beer-servers').then((data) => {
          emit({ type: EVENT_TYPE_BEER_SERVER_STATUS_CHANGE, payload: data });
        });
      }
    });

    socket.on(EVENT_TYPE_TABLET_STATUS_CHANGE, (devices) => {
      emit({ type: EVENT_TYPE_TABLET_STATUS_CHANGE, payload: devices });
    });
    socket.on(EVENT_TYPE_BEER_SERVER_STATUS_CHANGE, (beerServers) => {
      emit({
        type: EVENT_TYPE_BEER_SERVER_STATUS_CHANGE,
        payload: beerServers
      });
    });

    socket.on(EVENT_TYPE_NEW_SALE, (beerServers) => {
      emit({
        type: EVENT_TYPE_NEW_SALE,
        payload: beerServers
      });
    });

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      socket.off(EVENT_TYPE_REQUEST_SALE_DATA);
    };

    return unsubscribe;
  });
}

export function* watchOnInitData() {
  // re establish new socket on shop changed
  yield call(destroyWs);

  const socket = yield call(getWs);
  const socketChannel = yield call(createSocketChannel, socket);

  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const { type, payload } = yield take(socketChannel);
      switch (type) {
        case EVENT_TYPE_REQUEST_SALE_DATA:
          /*
          // TODO 元に戻す事
          console.log("type, payload", type, payload);
          console.log("type, payload 2", payload['statistics']);
          payload['statistics']['paymentMethod'] = {
            m1: 1,
            m2: 2,
            m3: 11,
            m4: 4,
            m5: 5,
            m6: 6,
          }
          payload['statistics']['paymentMethod1'] = '1';
          payload['statistics']['paymentMethod2'] = '2';
          payload['statistics']['paymentMethod3'] = '3';
          payload['statistics']['paymentMethod4'] = '4';
          payload['statistics']['paymentMethod5'] = '5';
          payload['statistics']['paymentMethod6'] = '6';
          yield put(initData(payload));
          break;
          // ここまで不要
          */
        case EVENT_TYPE_NEW_SALE:
          yield put(initData(payload));
          break;

        case EVENT_TYPE_TABLET_STATUS_CHANGE:
          yield put(updateTabletStatus(payload));
          break;

        case EVENT_TYPE_BEER_SERVER_STATUS_CHANGE:
          yield put(updateBeerServerStatus(payload));
          break;

        default:
          break;
      }
    } catch (err) {
      // console.error('socket error:', err);
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      // socketChannel.close()
    }
  }
}

export default function* statisticsSaga() {
  yield takeLatest(CURRENT_SHOP_SUCCESS, watchOnInitData);
}
