import React, { useEffect } from 'react';
import { ReduxState, useSafeSetState } from 'react-admin';
import { useSelector } from 'react-redux';
import l18n from 'containers/I18nProvider/index';
import Loading from 'components/LoadingIndicator';
import RealtimeIndicator from 'components/RealtimeIndicator';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { currency, DATE_FORMAT_DATE_ONLY } from 'containers/App/constants';
import DateField from 'components/DateField';

enum DATA_DISPLAY_TYPE {
  NUMBER = 1,
  PERCENTAGE
}

const formatPercent = (value) => `${Math.ceil(value * 10000) / 100} %`;

const Summary = () => {
  const { date: selectedDate } = useSelector(
      (state: ReduxState) => state.statisticsRealtimeSale
    ),
    createData = (key, name, type = DATA_DISPLAY_TYPE.NUMBER) => ({
      key,
      name,
      type
    }),
    rows = [
      createData('totalSale', l18n.translate('ra.common.totalSale')),
      createData('totalPurchaseCost', l18n.translate('ra.common.totalCost')),
      createData('grossProfit', l18n.translate('ra.common.totalNetSale')),
      createData(
        'grossMargin',
        l18n.translate('ra.common.totalMargin'),
        DATA_DISPLAY_TYPE.PERCENTAGE
      ),
      createData(
        'totalTransaction',
        l18n.translate('ra.common.totalTransaction')
      ),
      createData('totalItem', l18n.translate('ra.common.totalQuantity')),

      createData('houseMoney', 'ハウスマネー'),
      createData('selfTap', '・セルフタップ商品'),
      createData('commonItem', '・一般商品'),
      createData('charge', 'チャージ')
    ],
    initialStatisticData = {
      totalSale: '0',
      totalPurchaseCost: '0',
      grossProfit: '0',
      grossMargin: '0',
      totalTransaction: '0',
      totalItem: '0',
      totalTax: '0',
      houseMoney: '0',
      selfTap: '0',
      commonItem: '0',
      charge: '0'
    },
    [statisticData, setStatisticData] = useSafeSetState(initialStatisticData),
    { loadingChartData, statisticsData } = useSelector(
      (state: ReduxState) => state.statisticsRealtimeSale
    );

  useEffect(() => {
    setStatisticData(statisticsData?.statistics);
  }, [setStatisticData, statisticsData.statistics]);

  if (loadingChartData || !statisticData) return <Loading />;

  return (
    <Card style={{ minHeight: 570 }}>
      <CardHeader
        avatar={<RealtimeIndicator />}
        title={l18n.translate('ra.common.liveUpdate')}
      />
      <CardContent>
        <p>
          <strong>{l18n.translate('ra.common.totalSale')}</strong>
        </p>
        <p>
          Date:{' '}
          <DateField
            source="date"
            record={{ date: selectedDate }}
            format={DATE_FORMAT_DATE_ONLY}
          />
        </p>

        <Typography variant="h2" align="center">
          {`${currency.symbol} ${parseInt(
            statisticData?.totalSale,
            10
          ).toLocaleString()}`}
        </Typography>

        <Divider />

        <Typography variant="subtitle1" align="center">
          {`${l18n.translate('ra.common.totalTax')} ${
            currency.symbol
          } ${parseInt(statisticData?.totalTax, 10).toLocaleString()}`}
        </Typography>

        <Divider />

        <Table aria-label="simple table">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {row.key=="houseMoney"?(statisticData[row.key]?statisticData[row.key]:"No Data Received"):
                  row.key=="selfTap"?(statisticData[row.key]?statisticData[row.key]:"No Data Received"):
                  row.key=="commonItem"?(statisticData[row.key]?statisticData[row.key]:"No Data Received"):
                  row.key=="charge"?(statisticData[row.key]?statisticData[row.key]:"No Data Received"):
                  row.type === DATA_DISPLAY_TYPE.NUMBER
                    ? parseInt(statisticData[row.key], 10).toLocaleString()
                    : row.type === DATA_DISPLAY_TYPE.PERCENTAGE
                    ? formatPercent(statisticData[row.key])
                  : statisticData[row.key]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Summary;
