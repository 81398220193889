import { Option } from 'containers/App/types';

const scope: string = 'resources.devices.fields';

const DEVICE_MAX_ATTEMPT_TO_LOGIN_BEFORE_LOCK = 5;

const barrelStatus: Option[] = [
    {
        id: 0,
        name: `${scope}.barrelStatusObj.startSold`
    },
    {
        id: 1,
        name: `${scope}.barrelStatusObj.maintain`
    }
];

const barrelStatusDisplay = {
    0: `${scope}.barrelStatusDisplayObj.startSold`,
    1: `${scope}.barrelStatusDisplayObj.maintain`
};

export {
    barrelStatus,
    barrelStatusDisplay,
    DEVICE_MAX_ATTEMPT_TO_LOGIN_BEFORE_LOCK
};
