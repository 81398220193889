import React from 'react';
import { CSVLink } from 'react-csv';
import { Button, useSafeSetState } from 'react-admin';
import l18n from 'containers/I18nProvider/index';
import ExportIcon from '@material-ui/icons/CloudDownload';
import { Paper, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DataTable from 'react-data-table-component';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { datepickerTheme } from 'containers/Common/styles';
import { getListDataByCompanyId } from 'containers/Common/api';
import dataProvider from 'providers/data.provider';
import { loadGlobalFilter } from 'containers/Common/hocs';
import {
  SUBTOTAL_BEER_ONLY_COLUMNS,
  COUNT_ITEM_BEER_ONLY_COLUMNS,
  PROFIT_BEER_ONLY_COLUMNS,
  SUBTOTAL_GOODS_ONLY_COLUMNS,
  COUNT_ITEM_GOODS_ONLY_COLUMNS,
  PROFIT_GOODS_ONLY_COLUMNS,
  SUBTOTAL_BEER_AND_GOODS_COLUMNS,
  COUNT_ITEM_BEER_AND_GOODS_COLUMNS,
  PROFIT_BEER_AND_GOODS_COLUMNS
} from './constants';
import { DATE_FORMAT_DATE_ONLY } from 'containers/App/constants';
import DataTablePagination from 'components/DataTablePagination';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop="1rem">{children}</Box>}
    </div>
  );
}

const customStyles = {
  rows: {
    style: {
      borderRightStyle: 'solid',
      borderRightWidth: '0.5px',
      borderRightColor: '#dddddd'
    }
  },
  cells: {
    style: {
      borderLeftStyle: 'solid',
      borderLeftWidth: '0.5px',
      borderLeftColor: '#dddddd'
    }
  },
  headCells: {
    style: {
      backgroundColor: '#555555',
      color: 'white'
    },
    activeSortStyle: {
      color: 'white',
      '&:focus': {
        outline: 'none'
      },
      '&:hover:not(:focus)': {
        color: 'white'
      }
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: 'white'
      },
      '&:hover': {
        color: 'white'
      }
    }
  }
};

const SaleHistory = (props) => {
  document.title = document.title =
    process.env.REACT_APP_WEBSITE_NAME +
    ' - ' +
    l18n.translate('resources.sale-history.pageTitle');

  const useStyles = makeStyles({
      default_tabStyle: {
        color: '#333333',
        fontSize: 13,
        backgroundColor: '#cccccc'
      },
      active_tabStyle: {
        fontSize: 13,
        color: '#ffffff',
        backgroundColor: '#555555',
        fontWeight: 'bold'
      }
    }),
    classes = useStyles(),
    [startDate, setStartDate] = useSafeSetState(moment()),
    [endDate, setEndDate] = useSafeSetState(moment()),
    [saleLogItemType, setSaleLogItemType] = useSafeSetState(0),
    [aggregateColumn, setAggregateColum] = useSafeSetState(0),
    [columns, setColumns] = useSafeSetState([]),
    [data, setData] = useSafeSetState([]),
    [defaultSortField, setDefaultSortField] = useSafeSetState(''),
    handleChangeSaleLogItemType = (e) => {
      setSaleLogItemType(parseInt(e.target.value, 10));
    },
    handleChangeAggregateColumn = (event, newValue) => {
      setAggregateColum(newValue);
    };
  React.useEffect(() => {
    const COLUMNS = [
        [
          SUBTOTAL_BEER_ONLY_COLUMNS(props.basePath, startDate, endDate),
          COUNT_ITEM_BEER_ONLY_COLUMNS(props.basePath, startDate, endDate),
          PROFIT_BEER_ONLY_COLUMNS(props.basePath, startDate, endDate)
        ],
        [
          SUBTOTAL_GOODS_ONLY_COLUMNS(props.basePath),
          COUNT_ITEM_GOODS_ONLY_COLUMNS(props.basePath),
          PROFIT_GOODS_ONLY_COLUMNS(props.basePath)
        ],
        [
          SUBTOTAL_BEER_AND_GOODS_COLUMNS(props.basePath, startDate, endDate),
          COUNT_ITEM_BEER_AND_GOODS_COLUMNS(props.basePath, startDate, endDate),
          PROFIT_BEER_AND_GOODS_COLUMNS(props.basePath, startDate, endDate)
        ]
      ],
      ODER_OPTIONS = ['sub_total', 'count_item', 'profit'];
    (async function fetchData() {
      const results = await getListDataByCompanyId(
        dataProvider,
        'sale-logs/best-seller',
        {
          sort: { field: ODER_OPTIONS[aggregateColumn], order: 'DESC' },
          filter: {
            q: {
              shop_id: props.globalFilterReducer.shop?.id,
              startDate: startDate.format('YYYYMMDD'),
              endDate: endDate.format('YYYYMMDD'),
              order: ODER_OPTIONS[aggregateColumn]
            }
          }
        }
      );
      switch (saleLogItemType) {
        case 0: // Beer Only
          setData(
            results.filter(
              (item: { tap_beer_id: string | null }) =>
                item.tap_beer_id !== null
            )
          );
          break;

        case 1: // Goods Only
          setData(
            results.filter(
              (item: { goods_id: string | null }) => item.goods_id !== null
            )
          );
          break;

        default:
          setData(results);
          break;
      }

      setColumns(COLUMNS[saleLogItemType][aggregateColumn]);
      setDefaultSortField(ODER_OPTIONS[aggregateColumn]);
    })();
  }, [
    props.globalFilterReducer.shop?.id,
    setData,
    saleLogItemType,
    aggregateColumn,
    startDate,
    endDate,
    setColumns,
    props.basePath,
    setDefaultSortField
  ]);

  return (
    <div style={{ padding: '1rem' }}>
      <Paper style={{ padding: '1rem' }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {l18n.translate('resources.sale-history.targetPeriod')}：
            <ThemeProvider theme={datepickerTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="From"
                  format={DATE_FORMAT_DATE_ONLY}
                  value={startDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => setStartDate(date)}
                  size="small"
                  data-cy="startDateButton"
                  style={{ paddingLeft: '1rem' }}
                  className="startDateInput"
                />

                <span
                  style={{ display: 'inline-block', padding: '0.7rem' }}
                >{` ~ `}</span>

                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="To"
                  format={DATE_FORMAT_DATE_ONLY}
                  value={endDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => setEndDate(date)}
                  size="small"
                  data-cy="endDateButton"
                />

                <Button
                  label={l18n.translate('ra.action.search')}
                  variant="contained"
                  style={{ margin: '0.2em 0.8em' }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <CSVLink
              data={data}
              filename={`Sale_History_Report_${moment().format(
                DATE_FORMAT_DATE_ONLY
              )}.csv`}
              data-cy="downloadSaleHistory"
            >
              <Button
                label={l18n.translate('ra.action.download')}
                startIcon={<ExportIcon />}
                variant="outlined"
                style={{ float: 'right' }}
              />
            </CSVLink>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '1rem' }}>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={saleLogItemType}
              onChange={handleChangeSaleLogItemType}
            >
              <p style={{ paddingRight: '1rem' }}>
                {l18n.translate('resources.sale-history.target')}：
              </p>
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label={l18n.translate('resources.sale-history.beerOnly')}
                data-cy="beerOnlyRadio"
              />
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label={l18n.translate('resources.sale-history.productOnly')}
                data-cy="productOnlyRadio"
              />
              <FormControlLabel
                value={2}
                control={<Radio color="primary" />}
                label={l18n.translate('resources.sale-history.both')}
                data-cy="bothRadio"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '1rem' }}>
            <Tabs
              value={aggregateColumn}
              onChange={handleChangeAggregateColumn}
              aria-label="simple tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#000000'
                }
              }}
            >
              <Tab
                label={l18n.translate('resources.sale-history.bySale')}
                {...a11yProps(0)}
                className={
                  aggregateColumn === 0
                    ? classes.active_tabStyle
                    : classes.default_tabStyle
                }
                data-cy="bySaleTap"
              />
              <Tab
                label={l18n.translate('resources.sale-history.byQuantity')}
                {...a11yProps(1)}
                className={
                  aggregateColumn === 1
                    ? classes.active_tabStyle
                    : classes.default_tabStyle
                }
                data-cy="byQuantityTap"
              />
              <Tab
                label={l18n.translate('resources.sale-history.byGrossProfit')}
                {...a11yProps(2)}
                className={
                  aggregateColumn === 2
                    ? classes.active_tabStyle
                    : classes.default_tabStyle
                }
                data-cy="byGrossProfitTap"
              />
            </Tabs>

            <TabPanel value={0} index={0}>
              <DataTable
                data={data}
                columns={columns}
                defaultSortField={defaultSortField}
                defaultSortAsc={false}
                pagination
                paginationComponent={DataTablePagination}
                paginationPerPage={50}
                sortIcon={<ExpandMoreIcon />}
                striped
                highlightOnHover
                customStyles={customStyles}
                dense
                noHeader
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default loadGlobalFilter(SaleHistory);
