import React from 'react';
import { Collapse, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

export const AlertCommon = ({ ...props }) => {
  if (!props.message) {
    return null;
  }

  return (
    <div className="alert">
      <Collapse in={props.open}>
        <Alert
          severity={props.type}
          action={
            props.setState ? (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  saveState({
                    setState: props.setState,
                    open: false,
                    message: ''
                  });
                }}
                data-cy="closeAlertButton"
              >
                <Close fontSize="inherit" />
              </IconButton>
            ) : (
              <IconButton />
            )
          }
          data-cy="alert"
        >
          {props.message}
        </Alert>
      </Collapse>
    </div>
  );
};

export const saveState = ({
  setState,
  open,
  message,
  loading = false,
  type = 'error',
  state = {}
}) => {
  setState({
    ...state,
    open: open,
    message: message,
    type: type,
    loading: loading
  });
};
