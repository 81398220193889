export const RESET_SALE_LOG_FILTER_FORM = 'RESET_SALE_LOG_FILTER_FORM';
export const SET_SALE_LOG_FILTER_START_DATE = 'SET_SALE_LOG_FILTER_START_DATE';
export const SET_SALE_LOG_FILTER_END_DATE = 'SET_SALE_LOG_FILTER_END_DATE';
export const SET_SALE_LOG_FILTER_WEEK_DAY = 'SET_SALE_LOG_FILTER_WEEK_DAY';

export const resetForm = () => {
  return {
    type: RESET_SALE_LOG_FILTER_FORM
  };
};

export const setStartDate = (startDate: String) => {
  return {
    type: SET_SALE_LOG_FILTER_START_DATE,
    payload: startDate
  };
};

export const setEndDate = (endDate: String) => {
  return {
    type: SET_SALE_LOG_FILTER_END_DATE,
    payload: endDate
  };
};

export const setWeekDay = (weekDay: number) => {
  return {
    type: SET_SALE_LOG_FILTER_WEEK_DAY,
    payload: weekDay
  };
};
