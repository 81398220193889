import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { datepickerTheme } from 'containers/Common/styles';
import { Button, ReduxState } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/LoadingIndicator';
import l18n from 'containers/I18nProvider/index';
import {
  selectDate,
  SelectDatePayload
} from 'containers/Common/Actions/statisticsRealtimeSale';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import {
  Paper,
  Grid,
  ButtonGroup,
  Button as MUIButton
} from '@material-ui/core';
import Summary from 'components/Statistics/Summary';
import RealtimeChart from 'components/Statistics/Charts/RealtimeChart';
import HistoricalChart from 'components/Statistics/Charts/HistoricalChart';
import HistoricalSummary from 'components/Statistics/HistoricalSummary';
import { fetchingReportData } from 'containers/Common/Actions/reportData';
import { env } from 'environment';
import { stringify } from 'query-string';
import ErrorMessage from 'components/ErrorMessage';
import {
  CHART_MODE,
  switchChartMode
} from 'containers/Common/Actions/chartMode';
import {
  DATE_FORMAT_DATE_ONLY,
  DATE_FORMAT_MONTH_AND_DAY
} from 'containers/App/constants';

import RealtimeTable from 'components/Statistics/RealtimeTable';
import MonthlyTable from 'components/Statistics/MonthlyTable';
import YearlyTable from 'components/Statistics/YearlyTable';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import DataTable from 'react-data-table-component';
import DataTablePagination from 'components/DataTablePagination';

const LABELS_HOURS_KEYS = [
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06'
];
const LABELS_HOURS = {
  '07': '07:00 - 08:00',
  '08': '08:00 - 09:00',
  '09': '09:00 - 10:00',
  '10': '10:00 - 11:00',
  '11': '11:00 - 12:00',
  '12': '12:00 - 13:00',
  '13': '13:00 - 14:00',
  '14': '14:00 - 15:00',
  '15': '15:00 - 16:00',
  '16': '16:00 - 17:00',
  '17': '17:00 - 18:00',
  '18': '18:00 - 19:00',
  '19': '19:00 - 20:00',
  '20': '20:00 - 21:00',
  '21': '21:00 - 22:00',
  '22': '22:00 - 23:00',
  '23': '23:00 - 00:00',
  '00': '00:00 - 01:00',
  '01': '01:00 - 02:00',
  '02': '02:00 - 03:00',
  '03': '03:00 - 04:00',
  '04': '04:00 - 05:00',
  '05': '05:00 - 06:00',
  '06': '06:00 - 07:00'
};

const dailyActions = ({
  previousDay,
  selectedDate,
  handleDateChange,
  nextDay
}) => (
  <>
    <Button
      label={l18n.translate('ra.common.previousDay')}
      className="previousDayButton"
      onClick={previousDay}
      data-cy="ChartPreviousDayButton"
    />
    <ThemeProvider theme={datepickerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          className="chartDatepicker"
          data-cy="ChartDatePicker"
          format={DATE_FORMAT_MONTH_AND_DAY}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
    <Button
      label={l18n.translate('ra.common.nextDay')}
      className="nextDayButton"
      onClick={nextDay}
      data-cy="ChartNextDayButton"
    />
  </>
);

const monthlyActions = ({
  previousMonth,
  selectedDate,
  handleDateChange,
  nextMonth
}) => (
  <>
    <Button
      label={l18n.translate('ra.common.previousMonth')}
      className="previousDayButton"
      onClick={previousMonth}
      data-cy="ChartPreviousMonthButton"
    />
    <ThemeProvider theme={datepickerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          className="chartDatepicker"
          data-cy="MonthlyChartDatePicker"
          format="Y/M"
          openTo="month"
          views={['year', 'month']}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
    <Button
      label={l18n.translate('ra.common.nextMonth')}
      className="nextDayButton"
      onClick={nextMonth}
      data-cy="ChartNextMonthButton"
    />
  </>
);

const yearlyActions = ({
  previousYear,
  selectedDate,
  handleDateChange,
  nextYear
}) => (
  <>
    <Button
      label={l18n.translate('ra.common.previousYear')}
      className="previousDayButton"
      onClick={previousYear}
      data-cy="ChartPreviousYearButton"
    />
    <ThemeProvider theme={datepickerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          className="chartDatepicker"
          data-cy="YearlyChartDatePicker"
          format="Y"
          openTo="year"
          views={['year']}
          autoOk
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
    <Button
      label={l18n.translate('ra.common.nextYear')}
      className="nextDayButton"
      onClick={nextYear}
      data-cy="ChartNextYearButton"
    />
  </>
);

const RealtimeSaleChart = (props) => {
  document.title = document.title =
    process.env.REACT_APP_WEBSITE_NAME + ' - ' + props.title;

  const { chartMode } = useSelector((state: ReduxState) => state.chartMode),
    { loadingCompany, loadingShop, shop } = useSelector(
      (state: ReduxState) => state.globalFilterReducer
    ),
    { date: selectedDate } = useSelector(
      (state: ReduxState) => state.statisticsRealtimeSale
    ),
    isRealTime = () =>
      chartMode === CHART_MODE.DAILY &&
      selectedDate === moment().format(DATE_FORMAT_DATE_ONLY),
    dispatch = useDispatch(),
    setChartMode = (chartMode: CHART_MODE) =>
      dispatch(switchChartMode(chartMode)),
    dispatchSelectDate = (payload: SelectDatePayload) =>
      dispatch(selectDate(payload)),
    nextDay = () =>
      dispatchSelectDate({
        date: moment(selectedDate).add(1, 'days').format(DATE_FORMAT_DATE_ONLY)
      }),
    previousDay = () =>
      dispatchSelectDate({
        date: moment(selectedDate).add(-1, 'days').format(DATE_FORMAT_DATE_ONLY)
      }),
    nextMonth = () =>
      dispatchSelectDate({
        date: moment(selectedDate)
          .add(1, 'months')
          .format(DATE_FORMAT_DATE_ONLY)
      }),
    previousMonth = () =>
      dispatchSelectDate({
        date: moment(selectedDate)
          .add(-1, 'months')
          .format(DATE_FORMAT_DATE_ONLY)
      }),
    nextYear = () =>
      dispatchSelectDate({
        date: moment(selectedDate).add(1, 'years').format(DATE_FORMAT_DATE_ONLY)
      }),
    previousYear = () =>
      dispatchSelectDate({
        date: moment(selectedDate)
          .add(-1, 'years')
          .format(DATE_FORMAT_DATE_ONLY)
      }),
    handleDateChange = (val) =>
      dispatchSelectDate({ date: val.format(DATE_FORMAT_DATE_ONLY) });

  useEffect(() => {
    if (
      chartMode === CHART_MODE.DAILY &&
      selectedDate !== moment().format(DATE_FORMAT_DATE_ONLY)
    ) {
      const loadHistoryReportData = (selectedDate) => {
        const url = `${env.baseApi}/sale-logs/sale-report?${stringify({
          shop_id: shop?.id,
          date: moment(selectedDate, DATE_FORMAT_DATE_ONLY).format('YYYYMMDD')
        })}`;
        dispatch(fetchingReportData(url));
      };
      loadHistoryReportData(selectedDate);
    }

    if (chartMode === CHART_MODE.MONTHLY) {
      const loadHistoryReportData = (selectedDate) => {
        const url = `${env.baseApi}/sale-logs/monthly-report?${stringify({
          shop_id: shop?.id,
          month: moment(selectedDate, DATE_FORMAT_DATE_ONLY).format('YYYYMM')
        })}`;
        dispatch(fetchingReportData(url));
      };
      loadHistoryReportData(selectedDate);
    }

    if (chartMode === CHART_MODE.YEARLY) {
      const loadHistoryReportData = (selectedDate) => {
        const url = `${env.baseApi}/sale-logs/yearly-report?${stringify({
          shop_id: shop?.id,
          year: moment(selectedDate, DATE_FORMAT_DATE_ONLY).format('YYYY')
        })}`;
        dispatch(fetchingReportData(url));
      };
      loadHistoryReportData(selectedDate);
    }
  }, [chartMode, dispatch, selectedDate, shop?.id]);

  if (loadingCompany || loadingShop) return <Loading />;

  if (!shop)
    return (
      <ErrorMessage
        errorMessage={`${l18n.translate(
          'ra.message.reportNotAvailable'
        )}. ${l18n.translate('ra.message.pleaseChooseShop')}`}
      />
    );

  return (
    <div style={{ padding: '1rem' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper style={{ padding: '1rem' }}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <ButtonGroup aria-label="outlined button group">
                  <MUIButton
                    className={
                      chartMode === CHART_MODE.DAILY && 'activeButtonStyle'
                    }
                    onClick={() => setChartMode(CHART_MODE.DAILY)}
                    data-cy="DailyModeButton"
                  >
                    {l18n.translate('ra.common.daily')}
                  </MUIButton>
                  <MUIButton
                    className={
                      chartMode === CHART_MODE.MONTHLY && 'activeButtonStyle'
                    }
                    onClick={() => setChartMode(CHART_MODE.MONTHLY)}
                    data-cy="MonthlyModeButton"
                  >
                    {l18n.translate('ra.common.monthly')}
                  </MUIButton>
                  <MUIButton
                    className={
                      chartMode === CHART_MODE.YEARLY && 'activeButtonStyle'
                    }
                    onClick={() => setChartMode(CHART_MODE.YEARLY)}
                    data-cy="YearlyModeButton"
                  >
                    {l18n.translate('ra.common.yearly')}
                  </MUIButton>
                </ButtonGroup>
              </Grid>
              <Grid item xs={8} style={{ textAlign: 'center' }}>
                {chartMode === CHART_MODE.DAILY
                  ? dailyActions({
                      previousDay,
                      selectedDate,
                      handleDateChange,
                      nextDay
                    })
                  : chartMode === CHART_MODE.MONTHLY
                  ? monthlyActions({
                      nextMonth,
                      selectedDate,
                      handleDateChange,
                      previousMonth
                    })
                  : chartMode === CHART_MODE.YEARLY
                  ? yearlyActions({
                      nextYear,
                      selectedDate,
                      handleDateChange,
                      previousYear
                    })
                  : null}
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          {isRealTime() ? <Summary /> : <HistoricalSummary />}
        </Grid>
        <Grid item xs={9}>
          {isRealTime() ? <RealtimeChart /> : <HistoricalChart selectedDate={selectedDate} />}
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: '1rem' }}>
          {chartMode == CHART_MODE.DAILY? <RealtimeTable selectedDate={selectedDate} /> : chartMode == CHART_MODE.MONTHLY? <MonthlyTable selectedDate={selectedDate} /> : <YearlyTable selectedDate={selectedDate} />}
        </Grid>
    </div>
  );
};

export default RealtimeSaleChart;
