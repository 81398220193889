import React from 'react';
import {
  TextInput,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  BooleanInput
} from 'react-admin';
import { useSafeSetState } from 'ra-core';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import { AlertCommon } from 'containers/Common/alert';
import l18n from 'containers/I18nProvider';
// Local
import { getCreateGroupValidationSchema } from './validations';

const AddForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate(resource),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = (values) => {
      values['taxReduction'] = values['taxReduction'] ? 1 : 0;
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCreateGroupValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: create,
      onTransform: onTransform,
      state: state
    });

  let { company, availableCompanies } = globalFilterReducer;

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.groups.fields.groupCode')}
          source="groupCode"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 30
          })}
        />

        <TextInput
          title={l18n.translate('resources.groups.fields.groupName')}
          source="groupName"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <BooleanInput
          title={l18n.translate('resources.groups.fields.taxReduction')}
          required={true}
          label=""
          source="taxReduction"
          className="no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.groups.fields.company.companyName')}
          optionText="companyName"
          source="company.id"
          choices={availableCompanies}
          defaultValue={company.id}
          required
          className="form-control custom-select no-help-block"
        />
      </TableForm>
    </>
  );
};

export default AddForm;
