import React from 'react';
import CustomFilter from '../Filters/index';
import CommonAction from 'components/Actions/index';
import * as Constants from 'containers/Common/constants';
import l18n from 'containers/I18nProvider/index';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

const CreateSection = () => (
    <Link
      to={`${Constants.RESOURCE_SECTION}/create`}
      style={{ marginLeft: '1em' }}
    >
      <Button
        variant="contained"
        color="primary"
        className="btn-primary float-right"
        startIcon={<Add />}
        data-cy="addSectionButton"
      >
        {l18n.translate('resources.sections.createTitle')}
      </Button>
    </Link>
  );

const CustomAction = (props: any) => {
    return (
    <CommonAction { ...props } filters={<CustomFilter />} extra={<CreateSection />}/>
    );
};

export default CustomAction;
