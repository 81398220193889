import React from 'react';
import { SelectInput, useSafeSetState, useDataProvider } from 'react-admin';
import Loading from 'components/Loading';
import { getListData } from 'containers/Common/api';

const CountryInput = (props) => {
  let [state, setState]: any[] = useSafeSetState({
    choices: [],
    loading: true
  });
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    const updateShopChoices = () => {
      getListData(dataProvider, 'country-regions', {
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'name', order: 'ASC' }
      }).then((data) => {
        const countries = data.map((item) => ({
          id: item.id,
          name: item.name,
          flag: (
            <>
              <img
                src={item.flagUrl}
                alt={item.name}
                className="country-flag"
              />
              {`${item.name} ${item.region ? '(' + item.region + ')' : ''}`}
            </>
          )
        }));
        setState({
          choices: countries,
          loading: false
        });
      });
    };
    updateShopChoices();
  }, [dataProvider, setState]);

  if (state.loading && state.choices.length === 0) {
    return <Loading />;
  }

  return (
    <SelectInput
      {...props}
      choices={state.choices}
      optionText="flag"
      className="form-control custom-select no-help-block"
      translateChoice={false}
    />
  );
};

export default CountryInput;
