import { CustomDataGrid } from 'components/DataGrid/index';
import DateField from 'components/DateField';
import GeneralPagination from 'components/GeneralPagination';
import React from 'react';
import { List, SelectField, TextField } from 'react-admin';
import CustomAction from './Actions/index';

const CLASS_NAMES = [
  {
    id: 'BeerServersController',
    name: 'resources.operation-logs.BeerServersController'
  },
  {
    id: 'DeviceController',
    name: 'resources.operation-logs.DeviceController'
  },
  {
    id: 'ProductsController',
    name: 'resources.operation-logs.ProductsController'
  },
  {
    id: 'GoodsShopController',
    name: 'resources.operation-logs.GoodsShopController'
  },
  {
    id: 'TapBeersController',
    name: 'resources.operation-logs.TapBeersController'
  },
  {
    id: 'TapBeersShopController',
    name: 'resources.operation-logs.TapBeersShopController'
  },
  {
    id: 'GroupsController',
    name: 'resources.operation-logs.GroupsController'
  },
  {
    id: 'SectionsController',
    name: 'resources.operation-logs.SectionsController'
  },
  {
    id: 'UserController',
    name: 'resources.operation-logs.UserController'
  }
];

const METHOD_NAMES = [
  { id: 'createOne', name: 'resources.operation-logs.createOne' },
  { id: 'updateOne', name: 'resources.operation-logs.updateOne' },
  { id: 'deleteOne', name: 'resources.operation-logs.deleteOne' },
  { id: 'import', name: 'resources.operation-logs.name' }
];

const OperationLogList = (props) => (
  <List
    {...props}
    actions={<CustomAction />}
    pagination={<GeneralPagination />}
    bulkActionButtons={false}
    className="table-responsive"
    sort={{
      field: 'id',
      order: 'DESC'
    }}
  >
    <CustomDataGrid edit="false" delete="false">
      <DateField source="startTime" sortable={false} />
      <TextField source="account.loginName" sortable={false} />
      <SelectField source="className" choices={CLASS_NAMES} sortable={false} />
      <SelectField source="method" choices={METHOD_NAMES} sortable={false} />
      <TextField source="operationContent" sortable={false} component="code" />
    </CustomDataGrid>
  </List>
);

export { OperationLogList as List };
