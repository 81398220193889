import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { ExpandMore, Edit, Delete } from '@material-ui/icons';
// Components
import TapBeerAnimation from 'components/Icons/TapBeerAnimation';
// Containers
import l18n from 'containers/I18nProvider';
// Local
import { barrelStatusDisplay } from '../constants';
import {
  Button,
  ReferenceField,
  TextField,
  useDataProvider,
  useRefresh,
  useSafeSetState
} from 'react-admin';
import { getListDataWithOption } from 'containers/Common/api';
import { RESOURCE_BEER_SERVERS } from 'containers/Common/constants';

const BarrelList = ({ ...props }) => {
  let state = props.state,
    form = useFormState(),
    values = form.values;

  const dataProvider = useDataProvider(),
    refresh = useRefresh(),
    [barrelList, setData] = useSafeSetState([]),
    [deletedBarrel, setDeletedBarrel] = useSafeSetState(null),
    [openDeletePopup, setOpenDeletePopup] = useSafeSetState(false),
    onCloseDeletePopup = () => setOpenDeletePopup(false),
    onOpenDeletePopup = () => setOpenDeletePopup(true),
    onDeleteBarrel = (barrel) => {
      setDeletedBarrel(barrel);
      onOpenDeletePopup();
    },
    handleDeleteBarrel = () => {
      dataProvider.delete('tap-beer-server', {
        id: deletedBarrel.id,
        previousData: deletedBarrel
      });
      onCloseDeletePopup();
      refresh();
    };
  React.useEffect(() => {
    getListDataWithOption(dataProvider, 'tap-beer-server', {
      filter: { deviceId: values.id },
      pagination: { page: 1, perPage: 99 },
      sort: { field: 'id', order: 'ASC' }
    }).then((data) => {
      setData(data);
    });
  }, [dataProvider, setData, values.id]);

  if (!values.beerServer) {
    values.beerServer = { id: '' };
  }

  if (!values.tapBeer) {
    values.tapBeer = { id: '' };
  }

  const onEditBarrel = (barrel) => {
    props.setState({
      showEditDialogue: true,
      shopId: state.shopId,
      barrelRecord: barrel
    });
  };

  return (
    barrelList.length > 0 && (
      <Accordion
        defaultExpanded
        className="input-form-dialogue"
        data-cy="listBarrel"
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            {l18n.translate('resources.devices.fields.barrel')}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <div className="MuiCard-root MuiPaper-elevation1 MuiPaper-root MuiPaper-rounded barrel-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall">
                    {l18n.translate(
                      'resources.devices.fields.beerServer.serverCode'
                    )}
                  </TableCell>
                  <TableCell className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall">
                    {l18n.translate(
                      'resources.devices.fields.beerServer.beerName'
                    )}
                  </TableCell>
                  <TableCell className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall">
                    {l18n.translate(
                      'resources.devices.fields.beerServer.remainingAmount'
                    )}
                  </TableCell>
                  <TableCell className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall">
                    {l18n.translate(
                      'resources.devices.fields.beerServer.status'
                    )}
                  </TableCell>
                  <TableCell className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {barrelList.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>
                      <ReferenceField
                        record={data}
                        basePath={props.basePath}
                        source="beerServerId"
                        reference={RESOURCE_BEER_SERVERS}
                        link={false}
                        label={l18n.translate(
                          'resources.devices.fields.beerServer.serverCode'
                        )}
                      >
                        <TextField source="serverCode" />
                      </ReferenceField>
                    </TableCell>
                    <TableCell>{data.tapBeer?.beerName}</TableCell>
                    <TableCell className="beer-animation">
                      <TapBeerAnimation
                        key={data.id}
                        {...props}
                        record={data}
                      />
                    </TableCell>
                    <TableCell>
                      {l18n.translate(barrelStatusDisplay[data.maintainFlag])}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => onEditBarrel(data)}
                        color="primary"
                        className="icon-default"
                        data-cy="editBarrelButton"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => onDeleteBarrel(data)}
                        color="primary"
                        className="icon-delete"
                        data-cy="deleteBarrelButton"
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Dialog
            fullWidth
            open={Boolean(openDeletePopup)}
            onClose={onCloseDeletePopup}
          >
            <DialogTitle>
              {l18n.translate('ra.common.confirmDelete', {
                resource: l18n.translate('resources.tap-beer-server.name'),
                id: deletedBarrel?.id
              })}
            </DialogTitle>
            <DialogActions>
              <Button
                label={l18n.translate('ra.action.cancel')}
                onClick={onCloseDeletePopup}
                data-cy="editBarrelButton"
              />
              <Button
                label={l18n.translate('ra.action.delete')}
                onClick={handleDeleteBarrel}
                data-cy="confirmDeleteBarrelButton"
              />
            </DialogActions>
          </Dialog>
        </AccordionDetails>
      </Accordion>
    )
  );
};

export default BarrelList;
