import React from 'react';
import { TextInput, useCreate, useNotify, useRedirect } from 'react-admin';
import { useSafeSetState } from 'ra-core';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import { AlertCommon } from 'containers/Common/alert';
import l18n from 'containers/I18nProvider/index';
// Local
import { getCompanyValidationSchema } from './validations';

const AddForm = (props) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate(resource),
    notify = useNotify(),
    redirectTo = useRedirect(),
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCompanyValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: create
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.companies.fields.companyCode')}
          required={true}
          source="companyCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitCodeMax', { max: 30 })}
        />
        <TextInput
          title={l18n.translate('resources.companies.fields.companyName')}
          required={true}
          source="companyName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />
      </TableForm>
    </>
  );
};

export default AddForm;
