import React from 'react';
import {
  List,
  Edit,
  Create,
  TextField,
  Button,
  TopToolbar,
  useDataProvider,
  useRefresh,
  useNotify
} from 'react-admin';
import { loadGlobalFilter } from 'containers/Common/hocs';
import GeneralPagination from 'components/GeneralPagination';
import LockedIcon from '@material-ui/icons/Lock';
import UnLockedIcon from '@material-ui/icons/LockOpen';
import {
  DEVICE_MAX_ATTEMPT_TO_LOGIN_BEFORE_LOCK} from './constants';
import DeviceCreateForm from './Forms/DeviceCreateForm';
import DeviceEditForm from './Forms/DeviceEditForm';
import CustomAction from './Actions/index';
import { CustomDataGrid } from 'components/DataGrid/index';
import { UserRole } from 'providers/auth.provider';
import l18n from 'containers/I18nProvider/index';
import DeviceIsOnlineRealtimeField from 'components/DeviceIsOnlineRealtimeField';

const DeviceListPage = ({ globalFilterReducer, dispatch, ...props }) => {
  let hideEdit = '',
    hideDelete = '',
    isHideAll = false;
  if (props.permissions === UserRole.STAFF) {
    hideEdit = 'true';
    hideDelete = 'true';
    isHideAll = true;
  }

  return (
    <List
      {...props}
      actions={<CustomAction />}
      pagination={<GeneralPagination />}
      className="table-responsive"
      bulkActionButtons={false}
    >
      <CustomDataGrid edit={hideEdit} delete={hideDelete}>
        <TextField source="id" />
        <TextField source="hardwareCode" />
        <TextField source="hardwareName" />
        <DeviceIsOnlineRealtimeField label={l18n.translate('resources.devices.fields.isOnline')} />
        <TextField source="shop.shopCode" />
        {!isHideAll && (
          <TextField
            source="operation"
            sortable={false}
            label={l18n.translate('ra.action.operation')}
            className="operation"
          />
        )}
      </CustomDataGrid>
    </List>
  );
};

const DeviceList = loadGlobalFilter(DeviceListPage);

const DeviceCreatePage = ({ globalFilterReducer, ...props }) => (
  <Create {...props} className="table-responsive">
    <DeviceCreateForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Create>
);

const DeviceCreate = loadGlobalFilter(DeviceCreatePage);

const DeviceEditActions = (props) => {
  const dataProvider = useDataProvider(),
    notify = useNotify(),
    refresh = useRefresh(),
    SendRequestUnlockDevice = async (deviceId) => {
      dataProvider.getOne('devices/unlock', { id: deviceId });
      notify('resources.devices.deviceWasUnLocked');
      setTimeout(() => refresh(), 1000);
    };

  return (
    <TopToolbar>
      {props.data?.failedAttempts < DEVICE_MAX_ATTEMPT_TO_LOGIN_BEFORE_LOCK ? (
        <UnLockedIcon data-cy="unlockIcon"/>
      ) : (
        <>
          <p>
            <LockedIcon /> {l18n.translate('resources.devices.deviceWasLocked')}
          </p>
          <Button
            color="primary"
            onClick={() => SendRequestUnlockDevice(props.data?.id)}
            label={l18n.translate('resources.devices.unlockDevice')}
            variant="contained"
            data-cy="unlockDeviceButton"
          />
        </>
      )}
    </TopToolbar>
  );
};

const DeviceEditPage = ({ globalFilterReducer, ...props }) => (
  <Edit
    {...props}
    undoable={false}
    className="table-responsive"
    actions={<DeviceEditActions />}
  >
    <DeviceEditForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Edit>
);

const DeviceEdit = loadGlobalFilter(DeviceEditPage);

export { DeviceList as List, DeviceCreate as Create, DeviceEdit as Edit };
