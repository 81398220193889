import React from 'react';
import {
  List,
  Edit,
  Create,
  TextField,
  ReferenceField
} from 'react-admin';
import { loadGlobalFilter } from 'containers/Common/hocs';
import GeneralPagination from 'components/GeneralPagination';
import BeerServerCreateForm from './Forms/BeerServerCreateForm';
import BeerServerEditForm from './Forms/BeerServerEditForm';
import CustomAction from './Actions/index';
import { CustomDataGrid } from 'components/DataGrid/index';
import { UserRole } from 'providers/auth.provider';
import l18n from 'containers/I18nProvider/index';
import BeerServerIsOnlineRealtimeField from 'components/BeerServerIsOnlineRealtimeField';
import { RESOURCE_SHOPS } from 'containers/Common/constants';
import TapBeerField from 'components/TapBeerField';
import { Grid } from '@material-ui/core';

const CustomLabel = () => (
  <Grid container spacing={1}>
    <Grid item xs={3}>
      <span className="TableHeading">
        {l18n.translate('resources.devices.fields.beerServer.beerName')}
      </span>
    </Grid>
    <Grid item xs={3}>
      <span className="TableHeading">
        {l18n.translate('resources.tap-beers.fields.beerCode')}
      </span>
    </Grid>
    <Grid item xs={2}>
      <span className="TableHeading">
        {l18n.translate('resources.devices.fields.beerServer.amount')}
      </span>
    </Grid>
    <Grid item xs={2}>
      <span className="TableHeading">
        {l18n.translate('resources.devices.fields.beerServer.remainingAmount')}
      </span>
    </Grid>
    <Grid item xs={2}>
      <span className="TableHeading">
        {l18n.translate('resources.devices.fields.beerServer.beerOutStandard')}
      </span>
    </Grid>
  </Grid>
);

const BeerServerListPage = ({ globalFilterReducer, ...props }) => {
  let hideEdit = '',
    hideDelete = '',
    isHideAll = false;
  if (props.permissions === UserRole.STAFF) {
    hideEdit = 'true';
    hideDelete = 'true';
    isHideAll = true;
  }

  return (
    <List
      {...props}
      actions={<CustomAction />}
      pagination={<GeneralPagination />}
      bulkActionButtons={false}
      className="table-responsive"
    >
      <CustomDataGrid edit={hideEdit} delete={hideDelete}>
        <TextField source="id" />
        <TextField source="serverCode" />
        <BeerServerIsOnlineRealtimeField
          label={l18n.translate('resources.beer-servers.fields.isOnline')}
        />
        <ReferenceField
          label={l18n.translate('resources.beer-servers.fields.shop.shopCode')}
          source="shopId"
          reference={RESOURCE_SHOPS}
          link={false}
        >
          <TextField source="shopCode" />
        </ReferenceField>
        <TapBeerField label={<CustomLabel />} />
        {!isHideAll && (
          <TextField
            source="operation"
            sortable={false}
            label={l18n.translate('ra.action.operation')}
            className="operation"
          />
        )}
      </CustomDataGrid>
    </List>
  );
};

const BeerServerList = loadGlobalFilter(BeerServerListPage);

const BeerServerCreatePage = ({ globalFilterReducer, ...props }) => (
  <Create {...props} className="table-responsive">
    <BeerServerCreateForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Create>
);

const BeerServerCreate = loadGlobalFilter(BeerServerCreatePage);

const BeerServerEditPage = ({ globalFilterReducer, ...props }) => (
  <Edit
    {...props}
    undoable={false}
    className="table-responsive"
  >
    <BeerServerEditForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Edit>
);

const BeerServerEdit = loadGlobalFilter(BeerServerEditPage);

export {
  BeerServerList as List,
  BeerServerCreate as Create,
  BeerServerEdit as Edit
};
