import React from 'react';
import {
  TextInput,
  NumberInput,
  ImageInput,
  ImageField,
  SelectInput,
  useUpdate,
  useNotify,
  useRedirect,
  SaveButton,
  Toolbar,
  Button,
  ReferenceInput,
  FormDataConsumer,
  useDataProvider
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Slide, TableBody } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { AlertCommon } from 'containers/Common/alert';
import {
  getBase64,
  handleSubmitFactory,
  prepareImage
} from 'containers/Common/helper';
import { formatImageUrl } from 'containers/App/utils';
import l18n from 'containers/I18nProvider/index';
// Local
import { getEditTapBeerValidationSchema } from './validations';
import { colourValues, tasteValues } from '../constants';
import { currency } from 'containers/App/constants';
import CountryInput from 'components/CountryInput';
import { BackButton } from 'components/Toolbar';
import { Radar } from 'react-chartjs-2';
import SectionInput from 'components/SectionInput';
import Keg from 'components/Icons/Keg';
import { getListDataWithOption } from 'containers/Common/api';
import englishMessages from '../Messages/en';

const Transition = React.forwardRef((props, ref) => (
  // @ts-ignore: miss incompatible react -> React Component == React Element
  <Slide direction="up" ref={ref} {...props} />
));

const TapBeerToolBar = (props) => {
  const [open, setOpen] = React.useState(false),
    { record } = props,
    radarChartLabel = [
      `${l18n.translate('resources.tap-beers.fields.smell')} - ${
        englishMessages['tap-beers'].fields.smell
      }`,
      `${l18n.translate('resources.tap-beers.fields.acidity')} - ${
        englishMessages['tap-beers'].fields.acidity
      }`,
      `${l18n.translate('resources.tap-beers.fields.bitter')} - ${
        englishMessages['tap-beers'].fields.bitter
      }`,
      `${l18n.translate('resources.tap-beers.fields.sweet')} - ${
        englishMessages['tap-beers'].fields.sweet
      }`,
      `${l18n.translate('resources.tap-beers.fields.weight')} - ${
        englishMessages['tap-beers'].fields.weight
      }`
    ],
    radarChartData = [
      record.smell,
      record.acidity,
      record.bitter,
      record.sweet,
      record.weight
    ],
    handleOpen = () => {
      setOpen(true);
    },
    handleClose = () => {
      setOpen(false);
    },
    dataProvider = useDataProvider();

  let [tapBeerServerData, setTapBeerServerData] = useSafeSetState({
    originalAmount: 0,
    remainingAmount: 0
  });

  React.useEffect(() => {
    const fetchTapBeerServerData = (tapBeerId) => {
      getListDataWithOption(
        dataProvider,
        `tap-beer-server?tapBeerId=${tapBeerId}&fields=id,originalAmount,remainingAmount&`
      ).then((data) => {
        setTapBeerServerData(data[0]);
      });
    };
    fetchTapBeerServerData(props.record.id);
  }, [props.record.id, setTapBeerServerData, dataProvider]);

  let remainingPercentage = 0;
  if (
    tapBeerServerData.originalAmount > 0 &&
    tapBeerServerData.remainingAmount > 0
  ) {
    remainingPercentage = Math.ceil(
      (tapBeerServerData.remainingAmount / tapBeerServerData.originalAmount) *
        100
    );
  }

  return (
    <>
      <Toolbar {...props}>
        <BackButton {...props} />
        <Button
          variant="contained"
          color="primary"
          className="btn-default"
          label={l18n.translate('ra.action.show_detail')}
          onClick={handleOpen}
          data-cy="buttonShowDetailDialog"
        />
        <SaveButton className="btn-primary btn-save" data-cy="saveButton" />
      </Toolbar>

      <Dialog
        open={open}
        // @ts-ignore: unknown error
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogContent className="min-width-500">
          <div className="topRightTitle">
            <Keg />
            <span className="kegRemainingPercentage">{`${remainingPercentage}%`}</span>
          </div>

          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className="close-dialog-icon"
              data-cy="buttonCloseDetailDialog"
            >
              <CloseIcon />
            </IconButton>
          ) : null}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 100 }}>
                  <img
                    className="beerLogo"
                    src={record.imageUrl}
                    alt={record.beerCode}
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <Typography variant="h5" align="center">
                    <strong>{record.brewery}</strong>
                  </Typography>

                  <Typography variant="h4" align="center">
                    <strong>{record.beerName}</strong>
                  </Typography>

                  <Typography variant="h5" align="center">
                    {record.beerNameEn}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 100 }}></TableCell>
              </TableRow>
            </TableHead>
          </Table>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ width: '25%', borderRight: 'solid 1px #ddd' }}
                >
                  <Typography variant="h6" align="center">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <strong>
                          {l18n.translate(
                            'resources.tap-beers.fields.shop.sellingPrice'
                          )}
                        </strong>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="grayText">
                          {`${currency.symbol} ${record.sellingPrice}/ml`}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ width: '25%', borderRight: 'solid 1px #ddd' }}
                >
                  <Typography variant="h6" align="center">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <strong>
                          {l18n.translate('resources.tap-beers.fields.alcohol')}
                        </strong>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="grayText">{record.alcohol}%</span>
                      </Grid>
                      <Grid item xs={6}>
                        <strong>
                          {l18n.translate('resources.tap-beers.fields.ibu')}
                        </strong>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="grayText">{record.ibu}</span>
                      </Grid>
                    </Grid>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ width: '25%', borderRight: 'solid 1px #ddd' }}
                >
                  <Typography variant="h6" align="center">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <strong>
                          {l18n.translate(
                            'resources.tap-beers.fields.beerStyle'
                          )}
                        </strong>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="grayText">{record.beerStyle}</span>
                      </Grid>
                    </Grid>
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '25%' }}>
                  <Typography variant="h6" align="center">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <strong>
                          {l18n.translate(
                            'resources.tap-beers.fields.country.id'
                          )}
                        </strong>
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          className="countryFlag"
                          src={record.country?.flagUrl}
                          alt={record.country?.name}
                        />
                        <span className="grayText countryName">
                          {record.country?.name}
                        </span>
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </Grid>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colSpan={2}
                  style={{ width: '50%', borderRight: 'solid 1px #ddd' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Radar
                        height={200}
                        options={{
                          legend: { display: false },
                          maintainAspectRatio: false,
                          scale: {
                            ticks: {
                              suggestedMin: 0,
                              suggestedMax: 1
                            }
                          }
                        }}
                        data={{
                          labels: radarChartLabel,
                          datasets: [
                            {
                              backgroundColor: 'rgba(0, 0, 0, 0.15)',
                              borderColor: 'rgba(0, 0, 0, 0.50)',
                              pointRadius: 1,
                              data: radarChartData
                            }
                          ]
                        }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell colSpan={2} style={{ width: '50%' }}>
                  <Typography variant="h6">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <span className="grayText">{record.description}</span>
                      </Grid>
                    </Grid>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};
const EditForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    record = props.record,
    resource = props.resource,
    [update] = useUpdate(resource, record.id),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = async (values) => {
      // Get image base64 to pass to api
      if (values['imageUrl']) {
        const imageResult = await getBase64(values, 'imageUrl');
        if (imageResult) {
          values['image'] = prepareImage(imageResult);
        }
      }
      // @Todo
      // Display error msg when convert base64 error
      // saveState(setState, true, l18n.translate('resources.goods.validation.image.error'));
      // return;

      // @Todo
      // Display error msg when convert base64 error
      // saveState(setState, true, l18n.translate('resources.goods.validation.image.error'));
      // return;

      if (values['groupId']) {
        values['group'] = { id: values['groupId'] };
      }

      if (values['sectionId']) {
        values['section'] = { id: values['sectionId'] };
      }

      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getEditTapBeerValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: update,
      onTransform,
      successMsgKey: 'updated'
    });

  // Get company
  let { availableCompanies } = globalFilterReducer,
    company = availableCompanies.find((item) => item.id === record.companyId);

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit} toolbar={<TapBeerToolBar />}>
        <span title={l18n.translate('resources.tap-beers.fields.id')} id="id">
          {record.id}
        </span>

        <span
          title={l18n.translate('resources.beer-servers.fields.company.id')}
          id="company.id"
        >
          {company?.companyName}
        </span>

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerCode')}
          required
          source="beerCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitCodeMax', { max: 30 })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerName')}
          required
          source="beerName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerNameEn')}
          required
          source="beerNameEn"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerShortName')}
          required
          source="beerShortName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.colour')}
          source="colour"
          choices={colourValues}
          className="form-control custom-select no-help-block"
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.beerStyle')}
          required
          source="beerStyle"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <NumberInput
          title={l18n.translate('resources.tap-beers.fields.ibu')}
          required
          source="ibu"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <NumberInput
          title={l18n.translate('resources.tap-beers.fields.alcohol')}
          required
          source="alcohol"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.brewery')}
          required
          source="brewery"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.breweryEn')}
          required
          source="breweryEn"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <TextInput
          title={l18n.translate(
            'resources.tap-beers.fields.company.purchaseCostMl'
          )}
          required
          source="purchaseCost"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate(
            'resources.tap-beers.fields.company.sellingPriceMl'
          )}
          required
          source="sellingPrice"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.tap-beers.fields.description')}
          multiline
          rows="5"
          source="description"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitMax', { max: 255 })}
        />

        <ImageInput
          title={l18n.translate('resources.tap-beers.fields.imageUrl')}
          required
          format={formatImageUrl}
          source="imageUrl"
          label="Related pictures"
          accept=".jpg,.jpeg,.png"
          className="img-input"
          helperText={l18n.translate('ra.helpBlock.extension')}
        >
          <ImageField source="url" />
        </ImageInput>

        <CountryInput
          title={l18n.translate('resources.tap-beers.fields.country.id')}
          required
          source="country.id"
          id="country.id"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.smell')}
          required
          source="smell"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.acidity')}
          required
          source="acidity"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.bitter')}
          required
          source="bitter"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.sweet')}
          required
          source="sweet"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <SelectInput
          title={l18n.translate('resources.tap-beers.fields.weight')}
          required
          source="weight"
          choices={tasteValues}
          defaultValue={1}
          className="form-control custom-select no-help-block"
        />

        <ReferenceInput
          source="groupId"
          required
          reference="groups"
          className="form-control custom-select no-help-block"
          title={l18n.translate('resources.tap-beers.fields.group')}
        >
          <SelectInput optionText="groupName" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData }) =>
            formData.groupId && (
              <TableRow key="section">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.tap-beers.fields.section')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <SectionInput source="sectionId" />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>
      </TableForm>
    </>
  );
};

export default EditForm;
