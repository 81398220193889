import React from 'react';
import {
  TextInput,
  SelectInput,
  PasswordInput,
  FormDataConsumer,
  useCreate,
  useNotify,
  useRedirect
} from 'react-admin';
import { TableRow, TableCell } from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
// Providers
import { UserRole } from 'providers/auth.provider';
// Components
import TableForm from 'components/TableForm';
import ShopInput from 'components/ShopInput';
import Loading from 'components/LoadingIndicator';
// Containers
import l18n from 'containers/I18nProvider/index';
import { getAuthorityChoices } from 'containers/Resources/User/constants';
import { filterAuthorityChoicesByRole } from 'containers/Resources/User/utils';
import { AlertCommon } from 'containers/Common/alert';
import { handleSubmitFactory } from 'containers/Common/helper';
// Local
import { getCreateUserValidationSchema } from './validations';
import { CompanyInput } from 'components/InputCommon/index';

const AddForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate(resource),
    [createShopManagement] = useCreate('shop-managements'),
    notify = useNotify(),
    redirectTo = useRedirect(),
    // Filter authority by logged in account
    authority = props.permissions,
    authorityChoices = filterAuthorityChoicesByRole(
      authority,
      getAuthorityChoices
    ),
    onSuccessCallback = async ({ data, values }) => {
      // Attach account to shop
      const { addedShopIds } = values;
      if (addedShopIds && addedShopIds.length > 0) {
        await Promise.all(
          addedShopIds.map((shopId: number) =>
            createShopManagement({
              payload: {
                data: {
                  user: { id: data.id },
                  shop: { id: shopId }
                }
              }
            })
          )
        );
      }
    },
    { company, availableCompanies } = globalFilterReducer,
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCreateUserValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: create,
      onSuccessCallback
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.users.fields.loginId')}
          required={true}
          source="loginId"
          className="form-control"
          helperText={l18n.translate('resources.users.helpBlock.loginId')}
        />

        <TextInput
          title={l18n.translate('resources.users.fields.loginName')}
          required={true}
          source="loginName"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 30
          })}
        />

        <SelectInput
          title={l18n.translate('resources.users.fields.authority')}
          required={true}
          source="authority"
          className="form-control custom-select no-help-block"
          choices={authorityChoices}
        />

        <PasswordInput
          title={l18n.translate('resources.users.fields.password')}
          required={true}
          source="password"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitAlphabetSymbolMinMax', {
            min: 8,
            max: 50
          })}
        />

        <PasswordInput
          title={l18n.translate('resources.users.fields.confirmPassword')}
          required={true}
          source="confirmPassword"
          className="form-control no-help-block"
        />

        <FormDataConsumer>
          {({ formData }) =>
            formData.authority &&
            formData.authority !== UserRole.SYSTEM_ADMINISTRATOR && (
              <TableRow key="company">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.users.fields.company.id')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <CompanyInput
                    company={company}
                    availableCompanies={availableCompanies}
                    authority={authority}
                  />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) =>
            formData.authority &&
            formData.company &&
            formData.company.id &&
            [
              UserRole.SYSTEM_ADMINISTRATOR,
              UserRole.COMPANY_ADMINISTRATOR
            ].every((role) => role !== formData.authority) && (
              <TableRow key="shop">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.users.fields.shop.id')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <ShopInput source="addedShopIds" multiselect />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>
      </TableForm>
    </>
  );
};

export default AddForm;
