import produce from 'immer';
import { CHART_MODE, SWITCH_CHART_MODE } from '../Actions/chartMode';

const initialState = {
  chartMode: CHART_MODE.DAILY
};

const chartModeReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SWITCH_CHART_MODE:
        draft.chartMode = payload;
        break;
    }
  });

export default chartModeReducer;
