const messages = {
  sections: {
    name: 'Section List',
    createTitle: 'Create Section',
    updateTitle: 'Update Section',
    fields: {
      id: 'ID',
      sectionCode: 'Section Code',
      sectionName: 'Section Name',
      taxReduction: 'Tax Reduction',
      group: {
        groupName: 'Group Name'
      }
    }
  }
};

export default messages;
