import { PATTERN_CODE } from 'containers/Common/patterns';

/**
 * [Get Company validation schema]
 * @return  {[Object]} schema
 */
let getCompanyValidationSchema = () => ({
  companyCode: { required: true, maxLength: 30, pattern: PATTERN_CODE },
  companyName: { required: true, maxLength: 100, emojis: true }
});

export { getCompanyValidationSchema };
