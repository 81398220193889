import GeneralPagination from 'components/GeneralPagination';
import CustomAction from 'containers/Resources/Shop/Actions';
import { Grid, Chip, Button } from '@material-ui/core';
import React from 'react';
import {
  List,
  TextField,
  Datagrid,
  useSafeSetState,
  useRefresh
} from 'react-admin';
import TimeIcon from '@material-ui/icons/Timer';
import { getListData, postData } from 'containers/Common/api';
import dataProvider from 'providers/data.provider';
import moment from 'moment';
import {
  invalidColor,
  textColor,
  validColor
} from 'containers/Resources/Shop/constants';
import l18n from 'containers/I18nProvider/index';
import { DATE_FORMAT_DATE_AND_TIME } from 'containers/App/constants';

const CustomLabel = () => (
  <Grid container spacing={1} style={{ minWidth: 450 }}>
    <Grid item xs={5}>
      <span className="TableHeading">
        {l18n.translate('resources.shops.openTime')}
      </span>
    </Grid>
    <Grid item xs={5}>
      <span className="TableHeading">
        {l18n.translate('resources.shops.closeTime')}
      </span>
    </Grid>
    <Grid item xs={2}>
      <span className="TableHeading">
        {l18n.translate('ra.action.operation')}
      </span>
    </Grid>
  </Grid>
);

const ShopOpenCloseTimeField = (props) => {
  const { record } = props,
    refresh = useRefresh(),
    [shopOpenCloseTimeData, setShopOpenCloseTimeData] = useSafeSetState({
      openedAt: '', // example data : '2021-03-23T02:32:57.000Z'
      closedAt: ''
    }),
    openShopUrl = (shopId) => 'shops/' + shopId + '/open',
    closeShopUrl = (shopId) => 'shops/' + shopId + '/close',
    handleOpenShop = async (shopId) => {
      await postData(openShopUrl(shopId), {});
      refresh();
    },
    handleCloseShop = async (shopId) => {
      await postData(closeShopUrl(shopId), {});
      refresh();
    };

  React.useEffect(() => {
    const updateTapBeerServerData = () => {
      if (record && record.id) {
        getListData(dataProvider, 'shop-open-close-time', {
          filter: {
            'shopId||$eq': record.id
          }
        }).then((data) => {
          if (data.length > 0) {
            setShopOpenCloseTimeData(data.shift());
          }
        });
      }
    };
    updateTapBeerServerData();
  }, [setShopOpenCloseTimeData, record]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        {shopOpenCloseTimeData.openedAt ? (
          <Chip
            label={moment(shopOpenCloseTimeData.openedAt).format(
              DATE_FORMAT_DATE_AND_TIME
            )}
            size="small"
            icon={<TimeIcon style={{ color: textColor }} />}
            style={{
              fontSize: '1.1rem',
              color: textColor,
              backgroundColor: validColor
            }}
          />
        ) : (
          '--:--'
        )}
      </Grid>
      <Grid item xs={5}>
        {shopOpenCloseTimeData.closedAt ? (
          <Chip
            label={moment(shopOpenCloseTimeData.closedAt).format(
              DATE_FORMAT_DATE_AND_TIME
            )}
            size="small"
            icon={<TimeIcon style={{ color: textColor }} />}
            style={{
              fontSize: '1.1rem',
              color: textColor,
              backgroundColor: invalidColor
            }}
          />
        ) : (
          '--:--'
        )}
      </Grid>
      <Grid item xs={2}>
        {!!shopOpenCloseTimeData.openedAt === true &&
        !!shopOpenCloseTimeData.closedAt === true ? (
          ''
        ) : !!shopOpenCloseTimeData.openedAt === false ? (
          <Button
            variant="contained"
            color="primary"
            className="btn-primary"
            onClick={() => handleOpenShop(record.id)}
            cy-data="OpenShopButton"
          >
            {l18n.translate('resources.shops.openShop')}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className="btn-primary"
            onClick={() => handleCloseShop(record.id)}
            cy-data="CloseShopButton"
          >
            {l18n.translate('resources.shops.closeShop')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const ShopCheckIn = (props) => {
  return (
    <List
      {...props}
      resource="shops"
      actions={<CustomAction hide="true" />}
      pagination={<GeneralPagination />}
      bulkActionButtons={false}
      className="table-responsive"
    >
      <Datagrid>
        <TextField source="shopName" />
        <ShopOpenCloseTimeField label={<CustomLabel />} />
      </Datagrid>
    </List>
  );
};

export default ShopCheckIn;
