import React from 'react';
import {
  TextInput,
  useUpdate,
  useNotify,
  useRedirect,
  useSafeSetState,
  DateInput
} from 'react-admin';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import l18n from 'containers/I18nProvider/index';
import { AlertCommon } from 'containers/Common/alert';
// Local
import { getEditBeerServerValidationSchema } from './validations';
import moment from 'moment';

const EditForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    record = props.record,
    resource = props.resource,
    [update] = useUpdate(resource, record.id),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = (values) => {
      values['startUsingAt'] = moment(values['startUsingAt']).toISOString();
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getEditBeerServerValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: update,
      onTransform,
      successMsgKey: 'updated'
    });

  // Get company & shop
  let { availableCompanies, availableShops } = globalFilterReducer,
    company = availableCompanies.find((item) => item.id === record.companyId),
    shop = availableShops.find((item) => item.id === record.shopId);

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <span
          title={l18n.translate('resources.beer-servers.fields.id')}
          id="id"
        >
          {record.id}
        </span>

        <span
          title={l18n.translate('resources.beer-servers.fields.company.id')}
          id="company.id"
        >
          {company && company.companyName}
        </span>

        <span
          title={l18n.translate('resources.beer-servers.fields.shop.id')}
          id="shop.id"
        >
          {shop && shop.shopName}
        </span>

        <TextInput
          title={l18n.translate('resources.beer-servers.fields.serverCode')}
          required
          source="serverCode"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitCodeMax', { max: 100 })}
        />

        <TextInput
          title={l18n.translate('resources.beer-servers.fields.obnizId')}
          required
          source="obnizId"
          className="form-control"
        />

        <DateInput
          title={l18n.translate('resources.beer-servers.fields.startUsingAt')}
          required
          source="startUsingAt"
          className="form-control"
        />
      </TableForm>
    </>
  );
};

export default EditForm;
