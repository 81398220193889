import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { NativeSelect } from '@material-ui/core';
import l18n from 'containers/I18nProvider/index';
import { useCallback } from 'react';

interface DataTablePaginationProp {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: any;
  onChangeRowsPerPage: any;
  currentPage: number;
}

const DataTablePagination = (props: DataTablePaginationProp) => {
  const {
      rowsPerPage = 50,
      rowCount,
      onChangePage,
      onChangeRowsPerPage,
      currentPage
    } = props,
    dataLength = rowsPerPage * currentPage,
    offsetEnd = rowsPerPage * currentPage,
    offsetBegin = offsetEnd - rowsPerPage + 1,
    total = rowCount,
    perPageList = [10, 20, 50, 100];

  // Set page size
  let pageSize = 0;
  if (total > 0 && rowsPerPage > 0) {
    pageSize = Math.ceil(total / rowsPerPage);
  }
  const getPageRangeInfo = () => {
    if (dataLength === 0) {
      return l18n.translate('ra.navigation.page_one_record');
    } else {
      return l18n.translate('ra.navigation.page_range_info', {
        offsetBegin: offsetBegin,
        offsetEnd: offsetEnd,
        total: total
      });
    }
  };

  const handleOnChangePage = useCallback(
    (e, page) => onChangePage(page),
    [onChangePage]
  );
  const handleOnChangePerPage = useCallback(
    (e) => onChangeRowsPerPage(e.target.value),
    [onChangeRowsPerPage]
  );

  if (dataLength < 0) {
    return (
      <div className="empty-search-result" data-cy="empty-search-result">
        {l18n.translate('ra.navigation.no_results')}
      </div>
    );
  } else {
    return (
      <div className="wrapper-pagination">
        <Pagination
          count={pageSize}
          page={currentPage}
          shape="rounded"
          onChange={handleOnChangePage}
          variant="outlined"
          size="small"
          className="pagination"
          data-cy="pagination"
        />
        <span className="page-info" data-cy="page-info">
          {getPageRangeInfo()}
        </span>
        <div className="pagination-right">
          <span>
            <form
              autoComplete="off"
              className={pageSize <= 1 ? 'form-padding' : ''}
            >
              {l18n.translate('ra.navigation.page_rows_per_page')}
              <NativeSelect
                id="perPage"
                value={rowsPerPage}
                onChange={handleOnChangePerPage}
                className="form-control"
                data-cy="selectPerPage"
              >
                {perPageList.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </NativeSelect>
            </form>
          </span>
        </div>
      </div>
    );
  }
};

export default DataTablePagination;
