import React, { useEffect } from 'react';
import { ReduxState, useSafeSetState } from 'react-admin';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import l18n from 'containers/I18nProvider/index';
import { Line, Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import Loading from 'components/LoadingIndicator';
import RealtimeIndicator from 'components/RealtimeIndicator';
import { SaleData, ExtractedSaleData } from 'containers/Common/interfaces';
import { buildChartData } from 'containers/Common/helper';
import { currency } from 'containers/App/constants';
import moment from 'moment';

// FIXME 210827 サーバーが1時間ズレた時刻で返してくるため、暫定で時刻を+1するよう変更
const blockOfTimeToDate = (block: string): String => `${parseInt(block?.slice(-2),10)+1}:00`;

const extractChartData = (rawSaleData: SaleData[]): ExtractedSaleData => {
  if (Array.isArray(rawSaleData) === false) {
    return {
      labels: ['7:00','8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00','25:00'],
      data: []
    };
  }

  return {
    labels: rawSaleData.map((rawData) => blockOfTimeToDate(rawData.block)).concat(['25:00']),
    data: rawSaleData.map((rawData) => parseFloat(rawData.sale))
  };
};

const RealtimeChart = () => {
  const { loadingChartData, statisticsData } = useSelector(
      (state: ReduxState) => state.statisticsRealtimeSale
    ),
    [chartData, setChartData] = useSafeSetState(buildChartData([], []));
  
    if (statisticsData?.salePerHour) {
      var day = moment().format('YYYYMMDD');
      var nextDay = moment().add(1, 'days').format('YYYYMMDD');
      let tempLabels = [
        day + '06',
        day + '07',
        day + '08',
        day + '09',
        day + '10',
        day + '11',
        day + '12',
        day + '13',
        day + '14',
        day + '15',
        day + '16',
        day + '17',
        day + '18',
        day + '19',
        day + '20',
        day + '21',
        day + '22',
        day + '23',
        // nextDay + '00',
        // nextDay + '01',
        // nextDay + '02',
        // nextDay + '03',
        // nextDay + '04',
        // nextDay + '05',
        // nextDay + '06'
      ];
      var dailyData = tempLabels.map(block => {
        const salePerHourData = statisticsData?.salePerHour;
        for (let index = 0; index < salePerHourData.length; index++) {
          if (String(salePerHourData[index].block) === String(block)) {
            return salePerHourData[index];
          }
        }
        return {
          block: String(block),
          count_item: "0.00",
          count_transaction: "0.00",
          margin: "0.00",
          paymentMethod: 1,
          profit: "0.00",
          purchaseCost: "0.00",
          sale: "0.00",
          tax: "0.00"
        }
      });
    }




  useEffect(() => {
    const updateChart = (sales: SaleData[]) => {
      const { labels, data } = extractChartData(sales);
      setChartData(buildChartData(labels, data));
    };
    updateChart(dailyData);
  }, [statisticsData.salePerHour, setChartData]);

  if (loadingChartData) return <Loading />;

  return (
    <Card style={{ minHeight: 570 }}>
      <CardHeader
        avatar={<RealtimeIndicator />}
        title={l18n.translate('ra.common.liveUpdate')}
      />
      <CardContent>
        <div data-cy="realtimeSaleLineChart">
          <Line
            // @ts-ignore: load data from state
            data={chartData}
            options={{
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    return `${currency.symbol} ${parseInt(
                      tooltipItem.value,
                      10
                    ).toLocaleString('ja-JP')}`;
                  }
                }
              },
              legend: { display: false },
              animation: {
                easing: 'linear'
                // duration: 0.1 // general animation time
              },
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min:0,stepSize:1000,
                      callback: function (value) {
                        return `${currency.symbol} ${value.toLocaleString(
                          'ja-JP'
                        )}`;
                      }
                    }
                  }
                ],
                xAxes: [
                  {
                    ticks:{min:0},
                    offset: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'リアルタイム売上'
                    }
                  }
                ]
              }
            }}
            height={460}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default RealtimeChart;
