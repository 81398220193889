const messages = {
  topup: {
    name: 'Top Up',
    fields: {
      id: 'ID',
      pointPlusId: 'Point Plus Id',
      receiptCode: 'Receipt Code',
      amount: 'Amount',
      pointPlusBalance: 'Point Plus Balance',
      settlementTime: 'Settlement Completion Time',
      paymentMethod: 'Payment Method',
      company: {
        id: 'Company'
      },
      shop: {
        id: 'Company'
      }
    }
  }
};

export default messages;
