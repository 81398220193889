import React from 'react';
import {
  TextInput,
  PasswordInput,
  useUpdate,
  useNotify,
  useRedirect
} from 'react-admin';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { useSafeSetState } from 'ra-core';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import { AlertCommon } from 'containers/Common/alert';
import l18n from 'containers/I18nProvider';
// Local
import BarrelEditForm from './BarrelEditForm';
import BarrelCreateForm from './BarrelCreateForm';
import BarrelList from './BarrelList';
import { getEditDeviceValidationSchema } from './validations';

const EditForm = ({ globalFilterReducer, ...props }) => {
  const record = props.record,
    [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    [barrel, setBarrel] = useSafeSetState({
      showDialogue: false,
      showEditDialogue: false,
      shopId: record.shopId,
      barrelRecord: {}
    }),
    resource = props.resource,
    [update] = useUpdate(resource, record.id),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onShowDialogue = () => {
      /**
       * Show dialogue add barrel
       */
      setBarrel({
        showDialogue: true,
        showEditDialogue: false,
        shopId: record.shopId,
        barrelRecord: {}
      });
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getEditDeviceValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: update,
      state: state,
      successMsgKey: 'updated'
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm
        {...props}
        save={handleSubmit}
        extraComponents={
          <div>
            <BarrelCreateForm {...props} state={barrel} setState={setBarrel} />
            <BarrelEditForm {...props} state={barrel} setState={setBarrel} />

            {<BarrelList {...props} state={barrel} setState={setBarrel} />}
          </div>
        }
      >
        <span title={l18n.translate('resources.devices.fields.id')} id="id">
          {record.id}
        </span>

        <span
          title={l18n.translate('resources.devices.fields.company.companyName')}
          id="company.companyName"
        >
          {record.company?.companyName}
        </span>

        <span
          title={l18n.translate('resources.devices.fields.shop.shopCode')}
          id="shop.shopCode"
        >
          {record.shop?.shopCode}
        </span>

        <span
          title={l18n.translate('resources.devices.fields.hardwareCode')}
          id="hardwareCode"
        >
          {record.hardwareCode}
        </span>

        <TextInput
          title={l18n.translate('resources.devices.fields.hardwareName')}
          source="hardwareName"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <PasswordInput
          title={l18n.translate('resources.devices.fields.password')}
          source="password"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.numberEqual', { max: 6 })}
        />

        <PasswordInput
          title={l18n.translate('resources.devices.fields.confirmPassword')}
          source="confirmPassword"
          className="form-control no-help-block"
        />

        <TableRow key="addBarrel">
          <TableCell className="table-item-name">
            {l18n.translate('resources.devices.fields.barrel')}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={onShowDialogue}
              color="primary"
              className="icon-default"
              data-cy="addBarrelButton"
            >
              <AddCircle />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableForm>
    </>
  );
};

export default EditForm;
