const messages = {
  devices: {
    name: 'タブレット一覧',
    createTitle: 'タブレット詳細',
    updateTitle: 'タブレット詳細',
    fields: {
      id: 'ID',
      hardwareCode: 'タブレットCD',
      hardwareName: 'タブレット名',
      password: 'パスワード',
      confirmPassword: 'パスワード確認',
      shop: {
        shopCode: '店舗CD',
        shopName: '店舗名'
      },
      company: {
        companyName: '会社名'
      },
      lockStatusObj: {
        have: 'ロック中'
      },
      barrel: 'ビール樽',
      beerServer: {
        createTitle: 'ビール樽作成',
        editTitle: 'ビール樽編集',
        serverCode: 'サーバCD',
        beerName: 'ビール名',
        amount: '容量(ml)',
        beerOutStandard: '樽切れ標準(ml)',
        status: '状態',
        remainingAmount: '残容量(ml)',
        remainingAmountPercent: '残容量(%)'
      },
      barrelStatusObj: {
        maintain: 'メンテナンス',
        startSold: '販売開始'
      },
      barrelStatusDisplayObj: {
        maintain: 'メンテナンス中',
        startSold: '販売中'
      },
      operation: '操作',
      isOnline: 'オンライン'
    },
    unlockDevice: 'デバイスのロックを解除',
    deviceWasLocked: 'デバイスがロックされました',
    deviceWasUnLocked: 'デバイスのロックが解除されました'
  }
};

export default messages;
