import React from 'react';
import {
  TextInput,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
  BooleanInput,
  ReferenceInput,
  Button,
  Link,
  SaveButton,
  Toolbar
} from 'react-admin';
import { useSafeSetState } from 'ra-core';
import { ArrowBack } from '@material-ui/icons';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import { AlertCommon } from 'containers/Common/alert';
import l18n from 'containers/I18nProvider';
// Local
import { getCreateSectionValidationSchema } from './validations';
import * as Constants from 'containers/Common/constants';

const CustomToolBar = ({ ...props }) => (
  <Toolbar {...props}>
    <Link to="/groups">
      <Button
        variant="contained"
        color="primary"
        className="btn-default"
        startIcon={<ArrowBack />}
        label={l18n.translate('resources.groups.name')}
        data-cy="backToGroupButton"
      />
    </Link>
    <SaveButton className="btn-primary btn-save" data-cy="saveButton" />
  </Toolbar>
);

const AddForm = (props) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate(resource),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = (values) => {
      values['taxReduction'] = values['taxReduction'] ? 1 : 0;
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCreateSectionValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo(`/${Constants.RESOURCE_GROUP}`),
      onSubmit: create,
      onTransform: onTransform,
      state: state
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit} toolbar={<CustomToolBar />}>
        <TextInput
          title={l18n.translate('resources.sections.fields.sectionCode')}
          source="sectionCode"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 30
          })}
        />

        <TextInput
          title={l18n.translate('resources.sections.fields.sectionName')}
          source="sectionName"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <BooleanInput
          title={l18n.translate('resources.sections.fields.taxReduction')}
          required={true}
          label=""
          source="taxReduction"
          className="no-help-block"
        />

        <ReferenceInput
          source="group.id"
          reference="groups"
          className="form-control no-help-block"
          title={l18n.translate('resources.sections.fields.group.groupName')}
        >
          <SelectInput optionText="groupName" />
        </ReferenceInput>
      </TableForm>
    </>
  );
};

export default AddForm;
