import React, { useEffect } from 'react';
import { ReduxState, useSafeSetState } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import { Line, Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { SaleData, ExtractedSaleData } from 'containers/Common/interfaces';
import { buildChartData } from 'containers/Common/helper';
import { CHART_MODE } from 'containers/Common/Actions/chartMode';
import { currency, DATE_FORMAT_MONTH_AND_DAY, DATE_FORMAT_YEAR_AND_MONTH } from 'containers/App/constants';
import moment from 'moment';

const blockOfTimeToDate = (block: string, chartMode: CHART_MODE): String => {
  switch (chartMode) {
    case CHART_MODE.MONTHLY:
      return moment(block,'YYYYMMDD').format(DATE_FORMAT_MONTH_AND_DAY);

    case CHART_MODE.YEARLY:
      return moment(block,'YYYYMM').format(DATE_FORMAT_YEAR_AND_MONTH);

    default:
      return `${block?.slice(-2)}:00`;
  }
};

const extractChartData = (
  rawSaleData: SaleData[],
  chartMode: CHART_MODE
): ExtractedSaleData => {

  // if (!rawSaleData) {
  //   return {
  //     labels: [],
  //     data: []
  //   };
  // }

  let sortedData = Object.assign([],rawSaleData);
  // sortedData.sort((a, b) => {
  //   if (a.block < b.block) return -1;
  //   return 1;
  // })
  
  return {
    labels: sortedData
      .map((rawData) => blockOfTimeToDate(rawData.block, chartMode)),
    data: sortedData.map((rawData) => parseFloat(rawData.sale))
  };
};

const HistoricalChart = (props) => {
  const { chartMode } = useSelector((state: ReduxState) => state.chartMode),
    { data } = useSelector((state: ReduxState) => state.reportData),
    [chartData, setChartData] = useSafeSetState(buildChartData([], []));

  useEffect(() => {
    const updateChart = (sales: SaleData[]) => {
      const { labels, data: chartData } = extractChartData(sales, chartMode);
      setChartData(buildChartData(labels, chartData));
    };

    switch (chartMode) {
      case CHART_MODE.DAILY:
        if (data?.salePerHour) {
          var day = moment(props.selectedDate).format('YYYYMMDD');
          var nextDay = moment(props.selectedDate).add(1, 'days').format('YYYYMMDD');
          let tempLabels = [
            day + '07',
            day + '08',
            day + '09',
            day + '10',
            day + '11',
            day + '12',
            day + '13',
            day + '14',
            day + '15',
            day + '16',
            day + '17',
            day + '18',
            day + '19',
            day + '20',
            day + '21',
            day + '22',
            day + '23',
            nextDay + '00',
            nextDay + '01',
            nextDay + '02',
            nextDay + '03',
            nextDay + '04',
            nextDay + '05',
            nextDay + '06'
          ];
          var dailyData = tempLabels.map(block => {
            const salePerHourData = data?.salePerHour;
            for (let index = 0; index < salePerHourData.length; index++) {
              if (String(salePerHourData[index].block) === String(block)) {
                return salePerHourData[index];
              }
            }
            return {
              block: String(block),
              count_item: "0.00",
              count_transaction: "0.00",
              margin: "0.00",
              paymentMethod: 1,
              profit: "0.00",
              purchaseCost: "0.00",
              sale: "0.00",
              tax: "0.00"
            }
          });
        }
        // updateChart(data?.salePerHour);
        updateChart(dailyData);
        break;

      case CHART_MODE.MONTHLY:
        if (data?.salePerDay) {
          var month = moment(props.selectedDate).format('YYYYMM');
          var dayCount = moment(props.selectedDate).daysInMonth();
          var tempLabels = [];
          for(var i=0; i < dayCount; i++){
            tempLabels.push(moment(month).add(i,'days').format('YYYYMMDD'));
          }
          var monthlyData = tempLabels.map(block => {
            const salePerDayData = data?.salePerDay;
            for (let index = 0; index < salePerDayData.length; index++) {
              if (String(salePerDayData[index].block) === String(block)) {
                return salePerDayData[index];
              }
            }
            return {
              block: String(block),
              count_item: "0.00",
              count_transaction: "0.00",
              margin: "0.00",
              paymentMethod: 1,
              profit: "0.00",
              purchaseCost: "0.00",
              sale: "0.00",
              tax: "0.00"
            }
          });
        }

        // updateChart(data?.salePerDay);
        updateChart(monthlyData);
        break;

      case CHART_MODE.YEARLY:
        // その年の月一覧を生成する
        if (data?.salePerMonth) {
          var year = moment(props.selectedDate).format('YYYY');
          var tempLabels = [];
          for(var i=0; i < 12; i++){
            tempLabels.push(moment(year).add(i,'months').format('YYYYMM'));
          }
          // 一覧をループして、存在していればデータを入れ、存在してなければ0データを返す
          // FIXME 本来ならバックエンドで0データを入れて返さないとフォーマッタがないので後から値の変更に追従できない
          var yearlyData = tempLabels.map(block => {
            const salePerMonthData = data?.salePerMonth;
            for (let index = 0; index < salePerMonthData.length; index++) {
              if (String(salePerMonthData[index].block) === String(block)) {
                return salePerMonthData[index];
              }
            }
            return {
              block: String(block),
              count_item: "0.00",
              count_transaction: "0.00",
              margin: "0.00",
              paymentMethod: 1,
              profit: "0.00",
              purchaseCost: "0.00",
              sale: "0.00"
            }
          });
        }
        // updateChart(data?.salePerMonth);
        updateChart(yearlyData);
        break;
    }
  }, [data, setChartData, chartMode]);

  return (
    <Card style={{ minHeight: 500 }}>
      <CardContent>
        <div data-cy="realtimeSaleLineChart">
          <Line
            // @ts-ignore: load data from state
            data={chartData}
            options={{
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    return `${currency.symbol} ${parseInt(
                      tooltipItem.value,
                      10
                    ).toLocaleString('ja-JP')}`;
                  }
                }
              },
              legend: { display: false },
              animation: {
                easing: 'linear'
                // duration: 0.1 // general animation time
              },
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min:0,stepSize:1000,
                      callback: function (value) {
                        return `${currency.symbol} ${value.toLocaleString(
                          'ja-JP'
                        )}`;
                      }
                    }
                  }
                ],
                xAxes: [
                  {
                    ticks:{min:0},
                    offset: true
                  }
                ]
              }
            }}
            height={460}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default HistoricalChart;
