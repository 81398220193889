import * as React from 'react';

import NotificationNumber from 'components/NotificationNumber';
import { ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';

const DeviceAppendix = () => {
  const { tablets } = useSelector(
    (state: ReduxState) => state.tabletSurvivalSignals
  );
  const totalErrorTablets = tablets.reduce((res, tablets) => {
    if (tablets.isOnline === 0) {
      res += 1;
    }
    return res;
  }, 0)
  return (
    <NotificationNumber
      number={totalErrorTablets}
    />
  );
};

export default DeviceAppendix;
