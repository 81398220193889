import { Option } from 'containers/App/types';
import { UserRole } from 'providers/auth.provider';

const scope: string = 'resources.users';

const getAuthorityChoices: Option[] = [
  {
    id: UserRole.SYSTEM_ADMINISTRATOR,
    name: `${scope}.adminRole`
  },
  {
    id: UserRole.COMPANY_ADMINISTRATOR,
    name: `${scope}.companyRole`
  },
  {
    id: UserRole.SHOP,
    name: `${scope}.shopRole`
  },
  {
    id: UserRole.STAFF,
    name: `${scope}.staffRole`
  }
];

export { scope, getAuthorityChoices };
