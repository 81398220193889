const messages = {
    'error-logs': {
        name: 'Error Log List',
        fields : {
            occurredAt: 'Occurred At'
        }
    }
};

export default messages;
