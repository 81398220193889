import {
  UserRole,
  authProvider,
  getAccessToken
} from 'providers/auth.provider';

export const checkPermissions = (permissions, pathname) => {
  const result = authProvider.checkPermissions(permissions, pathname);

  return result.status === 200 ? true : false;
};

export const isAdmin = (userRole: number): boolean => {
  return userRole === UserRole.SYSTEM_ADMINISTRATOR;
};

// export const isCompanyAdmin = (userRole: number): boolean => {
//   return userRole === UserRole.COMPANY_ADMINISTRATOR;
// };

export const isShopAdmin = (userRole: number): boolean => {
  return userRole <= UserRole.SHOP;
};

// export const isStaff = (userRole: number): boolean => {
//   return userRole === UserRole.STAFF;
// };

/**
 * Convert url to fill into Input Field
 * @param url
 */
export const formatImageUrl = (url: any) => {
  if (!url || typeof url === 'string') {
    return { url: url };
  }

  return url;
};

export const sendImportFile = async (url: string, key: string, file: Blob) => {
  let headers = new Headers();
  headers.set('Authorization', `Bearer ${getAccessToken()}`);

  const formData = new FormData();
  formData.append(key, file);

  return fetch(url, {
    method: 'POST',
    body: formData,
    headers: headers
  });
};
