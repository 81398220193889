import * as React from 'react';
import { Grid, Paper, NativeSelect } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import l18n from 'containers/I18nProvider/index';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { datepickerTheme } from 'containers/Common/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Box from '@material-ui/core/Box';
import { weekDays } from 'containers/Resources/SaleLog/Filters/FilterForm';
import { DATE_FORMAT_DATE_ONLY } from 'containers/App/constants';

const HOUR_CHOICES = [
  {
    id: '07:00',
    name: '07:00'
  },
  {
    id: '08:00',
    name: '08:00'
  },
  {
    id: '09:00',
    name: '09:00'
  },
  {
    id: '10:00',
    name: '10:00'
  },
  {
    id: '11:00',
    name: '11:00'
  },
  {
    id: '12:00',
    name: '12:00'
  },
  {
    id: '13:00',
    name: '13:00'
  },
  {
    id: '14:00',
    name: '14:00'
  },
  {
    id: '15:00',
    name: '15:00'
  },
  {
    id: '16:00',
    name: '16:00'
  },
  {
    id: '17:00',
    name: '17:00'
  },
  {
    id: '18:00',
    name: '18:00'
  },
  {
    id: '19:00',
    name: '19:00'
  },
  {
    id: '20:00',
    name: '20:00'
  },
  {
    id: '21:00',
    name: '21:00'
  },
  {
    id: '22:00',
    name: '22:00'
  },
  {
    id: '23:00',
    name: '23:00'
  },
  {
    id: '00:00',
    name: '00:00'
  },
  {
    id: '01:00',
    name: '01:00'
  },
  {
    id: '02:00',
    name: '02:00'
  },
  {
    id: '03:00',
    name: '03:00'
  },
  {
    id: '04:00',
    name: '04:00'
  },
  {
    id: '05:00',
    name: '05:00'
  },
  {
    id: '06:00',
    name: '06:00'
  }
];

const FilterSidebar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  weekDay,
  setWeekDay
}) => {
  return (
    <Paper style={{ height: '100%' }}>
      <Grid container style={{ padding: '1rem' }}>
        <Grid item xs={12}>
          <TextField
            id="code"
            label={l18n.translate('resources.sale-history-with-filter.code')}
            data-cy="codeInputSaleHistoryWithFilter"
          />
        </Grid>

        <Grid item xs={12}>
          <p>{l18n.translate('resources.sale-history.targetPeriod')}：</p>

          <ThemeProvider theme={datepickerTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="From"
                format={DATE_FORMAT_DATE_ONLY}
                value={startDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => setStartDate(date)}
                size="small"
                data-cy="startDateButton"
              />

              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="To"
                format={DATE_FORMAT_DATE_ONLY}
                value={endDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => setEndDate(date)}
                size="small"
                data-cy="endDateButton"
                style={{ marginTop: '1em' }}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Grid>

        <Grid container>
          <Grid item xs={6} style={{ paddingTop: '1rem' }}>
            <p>{l18n.translate('ra.common.startHour')}：</p>
            <NativeSelect id="startHour" className="form-control">
              {HOUR_CHOICES.map((hour) => (
                <option
                  value={hour.id}
                  key={hour.id}
                  data-cy="startHourOptionSaleHistory"
                >
                  {hour.name}
                </option>
              ))}
            </NativeSelect>
          </Grid>

          <Grid item xs={6} style={{ paddingTop: '1rem' }}>
            <p>{l18n.translate('ra.common.endHour')}：</p>
            <NativeSelect id="endHour" className="form-control">
              {HOUR_CHOICES.map((hour) => (
                <option
                  value={hour.id}
                  key={hour.id}
                  data-cy="endHourOptionSaleHistory"
                >
                  {hour.name}
                </option>
              ))}
            </NativeSelect>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <RadioGroup
            aria-label={l18n.translate('ra.common.dayOfWeek')}
            name="weekDay"
            style={{ marginTop: '2em' }}
            value={weekDay}
            onChange={(e) => setWeekDay(parseInt(e.target.value, 10))}
          >
            <Box display="flex" flexDirection="row">
              <Box>
                <DateRangeIcon />
              </Box>
              <Box ml={1} pt={0.3} style={{ fontSize: 11 }}>
                {l18n.translate('ra.common.dayOfWeek')}
              </Box>
            </Box>

            {weekDays.map((weekDay) => (
              <FormControlLabel
                value={weekDay.id}
                control={<Radio />}
                label={l18n.translate(weekDay.name)}
                key={weekDay.id}
                data-cy="weekDayFilterSaleHistoryWithFilter"
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterSidebar;
