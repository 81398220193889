import { Table, Typography } from '@material-ui/core';
// Components
import GeneralPagination from 'components/GeneralPagination';
import MyDatagridRow from 'components/MyDatagridRow';
import { isShopAdmin } from 'containers/App/utils';
import { AlertCommon } from 'containers/Common/alert';
import { RESOURCE_SHOPS } from 'containers/Common/constants';
// Containers
import { loadGlobalFilter } from 'containers/Common/hocs';
import l18n from 'containers/I18nProvider/index';
import {
  getCurrentAuthority,
  getCurrentShopId,
  UserRole
} from 'providers/auth.provider';
import dataProvider from 'providers/data.provider';
import React, { useEffect } from 'react';
import {
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  List,
  Loading,
  Record,
  ReferenceField,
  TextField,
  useSafeSetState
} from 'react-admin';
// Local
import TapBeerActions from './Actions';
import CustomFilter from './Filters';
import ShopTapBeerCreateForm from './Forms/ShopTapBeerCreateForm';
import ShopTapBeerEditForm from './Forms/ShopTapBeerEditForm';
import TapBeerCreateForm from './Forms/TapBeerCreateForm';
import TapBeerEditForm from './Forms/TapBeerEditForm';

const TapBeerShopPanel = ({ record }) => {
  const [data, setData] = useSafeSetState([] as Record[]),
    [loading, setLoading] = useSafeSetState(true),
    [error, setError] = useSafeSetState(),
    authority = getCurrentAuthority(),
    canEdit = authority <= UserRole.SHOP,
    canCreate = authority <= UserRole.SHOP;

  useEffect(() => {
    dataProvider
      .getManyReference('tap-beers-shop', {
        filter: {
          'shopId||$eq': getCurrentShopId()
        },
        target: 'tapBeerId',
        id: record.id,
        pagination: {
          page: 1,
          perPage: 99
        },
        sort: {
          field: 'id',
          order: 'ASC'
        }
      })
      .then(({ data }) => {
        setData(data as Record[]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [record, setData, setError, setLoading]);

  if (loading) return <Loading />;
  if (error) return null;
  if (!data) return null;
  if (data.length === 0) {
    if (canCreate) {
      return (
        <ShopTapBeerCreateForm resource="tap-beers-shop" record={record} />
      );
    } else {
      return (
        <Typography align="center">
          {l18n.translate('ra.message.no_info')}
        </Typography>
      );
    }
  }

  return (
    <div className="sectionExpandWrap">
      <Table>
        <MyDatagridRow
          records={data}
          resource="tap-beers-shop"
          id={record.id}
          basePath="tap-beers-shop"
        >
          <ReferenceField
            source="shopId"
            reference={RESOURCE_SHOPS}
            link={false}
            label={l18n.translate('resources.shops.fields.shopName')}
          >
            <TextField source="shopName" />
          </ReferenceField>
          <TextField
            source="purchaseCost"
            label={l18n.translate(
              'resources.tap-beers.fields.shop.purchaseCost'
            )}
          />
          <TextField
            source="sellingPrice"
            label={l18n.translate(
              'resources.tap-beers.fields.shop.sellingPrice'
            )}
          />
          {canEdit && (
            <EditButton
              data-cy="editShopGoodsButton"
              variant="contained"
              disableElevation
            />
          )}
          {canEdit && <DeleteWithConfirmButton redirect="/tap-beers" />}
        </MyDatagridRow>
      </Table>
    </div>
  );
};
const TapBeerListPage = ({ globalFilterReducer, ...props }) => {
  let authority = props.permissions,
    hideEdit = false,
    hideDelete = false,
    showImportButton = false,
    showCreateButton = false;

  if (
    authority === UserRole.SYSTEM_ADMINISTRATOR ||
    authority === UserRole.COMPANY_ADMINISTRATOR
  ) {
    showImportButton = true;
    showCreateButton = true;
  }

  if (authority === UserRole.SHOP || authority === UserRole.STAFF) {
    hideEdit = true;
    hideDelete = true;
    showImportButton = false;
  }

  return (
    <List
      {...props}
      actions={
        <TapBeerActions
          showCreateButton={showCreateButton}
          showImportButton={showImportButton}
          filters={<CustomFilter />}
        />
      }
      pagination={<GeneralPagination />}
      bulkActionButtons={false}
      empty={false}
      className="table-responsive"
    >
      <Datagrid expand={TapBeerShopPanel}>
        <TextField source="id" />
        <TextField source="beerCode" />
        <TextField source="beerName" />
        <TextField source="brewery" />
        <TextField
          source="purchaseCost"
          label={l18n.translate(
            'resources.tap-beers.fields.company.purchaseCost'
          )}
        />
        <TextField
          source="sellingPrice"
          label={l18n.translate(
            'resources.tap-beers.fields.company.sellingPrice'
          )}
        />
        {!hideEdit && (
          <EditButton
            basePath={props.basePath}
            variant="contained"
            disableElevation
          />
        )}
        {!hideDelete && <DeleteWithConfirmButton />}
      </Datagrid>
    </List>
  );
};

const TapBeerList = loadGlobalFilter(TapBeerListPage);

const TapBeerCreatePage = ({ globalFilterReducer, ...props }) => (
  <Create {...props} className="table-responsive">
    <TapBeerCreateForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Create>
);

const TapBeerCreate = loadGlobalFilter(TapBeerCreatePage);

const TapBeerEditPage = ({ globalFilterReducer, ...props }) => (
  <Edit {...props} undoable={false} className="table-responsive">
    <TapBeerEditForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Edit>
);

const TapBeerEdit = loadGlobalFilter(TapBeerEditPage);

const ShopTapBeerEdit = (props) =>
  isShopAdmin(props.permissions) ? (
    <Edit {...props} undoable={false} className="table-responsive">
      <ShopTapBeerEditForm permissions={props.permissions} />
    </Edit>
  ) : (
    <AlertCommon
      message={l18n.translate('ra.common.restrictedArea', {
        reason: l18n.translate('ra.common.requireShopAdminRole')
      })}
      open={true}
      type={'error'}
    />
  );

export {
  TapBeerList as List,
  TapBeerCreate as Create,
  TapBeerEdit as Edit,
  ShopTapBeerEdit
};
