const messages = {
  groups: {
    name: 'Group List',
    createTitle: 'Create Group',
    updateTitle: 'Update Group',
    fields: {
      id: 'ID',
      groupCode: 'Group Code',
      groupName: 'Group Name',
      taxReduction: 'Tax Reduction',
      company: {
        companyName: 'Company Name'
      }
    }
  }
};

export default messages;
