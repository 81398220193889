import { Table, Typography } from '@material-ui/core';
import BooleanField from 'components/BooleanField';
import GeneralPagination from 'components/GeneralPagination';
import MyDatagridRow from 'components/MyDatagridRow';
import SectionField from 'components/SectionField';
import { AlertCommon } from 'containers/Common/alert';
import { RESOURCE_GROUP, RESOURCE_SHOPS } from 'containers/Common/constants';
// Containers
import { loadGlobalFilter } from 'containers/Common/hocs';
import l18n from 'containers/I18nProvider/index';
// Providers
import {
  getCurrentAuthority,
  getCurrentShopId,
  UserRole
} from 'providers/auth.provider';
import dataProvider from 'providers/data.provider';
import React, { useEffect } from 'react';
import {
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  List,
  Loading,
  NumberField,
  ReferenceField,
  TextField,
  useSafeSetState,
  toggleListItemExpand
} from 'react-admin';
import GoodsActions from './actions';
// Local
import GoodsCreateForm from './Forms/GoodsCreateForm';
import GoodsEditForm from './Forms/GoodsEditForm';
import ShopGoodsCreateForm from './Forms/ShopGoodsCreateForm';
import ShopGoodsEditForm from './Forms/ShopGoodsEditForm';
import { useSelector, useDispatch } from 'react-redux';

var updated = [];
const GoodShopPanel = ({ record }) => {
  const [data, setData] = useSafeSetState([]),
    [loading, setLoading] = useSafeSetState(true),
    [error, setError] = useSafeSetState(),
    authority = getCurrentAuthority(),
    canEdit = authority <= UserRole.SHOP;

  const state  = useSelector(state => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dataProvider
      .getList('goods-shop', {
        filter: {
          'companyId||$eq': record.companyId,
          'shopId||$eq': getCurrentShopId(),
          'goodsId||$eq': record.id
        },
        pagination: {
          page: 1,
          perPage: 99
        },
        sort: {
          field: 'id',
          order: 'DESC'
        }
      })
      .then(({ data }) => {
        // console.log("updated");
        // console.log(updated);
        // console.log(record.id);
        // console.log("updated.indexOf(record.id):"+updated.indexOf(record.id));
        if (updated.indexOf(record.id) == -1){
          // console.log("data");
          // console.log(data);
          // console.log("dataの数:"+data.length);
          if(data.length == 0){ 
            // console.log("閉じるよ:" + record.id);
            updated.push(record.id);
            dispatch(toggleListItemExpand("goods", record.id));
          }
        }
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [record, setData, setError, setLoading]);

  if (loading) return <Loading />;
  if (error) return null;
  if (!data) return null;
  if (data.length === 0) {
    if (canEdit) {
      return <ShopGoodsCreateForm resource="goods" record={record} />;
    } else {
      return (
        <Typography align="center">
          {l18n.translate('ra.message.no_info')}
        </Typography>
      );
    }
  }

  return (
    <div className="sectionExpandWrap">
      <Table>
        <MyDatagridRow
          records={data}
          resource="goods-shop"
          id={record.id}
          basePath="goods-shop"
        >
          <BooleanField
            source="handlingFlag"
            label={l18n.translate('resources.goods.fields.shop.handlingFlag')}
          />
          <BooleanField
            source="soldOutFlag"
            label={l18n.translate('resources.goods.fields.shop.soldOutFlag')}
          />
          <ReferenceField
            source="shopId"
            reference={RESOURCE_SHOPS}
            link={false}
            label={l18n.translate('resources.shops.fields.shopName')}
          >
            <TextField source="shopName" />
          </ReferenceField>
          <TextField
            source="description"
            label={l18n.translate('resources.goods.fields.shop.description')}
          />
          <TextField
            source="purchaseCost"
            label={l18n.translate('resources.goods.fields.shop.purchaseCost')}
          />
          <TextField
            source="sellingPrice"
            label={l18n.translate('resources.goods.fields.shop.sellingPrice')}
          />
          {canEdit && (
            <EditButton
              data-cy="editShopGoodsButton"
              variant="contained"
              disableElevation
            />
          )}
          {canEdit && <DeleteWithConfirmButton redirect="/goods" />}
        </MyDatagridRow>
      </Table>
    </div>
  );
};
const GoodsListPage = ({ globalFilterReducer, ...props }) => {
  let authority = props.permissions,
    // Button create
    showCreateButton = false,
    hideEdit = '',
    hideDelete = '',
    // Button import, export
    showBtn = false;
  // Set hide/show button create, import, export
  if (
    authority === UserRole.SYSTEM_ADMINISTRATOR ||
    authority === UserRole.COMPANY_ADMINISTRATOR
  ) {
    showCreateButton = true;
    showBtn = true;
  }

  if (authority === UserRole.SHOP || authority === UserRole.STAFF) {
    hideEdit = 'true';
    hideDelete = 'true';
  }

  const state  = useSelector(state => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if(state["admin"]){ console.log(state["admin"]);
      let resource = state["admin"].resources.goods;
      // console.log(resource);
      // console.log(resource.list.total);
      // console.log(resource.list.expanded);
      if (resource.list.total > 0) {
        resource.list.ids.forEach(id => {
          dispatch(toggleListItemExpand("goods", id));
        });
      }
    }
  }, []);

  return (
    <List
      {...props}
      actions={
        <GoodsActions
          showCreateButton={showCreateButton}
          showImportButton={showBtn}
          showExportButton={showBtn}
        />
      }
      pagination={<GeneralPagination />}
      bulkActionButtons={false}
      empty={false}
      className="table-responsive"
    >
      <Datagrid expand={GoodShopPanel}>
        <TextField source="id" />
        <TextField source="goodsCode" />
        <TextField source="janCode" />
        <TextField source="goodsName" />
        <ReferenceField
          source="groupId"
          reference={RESOURCE_GROUP}
          link={false}
          label={l18n.translate('resources.goods.fields.group')}
        >
          <TextField source="groupName" />
        </ReferenceField>
        <SectionField
          record={props.record}
          label={l18n.translate('resources.goods.fields.section')}
        />
        <NumberField
          source="purchaseCost"
          label={l18n.translate('resources.goods.fields.company.purchaseCost')}
        />
        <NumberField
          source="sellingPrice"
          label={l18n.translate('resources.goods.fields.company.sellingPrice')}
        />
        {!hideEdit && (
          <EditButton
            basePath={props.basePath}
            variant="contained"
            disableElevation
          />
        )}
        {!hideDelete && <DeleteWithConfirmButton />}
      </Datagrid>
    </List>
  );
};

const GoodsList = loadGlobalFilter(GoodsListPage);

const GoodsCreatePage = ({ globalFilterReducer, ...props }) => (
  <Create {...props} className="table-responsive">
    <GoodsCreateForm
      permissions={props.permissions}
      globalFilterReducer={globalFilterReducer}
    />
  </Create>
);

const GoodsCreate = loadGlobalFilter(GoodsCreatePage);

const GoodsEditPage = ({ globalFilterReducer, ...props }) => {
  return (
    <Edit {...props} undoable={false} className="table-responsive">
      <GoodsEditForm
        permissions={props.permissions}
        globalFilterReducer={globalFilterReducer}
      />
    </Edit>
  );
};

const GoodsEdit = loadGlobalFilter(GoodsEditPage);

const GoodsShopEdit = ({ saving, ...props }) =>
  props.permissions <= UserRole.SHOP ? (
    <Edit {...props} undoable={false} className="table-responsive">
      <ShopGoodsEditForm permissions={props.permissions} />
    </Edit>
  ) : props.permissions !== undefined ? (
    <AlertCommon
      message={l18n.translate('ra.common.restrictedArea', {
        reason: l18n.translate('ra.common.requireShopAdminRole')
      })}
      open={true}
      type={'error'}
    />
  ) : null;
export {
  GoodsList as List,
  GoodsCreate as Create,
  GoodsEdit as Edit,
  GoodsShopEdit
};
