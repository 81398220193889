import * as React from 'react';
import Badge from '@material-ui/core/Badge';

import PropTypes from 'prop-types';

const NotificationNumber = ({ number = 0 }) => {
  return <Badge badgeContent={number} color={"error"} />;
};

NotificationNumber.propTypes = {
  number: PropTypes.number.isRequired
};

export default NotificationNumber;
