import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Button,
  downloadCSV,
  FileField,
  FileInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRefresh
} from 'react-admin';
import React, { useCallback } from 'react';
import Cancel from '@material-ui/icons/Cancel';
import { getAccessToken } from 'providers/auth.provider';
import { env } from 'environment';

const ImportToolbar = ({ closeDrawer, ...props }) => (
  <Toolbar {...props}>
    <Button
      label="ra.action.cancel"
      onClick={closeDrawer}
      data-cy="closeImportGoodsForm"
      startIcon={<Cancel />}
      variant="contained"
      color="default"
      style={{ height: 32, marginRight: 10 }}
    />

    <SaveButton
      label="ra.action.import"
      data-cy="submitImportGoodsForm"
      redirect={false}
      submitOnEnter={false}
      disabled={props.pristine}
      variant="contained"
      color="primary"
    />
  </Toolbar>
);
const sendImportFile = (url: string, key: string, file: Blob) => {
  let headers = new Headers();
  headers.set('Authorization', `Bearer ${getAccessToken()}`);

  const formData = new FormData();
  formData.append(key, file);

  return fetch(url, {
    method: 'POST',
    body: formData,
    headers: headers
  });
};

const ImportForm = ({ closeDrawer }) => {
  const refresh = useRefresh();

  const handleSave = useCallback(
    async (values) => {
      const { importFile } = values;
      if (importFile) {
        sendImportFile(
          env.baseApi + '/tap-beers/import',
          'file',
          importFile.rawFile
        )
          .then((response) => response.blob())
          .then((res) => {
            downloadCSV(res, 'import-tap-beer-result');
            refresh();
          });
      }
    },
    [refresh]
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <h1>Import Beer</h1>
        <SimpleForm
          toolbar={<ImportToolbar closeDrawer={closeDrawer} />}
          record={{}}
          save={handleSave}
          data-cy="importTapBeerForm"
        >
          <FileInput source="importFile" label="File" required>
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Container>
    </React.Fragment>
  );
};

export default ImportForm;
