import React from 'react';
import {
  TextInput,
  PasswordInput,
  useNotify,
  useRedirect,
  useUpdate
} from 'react-admin';
import { TableRow, TableCell } from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
import { authProvider } from 'providers/auth.provider';
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
import { getEditProfileValidationSchema } from './validations';
import { AlertCommon } from 'containers/Common/alert';
import { handleSubmitFactory } from 'containers/Common/helper';
import * as Constants from 'containers/Common/constants';
import { UserRole } from 'providers/auth.provider';
import l18n from 'containers/I18nProvider/index';

const UserProfileEdit = (props) => {
  let [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    accountInfo = authProvider.getAccountInfo(),
    resource = Constants.RESOURCE_AUTH_PROFILE,
    // In case of staff, can not access user list page, thus need to redirect to dashboard
    basePath = (accountInfo.authority) === UserRole.STAFF ? '/dashboard' : '/users',
    notify = useNotify(),
    redirectTo = useRedirect(),
    [update] = useUpdate(resource, accountInfo.id),
    onSuccessCallback = async ({ data, values }) => {
      // Reload profile after updating
      await authProvider.getIdentity();
    },
    onTransform = (values) => {
      if (!values['password']) {
        delete values['password'];
        delete values['confirmPassword'];
      }
      return values;
    },
    handleSubmit = handleSubmitFactory({
      resource: resource,
      buildValidationSchema: getEditProfileValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', basePath),
      onSubmit: update,
      onSuccessCallback,
      onTransform: onTransform,
      successMsgKey: 'updated'
    }),
    company = accountInfo.company,
    shopManagements = accountInfo.shopManagements;

  return (
    <>
    <div className="edit-page table-responsive">
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <div className="wrapper-edit-profile">
        <div className="MuiCard-root edit-profile">
          <TableForm save={handleSubmit} resource="users" basePath={basePath} record={accountInfo} >
            <span title={l18n.translate('resources.users.fields.id')} id="id">
              {accountInfo.id}
            </span>

            <span
              title={l18n.translate('resources.users.fields.loginId')}
              id="loginId"
            >
              {accountInfo.loginId}
            </span>

            <span title={l18n.translate('resources.users.fields.authority')} id="authority">
              {accountInfo.authorityName}
            </span>

            {company && company.companyName && (
              <span title={l18n.translate('resources.users.fields.company.id')} id="company.id">
                {company.companyName}
              </span>
            )}
            {Array.isArray(shopManagements) && shopManagements.length > 0 && (
              <TableRow key="shopManagements">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.users.fields.shop.id')}
                </TableCell>
                <TableCell>
                  {shopManagements?.map(item => (
                    <span key={item.id} className="tag" data-cy="shopManagementTags">{ item.shop.shopName }</span>
                  ))}
                </TableCell>
              </TableRow>
            )}

            <TextInput
              title={l18n.translate('resources.users.fields.loginName')}
              required={true}
              source="loginName"
              className="form-control"
              helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
                max: 30
              })}
            />

            <PasswordInput
              title={l18n.translate('resources.users.fields.password')}
              source="password"
              className="form-control"
              defaultValue=""
              helperText={l18n.translate('ra.helpBlock.limitAlphabetSymbolMinMax', {
                min: 8,
                max: 50
              })}
            />

            <PasswordInput
              title={l18n.translate('resources.users.fields.confirmPassword')}
              source="confirmPassword"
              className="form-control no-help-block"
              defaultValue=""
            />
          </TableForm>
        </div>
      </div>
    </div>
    </>
  );
};

export default UserProfileEdit;
