const messages = {
  'beer-servers': {
    name: 'モジュール一覧',
    createTitle: 'ビールサーバー作成',
    updateTitle: 'ビールサーバー編集',
    fields: {
      id: 'ID',
      serverCode: 'サーバコード',
      obnizId: 'Obniz Id',
      shop: {
        id: '所属店舗',
        shopCode: '店舗CD',
        shopName: '店舗名'
      },
      company: {
        id: '所属会社',
        companyName: '会社名'
      },
      startUsingAt: '日付から使用を開始',
      isOnline: 'オンライン'
    },
    validation: {
      serverCode: {
        format: 'サーバコードは半角英数字でご入力ください。'
      }
    },
    headings: {
      tapBeerStatistic: '残りのタップビール'
    }
  }
};

export default messages;
