import React from 'react';
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import { ReduxState } from 'ra-core';
import { setSidebarVisibility, getResources } from 'react-admin';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@material-ui/core';
import { useSafeSetState } from 'ra-core';
import { resourceList } from 'containers/Common/resources';
import * as Constants from 'containers/Common/constants';
// Translate
import l18n from 'containers/I18nProvider/index';
import MenuItemLink from 'components/MenuItemLink';

export enum CollapseInType {
  ACCOUNT_SETTINGS = 1,
  MASTER_DATA_SETTINGS,
  SALE_CONFIRMATION,
  LOG_CONFIRMATION,
  STATISTICS,
  PRODUCT,
  PROCESSING
}

const CustomSidebar = (props) => {
  // Used to force redraw on navigation
  let pathname = useSelector(
      (state: ReduxState) => state.router.location.pathname
    ),
    pathParts = pathname.split('/'),
    rootPath = pathParts[1],
    collapseInType = -1;
  // Set type collapse menu
  switch (rootPath) {
    case Constants.RESOURCE_USERS:
    case Constants.RESOURCE_COMPANIES:
    case Constants.RESOURCE_SHOPS:
      collapseInType = CollapseInType.ACCOUNT_SETTINGS;
      break;
    case Constants.RESOURCE_GOODS:
    case Constants.RESOURCE_TAP_BEERS:
    case Constants.RESOURCE_BEER_SERVERS:
    case Constants.RESOURCE_DEVICES:
      collapseInType = CollapseInType.MASTER_DATA_SETTINGS;
      break;
    case Constants.RESOURCE_SALE_LOGS:
    case Constants.RESOURCE_SALE_AGGREGATION:
      collapseInType = CollapseInType.SALE_CONFIRMATION;
      break;
    case Constants.RESOURCE_OPERATION_LOGS:
    case Constants.RESOURCE_ERROR_LOGS:
      collapseInType = CollapseInType.LOG_CONFIRMATION;
      break;
  }

  // Init state variables collapse menu
  const [openAccountSetting, setOpenAccountSetting] = useSafeSetState(
      collapseInType === CollapseInType.ACCOUNT_SETTINGS ?? false
    ),
    [masterSetting, setMasterSetting] = useSafeSetState(
      collapseInType === CollapseInType.MASTER_DATA_SETTINGS ?? false
    ),
    [logConfirmation, setLogConfirmation] = useSafeSetState(
      collapseInType === CollapseInType.LOG_CONFIRMATION ?? false
    ),
    [statistics, setStatistics] = useSafeSetState(
      collapseInType === CollapseInType.STATISTICS ?? false
    ),
    [product, setProduct] = useSafeSetState(
      collapseInType === CollapseInType.PRODUCT ?? false
    );

  const { dense, onMenuClick } = props;
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const dispatch = useDispatch();
  const resources = useSelector(getResources, shallowEqual) as Array<any>;

  // Get state sub menu
  const getcollapseState = (name) => {
    switch (name) {
      case Constants.ADMIN_SETTING:
        return openAccountSetting;
      case Constants.MASTER_DATA_SETTING:
        return masterSetting;
      case Constants.LOG_CONFIRMATION:
        return logConfirmation;
      case Constants.STATISTICS:
        return statistics;
      case Constants.PRODUCT:
        return product;
    }
  };

  // Generate resource & sub resource
  const generateResouces = () => {
    return resourceList.map((resource) => {
      const subMenus = resource.subMenus;
      if (subMenus.length > 0) {
        // Avoid generate parent menu title of sub menu in case of no sub menu by permission
        let filterSubMenus = [];
        subMenus.forEach((subResource) => {
          const existsList = resources.find(
            (item) => item.name === subResource.name && item.hasList === true
          );
          if (existsList) {
            filterSubMenus.push(subResource);
          }
        });

        if (filterSubMenus.length > 0) {
          return (
            <div key={resource.name}>
              <ListItem button onClick={onChangeSubMenu} id={resource.name}>
                <ListItemIcon>{<resource.icon />}</ListItemIcon>
                <ListItemText
                  primary={l18n.translate(`ra.sidebar.${resource.name}`)}
                />
                {getcollapseState(resource.name) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={getcollapseState(resource.name)}
                timeout="auto"
                unmountOnExit
                className="sub-menu"
              >
                <List component="div" disablePadding>
                  {(filterSubMenus as Array<any>).map((subResource) => {
                    return (
                      <MenuItemLink
                        key={subResource.name}
                        to={`/${subResource.name}`}
                        primaryText={l18n.translate(
                          `resources.${subResource.name}.name`
                        )}
                        leftIcon={<subResource.icon />}
                        onClick={onMenuClick}
                        dense={dense}
                        sidebarIsOpen={open}
                        activeClassName="active"
                        rightIcon={subResource.appendix}
                      />
                    );
                  })}
                </List>
              </Collapse>
            </div>
          );
        } else {
          return null;
        }
      } else {
        // Filter menu by permission resources
        const existsList = resources.find(
          (item) => item.name === resource.name && item.hasList === true
        );
        if (existsList) {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={l18n.translate(`resources.${resource.name}.name`)}
              leftIcon={<resource.icon />}
              onClick={onMenuClick}
              dense={dense}
              sidebarIsOpen={open}
              activeClassName="active"
            />
          );
        } else {
          return null;
        }
      }
    });
  };

  // Event open or close sidebar
  const onChangeSidebar = (e) => {
    e.preventDefault();

    dispatch(setSidebarVisibility(!open));
  };

  // Event click collapse open or close menu
  const onChangeSubMenu = (e) => {
    const id = e.currentTarget.id;
    switch (id) {
      case Constants.ADMIN_SETTING:
        setOpenAccountSetting(!openAccountSetting);
        break;
      case Constants.MASTER_DATA_SETTING:
        setMasterSetting(!masterSetting);
        break;
      case Constants.LOG_CONFIRMATION:
        setLogConfirmation(!logConfirmation);
        break;
      case Constants.STATISTICS:
        setStatistics(!statistics);
        break;
      case Constants.PRODUCT:
        setProduct(!product);
        break;
    }
  };

  return (
    <div
      className={classnames(
        'sidebar',
        open ? 'sidebar-active' : 'sidebar-not-active'
      )}
    >
      <div className="sidebar-header">
        <Link to="" onClick={onChangeSidebar}>
          {open ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Link>
      </div>
      <div className="sidebar-body">{generateResouces()}</div>
    </div>
  );
};

export default CustomSidebar;
