import React from 'react';
import { Paper, Grid } from '@material-ui/core';

const ErrorMessage = ({errorMessage}: { errorMessage: String }) => (
  <div style={{ padding: '1rem' }}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper style={{ padding: '1rem' }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {errorMessage}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </div>
);

export default ErrorMessage