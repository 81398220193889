const messages = {
  users: {
    name: 'Account List',
    createTitle: 'Create Account',
    updateTitle: 'Update Account',
    fields: {
      id: 'ID',
      email: 'Email',
      password: 'Password',
      loginName: 'Full Name',
      loginId: 'User CD',
      authority: 'Authority',
      company: {
        id: 'Affiliation Company'
      },
      shop: {
        id: 'Affiliation Shop'
      },
      confirmPassword: 'Confirm Password',
      // Get field text when error validation in case of creating user
      addedShopIds: 'Affiliation Shop'
    },
    adminRole: 'System Admin',
    companyRole: 'Company Admin',
    shopRole: 'Shop Admin',
    staffRole: 'Staff',
    validation: {
      loginId: {
        format: 'Please use alphabet, number and these marks only for User CD. （_（Underscore）-（Hyphen）.（Dot））.'
      },
      password: {
        format: 'Please use alphabet, number and Unicode from （!"#$%&()*+,-./:;<=>?@[]^_`{|}~） for Password.'
      }
    },
    helpBlock: {
      loginId: 'Alphabet, Number And Symbol -_. only. Minimum 3 characters. Max 30 characters.'
    }
  },
  'users.login': {
    fields: {
      loginId: 'Login ID',
      password: 'Password'
    },
    validation: {
      loginId: {
        format: 'Please use alphabet, number and these marks only for Login ID. （_（Underscore）-（Hyphen）.（Dot））.'
      },
      password: {
        format: 'Please use alphabet, number and Unicode from （!"#$%&()*+,-./:;<=>?@[]^_`{|}~） for Password.'
      }
    },
    loginWrong: 'Wrong loginId or password.'
  }
};

export default messages;
