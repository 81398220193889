interface Currency {
  symbol: string;
}

const currency: Currency = {
  symbol: '¥'
};

const DATE_FORMAT_DATE_AND_TIME: string = 'YYYY/MM/DD HH:mm';
const DATE_FORMAT_DATE_ONLY: string = 'YYYY/MM/DD';
const DATE_FORMAT_MONTH_AND_DAY: string = 'MM/DD';
const DATE_FORMAT_YEAR_AND_MONTH: string = 'YYYY/MM';

export {
  currency,
  DATE_FORMAT_DATE_AND_TIME,
  DATE_FORMAT_DATE_ONLY,
  DATE_FORMAT_MONTH_AND_DAY,
  DATE_FORMAT_YEAR_AND_MONTH
};
