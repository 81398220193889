import { Button, FormControl, TextField } from '@material-ui/core';
import { AccountCircle, LockRounded } from '@material-ui/icons';
import Loading from 'components/LoadingIndicator';
import { AlertCommon, saveState } from 'containers/Common/alert';
import { validation } from 'containers/Common/validations';
import l18n from 'containers/I18nProvider/index';
import { useLogin, useSafeSetState } from 'ra-core';
import React from 'react';
import { Field, Form } from 'react-final-form';
import 'styles/login.css';
import { getLoginValidationSchema } from './validations';

const LoginPage = (props) => {
  document.title = document.title =
    process.env.REACT_APP_WEBSITE_NAME +
    ' - ' +
    l18n.translate('ra.auth.loginTitle');

  const [state, setState] = useSafeSetState({
    open: false,
    message: '',
    type: '',
    loading: false
  });

  const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      className="input-text input-border"
      margin="dense"
      variant="outlined"
      size="small"
      label=""
    />
  );

  const login = useLogin();

  // Login
  const submit = (values) => {
    // Show loading
    saveState({
      setState,
      open: false,
      message: '',
      loading: true
    });

    const showError = (message) =>
        saveState({ setState, open: true, message, state }),
      resource = 'users.login';

    const validationSchema = getLoginValidationSchema();
    const validationResult = validation(resource, validationSchema, values);
    if (validationResult) {
      showError(validationResult);
      return;
    }

    // Call api login
    login(values, 'dashboard')
      .then(() => {
        saveState({
          setState,
          open: false,
          message: ''
        });
      })
      .catch((error) => {
        showError(
          l18n.translate(`resources.${resource}.validation.loginWrong`)
        );
      });
  };

  const onKeyPress = (e) => {
    // Avoid enter submit form
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <div className="login-wrapper">
      {state.loading && <Loading />}
      <div className="login">
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              onKeyPress={onKeyPress}
              noValidate
              autoComplete="false"
            >
              <FormControl>
                <div className="loginTitle">
                  <img
                    id="logo-shallwetap-login"
                    src="/logo-shallwetap-black.png"
                    alt="Logo Shallwetap"
                  />
                </div>

                <AlertCommon
                  message={state.message}
                  open={state.open}
                  type={state.type}
                  setState={setState}
                />

                <Field
                  id="loginId"
                  name="loginId"
                  component={Input}
                  placeholder={l18n.translate('ra.auth.loginId')}
                  InputProps={{
                    startAdornment: <AccountCircle />,
                    className: 'text-white'
                  }}
                />

                <Field
                  id="password"
                  name="password"
                  component={Input}
                  type="password"
                  placeholder={l18n.translate('ra.auth.password')}
                  InputProps={{
                    startAdornment: <LockRounded />,
                    className: 'text-white'
                  }}
                />

                <Button
                  type="submit"
                  className="btn-info"
                  color="primary"
                  variant="contained"
                  disabled={state.loading}
                >
                  {l18n.translate('ra.auth.sign_in')}
                </Button>
              </FormControl>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default LoginPage;
