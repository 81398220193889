import { ReportData } from "../interfaces";

export const FETCHED = 'FETCHED';
export const FETCHING = 'FETCHING';

export const reportDataLoaded = (payload: ReportData) => {
  return {
    type: FETCHED,
    payload: payload
  };
};

export const fetchingReportData = (reportUrl: String) => {
  return {
    type: FETCHING,
    payload: reportUrl
  };
};
