import React from 'react';
import {
  TextInput,
  SelectInput,
  PasswordInput,
  FormDataConsumer,
  useCreate,
  useNotify,
  useRedirect
} from 'react-admin';
import { useSafeSetState } from 'ra-core';
import { TableRow, TableCell } from '@material-ui/core';
// Components
import TableForm from 'components/TableForm';
import Loading from 'components/LoadingIndicator';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import { AlertCommon } from 'containers/Common/alert';
import { isAdmin } from 'containers/App/utils';
import ShopInput from 'components/ShopInput';
import l18n from 'containers/I18nProvider';
// Local
import { getCreateDeviceValidationSchema } from './validations';

const AddForm = ({ globalFilterReducer, ...props }) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate(resource),
    notify = useNotify(),
    redirectTo = useRedirect(),
    authority = props.permissions,
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCreateDeviceValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', props.basePath),
      onSubmit: create,
      state: state
    });

  let { company, availableCompanies } = globalFilterReducer;

  // if (!company) {
  //   company = availableCompanies[0];
  // }

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.devices.fields.hardwareCode')}
          source="hardwareCode"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 30
          })}
        />

        <TextInput
          title={l18n.translate('resources.devices.fields.hardwareName')}
          source="hardwareName"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.limitNoEmojisMax', {
            max: 100
          })}
        />

        <PasswordInput
          title={l18n.translate('resources.devices.fields.password')}
          source="password"
          className="form-control"
          required
          helperText={l18n.translate('ra.helpBlock.numberEqual', { max: 6 })}
        />

        <PasswordInput
          title={l18n.translate('resources.devices.fields.confirmPassword')}
          source="confirmPassword"
          className="form-control no-help-block"
          required
        />

        <SelectInput
          title={l18n.translate('resources.devices.fields.company.companyName')}
          disabled={!isAdmin(authority)}
          optionText="companyName"
          source="company.id"
          choices={availableCompanies}
          defaultValue={company.id}
          required
          className="form-control custom-select no-help-block"
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.company &&
            formData.company.id && (
              <TableRow key="shop">
                <TableCell className="table-item-name">
                  {l18n.translate('resources.devices.fields.shop.shopName')}
                  <span className="required-asterisk">*</span>
                </TableCell>
                <TableCell>
                  <ShopInput source="shop.id" {...rest} />
                </TableCell>
              </TableRow>
            )
          }
        </FormDataConsumer>
      </TableForm>
    </>
  );
};

export default AddForm;
