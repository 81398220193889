import React from 'react';
import { useSafeSetState } from 'ra-core';
import { SelectInput, useDataProvider } from 'react-admin';
import { useFormState } from 'react-final-form';
import Loading from 'components/Loading';
import SelectArrayInput from './SelectArrayInput';
import { AddIconLink } from 'components/InputCommon/index';
import { getListDataByCompanyId } from 'containers/Common/api';

const ShopInput = ({ multiselect = false, defaultValue = [], ...props }) => {
  let [state, setState] = useSafeSetState({
      shopChoices: [],
      isDone: false
    }),
    dataProvider = useDataProvider(),
    // Get company from form
    {
      values: { company = { id: undefined }, shopmanagements = [] }
    } = useFormState();

  // TODO remove variable shopmanagements
  if (shopmanagements.length > 0) {
    defaultValue = [...shopmanagements];
  }

  // Reset select value when select company
  React.useEffect(() => {
    setState({
      shopChoices: [],
      isDone: false
    });
    const updateShopChoices = (companyId) => {
      getListDataByCompanyId(dataProvider, 'shops', {
        fields: ['id', 'shopName'],
        companyId
      }).then((data) => {
        setState({
          shopChoices: data,
          isDone: true
        });
      });
    };
    updateShopChoices(company.id);
  }, [company.id, setState, dataProvider]);

  if (!state.isDone) return <Loading />;

  if (state.isDone) {
    const shopChoices = state.shopChoices;

    if (Array.isArray(shopChoices) && shopChoices.length === 0) {
      // Return link add shop in case of empty shop
      return <AddIconLink to="/shops/create" />;
    }

    if (multiselect) {
      if (defaultValue.length > 0) {
        // Note: This is to avoid infinite loop when using form state shopmanagements
        return (
          <SelectArrayInput
            {...props}
            choices={shopChoices}
            optionText="shopName"
            defaultValue={defaultValue}
            className="no-help-block"
          />
        );
      }
      return (
        <SelectArrayInput
          {...props}
          choices={shopChoices}
          optionText="shopName"
          className="no-help-block"
        />
      );
    }

    return (
      <SelectInput
        {...props}
        choices={shopChoices}
        optionText="shopName"
        defaultValue={shopChoices[0].id}
        className="form-control custom-select no-help-block"
      />
    );
  }

  return null;
};

export default ShopInput;
