import { validation } from 'containers/Common/validations';
import { saveState } from 'containers/Common/alert';
import _ from 'lodash';
import l18n from 'containers/I18nProvider/index';
import CurrencyFormat from 'react-currency-format';
import { currency } from 'containers/App/constants';
import React from 'react';

/**
 * Split email
 * @param email
 */
let convertMultiEmails = (email) => {
  // In case of array return original data
  if (Array.isArray(email)) return email;

  if (typeof email !== 'string' || !email) return '';

  // Remove duplicate email before call api
  return _.uniq(email.split(/,|;/).map((value) => value.trim().toLowerCase()));
};

const getBase64 = (file, key): Promise<string> => {
  // Check raw file empty or file is url
  const image = file[key];
  if (!image || typeof image != 'object') return Promise.resolve('');

  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(String(temporaryFileReader.result));
    };

    temporaryFileReader.readAsDataURL(image['rawFile']);
  });
};

const getValueByStringPath = (o, s) => {
  // convert indexes to properties
  s = s.replace(/\[(\w+)\]/g, '.$1');
  // strip a leading dot
  s = s.replace(/^\./, '');
  let a = s.split('.');
  const arrayLength = a.length;
  for (let i = 0; i < arrayLength; ++i) {
    let k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

/**
 * Get extension file
 * @param filename
 */
let getFileExtension = (filename) => {
  if (!filename) return '';

  return filename.split('.').pop().toLowerCase();
};

/**
 * Get size file
 * @param size
 */
let getFileSize = (size) => {
  if (!size) return 0;

  // Convert byte to MB
  return (size / 1024 / 1024).toFixed(2);
};

const handleSubmitFactory = ({
  resource,
  buildValidationSchema,
  setState,
  notify,
  redirectTo,
  onSubmit,
  onTransform = (values) => values,
  onSuccessCallback = ({ data, values }: any) => {},
  state = {},
  successMsgKey = 'created'
}) => {
  const showLoading = () =>
    saveState({ setState, open: false, message: '', loading: true, state });
  const stopLoading = () =>
    saveState({ setState, open: false, message: '', loading: false, state });
  const showError = (message) =>
    saveState({ setState, open: true, message, loading: false, state });

  const onSuccess = async ({ data, values }) => {
    try {
      // Return success
      notify(`ra.notification.${successMsgKey}`, 'info', {
        attribute: data.id
      });
      onSuccessCallback({ data, values });
      redirectTo();
    } catch (error) {
      showError(l18n.translate('ra.common.fatalError'));
    }
    stopLoading();
  };

  const onFailure = (error) => {
    // Convert message error
    let errorMessage = error.message[0],
      msg = '';
    if (error.status === 500) {
      msg = l18n.translate('ra.common.fatalError');
    } else {
      if (
        typeof errorMessage === 'string' &&
        errorMessage.indexOf('already exist') > -1
      ) {
        // Get duplicate key from error message
        const messageParts = errorMessage.split("'"),
          duplicateKey = messageParts[1];
        if (duplicateKey) {
          msg = l18n.translate('ra.validation.duplication', {
            attribute: l18n.translate(
              `resources.${resource}.fields.${duplicateKey}`
            )
          });
        }
      } else {
        msg = errorMessage;
      }
    }

    // Show message error
    showError(msg);
    window.scrollTo(0, 10);
  };

  const handleSubmit = async (values) => {
    // Show loading
    showLoading();

    values = await onTransform(values);

    // Validation input parameters
    const validationSchema = buildValidationSchema(values);
    const validationResult = validation(resource, validationSchema, values);
    if (validationResult) {
      showError(validationResult);
      window.scrollTo(0, 10);
      return;
    }

    onSubmit(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => onSuccess({ data, values }),
        onFailure: onFailure
      }
    );
  };

  return handleSubmit;
};

const extractContentType = (base64: string) =>
  base64.split(';').shift().split('data:').pop();
const extractBase64Data = (base64: string) => base64.split('base64,').pop();

const prepareImage = (base64Result: string) => ({
  base64: extractBase64Data(base64Result),
  contentType: extractContentType(base64Result)
});

const getShopNameByShopId = (shopList, shopId) => {
  const shopInfo = shopList.find((item) => item.id === shopId);

  return shopInfo ? shopInfo.shopName : '';
};

const buildChartData = (labels: String[], data: number[]) => ({
  labels: labels,
  datasets: [
    {
      //   label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: data
    }
  ]
});

const renderPrice = (originalValue, prefix = `${currency.symbol} `) => {
  let value = Math.floor(originalValue);
  if (isNaN(value)) {
    value = originalValue;
  }

  return (
    <CurrencyFormat
      prefix={prefix}
      value={value}
      displayType={'text'}
      thousandSeparator={true}
    />
  );
};

const sumValueAtColumnIndex = (data, index) =>
  parseFloat(
    data.reduce((previous, item) => {
      previous += parseFloat(item[index]);
      return previous;
    }, 0)
  ).toFixed(2);

const onFailureCallback = (error, resource, setState, state) => {
  // Convert message error
  let errorMessage = error.message[0],
    msg = '';
  if (error.status === 500) {
    msg = l18n.translate('ra.common.fatalError');
  } else {
    if (
      typeof errorMessage === 'string' &&
      errorMessage.indexOf('already exist') > -1
    ) {
      // Get duplicate key from error message
      const messageParts = errorMessage.split("'"),
        duplicateKey = messageParts[1];
      if (duplicateKey) {
        msg = l18n.translate('ra.validation.duplication', {
          attribute: l18n.translate(
            `resources.${resource}.fields.${duplicateKey}`
          )
        });
      }
    } else {
      msg = errorMessage;
    }
  }

  // Show message error
  const showError = (message) =>
    saveState({ setState, open: true, message, loading: false, state });
  showError(msg);
  window.scrollTo(0, 10);
};

export {
  convertMultiEmails,
  getBase64,
  getValueByStringPath,
  getFileExtension,
  getFileSize,
  handleSubmitFactory,
  extractContentType,
  extractBase64Data,
  prepareImage,
  getShopNameByShopId,
  buildChartData,
  renderPrice,
  sumValueAtColumnIndex,
  onFailureCallback
};
