import { InputAdornment } from '@material-ui/core';
import Loading from 'components/LoadingIndicator';
// Components
import TableForm from 'components/TableForm';
import { currency } from 'containers/App/constants';
import { formatImageUrl } from 'containers/App/utils';
import { AlertCommon } from 'containers/Common/alert';
// Containers
import {
  getBase64,
  handleSubmitFactory,
  prepareImage
} from 'containers/Common/helper';
import l18n from 'containers/I18nProvider/index';
import { getCurrentCompanyId, getCurrentShopId } from 'providers/auth.provider';
import { useRefresh, useSafeSetState } from 'ra-core';
import React from 'react';
import {
  BooleanInput,
  ImageField,
  ImageInput,
  TextInput,
  useCreate,
  useNotify,
  useRedirect
} from 'react-admin';
// Local
import { getCreateShopGoodsValidationSchema } from './validations';

const ShopGoodsCreateForm = (props) => {
  const refresh = useRefresh();
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    resource = props.resource,
    [create] = useCreate('goods-shop'),
    notify = useNotify(),
    redirectTo = useRedirect(),
    onTransform = async (values) => {
      if (values.shop) {
        // Get image base64 to pass to api
        const imageResult = await getBase64(values.shop, 'imageUrl');
        if (imageResult) {
          values['image'] && delete values['image'];
          values['image'] = prepareImage(imageResult);
        }
      }
      // @Todo
      // Display error msg when convert base64 error
      // saveState(setState, true, l18n.translate('resources.goods.validation.image.error'));
      // return;

      return {
        goods: {
          id: values['id']
        },
        company: {
          id: getCurrentCompanyId()
        },
        shop: {
          id: getCurrentShopId()
        },
        purchaseCost: values['purchaseCost'],
        sellingPrice: values['sellingPrice'],
        handlingFlag: values['handlingFlag'] ? 1 : 0,
        soldOutFlag: values['soldOutFlag'] ? 1 : 0,
        description: values['description'],
        image: values['image']
      };
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getCreateShopGoodsValidationSchema,
      setState,
      notify,
      redirectTo: () => redirectTo('list', 'goods'),
      // TODO Call api save info to goods_shop by dataProvider
      onSubmit: create,
      onSuccessCallback: refresh,
      onTransform,
      successMsgKey: 'created'
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm {...props} save={handleSubmit}>
        <TextInput
          title={l18n.translate('resources.goods.fields.shop.purchaseCost')}
          required={true}
          source="purchaseCost"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.shop.sellingPrice')}
          required={true}
          source="sellingPrice"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
        />

        <TextInput
          title={l18n.translate('resources.goods.fields.shop.description')}
          multiline
          rows="5"
          source="description"
          className="form-control"
          helperText={l18n.translate('ra.helpBlock.limitMax', { max: 255 })}
        />

        <ImageInput
          title={l18n.translate('resources.goods.fields.shop.image')}
          required={true}
          format={formatImageUrl}
          source="shop.imageUrl"
          label="Related pictures"
          accept="image/*"
          className="img-input"
          helperText={l18n.translate('ra.helpBlock.extension')}
        >
          <ImageField source="url" />
        </ImageInput>

        <BooleanInput
          title={l18n.translate('resources.goods.fields.shop.handlingFlag')}
          required={true}
          label=""
          source="handlingFlag"
          className="no-help-block"
        />

        <BooleanInput
          title={l18n.translate('resources.goods.fields.shop.soldOutFlag')}
          required={true}
          label=""
          source="soldOutFlag"
          className="no-help-block"
        />
      </TableForm>
    </>
  );
};

export default ShopGoodsCreateForm;
