import dashboardMessages from 'containers/Resources/Dashboard/Messages/ja';
import userMessages from 'containers/Resources/User/Messages/ja';
import companyMessages from 'containers/Resources/Company/Messages/ja';
import shopMessages from 'containers/Resources/Shop/Messages/ja';
import goodMessages from 'containers/Resources/Goods/Messages/ja';
import tapBeerMessages from 'containers/Resources/TapBeer/Messages/ja';
import beerServerMessages from 'containers/Resources/BeerServer/Messages/ja';
import deviceMessages from 'containers/Resources/Device/Messages/ja';
import saleLogMessages from 'containers/Resources/SaleLog/Messages/ja';
import operationLogMessages from 'containers/Resources/OperationLog/Messages/ja';
import errorLogMessages from 'containers/Resources/ErrorLog/Messages/ja';
import eventLogMessages from 'containers/Resources/EventLog/Messages/ja';
import groupMessages from 'containers/Resources/Group/Messages/ja';
import sectionMessages from 'containers/Resources/Section/Messages/ja';
import topUpMessages from 'containers/Resources/TopUp/Messages/ja';

const messages = {
  ra: {
    action: {
      add_filter: '検索条件',
      add: '追加',
      back: '戻る',
      customBack: '%{page}に戻る',
      bulk_actions: '%{smart_count}件選択',
      cancel: 'キャンセル',
      clear_input_value: '空にする',
      clone: '複製',
      confirm: '削除',
      create: '新規作成',
      delete: '削除',
      edit: '編集',
      export: 'CSVエクスポート',
      download: 'CSVダウンロード',
      list: '一覧',
      refresh: '更新',
      remove_filter: '検索条件を削除',
      remove: '削除',
      save: '保存',
      search: '検索',
      show: '詳細',
      sort: '並び替え',
      undo: '元に戻す',
      unselect: '選択解除',
      expand: '開く',
      close: '閉じる',
      open_menu: '開く',
      close_menu: '閉じる',
      import: 'CSVインポート',
      operation: '操作',
      show_detail: '詳細を表示',
      close_detail: '確認'
    },
    boolean: {
      true: 'はい',
      false: 'いいえ',
      null: '未選択'
    },
    page: {
      create: '%{name} を作成',
      dashboard: 'ダッシュボード',
      edit: '%{name} #%{id}',
      error: '問題が発生しました',
      list: '%{name}',
      loading: '読込中',
      not_found: '見つかりませんでした',
      show: '%{name} #%{id}',
      empty: '%{name}はありません',
      invite: '作成しますか？',
      processing: '処理'
    },
    input: {
      file: {
        upload_several:
          'アップロードするファイルをドロップ、または選択してください',
        upload_single:
          'アップロードするファイルをドロップ、または選択してください'
      },
      image: {
        upload_several:
          'アップロードする画像をドロップ、または選択してください',
        upload_single: 'アップロードする画像をドロップ、または選択してください'
      },
      references: {
        all_missing: 'データが利用できなくなりました',
        many_missing: '選択したデータが利用できなくなりました',
        single_missing: '選択したデータが利用できなくなりました'
      },
      password: {
        toggle_visible: '非表示',
        toggle_hidden: '表示'
      }
    },
    message: {
      about: '詳細',
      are_you_sure: '本当によろしいですか？',
      bulk_delete_content:
        '%{name} を削除してよろしいですか？ |||| 選択した %{smart_count}件のアイテムを削除してよろしいですか？',
      bulk_delete_title: '%{name} を削除 |||| %{name} %{smart_count}件を削除',
      delete_content: '※削除すると元に戻すことができません',
      delete_title: '#%{id}を本当に削除しますか?',
      details: '詳細',
      error: 'クライアントエラーが発生し、処理を完了できませんでした',
      invalid_form:
        '入力値に誤りがあります。エラーメッセージを確認してください',
      loading: '読み込み中です。しばらくお待ちください',
      no: 'いいえ',
      not_found: '間違ったURLを入力したか、間違ったリンクを辿りました',
      yes: 'はい',
      unsaved_changes:
        '行った変更が保存されていません。このページから移動してよろしいですか？',
      reportNotAvailable: 'レポートは利用できません',
      pleaseChooseShop: 'ショップを選択してください',
      pleaseChooseEitherStartOrEndDate:
        '開始日または終了日のいずれかを選択してください',
      no_info: '店舗の情報がありません。'
    },
    navigation: {
      no_results: '結果が見つかりませんでした。',
      no_more_results:
        'ページ番号 %{page} は最大のページ数を超えています。前のページに戻ってください',
      page_out_of_boundaries:
        'ページ番号 %{page} は最大のページ数を超えています',
      page_out_from_end: '最大のページ数より後に移動できません',
      page_out_from_begin: '1 ページより前に移動できません',
      page_range_info: '%{offsetBegin} - %{offsetEnd} / %{total}件',
      page_one_record: '1件',
      page_rows_per_page: '表示件数：',
      next: '次',
      prev: '前'
    },
    auth: {
      loginTitle: 'ログイン',
      auth_check_error: '認証に失敗しました。再度ログインしてください',
      user_menu: 'プロフィール',
      loginId: 'ログインID',
      password: 'パスワード',
      sign_in: 'ログイン',
      logout: 'ログアウト',
      logo: 'CRAFT BEER PLATFORM',
      powered: 'Powered by CRAFT BEER',
      authority_admin: 'システム管理',
      authority_company: '会社アカウント',
      authority_shop_admin: '店舗アカウント',
      authority_shop_staff: 'スタッフ'
    },
    sort: {
      sort_by: '%{field}を%{order}で並び替え中',
      ASC: '昇順',
      DESC: '降順'
    },
    notification: {
      updated: 'ID#%{attribute}を更新しました。',
      created: 'ID#%{attribute}を登録完了しました。',
      deleted: '%{smart_count} つのアイテムが削除されました',
      bad_item: 'データが不正です',
      item_doesnt_exist: 'データが存在しませんでした',
      http_error: '通信エラーが発生しました',
      data_provider_error:
        'dataProviderエラー。詳細はコンソールを確認してください',
      i18n_error: '翻訳ファイルが読み込めませんでした',
      canceled: '元に戻しました',
      logged_out: '認証に失敗しました。再度ログインしてください'
    },
    validation: {
      required: '%{attribute}を入力してください。',
      selected: '%{attribute}を選択してください。',
      minLength: '%{attribute}は%{min}文字以上でご入力ください。',
      maxLength: '%{attribute}は%{max}文字以内でご入力ください。',
      equalLength: '%{attribute}は%{length}文字でご入力ください。',
      length: '%{attribute}は%{min}文字以上%{max}文字以内でご入力ください。',
      minValue: '%{attribute}は%{min}以上にしてください。',
      maxValue: '%{attribute}は%{max}以下にしてください。',
      equalValue: '%{attribute}は%{length}数字でご入力ください。',
      betweenValue: '%{attribute}は%{min}以上%{max}以下にしてください。',
      number: '%{attribute}は数字にしてください。',
      email: '%{attribute}は不正です。',
      oneOf: '次のいずれかである必要があります: %{options}',
      regex: '次の正規表現形式にする必要があります: %{pattern}',
      emojis: '%{attribute}は絵文字で入力しないでください。',
      confirmPassword:
        'パスワード確認はパスワードと一致している必要があります。',
      extension:
        'アップロード可能な%{attribute}の拡張子は「%{extension}」のみです。',
      size: 'アップロード可能な%{attribute}のサイズは%{maxSize}MBのみです。',
      duplication: '%{attribute}が重複しています。'
    },
    common: {
      forbidden: '利用権限がありません。',
      notFound: 'ページが見つかりませんできした。',
      fatalError: 'システムエラーが発生しました。管理者にご連絡ください。',
      detailModalTitle: 'エルディンガーヴァイスビア',
      daily: '日次',
      monthly: '月次',
      yearly: '年',
      previousDay: '前日',
      nextDay: '翌日',
      previousMonth: '前月',
      nextMonth: '翌月',
      previousYear: '前年',
      nextYear: '翌年',
      total: '合計',
      totalSale: '総売上',
      totalTax: '消費税',
      totalCost: '売上原価',
      totalNetSale: '粗利益',
      totalMargin: '粗利率',
      totalTransaction: '取引件数',
      totalQuantity: '商品点数',
      totalGrossSale: '総売上',
      averageUnitCost: '取引単価',
      timeZone: '時間帯',
      taxIncluded: '税込',
      taxExcluded: '税抜',
      compositionRatio: '構成比',
      discount: '値引き',
      pointUsage: 'ポイント利用',
      soldQuantity: '販売点数',
      returnQuantity: '返品数',
      totalCustomer: '客数',
      customerUnitPrice: '客単価',
      confirmDelete: '削除しますか %{resource} #%{id}',
      liveUpdate: 'ライブアップデート',
      dayOfWeek: '曜日',
      monday: '月曜日',
      tuesday: '火曜日',
      wednesday: '水曜日',
      thursday: '木曜日',
      friday: '金曜日',
      saturday: '土曜日',
      sunday: '日曜日',
      startDate: '日付から',
      endDate: '現在まで',
      startHour: '時間から',
      endHour: '時間に',
      searchById: 'IDで検索',
      resetFilter: 'フィルタをリセット',
      showErrorLogFromYesterday: '昨日からのエラーログのみを表示する',
      restrictedArea: '立入禁止区域. %{reason}.',
      requireShopAdminRole: '必要なショップアミンの役割'
    },
    sidebar: {
      'admin-setting': '管理者設定',
      product: '商品',
      'master-data-setting': 'システム機器',
      'log-confirmation': 'ログ確認',
      'sale-confirmation': '販売確認',
      statistics: '統計'
    },
    helpBlock: {
      limitCodeMax: '半角英数字のみ %{max}文字以内。',
      limitNoEmojisMax: '絵文字不可 %{max}文字以内。',
      limitAlphabetSymbolMinMax:
        '半角英数記号のみ（大文字小文字を必ず含む） %{min}文字以上 %{max}文字以内。',
      limitMax: '%{max}文字以内。',
      numberOnly: '半角数字のみ。',
      numberEqual: '%{max}半角数字。',
      numberMinMax: '%{min}以上 %{max}以下。',
      extension: 'jpg、jpg、pngの拡張子のみ。'
    }
  },
  resources: {
    processing: {
      name: '開店・閉店処理'
    },
    'realtime-sale-chart': {
      name: 'リアルタイム売上'
    },
    'sale-report-by-hour': {
      name: '時間帯別売上',
      pageTitle: '時間帯別売上集計'
    },
    'product-history': {
      name: '販売速報',
      pageTitle: '販売速報',
      ranking: '順位',
      productCode: '商品コード',
      beerName: 'ビール名',
      department: '部門',
      earning: '売上',
      goodsName: '商品名',
      beerAndGoodsName: 'ビール名/商品名',
      drinkAndGoodsCount: '杯数/数量',
    },
    'sale-history': {
      name: '販売集計',
      pageTitle: '販売履歴集計',
      targetPeriod: '対象期間',
      target: '対象',
      beerOnly: 'ビールのみ',
      productOnly: '商品のみ',
      both: '両方',
      loss: 'ロス',
      count_glass: '数量',
      quantity: '数量',
      cups: '杯数',
      grossProfit: '粗利',
      bySale: '売上別',
      byQuantity: '数量別',
      byGrossProfit: '粗利別',
      extraction: '抽出量(ml)',
      profit: '粗利'
    },
    'sale-history-with-filter': {
      name: '販売履歴一覧',
      pageTitle: '販売履歴一覧',
      code: '商品CD'
    },
    'tap-beer-server': {
      name: 'タップビールサーバー'
    },
    'sale-report-by-week-day': {
      name: '曜日別売上',
      pageTitle: '曜日別売上集計'
    },
    'sale-report-by-section': {
      name: '部門別売上',
      pageTitle: '部門別売上集計'
    },
    ...dashboardMessages,
    ...userMessages,
    ...companyMessages,
    ...shopMessages,
    ...goodMessages,
    ...tapBeerMessages,
    ...beerServerMessages,
    ...deviceMessages,
    ...saleLogMessages,
    ...operationLogMessages,
    ...errorLogMessages,
    ...eventLogMessages,
    ...groupMessages,
    ...sectionMessages,
    ...topUpMessages
  }
};

export default messages;
