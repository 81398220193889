const messages = {
  goods: {
    name: '一般商品一覧',
    createTitle: '商品作成',
    updateTitle: '商品編集',
    fields: {
      id: 'ID',
      goodsCode: '商品コード',
      janCode: 'JANコード',
      goodsName: '商品名',
      goodsShortName: '商品名短縮',
      purchaseCost: '会社原価',
      sellingPrice: '会社売価(税抜)',
      imageUrl: '商用画像(社)',
      company: {
        id: '所属会社',
        image: '商品画像',
        description: '商品説明',
        purchaseCost: '原価',
        sellingPrice: '販売価格（税抜）',
        companyName: '所属会社'
      },
      shop: {
        id: 'ID',
        image: '商品画像',
        description: '店舗商品説明',
        purchaseCost: '店舗設定原価',
        sellingPrice: '店舗設定販売価格（税抜）',
        handlingFlag: '取り扱い',
        soldOutFlag: '売り切れ'
      },
      goodsTypeObj: {
        beer: 'ビール',
        food: 'フード'
      },
      handlingFlagObj: {
        have: '有'
      },
      soldOutFlagObj: {
        soldOut: '売切'
      },
      taxReduction: '軽減税率',
      group: 'グループ',
      section: '部門',
      image: '商品画像'
    },
    filter: '絞り込み',
    validation: {
      goodsCode: {
        format: '商用コードは半角英数字でご入力ください。'
      },
      image: {
        error: '画像が変換できません。他の画像を洗濯してください。'
      }
    },
    searchFilterLabel: '絞込',
    goodsNameInKana: '商品名カナ'
  },
  'goods-shop': {
    name: '商品情報一覧',
    createTitle: 'ショップの商品を作成する',
    updateTitle: 'ショップの商品を編集する'
  }
};

export default messages;
