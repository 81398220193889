import {
  getSessionStorage,
  saveSessionStorage
} from 'containers/GlobalFilter/localStorageCaller';
import _ from 'lodash';
import { env } from 'environment';
import { httpClient } from 'network/httpClient';

export const getListData = async (dataProvider, resource, params?) => {
  const { data } = await dataProvider.getList(resource, {
    pagination: params?.pagination ?? { page: 1, perPage: 99 },
    sort: params?.sort ?? { field: 'id', order: 'DESC' },
    filter: params?.filter ?? {},
    exactFilters: {},
    fields: params?.fields
  });
  return data;
};

export const getListDataWithOption = async (
  dataProvider,
  resource,
  options?
) => {
  const { data } = await dataProvider.getList(resource, {
    pagination: options?.pagination ?? { page: 1, perPage: 99 },
    sort: options?.sort ?? { field: 'id', order: 'DESC' },
    filter: options?.filter ?? {},
    exactFilters: options?.exactFilters ?? {},
    fields: options?.params?.fields
  });
  return data;
};

export const getListDataByCompanyId = async (
  dataProvider,
  resource,
  params
) => {
  const opts = {
    pagination: params?.pagination ?? { page: 1, perPage: 99 },
    sort: params?.sort ?? { field: 'id', order: 'DESC' },
    filter: params?.filter ?? {},
    exactFilters: {},
    joins: [],
    fields: params.fields
  };
  if (params.companyId) {
    opts['companyId'] = params.companyId;
  }
  const { data } = await dataProvider.getList(resource, opts);
  return data;
};

export const getMultiListShopByCompanyIds = async (dataProvider, params) => {
  const results = await Promise.all(
    params.companyIds.map((companyId) =>
      getListDataByCompanyId(dataProvider, 'shops', {
        fields: params.fields,
        pagination: { page: 1, perPage: 99 },
        sort: { field: 'id', order: 'DESC' },
        companyId
      })
    )
  );

  let shopList = [];
  results.forEach((result) => {
    shopList = _.concat(shopList, result);
  });

  return shopList;
};

export const getReportData = async (url: string) => {
  const cachedKey = `cache|${url}`;
  const localStorageReportData = await getSessionStorage(cachedKey);
  if (localStorageReportData) {
    return { json: JSON.parse(localStorageReportData), error: null };
  }

  try {
    const { json } = await httpClient(url, {
      method: 'GET'
    });

    saveSessionStorage(cachedKey, json);
    return { json, error: null };
  } catch (error) {
    if (error.status === 500) {
      return { json: null, error: 'ra.common.fatalError' };
    }
    return { json: null, error: error.message };
  }
};

export const getData = async (url) => {
  const { data } = await httpClient(`${env.baseApi}/${url}`).then(
    ({ json }) => ({
      data: json
    })
  );
  return data;
};

export const postData = async (url, postData) => {
  const { data } = await httpClient(`${env.baseApi}/${url}`, {
    method: 'POST',
    body: JSON.stringify(postData)
  }).then(({ json }) => ({
    data: json
  }));
  return data;
};
