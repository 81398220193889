import { PATTERN_CODE } from 'containers/Common/patterns';

/**
 * [Get validation schema for creating BeerServer]
 * @return  {[Object]} schema
 */
const getCreateBeerServerValidationSchema = () => ({
  serverCode: { required: true, maxLength: 100, pattern: PATTERN_CODE },
  obnizId: { required: true, maxLength: 100},
  'company.id': { selected: true, number: true, minNumber: 1 },
  'shop.id': { selected: true, number: true, minNumber: 1 },
  startUsingAt: {required: true,}
});

/**
 * [Get validation schema for updating BeerServer]
 * @return  {[Object]} schema
 */
const getEditBeerServerValidationSchema = () => ({
  serverCode: { required: true, maxLength: 100, pattern: PATTERN_CODE },
  obnizId: { required: true, maxLength: 100},
  startUsingAt: {required: true,}
});

export {
  getCreateBeerServerValidationSchema,
  getEditBeerServerValidationSchema
};
