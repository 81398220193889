import produce from 'immer';
import { UPDATE_BEER_SERVER_STATUS } from '../Actions/beerServerSurvivalSignal';
import { BeerServerData } from '../interfaces';

const initialState: {
  beerServers: BeerServerData[];
} = {
  beerServers: []
};

const beerServerSurvivalSignalReducer = (
  state = initialState,
  { type, payload }
) =>
  produce(state, (draft) => {
    switch (type) {
      case UPDATE_BEER_SERVER_STATUS:
        draft.beerServers = payload;
        break;
    }
  });

export default beerServerSurvivalSignalReducer;
