import { SaleData, StatisticsData } from "../interfaces";

export const DATE_SELECTED = 'DATE_SELECTED';
export const INITIAL_STATISTIC_DATA = 'INITIAL_STATISTIC_DATA';

export interface SelectDatePayload {
  date: String;
}

export interface StatisticsDataPayload {
  salePerHour: SaleData[];
  statistics: StatisticsData;
}

export const selectDate = (payload: SelectDatePayload) => {
  return {
    type: DATE_SELECTED,
    payload: payload
  };
};

export const initData = (payload: StatisticsDataPayload) => {
  return {
    type: INITIAL_STATISTIC_DATA,
    payload: payload
  };
};
