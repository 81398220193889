const messages = {
  'operation-logs': {
    name: 'Operation Log List',
    fields: {
      startTime: 'Time',
      account: {
        loginName: 'Operator'
      },
      device: {
        hardwareCode: 'Device Code'
      },
      functionId: 'Function ID',
      operationType: 'Operation Type',
      operationStatus: '',
      status: 'Status',
      className: 'Class Name',
      method: 'Method',
      operationContent: 'Target entity',
      targetShop: {
        shopCode: 'Shop Code'
      },
      company: {
        companyCode: 'Company Code'
      },

      operationTypeObj: {
        login: 'Login',
        created: 'Created',
        updated: 'Updated',
        deleted: 'Deleted',
        imported: 'Imported'
      },
      statusObj: {
        success: 'Success',
        failed: 'Failed'
      }
    }
  }
};

export default messages;
