import { UserRole } from 'providers/auth.provider';
import { Option } from 'containers/App/types';

const availableAuthorityChoiceForSystemAdmin = (choice: Option): boolean => 
choice.id === UserRole.SYSTEM_ADMINISTRATOR ||
choice.id === UserRole.COMPANY_ADMINISTRATOR ||
choice.id === UserRole.SHOP ||
choice.id === UserRole.STAFF;
const availableAuthorityChoiceForCompanyAdmin = (choice: Option): boolean =>
  choice.id === UserRole.COMPANY_ADMINISTRATOR ||
  choice.id === UserRole.SHOP ||
  choice.id === UserRole.STAFF;

const availableAuthorityChoiceForShopAdmin = (choice: Option): boolean =>
  choice.id === UserRole.SHOP || choice.id === UserRole.STAFF;

const availableAuthorityFilters = {
  [UserRole.SYSTEM_ADMINISTRATOR] : availableAuthorityChoiceForSystemAdmin,
  [UserRole.COMPANY_ADMINISTRATOR] : availableAuthorityChoiceForCompanyAdmin,
  [UserRole.SHOP] : availableAuthorityChoiceForShopAdmin
}

export const filterAuthorityChoicesByRole = (
  userRole: UserRole,
  authorityChoices: Option[]
): Option[] => {
  return authorityChoices.filter(availableAuthorityFilters[userRole]);
};
