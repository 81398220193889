const PATTERN_EMAIL_REGEX = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PATTERN_TIME = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  PATTERN_LOGIN_ID = /^[a-zA-Z0-9-_.]+$/,
  PATTERN_PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
  PATTERN_CODE = /^[a-zA-Z0-9]+$/,
  PATTERN_IP_ADDRESS=/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export {
  PATTERN_EMAIL_REGEX,
  PATTERN_TIME,
  PATTERN_LOGIN_ID,
  PATTERN_PASSWORD,
  PATTERN_CODE,
  PATTERN_IP_ADDRESS
};
