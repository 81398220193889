/**
 * [Get validation schema for creating Section]
 * @return  {[Object]} schema
 */
const getCreateSectionValidationSchema = () => ({
  sectionCode: { required: true, maxLength: 30, emojis: true },
  sectionName: { required: true, maxLength: 100, emojis: true },
  taxReduction: { required: true },
  'group.id': { selected: true, number: true, minNumber: 1 }
});

/**
 * [Get validation schema for updating Section]
 * @return  {[Object]} schema
 */
const getEditSectionValidationSchema = () => ({
  sectionCode: { required: true, maxLength: 30, emojis: true },
  sectionName: { required: true, maxLength: 100, emojis: true },
  taxReduction: { required: true }
});

export { getCreateSectionValidationSchema, getEditSectionValidationSchema };
