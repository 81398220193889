import { InputAdornment } from '@material-ui/core';
import Loading from 'components/LoadingIndicator';
// Components
import TableForm from 'components/TableForm';
import CustomToolBar from 'components/Toolbar';
import { currency } from 'containers/App/constants';
import { AlertCommon } from 'containers/Common/alert';
import { RESOURCE_TAP_BEERS } from 'containers/Common/constants';
// Containers
import { handleSubmitFactory } from 'containers/Common/helper';
import l18n from 'containers/I18nProvider/index';
import { useSafeSetState } from 'ra-core';
import React from 'react';
import { TextInput, useNotify, useUpdate } from 'react-admin';
// Local
import { getShopTapBeerValidationSchema } from './validations';

const ShopTapBeerToolBar = (props) => (
  <CustomToolBar {...props} basePath={'/' + RESOURCE_TAP_BEERS} />
);

const EditForm = (props) => {
  const [state, setState] = useSafeSetState({
      open: false,
      message: '',
      type: '',
      loading: false
    }),
    record = props.record,
    resource = props.resource,
    [update] = useUpdate('tap-beers-shop', record.id),
    notify = useNotify(),
    onTransform = async (values) => {
      return {
        purchaseCost: values['purchaseCost'],
        sellingPrice: values['sellingPrice']
      };
    },
    handleSubmit = handleSubmitFactory({
      resource,
      buildValidationSchema: getShopTapBeerValidationSchema,
      setState,
      notify,
      redirectTo: () => {},
      // TODO Call api save info to tap_beer_shop by dataProvider
      onSubmit: update,
      onTransform,
      successMsgKey: 'updated'
    });

  return (
    <>
      {state.loading && <Loading />}

      <AlertCommon
        message={state.message}
        open={state.open}
        type={state.type}
        setState={setState}
      />

      <TableForm
        {...props}
        save={handleSubmit}
        toolbar={<ShopTapBeerToolBar />}
      >
        <span title={l18n.translate('resources.tap-beers.fields.id')} id="id">
          {record.id}
        </span>

        <TextInput
          title={l18n.translate(
            'resources.tap-beers.fields.shop.purchaseCostMl'
          )}
          source="purchaseCost"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
          data-cy="shopTapBeerPurchaseCostInput"
        />

        <TextInput
          title={l18n.translate(
            'resources.tap-beers.fields.shop.sellingPriceMl'
          )}
          source="sellingPrice"
          className="form-control"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currency.symbol}
              </InputAdornment>
            )
          }}
          min={0}
          helperText={l18n.translate('ra.helpBlock.numberOnly')}
          data-cy="shopTapBeerSellingPriceInput"
        />
      </TableForm>
    </>
  );
};

export default EditForm;
