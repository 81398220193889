import React from 'react';
import moment from 'moment';
import { DATE_FORMAT_DATE_AND_TIME } from 'containers/App/constants';
const DateField = ({ source, record = {} , format = DATE_FORMAT_DATE_AND_TIME, sortable = false }) => {
  const currentUtcOffset = moment().utcOffset();
  return (
    <span>
      {moment(record[source]).utcOffset(currentUtcOffset, true).format(format)}
    </span>
  );
};

export default DateField;
