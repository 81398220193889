import * as React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const BooleanField = ({ source, record = {}, label = '' }) => {
  if (record[source] === 1) {
    //@ts-ignore
    return <CheckCircleIcon color="secondary" label={label} />;
  }
  //@ts-ignore
  return <CancelIcon color="error" label={label} />;
};

BooleanField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export default BooleanField;
