const messages = {
    'error-logs': {
        name: 'エラーログ',
        fields : {
            occurredAt: '発生した時間'
        }
    }
};

export default messages;
