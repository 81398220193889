import React from 'react';
import { SvgIcon } from '@material-ui/core';

const TapBeerAnimation = ({ ...props }) => {
  let record = props.record,
    originalAmount = record.originalAmount,
    remainingAmount = record.remainingAmount,
    remainingPercent =
      originalAmount > 0 && remainingAmount > 0
        ? (remainingAmount / originalAmount) * 100
        : 0,
    remainingOffset = Math.round(remainingPercent) + '%',
    defaultStroke = 'black',
    linearId = record.beerServer?.id + '_' + record.tapBeer?.id,
    pathFill = 'url(#' + linearId + ')';

  if (remainingPercent < record.outStandardDisplay) {
    defaultStroke = 'red';
  }

  return (
    <>
      <SvgIcon viewBox="0 0 512 512">
        <g>
          <linearGradient id={linearId} x1="0.5" y1="1" x2="0.5" y2="0">
            <stop offset="0%" stopOpacity="1" stopColor="#e4d96f">
              <animate
                attributeName="stopColor"
                values="#d4af37; #a76d54; #9b111e;"
                dur="3s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset={remainingOffset} stopOpacity="1" stopColor="#e4d96f">
              <animate
                attributeName="stopColor"
                values="#d4af37; #a76d54; #9b111e;"
                dur="3s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset={remainingOffset} stopOpacity="0" stopColor="#e4d96f">
              <animate
                attributeName="stopColor"
                values="#d4af37; #a76d54; #9b111e;"
                dur="3s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="100%" stopOpacity="0" stopColor="#e4d96f">
              <animate
                attributeName="stopColor"
                values="#d4af37; #a76d54; #9b111e;"
                dur="3s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
          <path
            fill={pathFill}
            d="M469.282,256.772v-14.181c0-5.184-4.202-9.387-9.387-9.387s-9.387,4.203-9.387,9.387v13.141h-12.39
                    c-0.014-43.365-3.117-86.968-9.232-129.905h0.548c2.824,0,5.497-1.272,7.28-3.461c1.782-2.19,2.485-5.065,1.911-7.83
                    l-14.517-70.092c-0.902-4.358-4.741-7.484-9.192-7.484h-3.232c-2.482-10.115-5.137-20.218-7.937-30.126
                    C402.606,2.791,398.917,0,394.715,0H53.027c-4.204,0-7.897,2.795-9.036,6.843c-2.842,10.095-5.497,20.189-7.935,30.118h-3.234
                    c-4.451,0-8.29,3.125-9.192,7.484L9.113,114.537c-0.573,2.765,0.13,5.641,1.911,7.83c1.784,2.189,4.457,3.461,7.28,3.461H67.58
                    c-9.299,86.436-9.299,173.907,0,260.343h-29.72c-11.128-76.369-12.445-153.949-3.845-230.733
                    c0.577-5.153-3.132-9.797-8.283-10.375c-5.171-0.576-9.797,3.133-10.374,8.285c-8.678,77.473-7.471,155.74,3.527,232.823h-0.58
                    c-2.824,0-5.497,1.272-7.28,3.461c-1.782,2.19-2.484,5.065-1.911,7.83l14.517,70.092c0.902,4.358,4.741,7.484,9.192,7.484h3.234
                    c2.438,9.927,5.093,20.021,7.937,30.118c1.139,4.048,4.83,6.843,9.036,6.843h341.688c4.201,0,7.892-2.791,9.034-6.834
                    c2.801-9.912,5.456-20.015,7.937-30.127h3.232c4.451,0,8.29-3.125,9.192-7.484l14.517-70.092c0.573-2.765-0.13-5.641-1.911-7.83
                    c-1.784-2.189-4.457-3.461-7.28-3.461h-0.546c3.49-24.486,5.997-49.333,7.497-74.115h10.37v33.793
                    c0,5.184,4.202,9.387,9.387,9.387h37.548c5.186,0,9.387-4.203,9.387-9.387v-46.932
                    C503.079,278.329,488.594,261.072,469.282,256.772z M417.645,310.936c-1.524,25.168-4.12,50.398-7.735,75.233h-29.73
                    c8.622-80.274,9.252-161.74,1.821-242.323c-0.477-5.163-5.064-8.966-10.21-8.486c-5.163,0.476-8.962,5.047-8.486,10.21
                    c7.38,80.017,6.684,160.915-2.013,240.601h-72.22c3.546-86.433,3.546-173.911,0-260.343h120.847
                    c6.276,43.142,9.44,86.99,9.421,130.587c-12.584,2.81-22.022,14.06-22.022,27.479
                    C397.318,296.702,405.918,307.534,417.645,310.936z M387.583,18.775c1.647,6.018,3.232,12.096,4.76,18.185h-25.099
                    c-1.113-6.034-2.287-12.106-3.512-18.185H387.583z M344.571,18.775c1.245,6.072,2.438,12.145,3.572,18.185h-64.008
                    c-0.422-6.017-0.87-12.092-1.339-18.185H344.571z M183.775,18.775h80.191c0.472,6.092,0.921,12.165,1.347,18.185h-82.885
                    C182.853,30.953,183.302,24.88,183.775,18.775z M103.169,18.775h61.775c-0.471,6.108-0.919,12.182-1.339,18.185H99.597
                    C100.731,30.918,101.924,24.846,103.169,18.775z M60.164,18.775H84.01c-1.225,6.079-2.399,12.151-3.512,18.185H55.4
                    C56.913,30.938,58.498,24.867,60.164,18.775z M29.835,107.052l10.628-51.318h366.812l10.627,51.318H29.835z M158.664,386.17
                    H86.466c-9.445-86.425-9.445-173.917,0-260.343h72.214c-2.497,60.963-3.258,122.981-2.214,184.531
                    c0.086,5.13,4.272,9.228,9.382,9.228c0.055,0,0.109,0,0.163-0.001c5.184-0.088,9.315-4.362,9.227-9.545
                    c-1.041-61.447-0.277-123.364,2.233-184.213h92.809c3.568,86.429,3.568,173.914,0,260.343h-92.824
                    c-0.632-15.321-1.163-30.811-1.57-46.19c-0.136-5.183-4.461-9.255-9.631-9.136c-5.183,0.136-9.272,4.45-9.136,9.633
                    C157.521,355.689,158.044,371.009,158.664,386.17z M60.164,493.225c-1.666-6.094-3.251-12.165-4.764-18.188h25.098
                    c1.113,6.033,2.287,12.107,3.512,18.188H60.164z M263.966,493.225h-80.191c-0.473-6.107-0.924-12.181-1.348-18.188h82.886
                    C264.887,481.058,264.438,487.132,263.966,493.225z M344.572,493.225h-61.776c0.469-6.094,0.916-12.17,1.339-18.188h64.009
                    C347.011,481.078,345.818,487.151,344.572,493.225z M387.583,493.225h-23.851c1.225-6.081,2.399-12.154,3.512-18.188h25.099
                    C390.816,481.128,389.23,487.205,387.583,493.225z M407.275,456.263H153.262c-5.186,0-9.387,4.203-9.387,9.387
                    s4.202,9.387,9.387,9.387h10.344c0.422,6.003,0.87,12.08,1.341,18.188h-61.776c-1.245-6.074-2.438-12.146-3.572-18.188h22.373
                    c5.186,0,9.387-4.203,9.387-9.387s-4.202-9.387-9.387-9.387H40.463l-10.628-51.318h138.599c0.004,0,0.009,0,0.013,0
                    c0.01,0,0.019,0,0.028,0h201.224c0.008,0,0.014,0.001,0.021,0.001c0.009,0,0.018-0.001,0.026-0.001h48.157L407.275,456.263z
                    M484.304,336.46L484.304,336.46h-18.774v-33.793c0-5.184-4.202-9.387-9.387-9.387h-30.664c-5.176,0-9.386-4.211-9.386-9.386
                    c0-5.176,4.211-9.387,9.386-9.387h34.415c13.459,0,24.41,10.951,24.41,24.408V336.46z"
            stroke={defaultStroke}
            strokeWidth="5"
          />
        </g>
      </SvgIcon>
      <span className="beerInfo">
        <strong>
          {remainingAmount > record.outStandardDisplay ? (
            <span className="inline green">{remainingAmount}</span>
          ) : (
            <span className="inline red">{remainingAmount}</span>
          )}
        </strong>
          {` / ${originalAmount}`}

        <br />
        <i>({remainingOffset})</i>
      </span>
    </>
  );
};

export default TapBeerAnimation;
