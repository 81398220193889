const messages = {
  shops: {
    name: 'Shop List',
    createTitle: 'Create Shop',
    updateTitle: 'Update Shop',
    fields: {
      id: 'ID',
      shopName: 'Shop Name',
      shopPrintableName: 'Shop Name On Receipt',
      shopPostalCode: 'Shop Postal Code',
      shopAddress: 'Shop Address',
      shopPhone: 'Shop Phone',
      shopCode: 'Shop Code',
      paymentType: 'Payment Type',
      paymentMethod: 'Payment Method',
      beerOrderMode: 'Beer Order Mode',
      notificationTime: 'Notification Time',
      notificationStartTime: 'Notification Start Time',
      notificationEndTime: 'Notification End Time',
      notificationEmails: 'Notification Email',
      businessTime: 'Business Time',
      businessStartTime: 'Business Start Time',
      businessEndTime: 'Business End Time',
      company: {
        id: 'Company'
      },
      paymentObj: {
        immediate: 'Immediate',
        buffet: 'Buffet'
      },
      paymentMethodObj: {
        pointPlus: 'PointPlus'
      },
      beerOrderModeObj: {
        usage: 'Usage Quantity'
      },
      logo1: "Shop Logo 1",
      logo2: "Shop Logo 2",
      receiptHeader: 'Receipt Header',
      receiptFooter: 'Receipt Footer',
      receiptStamp: 'Receipt Stamp',
      beerPouringErrorCorrectionValue: 'Beer Pouring Error Correction Value'
    },
    validation: {
      shopCode: {
        format: 'Please use alphabet, number only for Shop Code.'
      },
      notificationStartTime: {
        format: 'Please use format HH:mm for Notification Start Time.'
      },
      notificationEndTime: {
        format: 'Please use format HH:mm for Notification End Time.'
      },
      businessStartTime: {
        format: 'Please use format HH:mm for Business Start Time.'
      },
      businessEndTime: {
        format: 'Please use format HH:mm for Business End Time.'
      }
    },
    helpBlock: {
      notificationEmails: 'Format Email. Split by , or ;'
    },
    openTime: 'Open Time',
    closeTime: 'Close Time',
    openShop: 'Open Shop',
    closeShop: 'Close Shop',
  }
};

export default messages;
