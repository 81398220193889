import l18n from 'containers/I18nProvider/index';
import { env } from 'environment';
import { httpClient } from 'network/httpClient';
import { AuthProvider, fetchUtils } from 'react-admin';
import * as Constants from 'containers/Common/constants';

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
export const CURRENT_COMPANY_ID_KEY = 'CURRENT_COMPANY_ID';
export const CURRENT_SHOP_ID_KEY = 'CURRENT_SHOP_ID';
export const REFESH_TOKEN_KEY = 'REFESH_TOKEN';

export enum UserRole {
  SYSTEM_ADMINISTRATOR = 1,
  COMPANY_ADMINISTRATOR,
  SHOP,
  STAFF
}

export interface User {
  id: string;
  loginName: string;
  loginId: string;
  authority: UserRole;
  authorityName: string;
  accessToken: string;
  refeshToken: string;
}

export const AuthorityNames = {
  [UserRole.SYSTEM_ADMINISTRATOR]: l18n.translate('ra.auth.authority_admin'),
  [UserRole.COMPANY_ADMINISTRATOR]: l18n.translate('ra.auth.authority_company'),
  [UserRole.SHOP]: l18n.translate('ra.auth.authority_shop_admin'),
  [UserRole.STAFF]: l18n.translate('ra.auth.authority_shop_staff')
};

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
}

export function getCurrentCompany() {
  const currentCompany = localStorage.getItem('currentCompany');
  return currentCompany
    ? JSON.parse(localStorage.getItem('currentCompany'))
    : null;
}

export function getCurrentCompanyId() {
  const currentCompany = localStorage.getItem('currentCompany');
  return currentCompany
    ? JSON.parse(localStorage.getItem('currentCompany')).id
    : null;
}

export function getCurrentAuthority() {
  const auth = localStorage.getItem('auth');
  return auth ? JSON.parse(localStorage.getItem('auth')).authority : null;
}

export function getCurrentShopId() {
  const currentShop = localStorage.getItem('currentShop');
  return !!currentShop
    ? JSON.parse(localStorage.getItem('currentShop')).id
    : null;
}

export function getRefeshToken() {
  return localStorage.getItem(REFESH_TOKEN_KEY);
}

export function storeTokens(accessToken: string, refeshToken: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFESH_TOKEN_KEY, refeshToken);
}

export async function refreshToken() {
  const token = getRefeshToken();
  const { json } = await fetchUtils.fetchJson(
    `${env.baseApi}/auth/refesh-token?token=${token}`,
    {
      method: 'GET'
    }
  );
  storeTokens(json.accessToken, json.refeshToken);
}

export const authProvider: AuthProvider = {
  login: async (credentials) => {
    const body = JSON.stringify(credentials);
    const method = 'POST';
    const { json } = await fetchUtils.fetchJson(`${env.baseApi}/auth/login`, {
      body,
      method
    });
    // Use to call other api
    storeTokens(json.accessToken, json.refeshToken);
  },

  logout: async () => {
    localStorage.clear();
    sessionStorage.clear();
  },

  checkError: (err) => {
    if (err.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      return Promise.reject('Token expired, please login again.');
    }
    if (err.status === 403) {
      return Promise.reject('Authorized Forbidden!');
    }
    return Promise.resolve();
  },

  checkAuth: async () => {
    const token = getAccessToken();
    if (!token) {
      return Promise.reject('Unauthorized');
    }
    return Promise.resolve();
  },

  getPermissions: async () => {
    // Get permission to check resource
    const auth = await authProvider.getIdentity();
    return Promise.resolve(auth.authority);
  },

  getIdentity: async () => {
    let { json } = await httpClient(`${env.baseApi}/auth/profile`);
    if (!json) return Promise.reject('Unauthorized');

    json.authorityName = AuthorityNames[json.authority];

    const localAuthInfo = JSON.stringify({
      id: json.id,
      loginId: json.loginId,
      loginName: json.loginName,
      authority: json.authority,
      authorityName: AuthorityNames[json.authority],
      company:
        json.company && json.company['id'] !== 'null' ? json.company : null,
      shopManagements: json.shopManagements
    });
    localStorage.setItem('auth', localAuthInfo);

    return Promise.resolve(json);
  },

  getAccountInfo: () => {
    // Because of calling api on app Resource
    // To avoid call api more times, need to get account info from localStorage for checking permission on base layout
    const auth = localStorage.getItem('auth');
    if (!auth) return Promise.reject('Unauthorized');

    return JSON.parse(auth);
  },

  checkPermissions: (permissions: UserRole, pathname = '/') => {
    const adminRoutes = [
        Constants.RESOURCE_COMPANIES,
        Constants.RESOURCE_OPERATION_LOGS,
        Constants.RESOURCE_ERROR_LOGS,
        Constants.RESOURCE_EVENT_LOGS
      ],
      pathParts = pathname.split('/'),
      rootPath = pathParts[1],
      childPath = pathParts[2],
      forbiddenStatus = { status: 403 };

    // In case of not admin system, can not access companies, operation-logs, groups & error logs page
    if (
      permissions !== UserRole.SYSTEM_ADMINISTRATOR &&
      adminRoutes.includes(rootPath)
    ) {
      return forbiddenStatus;
    }

    // Check permissions with role shop amdin
    if (permissions === UserRole.SHOP) {
      // In case of shop admin, can not access create goods and tap beers page
      if (
        (rootPath === Constants.RESOURCE_GOODS ||
          rootPath === Constants.RESOURCE_TAP_BEERS ||
          rootPath === Constants.RESOURCE_GROUP) &&
        childPath === 'create'
      ) {
        return forbiddenStatus;
      }
    }

    // Check permissions with role staff
    if (permissions === UserRole.STAFF) {
      // In case of staff, can not access create, update page
      if (
        (rootPath !== Constants.RESOURCE_AUTH_PROFILE && childPath) ||
        rootPath === Constants.RESOURCE_USERS
      ) {
        return forbiddenStatus;
      }
    }

    return { status: 200 };
  }
};
