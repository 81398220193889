import React from 'react';
import GeneralPagination from 'components/GeneralPagination';
import { List, Datagrid, TextField } from 'react-admin';
import DateField from 'components/DateField';
const MessageField = ({ source, record = {}, label = '' }) => (
  <p style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>
    {record[source]}
  </p>
);

const ErrorLogList = (props) => {
  return (
    <List
      {...props}
      actions={false}
      pagination={<GeneralPagination />}
      bulkActionButtons={false}
      className="table-responsive"
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="obnizID" />
        <MessageField source="message" />
        <DateField source="occurredAt" />
      </Datagrid>
    </List>
  );
};

export { ErrorLogList as List };
