const messages = {
  sections: {
    name: 'セクションリスト',
    createTitle: '部門作成',
    updateTitle: '部門の更新',
    fields: {
      id: 'ID',
      sectionCode: '部門コード',
      sectionName: '部門名',
      taxReduction: '軽減税率',
      group: {
        groupName: 'グループ名'
      }
    }
  }
};

export default messages;
