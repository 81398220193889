/*
 *
 * GlobalFilter reducer
 *
 */
import produce from 'immer';
import {
  SET_CURRENT_COMPANY_SUCCESS,
  // CURRENT_COMPANY_ERROR,
  AVAILABLE_COMPANIES_SUCCESS,
  LOAD_CURRENT_SHOP,
  CURRENT_SHOP_SUCCESS,
  // CURRENT_SHOP_ERROR,
  AVAILABLE_SHOPS_SUCCESS,
  HIDE_BREADCRUMB_SHOP,
  SHOW_BREADCRUMB_SHOP,
  CLEAR_GLOBAL_FILTER_STATE
} from './constants';

export const initialState = {
  company: undefined,
  shop: undefined,
  loadingCompany: false,
  loadingShop: false,
  availableCompanies: [],
  availableShops: [],
  isDisplayShopFilter: false
};

/* eslint-disable default-case, no-param-reassign */
const globalFilterReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_COMPANY_SUCCESS:
        draft.loadingCompany = false;
        draft.company = action.company;
        break;

      case AVAILABLE_COMPANIES_SUCCESS:
        draft.loadingCompany = false;
        draft.availableCompanies = action.availableCompanies;
        break;

      // case CURRENT_COMPANY_ERROR:
      //   draft.loadingCompany = false;
      //   break;

      case LOAD_CURRENT_SHOP:
        draft.loadingShop = true;
        break;

      case CURRENT_SHOP_SUCCESS:
        draft.loadingShop = false;
        draft.shop = action.shop;
        break;

      // case CURRENT_SHOP_ERROR:
      //   draft.loadingShop = false;
      //   break;

      case AVAILABLE_SHOPS_SUCCESS:
        draft.loadingShop = false;
        draft.availableShops = action.availableShops;
        break;

      case HIDE_BREADCRUMB_SHOP:
        draft.isDisplayShopFilter = false;
        break;

      case SHOW_BREADCRUMB_SHOP:
        draft.isDisplayShopFilter = true;
        break;

      case CLEAR_GLOBAL_FILTER_STATE:
        draft.company = undefined;
        draft.shop = undefined;
        draft.loadingCompany = false;
        draft.loadingShop = false;
        draft.availableCompanies = [];
        draft.availableShops = [];
        draft.isDisplayShopFilter = false;
        break;
    }
  });

export default globalFilterReducer;
