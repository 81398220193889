const messages = {
  devices: {
    name: 'Device List',
    createTitle: 'Create Device',
    updateTitle: 'Update Device',
    fields: {
      id: 'ID',
      hardwareCode: 'Tablet Code',
      hardwareName: 'Tablet Name',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      shop: {
        shopCode: 'Shop CD',
        shopName: 'shop Name'
      },
      company: {
        companyName: 'Company Name'
      },
      lockStatusObj: {
        have: 'Locked'
      },
      barrel: 'Beer Barrel',
      beerServer: {
        createTitle: 'Create Barrel',
        editTitle: 'Update Barrel',
        serverCode: 'Server Code',
        beerName: 'Beer Name',
        amount: 'Beer Amount(ml)',
        beerOutStandard: 'Beer Out Standard(Below ml)',
        status: 'Status',
        remainingAmount: 'Remaining Amount(ml)',
        remainingAmountPercent: 'Remaining Amount(%)'
      },
      barrelStatusObj: {
        maintain: 'Maintain',
        startSold: 'Start Sold'
      },
      barrelStatusDisplayObj: {
        maintain: 'Maintaining',
        startSold: 'Solding'
      },
      operation: 'Operation',
      isOnline : 'Is Online'
    },
    unlockDevice: 'Unlock Device',
    deviceWasLocked: 'Device was locked',
    deviceWasUnLocked: 'Device was unlocked'
  }
};

export default messages;
