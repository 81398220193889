import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';

const useStyles = makeStyles({
  thumbnail: {
    '& img': {
      margin: '0.5rem',
      height: '10rem',
      width: '10rem',
      objectFit: 'contain',
    },
  },
  pageWrap: {
    height: 'calc(80%)',
    paddingBottom: '50%',
    overflowY: 'scroll',
    '& .MuiFormControl-fullWidth': {
      width: '100%!important',
    },
  },
});

const datepickerTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#cccccc',
      main: '#313131',
      dark: '#111111',
      contrastText: '#fff'
    }
  }
});

const customDataTableStyles = {
  rows: {
    style: {
      borderRightStyle: 'solid',
      borderRightWidth: '0.5px',
      borderRightColor: '#dddddd'
    }
  },
  cells: {
    style: {
      borderLeftStyle: 'solid',
      borderLeftWidth: '0.5px',
      borderLeftColor: '#dddddd'
    }
  },
  headCells: {
    style: {
      backgroundColor: '#555555',
      color: 'white'
    },
    activeSortStyle: {
      color: 'white',
      '&:focus': {
        outline: 'none'
      },
      '&:hover:not(:focus)': {
        color: 'white'
      }
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: 'white'
      },
      '&:hover': {
        color: 'white'
      }
    }
  }
};

export { useStyles, datepickerTheme, customDataTableStyles };
