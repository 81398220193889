import React, { useEffect } from 'react';
import { Button, ReduxState, useSafeSetState, useNotify } from 'react-admin';
import { CSVLink } from 'react-csv';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import l18n from 'containers/I18nProvider/index';
import ExportIcon from '@material-ui/icons/CloudDownload';
import { Paper, Grid } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { currency, DATE_FORMAT_DATE_ONLY } from 'containers/App/constants';
import { datepickerTheme } from 'containers/Common/styles';
import { ThemeProvider } from '@material-ui/styles';
import { getReportData } from 'containers/Common/api';
import Loading from 'components/LoadingIndicator';
import { env } from 'environment';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { ExtractedSaleData } from 'containers/Common/interfaces';
import {
  buildChartData,
  renderPrice,
  sumValueAtColumnIndex
} from 'containers/Common/helper';

const SaleReportBySection = (props) => {
  document.title = document.title =
    process.env.REACT_APP_WEBSITE_NAME +
    ' - ' +
    l18n.translate('resources.sale-report-by-section.pageTitle');
  const [startDate, setStartDate] = useSafeSetState(moment()),
    [endDate, setEndDate] = useSafeSetState(moment()),
    { loadingCompany, loadingShop, shop } = useSelector(
      (state: ReduxState) => state.globalFilterReducer
    ),
    [chartData, setChartData] = useSafeSetState({
      labels: [],
      datasets: [
        {
          //   label: 'My First dataset',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    }),
    // @ts-ignore
    [saleData, setSaleData] = useSafeSetState([]),
    extractChartData = (rawSaleData): ExtractedSaleData => {
      if (rawSaleData?.length === 0) {
        return {
          labels: [],
          data: []
        };
      }

      let labels = [],
        data = [];

      for (let index = 0; index < rawSaleData?.length; index++) {
        const rawSaleDataSale = rawSaleData[index];
        labels.push(rawSaleDataSale.section_name);
        data.push(parseFloat(rawSaleDataSale.sale));
      }

      return {
        labels: labels,
        data: data
      };
    },
    notify = useNotify();

  useEffect(() => {
    (async function fetchData() {
      const transformChartData = (rawSaleData) => {
        const { labels, data: chartData } = extractChartData(
          rawSaleData?.saleBySections
        );
        return buildChartData(labels, chartData);
      };
      const transformSaleData = (rawSaleData): any[] => {
        return rawSaleData?.map((saleData) => [
          saleData.section_name,
          parseFloat(saleData.sale).toFixed(2),
          (parseFloat(saleData.sale) - parseFloat(saleData.tax)).toFixed(2),
          parseFloat(saleData.tax).toFixed(2),
          '-',
          parseFloat(saleData.profit).toFixed(2),
          '-',
          '-',
          parseFloat(saleData.count_item).toFixed(2),
          parseFloat(saleData.count_transaction).toFixed(2),
          (
            parseFloat(saleData.purchaseCost) / parseFloat(saleData.count_item)
          ).toFixed(2),
          (saleData.totalCustomer?parseFloat(saleData.totalCustomer):0),
          (saleData.customerUnitPrice?parseFloat(saleData.customerUnitPrice):0),
          (saleData.paymentMethod_1?parseFloat(saleData.paymentMethod_1).toFixed(2):0),
          (saleData.paymentMethod_2?parseFloat(saleData.paymentMethod_2).toFixed(2):0),
          (saleData.paymentMethod_3?parseFloat(saleData.paymentMethod_3).toFixed(2):0),
          (saleData.paymentMethod_4?parseFloat(saleData.paymentMethod_4).toFixed(2):0),
          (saleData.paymentMethod_5?parseFloat(saleData.paymentMethod_5).toFixed(2):0),
          (saleData.paymentMethod_6?parseFloat(saleData.paymentMethod_6).toFixed(2):0)
        ]);
      };
      const url = `${env.baseApi}/sale-logs/by-section?${stringify({
        shop_id: shop?.id,
        startDate: moment(startDate).format('YYYYMMDD'),
        endDate: moment(endDate).format('YYYYMMDD')
      })}`;

      const { json, error } = await getReportData(url);
      if (error) {
        notify(error);
      } else {
        setSaleData(transformSaleData(json?.saleBySections));
        const chartData = transformChartData(json);
        //   @ts-ignore
        setChartData(chartData);
      }
    })();
  }, [startDate, endDate, shop?.id, setChartData, setSaleData, notify]);

  if (loadingCompany || loadingShop) return <Loading />;

  const csvFileName = `Sale_Report_By_Section_Of_Shop_#${
    shop?.id
  }_From_${startDate.format(DATE_FORMAT_DATE_ONLY)}_To_${endDate.format(
    DATE_FORMAT_DATE_ONLY
  )}.csv`;
  let csvData = [
    [
      l18n.translate('ra.common.timeZone'),
      l18n.translate('ra.common.totalSale'),
      l18n.translate('ra.common.totalSale') +
        ' ' +
        l18n.translate('ra.common.taxExcluded'),
      l18n.translate('ra.common.totalTax'),
      l18n.translate('ra.common.compositionRatio'),
      l18n.translate('ra.common.totalNetSale'),
      l18n.translate('ra.common.discount'),
      l18n.translate('ra.common.pointUsage'),
      l18n.translate('ra.common.soldQuantity') +
        ' ' +
        l18n.translate('ra.common.returnQuantity'),
      l18n.translate('ra.common.totalTransaction'),
      l18n.translate('ra.common.averageUnitCost'),
      l18n.translate('ra.common.totalCustomer'),
      l18n.translate('ra.common.customerUnitPrice')
    ],
    ...saleData
  ];

  csvData.push([
    l18n.translate('ra.common.total'),
    sumValueAtColumnIndex(saleData, 1),
    sumValueAtColumnIndex(saleData, 2),
    sumValueAtColumnIndex(saleData, 3),
    '-',
    sumValueAtColumnIndex(saleData, 5),
    '-',
    '-',
    sumValueAtColumnIndex(saleData, 8),
    sumValueAtColumnIndex(saleData, 9),
    sumValueAtColumnIndex(saleData, 10),
    sumValueAtColumnIndex(saleData, 11),
    sumValueAtColumnIndex(saleData, 12)
  ]);

  return (
    <div style={{ padding: '1rem' }}>
      <Paper style={{ padding: '1rem' }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {l18n.translate('resources.sale-history.targetPeriod')}：
            <ThemeProvider theme={datepickerTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="From"
                  format={DATE_FORMAT_DATE_ONLY}
                  value={startDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => setStartDate(date)}
                  size="small"
                  data-cy="startDateButton"
                />

                <span
                  style={{ display: 'inline-block', padding: '0.7rem' }}
                >{` ~ `}</span>

                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="To"
                  format={DATE_FORMAT_DATE_ONLY}
                  value={endDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => setEndDate(date)}
                  size="small"
                  data-cy="endDateButton"
                />

                <Button
                  label={l18n.translate('ra.action.search')}
                  variant="contained"
                  style={{ margin: '0.2em 0.8em' }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <CSVLink
              data={csvData}
              filename={csvFileName}
              data-cy="downloadSaleReportBySection"
            >
              <Button
                label={l18n.translate('ra.action.download')}
                startIcon={<ExportIcon />}
                variant="outlined"
                style={{ float: 'right' }}
              />
            </CSVLink>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '1rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                    style={{
                      backgroundColor: '#555555',
                      border: '0.5px solid #dddddd',
                      color: '#ffffff'
                    }}
                  >
                    {l18n.translate('ra.common.averageUnitCost')}
                  </TableCell>
                  <TableCell
                    className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                    style={{
                      textAlign: 'right',
                      border: '0.5px solid #dddddd'
                    }}
                  >
                    {renderPrice(sumValueAtColumnIndex(saleData, 10))}
                  </TableCell>
                  <TableCell
                    className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                    style={{
                      backgroundColor: '#555555',
                      border: '0.5px solid #dddddd',
                      color: '#ffffff'
                    }}
                  >
                    {l18n.translate('ra.common.totalGrossSale')}
                  </TableCell>
                  <TableCell
                    className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                    style={{
                      textAlign: 'right',
                      border: '0.5px solid #dddddd'
                    }}
                  >
                    {renderPrice(sumValueAtColumnIndex(saleData, 1))}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '1rem' }}>
          <div data-cy="realtimeSaleLineChart">
            <Bar
              // @ts-ignore: load data from state
              data={chartData}
              options={{
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      return `${currency.symbol} ${parseInt(
                        tooltipItem.value,
                        10
                      ).toLocaleString('ja-JP')}`;
                    }
                  }
                },
                legend: { display: false },
                animation: {
                  easing: 'linear'
                  // duration: 0.1 // general animation time
                },
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        callback: function (value) {
                          return `${currency.symbol} ${value.toLocaleString(
                            'ja-JP'
                          )}`;
                        }
                      }
                    }
                  ],
                  xAxes: [
                    {
                      offset: true
                    }
                  ]
                }
              }}
              height={200}
            />
          </div>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '1rem' }}>
          <Table>
            <TableHead className="borderTableHead">
              <TableRow>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.timeZone')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalSale')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalSale')}
                  <br />({l18n.translate('ra.common.taxExcluded')})
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalTax')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.compositionRatio')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  colSpan={3}
                  style={{ textAlign: 'center' }}
                >
                  {l18n.translate('ra.common.averageUnitCost')}
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.soldQuantity')}
                  <br />({l18n.translate('ra.common.returnQuantity')})
                </TableCell>

                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalTransaction')}
                </TableCell>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.averageUnitCost')}
                </TableCell>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.totalCustomer')}
                </TableCell>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall short-column"
                  rowSpan={2}
                >
                  {l18n.translate('ra.common.customerUnitPrice')}
                </TableCell>
                <TableCell
                  className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"
                  colSpan={6}
                  style={{ textAlign: 'center' }}
                >
                  {"会計種別"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {l18n.translate('ra.common.totalNetSale')}
                </TableCell>
                <TableCell>{l18n.translate('ra.common.discount')}</TableCell>
                <TableCell>{l18n.translate('ra.common.pointUsage')}</TableCell>
                <TableCell>{"現金"}</TableCell>
                <TableCell>{"ハウスマネー"}</TableCell>
                <TableCell>{"電子マネー"}</TableCell>
                <TableCell>{"クレジットカード"}</TableCell>
                <TableCell>{"QR決済"}</TableCell>
                <TableCell>{"その他"}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="borderTableBody">
              {saleData?.map((data) => (
                <TableRow key={data[0]}>
                  <TableCell>{data[0]}</TableCell>
                  <TableCell>{renderPrice(data[1])}</TableCell>
                  <TableCell>{renderPrice(data[2])}</TableCell>
                  <TableCell>{renderPrice(data[3])}</TableCell>
                  <TableCell>{renderPrice(data[4], '')}</TableCell>
                  <TableCell>{renderPrice(data[5])}</TableCell>
                  <TableCell>{renderPrice(data[6], '')}</TableCell>
                  <TableCell>{renderPrice(data[7], '')}</TableCell>
                  <TableCell>{renderPrice(data[8], '')}</TableCell>
                  <TableCell>{renderPrice(data[9], '')}</TableCell>
                  <TableCell>{renderPrice(data[10])}</TableCell>
                  <TableCell>{renderPrice(data[11], '')}</TableCell>
                  <TableCell>{renderPrice(data[12])}</TableCell>
                  <TableCell>{renderPrice(data[13])}</TableCell>
                  <TableCell>{renderPrice(data[14])}</TableCell>
                  <TableCell>{renderPrice(data[15])}</TableCell>
                  <TableCell>{renderPrice(data[16])}</TableCell>
                  <TableCell>{renderPrice(data[17])}</TableCell>
                  <TableCell>{renderPrice(data[18])}</TableCell>
                </TableRow>
              ))}

              <TableRow key="summary" className="dataTableFooter">
                <TableCell>
                  <strong>{l18n.translate('ra.common.total')}</strong>
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 1))}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 2))}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 3))}
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 5))}
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 8), '')}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 9), '')}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 10))}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 11), '')}
                </TableCell>
                <TableCell>
                  {renderPrice(sumValueAtColumnIndex(saleData, 12))}
                </TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 13))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 14))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 15))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 16))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 17))}</TableCell>
                <TableCell>{renderPrice(sumValueAtColumnIndex(saleData, 18))}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Paper>
    </div>
  );
};

export default SaleReportBySection;
