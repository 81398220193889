import React from 'react';
import l18n from 'containers/I18nProvider/index';
import {
  ReferenceField,
  TextField,
  useDataProvider,
  useSafeSetState
} from 'react-admin';
import {
  RESOURCE_GOODS,
  RESOURCE_TAP_BEERS
} from 'containers/Common/constants';
import { getData } from 'containers/Common/api';
import moment from 'moment';

export const renderReferenceGoodsCode = (basePath, goods_id) => (
  <ReferenceField
    source="goodsId"
    reference={RESOURCE_GOODS}
    basePath={basePath}
    link={false}
    record={{ id: `goodsCode_${goods_id}`, goodsId: goods_id }}
  >
    <TextField source="goodsCode" />
  </ReferenceField>
);

export const renderReferenceGoodsName = (basePath, goods_id) => (
  <ReferenceField
    source="goodsId"
    reference={RESOURCE_GOODS}
    basePath={basePath}
    link={false}
    record={{ id: `goodsName_${goods_id}`, goodsId: goods_id }}
  >
    <TextField source="goodsName" />
  </ReferenceField>
);

export const renderReferenceTapBeerCode = (basePath, tap_beer_id) => (
  <ReferenceField
    source="tapBeerId"
    reference={RESOURCE_TAP_BEERS}
    basePath={basePath}
    link={false}
    record={{
      id: `tapbeerCode_${tap_beer_id}`,
      tapBeerId: tap_beer_id
    }}
  >
    <TextField source="beerCode" />
  </ReferenceField>
);

export const renderReferenceTapBeerName = (basePath, tap_beer_id) => (
  <ReferenceField
    source="tapBeerId"
    reference={RESOURCE_TAP_BEERS}
    basePath={basePath}
    link={false}
    record={{
      id: `tapbeerName_${tap_beer_id}`,
      tapBeerId: tap_beer_id
    }}
  >
    <TextField source="beerName" />
  </ReferenceField>
);

export const LossBeer = (tapBeerId, startDate, endDate) => {
  let [lossBeer, setLossBeer] = useSafeSetState('');
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    const updateShopChoices = async () => {
      const {
        data: { amount }
      } = await getData(
        'loss/by-tap-beer?tapBeerId=' +
          tapBeerId +
          '&startDate=' +
          moment(startDate).format('YYYYMMDD') +
          '&endDate=' +
          moment(endDate).format('YYYYMMDD')
      );
      setLossBeer(amount);
    };
    updateShopChoices();
  }, [dataProvider, endDate, setLossBeer, startDate, tapBeerId]);

  return lossBeer;
};

const RANKING_COLUMN = [
  {
    name: l18n.translate('resources.product-history.ranking'),
    format: (row, index) => `${index + 1}`,
    selector: 'tap_beer_id',
    width: '70px'
  }
];

// ------ BEER ONLY ------

export const PROFIT_BEER_ONLY_COLUMNS = (basePath, startDate, endDate) => [
  ...RANKING_COLUMN,
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.tap_beer_id
        ? renderReferenceTapBeerCode(basePath, row.tap_beer_id)
        : '',
    width: '150px'
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.product-history.beerName'),
    format: (row) =>
      row.tap_beer_id
        ? renderReferenceTapBeerName(basePath, row.tap_beer_id)
        : ''
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.sale-history.loss'),
    format: (row) =>
      row.tap_beer_id ? LossBeer(row.tap_beer_id, startDate, endDate) : '',
    type: 'number',
    center: true,
    width: '120px'
  },
  {
    selector: 'count_glass',
    name: l18n.translate('resources.sale-history.cups'),
    type: 'number',
    sortable: true,
    format: (row) => row.count_glass?.toLocaleString('ja-JP'),
    center: true,
    width: '120px'
  },
  {
    selector: 'profit',
    name: l18n.translate('resources.sale-history.profit'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.profit).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

export const COUNT_ITEM_BEER_ONLY_COLUMNS = (basePath, startDate, endDate) => [
  ...RANKING_COLUMN,
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.tap_beer_id
        ? renderReferenceTapBeerCode(basePath, row.tap_beer_id)
        : '',
    width: '150px'
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.product-history.beerName'),
    format: (row) =>
      row.tap_beer_id
        ? renderReferenceTapBeerName(basePath, row.tap_beer_id)
        : ''
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.sale-history.loss'),
    format: (row) =>
      row.tap_beer_id ? LossBeer(row.tap_beer_id, startDate, endDate) : '',
    type: 'number',
    center: true,
    width: '120px'
  },
  {
    selector: 'count_glass',
    name: l18n.translate('resources.sale-history.cups'),
    type: 'number',
    sortable: true,
    format: (row) => row.count_glass?.toLocaleString('ja-JP'),
    center: true,
    width: '120px'
  },
  {
    selector: 'count_item',
    name: l18n.translate('resources.sale-history.extraction'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.count_item).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

export const SUBTOTAL_BEER_ONLY_COLUMNS = (basePath, startDate, endDate) => [
  ...RANKING_COLUMN,
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.tap_beer_id
        ? renderReferenceTapBeerCode(basePath, row.tap_beer_id)
        : '',
    width: '150px'
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.product-history.beerName'),
    format: (row) =>
      row.tap_beer_id
        ? renderReferenceTapBeerName(basePath, row.tap_beer_id)
        : ''
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.sale-history.loss'),
    format: (row) =>
      row.tap_beer_id ? LossBeer(row.tap_beer_id, startDate, endDate) : '',
    type: 'number',
    center: true,
    width: '120px'
  },
  {
    selector: 'count_glass',
    name: l18n.translate('resources.sale-history.cups'),
    type: 'number',
    sortable: true,
    format: (row) => row.count_glass?.toLocaleString('ja-JP'),
    center: true,
    width: '120px'
  },
  {
    selector: 'sub_total',
    name: l18n.translate('resources.product-history.earning'),
    type: 'number',
    format: (row) => Math.floor(row.sub_total).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

// ------ GOODS ONLY ------

export const PROFIT_GOODS_ONLY_COLUMNS = (basePath) => [
  ...RANKING_COLUMN,
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.goods_id ? renderReferenceGoodsCode(basePath, row.goods_id) : ''
  },
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.goodsName'),
    format: (row) =>
      row.goods_id ? renderReferenceGoodsName(basePath, row.goods_id) : ''
  },
  {
    selector: 'profit',
    name: l18n.translate('resources.sale-history.profit'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.profit).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

export const COUNT_ITEM_GOODS_ONLY_COLUMNS = (basePath) => [
  ...RANKING_COLUMN,
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.goods_id ? renderReferenceGoodsCode(basePath, row.goods_id) : ''
  },
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.goodsName'),
    format: (row) =>
      row.goods_id ? renderReferenceGoodsName(basePath, row.goods_id) : ''
  },
  {
    selector: 'count_item',
    name: l18n.translate('resources.sale-history.quantity'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.count_item).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

export const SUBTOTAL_GOODS_ONLY_COLUMNS = (basePath) => [
  ...RANKING_COLUMN,
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.goods_id ? renderReferenceGoodsCode(basePath, row.goods_id) : ''
  },
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.goodsName'),
    format: (row) =>
      row.goods_id ? renderReferenceGoodsName(basePath, row.goods_id) : ''
  },
  {
    selector: 'sub_total',
    name: l18n.translate('resources.product-history.earning'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.sub_total).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

// ------ BEER AND GOODS ------

export const PROFIT_BEER_AND_GOODS_COLUMNS = (basePath, startDate, endDate) => [
  ...RANKING_COLUMN,
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.goods_id
        ? renderReferenceGoodsCode(basePath, row.goods_id)
        : renderReferenceTapBeerCode(basePath, row.tap_beer_id),
    width: '150px'
  },
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.beerAndGoodsName'),
    format: (row) =>
      row.goods_id
        ? renderReferenceGoodsName(basePath, row.goods_id)
        : renderReferenceTapBeerName(basePath, row.tap_beer_id)
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.sale-history.loss'),
    format: (row) =>
      row.tap_beer_id ? LossBeer(row.tap_beer_id, startDate, endDate) : '',
    type: 'number',
    center: true,
    width: '120px'
  },
  {
    selector: 'count_glass',
    name: l18n.translate('resources.product-history.drinkAndGoodsCount'),
    type: 'number',
    sortable: true,
    format: (row) => row.count_glass?.toLocaleString('ja-JP'),
    center: true,
    width: '120px'
  },
  {
    selector: 'profit',
    name: l18n.translate('resources.sale-history.profit'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.profit).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

export const COUNT_ITEM_BEER_AND_GOODS_COLUMNS = (
  basePath,
  startDate,
  endDate
) => [
  ...RANKING_COLUMN,
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.goods_id
        ? renderReferenceGoodsCode(basePath, row.goods_id)
        : renderReferenceTapBeerCode(basePath, row.tap_beer_id),
    width: '150px'
  },
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.beerAndGoodsName'),
    format: (row) =>
      row.goods_id
        ? renderReferenceGoodsName(basePath, row.goods_id)
        : renderReferenceTapBeerName(basePath, row.tap_beer_id)
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.sale-history.loss'),
    format: (row) =>
      row.tap_beer_id ? LossBeer(row.tap_beer_id, startDate, endDate) : '',
    type: 'number',
    center: true,
    width: '120px'
  },
  {
    selector: 'count_glass',
    name: l18n.translate('resources.product-history.drinkAndGoodsCount'),
    type: 'number',
    sortable: true,
    format: (row) => row.count_glass?.toLocaleString('ja-JP'),
    center: true,
    width: '120px'
  },
  {
    selector: 'count_item',
    name: l18n.translate('resources.sale-history.extraction'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.count_item).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];

export const SUBTOTAL_BEER_AND_GOODS_COLUMNS = (
  basePath,
  startDate,
  endDate
) => [
  ...RANKING_COLUMN,
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.productCode'),
    format: (row) =>
      row.goods_id
        ? renderReferenceGoodsCode(basePath, row.goods_id)
        : renderReferenceTapBeerCode(basePath, row.tap_beer_id),
    width: '150px'
  },
  {
    selector: 'goods_id',
    name: l18n.translate('resources.product-history.beerAndGoodsName'),
    format: (row) =>
      row.goods_id
        ? renderReferenceGoodsName(basePath, row.goods_id)
        : renderReferenceTapBeerName(basePath, row.tap_beer_id)
  },
  {
    selector: 'tap_beer_id',
    name: l18n.translate('resources.sale-history.loss'),
    format: (row) =>
      row.tap_beer_id ? LossBeer(row.tap_beer_id, startDate, endDate) : '',
    type: 'number',
    center: true,
    width: '120px'
  },
  {
    selector: 'count_glass',
    name: l18n.translate('resources.product-history.drinkAndGoodsCount'),
    type: 'number',
    sortable: true,
    format: (row) => row.count_glass?.toLocaleString('ja-JP'),
    center: true,
    width: '120px'
  },
  {
    selector: 'sub_total',
    name: l18n.translate('resources.product-history.earning'),
    type: 'number',
    sortable: true,
    format: (row) => Math.floor(row.sub_total).toLocaleString('ja-JP'),
    right: true,
    width: '150px'
  }
];
