import React from 'react';
import { Option } from 'containers/App/types';
import l18n from 'containers/I18nProvider/index';

const scope: string = 'resources.tap-beers.fields';

interface ColourOption {
  id: string;
  name: any;
}

export const tasteValues: Option[] = [
  {
    id: 1,
    name: '1'
  },
  {
    id: 2,
    name: '2'
  },
  {
    id: 3,
    name: '3'
  },
  {
    id: 4,
    name: '4'
  },
  {
    id: 5,
    name: '5'
  }
];

export const colourValues: ColourOption[] = [
  {
    id: 'red',
    name: (
      <span className="color-option red">
        {l18n.translate(`${scope}.colourOptions.red`)}
      </span>
    )
  },
  {
    id: 'yellow',
    name: (
      <span className="color-option yellow">
        {l18n.translate(`${scope}.colourOptions.yellow`)}
      </span>
    )
  },
  {
    id: 'white',
    name: (
      <span className="color-option white">
        {l18n.translate(`${scope}.colourOptions.white`)}
      </span>
    )
  },
  {
    id: 'black',
    name: (
      <span className="color-option black">
        {l18n.translate(`${scope}.colourOptions.black`)}
      </span>
    )
  },
  {
    id: 'orange',
    name: (
      <span className="color-option orange">
        {l18n.translate(`${scope}.colourOptions.orange`)}
      </span>
    )
  },
  {
    id: 'sour',
    name: (
      <span className="color-option sour">
        {l18n.translate(`${scope}.colourOptions.sour`)}
      </span>
    )
  },
  {
    id: 'highball',
    name: (
      <span className="color-option highball">
        {l18n.translate(`${scope}.colourOptions.highball`)}
      </span>
    )
  },
];
